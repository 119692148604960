import * as MediaService from '../../service/media/Media.service';
import {
  createPromiseThunk,
  reducerUtils,
  handleAsyncActions,
  createPromiseThunkById,
  handleAsyncActionsById
} from '../../helpers/asyncHelper';
import { getPagingData } from '../../helpers/apiHelper';

// /* 액션 타입 */
const SET_IS_MORE = 'youth_bbs/SET_IS_MORE';
const SET_NEWS_PARAMS = 'youth_bbs/SET_NEWS_PARAMS';
const SET_NEWS_PAGING_DATA = 'youth_bbs/SET_NEWS_PAGING_DATA';
const GET_NEWS_LIST = 'youth_bbs/GET_NEWS_LIST';
const GET_NEWS_LIST_SUCCESS = 'youth_bbs/GET_NEWS_LIST_SUCCESS'; // 요청 성공
const GET_NEWS_LIST_ERROR = 'youth_bbs/GET_EASY_LIST_ERROR'; // 요청 실패

const SET_PHOTO_PARAMS = 'youth_bbs/SET_PHOTO_PARAMS';
const SET_PHOTO_PAGING_DATA = 'youth_bbs/SET_PHOTO_PAGING_DATA';
const GET_PHOTO_LIST = 'youth_bbs/GET_PHOTO_LIST';
const GET_PHOTO_LIST_SUCCESS = 'youth_bbs/GET_PHOTO_LIST_SUCCESS'; // 요청 성공
const GET_PHOTO_LIST_ERROR = 'youth_bbs/GET_FAKE_FOUL_NEWS_LIST_ERROR'; // 요청 실패

const GET_INFO = 'youth_bbs/GET_INFO';
const GET_INFO_SUCCESS = 'youth_bbs/GET_INFO_SUCCESS';
const GET_INFO_ERROR = 'youth_bbs/GET_INFO_ERROR';

export const getNewsList = createPromiseThunk(GET_NEWS_LIST, MediaService.getList, SET_NEWS_PAGING_DATA);
export const getPhotoList = createPromiseThunk(GET_PHOTO_LIST, MediaService.getList, SET_PHOTO_PAGING_DATA);

export const setNewsParams = params => {
  return dispatch => {
    dispatch({ type: SET_NEWS_PARAMS, params });
    dispatch(getNewsList(params))
  }
}

export const setPhotoParams = params => {
  return dispatch => {
    dispatch({ type: SET_PHOTO_PARAMS, params });
    dispatch(getPhotoList(params))
  }
}

export const setIsMore = value => ({ type: SET_IS_MORE, isMore: value });
export const getInfo = createPromiseThunkById(GET_INFO, MediaService.getInfo);
const initialState = {
  newsParams: {},
  photoParams: {},
  newsList: reducerUtils.initial(),
  photoList: reducerUtils.initial(),
  newsPagingData: {},
  photoPagingData: {},
  newsPageSize: 10,
  photoPageSize: 16,
  isMore: false,
  info: {},
};


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_NEWS_PARAMS:
      return {
        ...state,
        newsParams: action.params
      };
    case GET_NEWS_LIST:
    case GET_NEWS_LIST_SUCCESS:
    case GET_NEWS_LIST_ERROR:
      return handleAsyncActions(GET_NEWS_LIST, 'newsList')(state, action);
    case SET_NEWS_PAGING_DATA:
      return {
        ...state,
        newsPagingData: getPagingData(state.newsParams.start, action.pageInfo, state.newsPageSize)
      }
    case SET_PHOTO_PARAMS:
      return {
        ...state,
        photoParams: action.params
      };
    case GET_PHOTO_LIST:
    case GET_PHOTO_LIST_SUCCESS:
    case GET_PHOTO_LIST_ERROR:
      return handleAsyncActions(GET_PHOTO_LIST, 'photoList')(state, action);
    case SET_PHOTO_PAGING_DATA:
      return {
        ...state,
        photoPageSize: getPagingData(state.photoParams.start, action.pageInfo, state.funPageSize)
      }
    case SET_IS_MORE:
      return {
        ...state,
        isMore: action.isMore
      }
    case GET_INFO:
    case GET_INFO_SUCCESS:
    case GET_INFO_ERROR:
      return handleAsyncActionsById(GET_INFO, 'info', true)(state, action);
    default:
      return state;
  }
}