import React, { useState, useEffect } from 'react';
import {useSelector} from "react-redux";
import MeasurementMemberComponent from './MeasurementMemberComponent'
import AlertComponent from "../../../commons/popup/AlertComponent";
import {getYouthMeasurementMemberRecords, getYouthMeasurementRecordsBySchoolGrade} from "../../../../service/youth/Measurement.service";

const MeasurementMemberContainer = props => {
    const { history } = props;
    const { isLogin } = useSelector(state => state.commonAuth);

    const [personalData, setPersonalData] = useState(null); //개인 기록
    const [physicalData, setPhysicalData] = useState(null); //개인 피지컬 측정 데이터
    const [bySchoolData, setBySchoolData] = useState(null); //학교 구분별 최저, 평균, 최대, 개인 기록
    const [schoolGradeList, setSchoolGradeList] = useState(null); //개인별 학교 구분 리스트

    const [avgData, setAvgData] = useState(null); //그래프용 평균 기록
    const [gapData, setGapData] = useState(null); //그래프용 Gap

    const [schoolGrade, setSchoolGrade] = useState("2"); //학교 구분 선택값 (1:초, 2:중, 3:고)

    const [alertMessage, setAlertMessage] = useState('');
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }
    const closeAlertPopup = e => {
        e.preventDefault();
        setAlertMessage('');
        setIsAlertPopupDisplay(false);
        if (!isLogin) {
            history.push({ pathname: '/auth/login', state: { from: history.location.pathname} });
        }
    }

    useEffect(() => {
        console.log('MeasurementMemberContainer::componentDidMount');

        if (!isLogin) {
            openAlertPopup('로그인이 필요한 페이지 입니다.');
        }else{
            const setMemberMeasurementData = async() => {
                const result = await getYouthMeasurementMemberRecords();

                if (result) {
                    setPersonalData(result.personalData);
                    setPhysicalData(result.physicalData);
                    setSchoolGradeList(result.schoolGradeList);
                    setSchoolGrade(result.schoolGradeList[result.schoolGradeList.length-1]);
                    setAvgData(result.avgData);
                    setGapData(result.gapData);
                }
            }
            setMemberMeasurementData();
        }

        return function cleanup() {
            console.log('MeasurementMemberContainer::componentWillUnmount');
        }
    }, []);

    useEffect(() => {
        if (!isLogin) {
            openAlertPopup('로그인이 필요한 페이지 입니다.');
        }else{
            const getAvgMeasurementData = async() => {
                const result = await getYouthMeasurementRecordsBySchoolGrade({schoolGrade: schoolGrade});

                if(result && (Array.isArray(result))) {
                    setBySchoolData(result);
                }
            }
            getAvgMeasurementData();
        }
    }, [schoolGrade]);

    return (
        <>
        {
            isLogin ?
                <MeasurementMemberComponent
                    {...props}
                    personalData={personalData}
                    physicalData={physicalData}
                    bySchoolData={bySchoolData}
                    schoolGradeList={schoolGradeList}
                    schoolGrade={schoolGrade}
                    setSchoolGrade={setSchoolGrade}
                    avgData={avgData}
                    gapData={gapData}
                />
            :
                null
        }
            <AlertComponent
                isDisplay={isAlertPopupDisplay}
                clickCloseButton={closeAlertPopup}
                content={alertMessage}
            />
        </>
    )
}

export default MeasurementMemberContainer;