import * as ApiService from '../Api.service'

export const getList = async (params = {}) => {
    console.log('Social.service:getList');
    const url = '/web/socials';

    return ApiService.get(url, params);
}

export const getLinks = async (params = {}) => {
    console.log('Social.service:getList');
    const url = '/links';

    return ApiService.get(url, params);
}