import React, { useEffect, useState } from 'react';

const TermsComponent = props => {
    const { terms, clickTermsLoginButton } = props;
    const [ agree, setAgree ] = useState(false)

    const clickCheckBox = e => {
        const checked = e.currentTarget.checked;
        setAgree(checked)
    }

    const clickLogin = e => {
        e.preventDefault();
        clickTermsLoginButton(terms.termsType, agree);
    }

    useEffect(() => {
        // window.ntmEvent();
    }, []);

    return (
        <div className={`contents auth`}>
            <div className={`join-box`}>
                <div className={`tit`}>
                    <i className={`logo-kbl`} />
                    <h4>KBL 통합 회원가입</h4>
                    <p>KBL 연맹 및 통합사이트 내 <br className={`mobile-only`} />모든 서비스를 이용할 수 있습니다.</p>
                </div>
                <div className={`box`}>
                    <form>
                        <div className={`agree-wrap`}>
                            <input id={`agree`} type={`checkbox`} checked={agree} data-type={terms.termsType} onClick={clickCheckBox} />
                            <label htmlFor={`agree`}><span><span className={`font-red`}>{`(${terms.isRequired ? '필수' : '선택'})`}</span> {`${terms.title}`}</span></label>
                        </div>
                        <div className={`agree-desc`} dangerouslySetInnerHTML={{__html: terms.contents}}>
                        </div>
                    </form>
                    <div className={`join-btn`}>
                        <button className={`pb`} onClick={clickLogin}>확인</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TermsComponent;