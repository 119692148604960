import React, { useEffect } from 'react';
import MeasurementIntroComponent from './MeasurementIntroComponent'


const MeasurementIntroContainer = props => {

    useEffect(() => {
        console.log('MeasurementIntroContainer::componentDidMount');

        return function cleanup() {
            console.log('MeasurementIntroContainer::componentWillUnmount');
        }
    }, []);

    return (
        <MeasurementIntroComponent
            {...props}
        />
    )
}

export default MeasurementIntroContainer;