import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';

const YouthYouTubeViewComponent = props => {
  const { info } = props;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", resizeCallback);
    resizeCallback();

    return () => {
      window.removeEventListener("resize", resizeCallback);
    }
  }, [])

  const resizeCallback = () => {
    setIsMobile(window.innerWidth <= 768);
  }

  const defaultOption = {
    height: '675',
    width: '100%',
    playerVars: {
      autoplay: 1,
    }
  };

  const mobileOption = {
    height: '260',
    width: '100%',
  };

  const [player, setPlayer] = useState(null);

  const onReady = e => {
    setPlayer(e.target);
  }

  return (
    <React.Fragment>
      <div style={{position: 'relative', textAlign: 'center' }} align="center" id="utube_player">
        {info.videoId && <YouTube key={info.videoId} videoId={info.videoId} opts={!isMobile ? defaultOption : mobileOption } onReady={onReady} />}
      </div>
      {/*<p>&nbsp;</p>*/}
      {/*<p>* 본 영상의 저작권은 (사) 케이비엘에 있으며 무단으로 영상을 복제, 도용하거나 상업적으로 이용하는 행위는 관련 법규에 의거 처벌 될 수 있음을 안내드립니다&nbsp; &#8203;</p>*/}
      {/*<p>&nbsp;</p>*/}
      {/*<p>&nbsp;</p>*/}
    </React.Fragment>
  );
}

export default YouthYouTubeViewComponent;