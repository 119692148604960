import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import AlertComponent from "./AlertComponent";
import {
    optAuthConfirm,
    optAuthMailConfirm,
    optAuthMailRequest,
    optAuthRequest
} from "../../../service/auth/Auth.service";
import {cancelSleeper} from "../../../service/member/Member.service";

const SleeperAccountComponent = props => {
    const { isDisplay, clickOkButton, clickNoButton, sleeperId } = props;
    const { userInfo } = useSelector(state => state.commonAuth);
    const [sendType, setSendType] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isDoneAlertDisplay, setIsDoneAlertDisplay] = useState(false);
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [name, setName] = useState('');
    const [authNumber, setAuthNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [requestTime, setRequestTime] = useState(0);
    const [time, setTime] = useState(300);
    const timeRef = useRef(300);

    useEffect(()=>{
        if(requestTime > 0) {
            let timer = setInterval(()=>{
                setTime((timeRef.current -= 1));
                if(timeRef.current === 0){
                    clearInterval(timer);
                }
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [requestTime]);

    useEffect(()=>{
        if(time === 0){
            // openAlertPopup('인증번호가 만료되었습니다.');
            setTime(300);
            setRequestTime(0);
        }
    }, [time]);

    const changeSendType = e => {
        const value = e.currentTarget.value;
        setSendType(value);
    }

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }

    const alertPopupClose = e => {
        e.preventDefault();
        setIsAlertPopupDisplay(false);
        setAlertMessage('');
    }

    const clickAuthRequestBySendType = async e => {
        e.preventDefault();

        if (isProcessing || !sendType) {
            return;
        }

        if (!name) {
            openAlertPopup('이름을 입력해주세요.')
            return;
        }

        if (!sleeperId && name !== userInfo.name) {
            openAlertPopup('이름이 일치하지 않습니다.')
            return;
        }

        if(sendType === 'sms') {
            if (!phoneNumber) {
                openAlertPopup('휴대전화 번호를 입력해주세요.')
                return;
            }
            const params = { authType: 'VERIFY', mobilePhoneNo: phoneNumber };

            setIsProcessing(true);
            const result = await optAuthRequest(params);
            setIsProcessing(false);

            if (result && result.status === 200) {
                openAlertPopup('인증번호가 전송되었습니다.')
                setRequestTime(300)
            } else {
                openAlertPopup(result.data.message);
            }
        } else if (sendType === 'email') {
            if (!email) {
                openAlertPopup('이메일 주소를 입력해주세요.')
                return;
            }
            const params = { authType: 'VERIFY', email: email };
            setIsProcessing(true);
            const result = await optAuthMailRequest(params);
            setIsProcessing(false);

            if (result && result.status === 200) {
                openAlertPopup('인증번호가 전송되었습니다.')
                setRequestTime(300)
            } else {
                openAlertPopup(result.data.message);
            }
        }
    }

    const clickAuthConfirm = async e => {
        e.preventDefault();
        if (isProcessing || !sendType || isConfirmed) {
            return;
        }

        if (requestTime === 0) {
            openAlertPopup('인증요청을 해주세요.');
            return;
        }

        if(sendType === 'sms') {
            setIsProcessing(true);
            const result = await optAuthConfirm({ authType: "VERIFY", mobilePhoneNo: phoneNumber, otp: authNumber });
            setIsProcessing(false);

            if (result.status !== 200) {
                openAlertPopup(result.data.message);
                return;
            } else {
                setIsConfirmed(true)
            }
        } else if (sendType === 'email') {
            setIsProcessing(true);
            const result = await optAuthMailConfirm({ authType: "VERIFY", email: email, otp: authNumber });
            setIsProcessing(false);

            if (result.status !== 200) {
                openAlertPopup(result.data.message);
                return;
            } else {
                setIsConfirmed(true)
            }
        }
    }

    const clickCancelSleeperButton = async e => {
        if(isConfirmed) {
            if(!sleeperId && !userInfo.id) {
                openAlertPopup("오류가 발생했습니다.");
                return;
            }

            setIsProcessing(true);
            const result = await cancelSleeper(!sleeperId ? userInfo.id : sleeperId);
            setIsProcessing(false);
            if (result.status !== 200) {
                openAlertPopup(result.data.message);
                return;
            } else {
                setIsDoneAlertDisplay(true)
            }
        } else {
            openAlertPopup('본인인증을 완료해 주세요.');
        }
    }

    const getExpiryTime = seconds => {
        if(!seconds) return
        let result = '00:00';
        if(seconds > 0) {
            let min = parseInt((seconds%3600)/60) < 10 ? '0'+ parseInt((seconds%3600)/60) : parseInt((seconds%3600)/60);
            let sec = seconds % 60 < 10 ? '0'+seconds % 60 : seconds % 60;
            result = `${min}:${sec}`
        }

        return result;
    }

    useEffect(() => {
        // window.ntmEvent();
    }, []);

    return (
        <>
            <div className={`popup`} style={{ display: isDisplay && !isDoneAlertDisplay ? 'block' : 'none' }}>
                <div className={`pop-wrap`}>
                    <div className={`pop-tit`}>
                        <h4>휴면 회원 해제</h4>
                        <i className={`ic-pop-close-24`} onClick={clickNoButton} />
                    </div>
                    <div className={`pop-con`}>
                        <div className={`member-pop`}>
                            <i className={`ic-rock-100`}></i>
                            <h6>회원님의 아이디는 휴면상태로 전환되었습니다.</h6>
                            {
                                sendType ?
                                    sendType === 'sms'
                                        ? <p>가입 당시 등록한 휴대전화 번호를 입력한 후 <br/>‘인증요청’을 통해 인증번호를 등록해주세요.</p>
                                        : <p>가입 당시 등록한 이메일 주소를 입력한 후 <br/>‘인증요청’을 통해 인증번호를 등록해주세요.</p>
                                    :
                                    <p>회원님은 로그인 한 지 1년 이상이 지나 아이디가<br/>휴면 상태로 전환되었습니다.<br/>본인확인 후 다시 KBL 사용이 가능합니다.</p>
                            }
                            <ul className={`auth`}>
                                <li><input type={`radio`} name={'type'} id={'sms'} value={'sms'} checked={sendType === 'sms'} onChange={changeSendType} /><label htmlFor={'sms'}><span>휴대전화로 인증<span>(회원정보에 등록된 휴대전화)</span></span></label></li>
                                <li><input type={`radio`} name={'type'} id={'email'} value={'email'} checked={sendType === 'email'} onChange={changeSendType} /><label htmlFor={'email'}><span>이메일로 인증<span>(회원정보에 등록된 이메일)</span></span></label></li>
                            </ul>
                            <div className={`auth-wrap`} style={{display: sendType ? 'block' : 'none'}}>
                                <h4>인증하기</h4>
                                <ul>
                                    <li>이름</li>
                                    <li><input type={`text`} placeholder={`이름을 입력해 주세요.`} value={name || ''} onChange={(evt) => {setName(evt.currentTarget.value)}} /></li>
                                    {
                                        sendType === 'sms' ?
                                            <>
                                                <li>휴대전화</li>
                                                <li><input type={`tel`} placeholder={`휴대전화 번호를 입력해 주세요.`} value={phoneNumber || ''} onChange={(evt) => {setPhoneNumber(evt.currentTarget.value)}} /><button className={`gr md`} onClick={clickAuthRequestBySendType}>인증요청</button></li>
                                                <li><input type={`text`} placeholder={`인증번호를 입력해 주세요.`} value={authNumber || ''} onChange={(evt) => {setAuthNumber(evt.currentTarget.value)}} /><button className={`gr md`} onClick={clickAuthConfirm}>인증확인</button></li>
                                            </>
                                            :
                                            <>
                                                <li>이메일</li>
                                                <li><input type={`email`} placeholder={`이메일을 입력해 주세요.`} value={email || ''} onChange={(evt) => {setEmail(evt.currentTarget.value)}}  /><button className={`gr md`} onClick={clickAuthRequestBySendType}>인증요청</button></li>
                                                <li><input type={`text`} placeholder={`인증번호를 입력해 주세요.`} value={authNumber || ''} onChange={(evt) => {setAuthNumber(evt.currentTarget.value)}}  /><button className={`gr md`} onClick={clickAuthConfirm}>인증확인</button></li>
                                            </>
                                    }
                                    {
                                        requestTime > 0 ?
                                            !isConfirmed
                                            ? <li><p className={`font-red`}>입력제한시간 {getExpiryTime(time)}</p></li>
                                            : <li><p className={`font-blue`}>인증이 완료되었습니다.</p></li>
                                            : null
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={`pop-btn`}>
                        <button className={`light-pb`} onClick={clickNoButton}>취소</button>
                        <button className={`pb`} onClick={clickCancelSleeperButton}>휴면 해제하기</button>
                    </div>
                </div>
            </div>
            <div className={`popup`} style={{ display: isDisplay && isDoneAlertDisplay ? 'block' : 'none' }}>
                <div className={`pop-wrap`}>
                    <div className={`pop-tit`}>
                        <h4>휴면 회원 해제 완료</h4>
                        <i className={`ic-pop-close-24`} onClick={clickOkButton} />
                    </div>
                    <div className={`pop-con`}>
                        <div className={`member-pop`}>
                            <i className={`ic-unrock-100`}></i>
                            <p>회원님의 계정이 휴면 해제 되었습니다.</p>
                            <p>다시 로그인해 주세요.</p>
                        </div>
                    </div>
                    <div className={`pop-btn`}>
                        <button className={`pb`} onClick={clickOkButton}>확인</button>
                    </div>
                </div>
            </div>
            <AlertComponent
                isDisplay={isAlertPopupDisplay}
                clickCloseButton={alertPopupClose}
                content={alertMessage}
            />
        </>
  );
}


SleeperAccountComponent.propTypes = {
    isDisplay: PropTypes.bool.isRequired,
    clickOkButton: PropTypes.func.isRequired,
    clickNoButton: PropTypes.func.isRequired,
};

export default SleeperAccountComponent;