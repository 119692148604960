import * as ApiService from '../Api.service'

const preFix = '/web';
export const getList = async () => {
    console.log('Branch.service:getList');
    const url = preFix + '/youths/gyms';

    return ApiService.get(url);
}

export const getInfo = async params => {
    console.log('Branch.service:getInfo');
    const url = preFix + '/youths/gyms/info';

    return ApiService.get(url, params);
}

export const getGymList = async () => {
    console.log('Branch.service:getInfo');
    const url = preFix + '/youths/gyms/list';
    return ApiService.get(url);
}

export const getAllList = async () => { //hb
    console.log('Branch.service:getAllList');
    const url = preFix + '/youths/club/branch';

    return ApiService.get(url);
}

export const getAllGymList = async () => { //hb
    console.log('Branch.service:getAllGymList');
    const url = preFix + '/youths/club/list';

    return ApiService.get(url);
}

export const getGymBranchInfo = async gymSq => {
    console.log('Branch.service:getGymBranchInfo');
    const url = preFix + `/youths/club/info/${gymSq}`;

    return ApiService.get(url);
}

export const getGymStaffList = async infoSq => {
    console.log('Branch.service:getGymStaffList');
    const url = preFix + `/youths/club/staff/${infoSq}`;

    return ApiService.get(url);
}

export const getYouthOperationStatus = async () => { //hb
    console.log('Branch.service:getAllGymList');
    const url = preFix + '/youths/operation';

    return ApiService.get(url);
}


