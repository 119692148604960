import React, { useState, useEffect } from 'react';
import { getUA } from "react-device-detect";
import { getMenuByTeamClass } from '../../Menu'
import { useSelector, useDispatch } from 'react-redux';
import {removeAuthTokens} from '../../../helpers/authHelper';
import {getTeamClassByDomain, getTeamCodeByDomain} from '../../../helpers/commonHelper';
import { setIsLogin, setAuthUserInfo } from '../../../store/common/auth'
import { logout } from '../../../service/auth/Auth.service'
import AlertComponent from '../../commons/popup/AlertComponent';
import { useLocation } from 'react-router-dom';
import {setIsApp, setTeamClass} from "../../../store/common/layout";
import {appUserAgentKey, getMobileAppHeaderData} from "../../../helpers/apiHelper";
import queryString from "query-string";
import {getCookie} from "../../../helpers/cookieHelper";

const HeaderComponent = props => {
    const { children, youtubueInfo, facebookInfo, instagramInfo, naverPostInfo, naverTvInfo } = props;
    const { isLogin, userInfo } = useSelector(state => state.commonAuth);
    const dispatch = useDispatch();
    const history = children.props.history;
    const [isShowTeamLogo, setIsShowTeamLogo] = useState(false);
    const [isShowMenu, setIsShowMenu] = useState(false);
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [menuList, setMenuList] = useState(null);
    const [shopLinkUrl, setShopLinkUrl] = useState(null);
    const [curMenuPath, setCurMenuPath] = useState(useLocation().pathname.substring(0, useLocation().pathname.indexOf('/', 1)));
    const [curSubMenuPath, setCurSubMenuPath] = useState(useLocation().pathname);
    const teamCode = getTeamCodeByDomain();

    const headlessList = new Set([
        '/auth/login-shop',
        '/member/signup-shop',
        '/member/mypage/player',
    ]);

    const teamClassValue = getTeamClassByDomain();
    dispatch(setTeamClass(teamClassValue));

    const isApp = getUA.includes(appUserAgentKey);
    dispatch(setIsApp(isApp));

    let appClassValue = ''
    if (isApp || headlessList.has(window.location.pathname)) {
        appClassValue = 'mobile-view';
    }

    const teamClassName = `${teamClassValue} ${appClassValue}`;

    const teamCodeList = [
        'kbl'
        , 'db'
        , 'ss'
        , 'sono'
        , 'sk'
        , 'lg'
        , 'kcc'
        , 'kgc'
        , 'kt'
        , 'gas'
        , 'hd'
    ];

    const teamNameMap = {
        'kbl' :'KBL',
        'db':'원주 DB 프로미',
        'ss':'서울 삼성 썬더스',
        'sono':'고양 소노 스카이거너스',
        'sk':'서울 SK 나이츠',
        'lg':'창원 LG 세이커스',
        'kcc':'부산 KCC 이지스',
        'kgc':'안양 정관장 레드부스터스',
        'kt':'수원 KT 소닉붐',
        'gas':'대구 한국가스공사',
        'hd':'울산 현대모비스 피버스'
    }

    const clickMenu = (e) => {
        e.preventDefault();
        setIsShowMenu(false);
        if (e.currentTarget.dataset.func === 'logout') {
            clickLogout(e);
            return;
        }

        let path = e.currentTarget.getAttribute('data-path');
        const pathDev = e.currentTarget.getAttribute('data-pathdev');
        const cookieKey = e.currentTarget.getAttribute('data-cookie');
        if (pathDev) {
            path = process.env.REACT_APP_ENV !== 'dev' ? path : pathDev;
        }

        if (!path) {
            path = '/preparing'
        }

        if (path.startsWith("http")) {
            const cookie = getCookie(cookieKey);
            if (cookie) {
                window.open(path + cookie, '_blank');
            } else {
                window.open(path, '_blank')
            }
        } else {
            setCurSubMenuPath(path);
            if(path ==='/member/signup'){
                window.open('https://kbl.or.kr/join', '_blank')
            } else {
                history.push(path);
            }
        }
    }

    const clickLogout = async e => {
        e.preventDefault()
        await logout();
        removeAuthTokens();
        dispatch(setIsLogin(false));
        dispatch(setAuthUserInfo({}));
        history.push('/');
    }

    const clickSubDomain = e => {
        e.preventDefault();
        const teamCode = e.currentTarget.getAttribute('data-team-code');

        let url = '';

        if (teamCode === 'db') {
            url = process.env.REACT_APP_SUB_DOMAIN_DB
        } else if (teamCode === 'ss') {
            url = process.env.REACT_APP_SUB_DOMAIN_SS
        } else if (teamCode === 'sk') {
            url = process.env.REACT_APP_SUB_DOMAIN_SK
        } else if (teamCode === 'lg') {
            url = process.env.REACT_APP_SUB_DOMAIN_LG
        } else if (teamCode === 'sono') {
            url = process.env.REACT_APP_SUB_DOMAIN_SONO
        } else if (teamCode === 'gas') {
            url = process.env.REACT_APP_SUB_DOMAIN_GAS
        } else if (teamCode === 'kcc') {
            url = process.env.REACT_APP_SUB_DOMAIN_KCC
        } else if (teamCode === 'kgc') {
            url = process.env.REACT_APP_SUB_DOMAIN_KGC
        } else if (teamCode === 'kt') {
            url = process.env.REACT_APP_SUB_DOMAIN_KT
        } else if (teamCode === 'hd') {
            url = process.env.REACT_APP_SUB_DOMAIN_HD
        } else {
            url = process.env.REACT_APP_SUB_DOMAIN_YH
        }
        window.location.href = `${process.env.REACT_APP_DOMAIN_PREFIX}${url}`;
    }

    const renderHomePage = e => {
        e.preventDefault();
        history.push('/');
    }

    const clickMenuTitle = (e, path, pathDev, cookieKey) => {
        e.preventDefault();

        if (pathDev) {
            path = process.env.REACT_APP_ENV === 'prod' ? path : pathDev;
        }

        if (path.startsWith("http")) {
            const cookie = getCookie(cookieKey);

            if (path.includes('youth.kbl.or.kr')) {
                // data story (유소년)
                const params = queryString.parse(window.location.search);
                const mobileAppHeaerValues = getMobileAppHeaderData();

                if (mobileAppHeaerValues) {
                    params.device = mobileAppHeaerValues.device;
                } else {
                    params.device = 'WEB'
                }

                params.type = 'redirect';
                params.site = 'youth.kbl.or.kr';
                // window.logEvent("kblyouth", params);
                window.ntmEvent("out_page", {id: "KBL_Youth"})
            }

            if (cookie) {
                window.open(path + cookie, '_blank');
            } else {
                window.open(path, '_blank')
            }
        } else {
            setCurSubMenuPath(path);
            history.push(path)
        }
    }

    const clickMenuTitleMb = (e, path) => {
        e.preventDefault();
        setCurMenuPath(path);

        if(path === '/youth') {
            // data story (유소년)
            const params = queryString.parse(window.location.search);
            const mobileAppHeaerValues = getMobileAppHeaderData();
            if (mobileAppHeaerValues) {
                params.device = mobileAppHeaerValues.device;
            } else {
                params.device = 'WEB'
            }

            params.type = 'redirect';
            params.site = 'youth.kbl.or.kr';
            // window.logEvent("kblyouth", params);
            window.ntmEvent("out_page", {id: "KBL_Youth"})
            let url = 'https://youth.kbl.or.kr'
            window.open(url, '_blank')
        }
    }

    const clickLogo = e => {
        e.preventDefault();
        setIsShowTeamLogo(!isShowTeamLogo);
    }

    const clickOpenMenu = e => {
        e.preventDefault()
        setIsShowMenu(true);
    }

    const clickCloseMenu = e => {
        e.preventDefault()
        setIsShowMenu(false);
    }

    const renderPage = e => {
        e.preventDefault();
        const url = e.currentTarget.getAttribute('data-target');

        if (url) {
            if(url === '/member/signup'){
                window.open('https://kbl.or.kr/join', '_blank')
            } else {
                history.push(url);
            }
        }
    }

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }

    const alertPopupClose = e => {
        e.preventDefault();
        setAlertMessage('');
        setIsAlertPopupDisplay(false);
        if (!isLogin) {
            history.push('/auth/login');
        }
    }

    useEffect(() => {
        setMenuList(getMenuByTeamClass(getTeamClassByDomain()));
    }, []);

    return (
        <header className={teamClassName}>
            <AlertComponent
              isDisplay={isAlertPopupDisplay}
              clickCloseButton={alertPopupClose}
              content={alertMessage}
            />
            {/* 웹 헤더, 메뉴 */}
            <div className={`desktop-only`}>
                <div className={`user-wrap`}>
                    <div className={`user-con`}>
                        <ul className={`user`}>
                            {
                                !isLogin ?
                                  <React.Fragment>
                                      <li>
                                          <a className='' href='#' data-target='/auth/login' onClick={renderPage}>로그인</a>
                                      </li>
                                      <li>
                                          <a className='' href='#' data-target='/member/signup' onClick={renderPage}>회원가입</a>
                                      </li>
                                  </React.Fragment>
                                  :
                                  <React.Fragment>
                                      <li>
                                          <a className='' data-target='/member/mypage' onClick={renderPage} href='#'>마이페이지</a>
                                      </li>
                                      <li>
                                          <a className='' href='#' onClick={clickLogout}>로그아웃</a>
                                      </li>
                                  </React.Fragment>
                            }
                        </ul>
                    </div>
                </div>
                <div className={`header-wrap`}>
                    <div className={`header-con`}>
                        <div className={`logo`}>
                            <a onClick={renderHomePage}>
                                <i className={`logo-kbl`}></i>
                            </a>
                        </div>
                        <ul className={`emblem-wrap`} style={{display: 'none'}}>
                            {
                                teamCodeList.map((item, index) =>
                                    <li key={`team-list-nav-${index}`} onClick={clickSubDomain} data-team-code={item}>
                                        <i className={`emblem-${item}`}></i>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
                <div className={`nav-wrap`}>
                    <ul className={`nav-con`}>
                        {
                            menuList ?
                                menuList.map((item, index, arr) =>
                                    <li key={`menu-${index}`} className={`${item.path && item.path === curMenuPath ? 'active' : ''}`}>
                                        <h6 onClick={(e) => clickMenuTitle(e, item.subList[0].path, item.subList[0].pathDev, item.subList[0].cookieKey)}>{item.title}</h6>
                                        {
                                            item.subList && item.subList.length > 0 && !item.subList[0].hide ?
                                                <ul>
                                                    {
                                                        item.subList.map((value, idx) =>
                                                            !value.hide && !value.admin ?
                                                                <li key={`sub-menu-${idx}`} className={`${value.path && value.path === curSubMenuPath ? 'active' : ''}`} onClick={clickMenu} data-path={value.path} data-pathdev={value.pathDev} data-cookie={value.cookieKey}>{value.title}</li>
                                                            :
                                                                !value.hide && value.admin && isLogin && userInfo.isYouthAdmin ?
                                                                    <li key={`sub-menu-${idx}`} className={`${value.path && value.path === curSubMenuPath ? 'active' : ''}`} onClick={clickMenu} data-path={value.path} data-pathdev={value.pathDev} data-cookie={value.cookieKey}>{value.title}</li>
                                                                : null
                                                        )
                                                    }
                                                </ul>
                                                : null
                                        }
                                    </li>
                                ) : null
                        }
                    </ul>
                </div>
            </div>

            {/* 모바일 헤더 */}
            <div className={`mobile-only`}>
                <div className={`header-wrap`}>
                    <div className={`mobile-menu ${isShowMenu ? 'active' : ''}`} onClick={()=> setIsShowMenu(!isShowMenu)}>
                        <i className={`ic-menu-24`} />
                    </div>
                    <div className={`logo ${isShowTeamLogo ? 'active' : ''}`}>
                        <i onClick={renderHomePage} className={`logo-kbl`} />
                        <i className={`ic-mobile-dropdown-12`} onClick={()=>{setIsShowTeamLogo(!isShowTeamLogo)}} style={{display: 'none'}}/>
                    </div>
                    {/*<ul className={`icon`}>*/}
                    {/*    <li onClick={clickMenu} data-path='/ticket/reservation'><i className={`ic-ticket-24`} /></li>*/}
                    {/*</ul>*/}
                </div>

                {/* 모바일 메뉴 */}
                <div className={`mobile-menu-wrap ${isShowMenu ? 'active' : ''}`}>
                    <div className={`user-wrap`}>
                        {
                            !isLogin ?
                                <ul>
                                    <li data-path='/auth/login' onClick={clickMenu}>
                                        <a href='#'>로그인</a>
                                    </li>
                                    <li data-path='/member/signup' onClick={clickMenu}>
                                        <a href='#'>회원가입</a>
                                    </li>
                                </ul>
                                :
                                <ul>
                                    <li data-path='/member/mypage' onClick={clickMenu}>
                                        <a href='#'>마이페이지</a>
                                    </li>
                                    <li onClick={clickMenu} data-func='logout'>
                                        <a href='#'>로그아웃</a>
                                    </li>
                                </ul>
                        }
                    </div>
                    <ul className={`mobile-menu-con`}>
                        {
                            menuList ?
                                menuList.map((item, index, arr) =>
                                    <li key={`menu-${index}`} className={`${item.path && item.path === curMenuPath ? 'active' : ''}`}>
                                        <h6 onClick={(e)=>clickMenuTitleMb(e, item.path)}>{item.title}</h6>
                                        <ul>
                                            {
                                                item.subList ?
                                                    item.subList.map((value, idx) =>
                                                        !value.hide && !value.admin ?
                                                            <li key={`sub-menu-${idx}`} className={`${value.path && value.path === curSubMenuPath ? 'active' : ''}`} onClick={clickMenu} data-path={value.path} data-pathdev={value.pathDev} data-cookie={value.cookieKey}>{value.title}</li>
                                                        :
                                                            !value.hide && value.admin && isLogin && userInfo.isYouthAdmin ?
                                                                <li key={`sub-menu-${idx}`} className={`${value.path && value.path === curSubMenuPath ? 'active' : ''}`} onClick={clickMenu} data-path={value.path} data-pathdev={value.pathDev} data-cookie={value.cookieKey}>{value.title}</li>
                                                            : null
                                                    ) : null
                                            }
                                        </ul>
                                    </li>
                                ) : null
                        }
                        <li>
                            <div>
                                <ul className={`mobile-menu-sns`}>
                                    {
                                        facebookInfo ?
                                            <li>
                                                <a target='_blank' rel='noopener noreferrer' href={facebookInfo.snsUrl}>
                                                    <i className={`ic-mobile-facebook`} />
                                                </a>
                                            </li>
                                            : null
                                    }
                                    {
                                        instagramInfo ?
                                            <li>
                                                <a target='_blank' rel='noopener noreferrer' href={instagramInfo.snsUrl}>
                                                    <i className={`ic-mobile-instagram`} />
                                                </a>
                                            </li>
                                            : null
                                    }
                                    {
                                        youtubueInfo ?
                                            <li>
                                                <a target='_blank' rel='noopener noreferrer' href={youtubueInfo.snsUrl}>
                                                    <i className={`ic-mobile-youtube`} />
                                                </a>
                                            </li>
                                            : null
                                    }
                                    {
                                        naverPostInfo ?
                                            <li>
                                                <a target='_blank' rel='noopener noreferrer' href={naverPostInfo.snsUrl}>
                                                    <i className={`ic-mobile-naver`} />
                                                </a>
                                            </li>
                                            : null
                                    }
                                    {
                                        naverTvInfo ?
                                            <li>
                                                <a target='_blank' rel='noopener noreferrer' href={naverTvInfo.snsUrl}>
                                                    <i className={`ic-mobile-navertv`} />
                                                </a>
                                            </li>
                                            : null
                                    }
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                        
                {/* 모바일 구단 모음 */}
                <ul className={`mobile-club-wrap ${isShowTeamLogo ? 'active' : ''}`}>
                    {
                        teamCodeList.map((item, index) =>
                            <li key={`team-list-nav-${index}`} onClick={clickSubDomain} data-team-code={item}>
                                <i className={`emblem-${item}`}/> <p>{teamNameMap[item]}</p>
                            </li>
                        )
                    }
                </ul>
            </div>
        </header>
    );
}
export default HeaderComponent;