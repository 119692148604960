import React, { useEffect, useState } from 'react';
import queryString from "query-string";
import FilterComponent from "../../../commons/filter/FilterComponent";
import YouthClubBranchComponent from "./YouthClubBranchComponent";
import YouthClubOperationComponent from "./operation/YouthClubOperationComponent";

const YouthClubComponent = props => {
    const { history } = props;
    const [selectedTab, setSelectedTab] = useState('branch');

    const [selectedTeam, setSelectedTeam] = useState('');

    const tabSelectList = [
        {
            key: 'branch',
            value: '지점 찾기'
        },
        /*{
            key: 'info',
            value: '지점 안내'
        },*/
        {
            key: 'operation',
            value: '구단 클럽 운영 현황'
        },
    ];

    const changeParams = (key, value) => {
        // setSelectedTab(key);
        history.push(`/youth/club?type=${key}`);
    }

    useEffect(() => {
        if(window.location.search) {
            setSelectedTab(queryString.parse(window.location.search).type);
        }
    }, [window.location.search]);

    useEffect(()=>{
    }, [selectedTab]);

    return (
        <>
            {
                props.mainTab == true?
                    <YouthClubBranchComponent
                        history = {history}
                    />
                    :
                    <div className={`contents`}>
                        <div className={`con-header`}>
                            <h2>클럽 소개</h2>
                            <p>클럽의 지점 위치 및 상세 정보를 확인하실 수 있는 페이지입니다.</p>
                        </div>
                        <ul className={`filter-wrap`}>
                            <li>
                                <h6>구분</h6>
                                <FilterComponent
                                    selectChange={changeParams}
                                    selectList={tabSelectList}
                                    selectedKey={selectedTab}
                                />
                            </li>
                        </ul>
                        {
                            selectedTab === 'branch' ?
                                <div className={`con-box`}>
                                    <div className={`con-tit`}>
                                        <h4>지점 선택</h4>
                                        <span>찾고 싶은 지점의 구단을 선택하시면 상세 주소를 지도에서 확인하실 수 있습니다. </span>
                                    </div>
                                    <YouthClubBranchComponent
                                        history = {history}
                                    />
                                </div>
                                :
                                /*selectedTab === 'info' ?
                                    <YouthClubInfoComponent
                                        selectedGymSq = {selectedGymSq}
                                    /> : */
                                    selectedTab === 'operation' ?
                                        <YouthClubOperationComponent
                                            setSelectedTeam = {setSelectedTeam}
                                            selectedTeam = {selectedTeam}
                                            history = {history}
                                        />
                                        : null
                        }
                    </div>
            }
        </>
    )
}

export default YouthClubComponent;