import React, { useState, useEffect } from 'react';
import YouthPhotoListComponent from './YouthPhotoListComponent'

import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { getQueryStringFromObject } from '../../../../../helpers/apiHelper';
import {isEmptyObject} from "../../../../../helpers/objectHelper";
import {setIsMore, setTopVideoEmpty, setYouthPhotoParams} from "../../../../../store/youth/content";
import * as CommonHelper from "../../../../../helpers/commonHelper";
import {getYouthGameList} from "../../../../../service/youth/Match.service";

const YouthPhotoListContainer = props => {
  const { history, location } = props;
  const { photoList, photoParams, photoPagingData, photoPageSize } = useSelector(state => state.youthContent);
  const [photoType, setPhotoType] = useState(null);
  const [searchType, setSearchType] = useState(null);
  const [searchWord, setSearchWord] = useState('');
  const [seasonList, setSeasonList] = useState(null);
  const [season, setSeason] = useState('all');
  const [gameList, setGameList] = useState([{key: 'all', value: '전체'}]);
  const [game, setGame] = useState('all');
  const dispatch = useDispatch();

  const searchParams = {
    boardCode: 'PHOTO',
    boardCatSq: '1',
    season: '',
    game: '',
    start: 0,
    limit: photoPageSize,
    searchType: 'title',
    searchWord: ''
  }

  const getDataList = async () => {
    const _seasonSelectList = await CommonHelper.getYouthSeasonSelectList();
    setSeasonList(_seasonSelectList);
  }

  const getGameListBySeason = async (season) => {
    if(!season) return;

    let result = [];
    result.push({key: 'all', value: '전체'});
    if(season !== 'all' && seasonList) {
      let seasonData = seasonList.find(item => item.key == season);
      if (!isEmptyObject(seasonData)) {
        let res = await getYouthGameList({seasonKind: seasonData.seasonKind, seasonCode: season});
        if(res && res.length > 0) {
          res.map(item => {
            let obj = {};
            obj.key = item.gmkey;
            obj.value = `[${item.tcodeName}] ${item.tnameA} : ${item.tnameH}`;
            obj.seasonKind = item.seasonKind;
            result.push(obj);
          })
        }
      }
    }
    setGameList(result);
  }

  const setDefaultParams = async () => {
    const _queryString = queryString.parse(location.search);

    if (!isEmptyObject(_queryString)) {
      dispatch(setYouthPhotoParams(_queryString));
      setPhotoType(_queryString.boardCatSq);
      setSearchType(_queryString.searchType);
      setSearchWord(_queryString.searchWord);
      setSeason(_queryString.season);
      setGame(_queryString.game);
      await getGameListBySeason(_queryString.season);
    } else {
      dispatch(setYouthPhotoParams(searchParams));
      setPhotoType('1');
      setSearchType('title');
      setSearchWord('');
    }
  }

  const changeSearchWord = e => {
    setSearchWord(e.currentTarget.value);
  }

  const changeSearchType = (type, key, value) => {
    setSearchType(key);
  }

  const changeParams = async (type, key, value) => {
    if (type === 'changeSeason') {
      if (photoParams.seasonCode === key) {
        return;
      }
      setSeason(key);
      dispatch(setIsMore(false));
      photoParams.season = key != 'all' ? key : '';
      photoParams.game = '';
      photoParams.start = 0;
      photoParams.limit = photoPageSize;
      await getGameListBySeason(key);
    } else if (type === 'changeGame') {
      if (photoParams.boardCatSq === key) {
        return;
      }
      dispatch(setTopVideoEmpty());
      setGame(key);
      dispatch(setIsMore(false));
      photoParams.game = key != 'all' ? key : '';
      photoParams.start = 0;
      photoParams.limit = photoPageSize;
    } else if (type === 'changePhotoType') {
      if (photoParams.boardCatSq === key) {
        return;
      }
      setPhotoType(key);
      dispatch(setIsMore(false));
      photoParams.boardCatSq = key;
      photoParams.start = 0;
      photoParams.limit = photoPageSize;
    } else if (type === 'changePage') {
      if (value === photoPagingData.currentPage) {
        return
      }
      dispatch(setIsMore(false));
      photoParams.start = (value - 1) * photoPageSize
      photoParams.limit = photoPageSize;
    } else if (type === 'moreView') {
      if (value === photoPagingData.currentPage) {
        return
      }
      dispatch(setIsMore(true));
      photoParams.start = (value - 1) * photoPageSize;
      photoParams.limit = photoPageSize;
    } else if (type === 'clickSearch') {
      photoParams.searchWord = searchWord;
      photoParams.searchType = searchType;
      photoParams.start = 0;
      photoParams.limit = photoPageSize;
    }
    history.push(`${history.location.pathname}${getQueryStringFromObject(photoParams)}`)

    dispatch(setYouthPhotoParams(photoParams));
  }

  useEffect(_ => {
    getDataList();
    return function cleanup() {
      // window.scrollTo(0, 0);
    }
  }, [location])

  useEffect(_ => {
    if(!seasonList) return
    setDefaultParams();
  }, [seasonList])

  return (
      photoList.data ?
      <YouthPhotoListComponent
        history={history}
        searchType={searchType}
        searchWord={searchWord}
        changeParams={changeParams}
        list={photoList.data}
        pagingData={photoPagingData}
        seasonList={seasonList}
        season={season}
        gameList={gameList}
        game={game}
        changeSearchWord={changeSearchWord}
        changeSearchType={changeSearchType}
        photoType={photoType}
        params={photoParams}
      />
      : null
  );
}

export default YouthPhotoListContainer;