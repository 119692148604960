import React, { useEffect } from 'react';

const TeamBtnComponent = (props) => {
    useEffect(() => {
    }, []);

    return (
        <>
            <ul className={`chain-wrap desktop-only`}>
                <li className={props.selectedTeam == '16' ? `active` : ''} onClick={() => { props.setSelectedTeam('16')}} value={'16'}>
                    <i className={`emblem-db`}></i><p>원주 DB</p></li>
                <li className={props.selectedTeam == '35' ? `active` : ''} onClick={() => { props.setSelectedTeam('35')}} value={'35'}>
                    <i className={`emblem-ss`}></i><p>서울 삼성</p></li>
                <li className={props.selectedTeam == '55' ? `active` : ''} onClick={() => { props.setSelectedTeam('55')}} value={'55'}>
                    <i className={`emblem-sk`}></i><p>서울 SK</p></li>
                <li className={props.selectedTeam == '50' ? `active` : ''} onClick={() => { props.setSelectedTeam('50')}} value={'50'}>
                    <i className={`emblem-lg`}></i><p>창원 LG</p></li>
                {/*<li className={props.selectedTeam == '90' ? `active` : ''} onClick={() => { props.setSelectedTeam('90')}} value={'90'}>
                    <i className={`emblem-car`}></i><p>고양 데이원</p></li>*/}
                <li className={props.selectedTeam == '60' ? `active` : ''} onClick={() => { props.setSelectedTeam('60')}} value={'60'}>
                    <i className={`emblem-kcc`}></i><p>부산 KCC</p></li>
                <li className={props.selectedTeam == '70' ? `active` : ''} onClick={() => { props.setSelectedTeam('70')}} value={'70'}>
                    <i className={`emblem-kgc`}></i><p>안양 정관장</p></li>
                <li className={props.selectedTeam == '6' ? `active` : ''} onClick={() => { props.setSelectedTeam('6')}} value={'6'}>
                    <i className={`emblem-kt`}></i><p>수원 KT</p></li>
                <li className={props.selectedTeam == '10' ? `active` : ''} onClick={() => { props.setSelectedTeam('10')}} value={'10'}>
                    <i className={`emblem-hd`}></i><p>울산 현대 모비스</p></li>
                <li className={props.selectedTeam == '64' ? `active` : ''} onClick={() => { props.setSelectedTeam('64')}} value={'64'}>
                    <i className={`emblem-gas`}></i><p>대구 한국가스공사</p></li>
                <li className={props.selectedTeam == '66' ? `active` : ''} onClick={() => { props.setSelectedTeam('66')}} value={'66'}>
                    <i className={`emblem-sono`}></i><p>고양 소노</p></li>
            </ul>

            <div className={`mobile-only chain-search-mobile`}>
                <select onChange={(e) => { props.setSelectedTeam(e.currentTarget.value) }} value={props.selectedTeam}>
                    <option value={''}>구단 선택</option>
                    <option value={'16'}>원주 DB</option>
                    <option value={'35'}>서울 삼성</option>
                    <option value={'55'}>서울 SK</option>
                    <option value={'50'}>창원 LG</option>
                    {/*<option value={'90'}>고양 데이원</option>*/}
                    <option value={'60'}>부산 KCC</option>
                    <option value={'70'}>안양 정관장</option>
                    <option value={'6'}>수원 KT</option>
                    <option value={'10'}>울산 현대 모비스</option>
                    <option value={'64'}>대구 한국가스공사</option>
                    <option value={'66'}>고양 소노</option>
                </select>

            </div>
        </>
    );
}

export default TeamBtnComponent;