import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import { Helmet } from "react-helmet-async";
import YouthYouTubeViewComponent from "./YouthYouTubelViewComponent";

const YouthDetailViewComponent = props => {
  const { history, match, location, info, articleClass, listUrl, prevNextList, youtubeData } = props;
  //const { isApp } = useSelector(state => state.commonLayout);
  const [content, setContent] = useState(null);

  const renderListPage = () => {
    history.push(listUrl);
  }

  const detailPage = e => {
    e.preventDefault();
    history.push({
      pathname: `${location.state.from}/${e.currentTarget.getAttribute('data-id')}`,
      state: {
        from: location.state.from,
        contentIdx: e.currentTarget.getAttribute('data-index') ? e.currentTarget.getAttribute('data-index') : null,
        type: location.state.type ? location.state.type : null,
        fromDetailPage: true,
      }
    });
  }

  const clickDownload = e =>{
      e.preventDefault();
      const url = e.currentTarget.dataset.url;
      let downUrl = `${process.env.REACT_APP_DOMAIN_PREFIX}${process.env.REACT_APP_DOMAIN}${url}`;
      window.open(downUrl, '_blank');
  }

  const renderPrevNextButton = () => {
    const result = [];
    if (prevNextList.articles) {
      if (prevNextList.articles.length === 3) {
        result.push(
          <div className={`con-view-btn borad-btn`}>
            <button className={`prev line-gr`} onClick={detailPage} data-id={prevNextList.articles[1].artcSq} data-index={Number(location.state.contentIdx) + 1}>
              <i className={`ic-arrow-left-16-gr`} />이전글
            </button>
            <button className={`pb`} style={{margin: '0 auto'}} onClick={renderListPage}>목록으로</button>
            <button className={`next line-gr`} onClick={detailPage} data-id={prevNextList.articles[0].artcSq} data-index={Number(location.state.contentIdx) - 1}>
              다음글<i className={`ic-arrow-right-16-gr`} />
            </button>
          </div>
        )
      } else if (prevNextList.articles.length === 2) {
        if (info.artcSq === prevNextList.articles[0].artcSq) {
          result.push(
            <div className={`con-view-btn borad-btn`}>
            {/*이전글만*/}
              <button className={`prev line-gr`} onClick={detailPage} data-id={prevNextList.articles[1].artcSq} data-index={Number(location.state.contentIdx) + 1}>
                <i className={`ic-arrow-left-16-gr`} />이전글
              </button>
              <button className={`pb`} style={{margin: '0 auto'}} onClick={renderListPage}>목록으로</button>
              <button className={`next line-gr`} disabled>다음글<i className={`ic-arrow-right-16-gr`} /></button>
            </div >
          )
        } else {
          result.push(
            <div className={`con-view-btn borad-btn`}>
            {/*다음글만*/}
              <button className={`prev line-gr`} disabled><i className={`ic-arrow-left-16-gr`} />이전글</button>
              <button className={`pb`} style={{margin: '0 auto'}} onClick={renderListPage}>목록으로</button>
              <button className={`next line-gr`} onClick={detailPage} data-id={prevNextList.articles[0].artcSq} data-index={Number(location.state.contentIdx) - 1}>
                다음글<i className={`ic-arrow-right-16-gr`} />
              </button>
            </div >
          )
        }
      }
    }
    return result;
  }

  useEffect(() => {
      if(info && info.artcContent) {
          let content = info.artcContent.replaceAll('../upload', '/files/youth');
          setContent(content);
      }
  }, [info])

  return (
    info ?
      <>
        {(info.boardCode === 'NOTICE') &&
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, user-scalable=yes"/>
          </Helmet>
        }
        <div className={`con-box`}>
          <div className={`board-view-wrap`} id={'board-wrap'}>
            <div className={`board-tit`}>
              <h4>{info.artcTitle}</h4>
              <ul className={`flex-txt`}>
                <li><h6>작성일</h6><p>{info.regDttm}</p></li>
                <li><h6>조회수</h6><p>{info.viewCnt}</p></li>
              </ul>
            </div>
            <div className={`board-con`} style={{lineHeight : 'initial'}}>
              {youtubeData && <YouthYouTubeViewComponent info={youtubeData}/>}
              {content && ReactHtmlParser(content)}
            </div>
            {
              info.mediaList && info.mediaList.length > 0 ?
                info.boardCode === 'PHOTO' ?
                  <div className={`board-con`}>
                    <ul>
                      {
                        info.mediaList.map(item =>
                            <div className='img'>
                              <img src={item.mediaPath} alt={item.originalFileName} />
                            </div>
                        )
                      }
                    </ul>
                  </div>
                  :
                  <div className={`board-file`}>
                    <h6>첨부파일</h6>
                    <ul>
                      {
                        info.mediaList.map(item =>
                            <li onClick={clickDownload} data-url={item.mediaPath} className={'cursor-link'}><i className={`ic-clip-16`} /><p>{item.originalFileName}</p></li>
                        )
                      }
                    </ul>
                  </div>
                : null
            }
          </div>
        </div>
        {
          prevNextList ?
            renderPrevNextButton()
            :
            <div className={`con-view-btn borad-btn`}>
              <button className={`pb`} style={{margin: '0 auto'}} onClick={renderListPage}>목록으로</button>
            </div>
        }
      </>
      : null
  );
}

YouthDetailViewComponent.propTypes = {
  articleClass: PropTypes.string.isRequired,
  listUrl: PropTypes.string.isRequired,
};

export default YouthDetailViewComponent;