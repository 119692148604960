import React from 'react';

const MeasurementComponent = props => {
    const { isMobile } = props;

    return (
        /*<div className={`con-box`}>
            <div className={`youth-box`}>
                <div className={`youth-tit`}>
                    <h4>소개</h4>
                </div>
                <p>
                    초, 중, 고 엘리트 유소년 선수들의 신체 성장 및 운동 수행 능력을 (선수 퍼포먼스 측정 → 측정한 데이터 분석 → 분석한 데이터 평가 → 선수 피드백 제공) 4단계 프로세스를 통해 <br className={`desktop-only`} />
                    객관적인 자료 수집, 선수들에게는 강점과 약점 파악을 통한 새로운 목표 및 동기부여를 제공하는 신체 피지컬 테스트
                </p>
            </div>
            <div className={`youth-box`}>
                <div className='player'>
                    <div className='video-container'>
                        <iframe src='https://tv.naver.com/embed/23820567' allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '675' : '200'}`} style={{borderRadius: '12px' }} />
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/measurement1.jpg`} style={{width: '-webkit-fill-available'}}/>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/measurement2.jpg`} style={{width: '-webkit-fill-available'}}/>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/measurement3.jpg`} style={{width: '-webkit-fill-available'}}/>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/measurement4.jpg`} style={{width: '-webkit-fill-available'}}/>
                </div>
            </div>
        </div>*/

        <div className={`youth-box`}>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    {/*<h4>영상</h4>*/}
                </div>
                <ul className={`youth-game-highlight-photo`}>
                    <li>
                        <div className='player'>
                            <div className='video-container'>
                                <iframe src={`https://tv.naver.com/embed/23820567`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '330' : '220'}`} style={{borderRadius: '12px' }} />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='player'>
                            <div className='video-container'>
                                <iframe src={`https://www.youtube.com/embed/8gBD4p3BV2w?start=625`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '330' : '220'}`} style={{borderRadius: '12px' }} />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>소개</h4>
                </div>
                <p>
                    초, 중, 고 엘리트 유소년 선수들의 신체 성장 및 운동 수행 능력을 (선수 퍼포먼스 측정 → 측정한 데이터 분석 → 분석한 데이터 평가 → 선수 피드백 제공) 4단계 프로세스를 통해 <br className={`desktop-only`} />
                    객관적인 자료 수집, 선수들에게는 강점과 약점 파악을 통한 새로운 목표 및 동기부여를 제공하는 신체 피지컬 테스트
                </p>
                <ul className={`youth-game-photo`}>
                    <li style={{backgroundImage: `url(/files/youth/intro/measurement1.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/measurement2.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/measurement3.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/measurement4.jpg)`}}></li>
                </ul>
            </div>
        </div>
    )
}

export default MeasurementComponent;