import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const YouthBranchInfoBot = (props) => {
    const { info } = props;

    return(

        <div className={`con-box`}>
            <div className={`con-tit`}>
                <h4>안내‧가입</h4>
            </div>
            {/*<div className={`con-tit`}>
                <p dangerouslySetInnerHTML={{ __html: info[0].content }}></p>
            </div>*/}
            <div className={`board-view-wrap`} id={'board-wrap'}>
                <div className={`board-con`} style={{lineHeight : 'initial'}}>
                    {info && ReactHtmlParser(info[0].content)}
                </div>
            </div>
        </div>
    );
}

export default YouthBranchInfoBot;
