import React from 'react';
import { getSimpleDateFormat } from '../../../helpers/dateHelper'
import EmptyContentComponent from "./EmptyContentComponent";

const YouthPhotoBBSComponent = props => {
    const { history, type, list, photoType, startIndex } = props;

    const detailPage = e => {
        e.preventDefault();
        history.push({
            pathname: `${history.location.pathname}/${e.currentTarget.getAttribute('data-id')}`,
            state: {
                from: history.location.pathname,
                contentIdx: e.currentTarget.getAttribute('data-index') ? e.currentTarget.getAttribute('data-index') : null,
                type: type ? type : null
            }
        });
    }

    return (
        list && list.length > 0 ?
        <ul className={'media-list-grid-3'}>
            {
                list.map((item, index) =>
                    <li key={`media-photo-list-${index}`} onClick={detailPage} data-id={item.artcSq} data-index={Number(startIndex) + index}>
                        <div className={`img`} style={{backgroundImage: `url(${item.mediaListThumbPath ? item.mediaListThumbPath : ''})`}} />
                        <div className={`txt`}>
                            <div className={`title`}>
                                <span className={`tag`}>{photoType}</span>
                                <h6>{item.artcTitle}</h6>
                            </div>
                            <span>{getSimpleDateFormat(item.regDttm)}</span>
                        </div>
                    </li>
                )
            }
        </ul>
        : <EmptyContentComponent />
    );
}

export default YouthPhotoBBSComponent;