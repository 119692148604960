import React, { useState, useEffect } from 'react';
import InquiryComponent from './YouthInquiryComponent';
import {addYouthInquireInfo} from '../../../../../service/member/Member.service'
import { useSelector } from 'react-redux';
import {getMobileAppHeaderData} from "../../../../../helpers/apiHelper";
import AlertComponent from "../../../../commons/popup/AlertComponent";

const YouthInquiryContainer = props => {
  const { history } = props;
  const { isLogin } = useSelector(state => state.commonAuth);
  const [title, setTitle] = useState(null);
  const [category, setCategory] = useState('경기');
  const [content, setContent] = useState(null);
  const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const changeValues = e => {
    const type = e.currentTarget.dataset.type;
    const value = e.currentTarget.value;
    if (type === 'title') {
      setTitle(value)
    } else {
      setContent(value)
    }
  }

  const clickSubmit = async e => {
    e.preventDefault();
    if (!title) {
      openAlertPopup('제목을 입력해 주세요.');
      return;
    }

    if (!category) {
      openAlertPopup('구분을 선택해 주세요.');
      return;
    }

    if (!content) {
      openAlertPopup('내용을 입력해 주세요.');
      return;
    }

    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    const result = await addYouthInquireInfo({ title: title, content: content, category: category });

    setIsProcessing(false);

    if (result.status !== 200) {
      openAlertPopup(result.data.message);
    } else {
      openAlertPopup("문의∙제안사항이 등록되었습니다.\n 답변은 \'마이페이지\'에서 확인하실 수 있습니다.");
      setIsCompleted(true);
      //history.push('/');
    }
  }

  const clickCancel = e => {
    e.preventDefault();
    history.push('/');
  }

  const alertPopupClose = e => {
    e.preventDefault();
    setAlertMessage('');
    setIsAlertPopupDisplay(false);
    if (!isLogin) {
      history.push('/auth/login');
    }

    if (isCompleted) {
      const mobileAppHeaerValues = getMobileAppHeaderData();
      // app 인 경우 웹뷰 종료 callback 호출
      if (mobileAppHeaerValues) {
        if (mobileAppHeaerValues.device.toUpperCase() === 'ANDROID') {
          if (window.KblAndroid && window.KblAndroid.exitCallback) {
            window.KblAndroid.exitCallback();
          }
        } else {
          if (window.webkit
            && window.webkit.messageHandlers
            && window.webkit.messageHandlers.exitCallback
            && window.webkit.messageHandlers.exitCallback.postMessage) {
            window.webkit.messageHandlers.exitCallback.postMessage('');
          }
        }

      }
      history.push('/');
    }
  }

  const openAlertPopup = message => {
    setAlertMessage(message);
    setIsAlertPopupDisplay(true);
  }

  useEffect(_ => {
    console.log('InquiryComponent::componentDidMount');
    if (!isLogin) {
      openAlertPopup('로그인이 필요한 페이지 입니다.')
    }

    return function cleanup() {
      console.log('InquiryComponent::componentWillUnmount');
    }
  }, [])

  return (
    <React.Fragment>
      {
        isLogin ?
          <InquiryComponent
            {...props}
            title={title}
            content={content}
            changeValues={changeValues}
            clickSubmit={clickSubmit}
            clickCancel={clickCancel}
            category={category}
            setCategory={setCategory}
          />
          : null
      }
      <AlertComponent
        isDisplay={isAlertPopupDisplay}
        clickCloseButton={alertPopupClose}
        content={alertMessage}
      />
    </React.Fragment>
  );
}

export default YouthInquiryContainer;