import React from 'react';

const YouthRegistSuccess = props => {
    const {isRegistPopupDisplay, setIsRegistPopupDisplay, history} = props;

    const registConfirm = () => {
        setIsRegistPopupDisplay(false);
        history.push('/');
    }

    return(
        <div className={`popup`} style={{ display: isRegistPopupDisplay ? 'block' : 'none' }}>
            <div className={`pop-wrap`}>
                <div className={`pop-tit`}>
                    <h4>유소년 등록 완료</h4>
                    <i className={`ic-pop-close-24`} onClick={() => {setIsRegistPopupDisplay(false)}} />
                </div>
                <div className={`pop-con`}>
                    <div className={`pop-empty`}>
                        <i className={`img-join-complete-120`} />
                        <h6>유소년 등록이 완료되었습니다.</h6>
                        <p>유소년 정보 변경은 마이페이지 내에서 변경해주세요.<br/><span className={`font-red`}>(관리자 승인 후 최종 등록이 완료됩니다.)</span></p>
                    </div>
                </div>
                <div className={`pop-btn`}>
                    <button className={`pb`} onClick={registConfirm}>확인</button>
                </div>
            </div>
        </div>
    );
}

export default YouthRegistSuccess;