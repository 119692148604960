import React from 'react';
import PropTypes from 'prop-types';

const ResetPasswordComponent = props => {
  const { isDisplay, resetPwd, resetPwdConfirm, clickConfirm, clickCloseButton,
    resetPwdChange, resetPwdConfirmChange, isValidPwd, isSamePwd } = props;

  return (
    <div className='popup' style={{ display: isDisplay ? 'block' : 'none' }}>
      <div className={`pop-wrap`}>
        <div className={`pop-tit`}>
          <h4>비밀번호 재설정</h4>
          <i className={`ic-pop-close-24`} onClick={clickCloseButton} />
        </div>
        <div className={`pop-con`}>
          <p>새로운 비밀번호 설정 후 로그인을 시도해 주세요.<br/>비밀번호는 영문,숫자,특수기호 조합으로 설정해주세요.</p>
          <ul className={`auth-input`}>
            <li>
              <input type='password' placeholder='새로운 비밀번호(8~15자리)를 입력해주세요.' value={resetPwd} onChange={resetPwdChange} />
            </li>
            <li>
              <span className={'font-red'} style={{ display: !isValidPwd ? 'block' : 'none' }}>사용할 수 없는 비밀번호입니다.</span>
            </li>
            <li>
              <input type='password' placeholder={'비밀번호를 재입력해주세요.'} value={resetPwdConfirm} onChange={resetPwdConfirmChange} />
            </li>
            <li>
              <span className={'font-red'} style={{ display: !isSamePwd ? 'block' : 'none' }}>비밀번호가 일치하지 않습니다.</span>
            </li>
          </ul>

        </div>
        <div className={`pop-btn`}>
          <button className={`light-pb`} onClick={clickCloseButton}>취소</button>
          <button className={`pb`} onClick={clickConfirm}>확인</button>
        </div>
      </div>
    </div>
  );
}


ResetPasswordComponent.propTypes = {
  isDisplay: PropTypes.bool.isRequired,
  clickCloseButton: PropTypes.func.isRequired,
  clickConfirm: PropTypes.func.isRequired,
  resetPwdChange: PropTypes.func.isRequired,
  resetPwdConfirmChange: PropTypes.func.isRequired,
  resetPwd: PropTypes.string.isRequired,
  resetPwdConfirm: PropTypes.string.isRequired,

};

export default ResetPasswordComponent;