import * as BrsnchService from '../../service/youth/Branch.service';
import {createPromiseThunkById, handleAsyncActionsById} from '../../helpers/asyncHelper';

const GET_INFO = 'youth_branch/GET_INFO';
const GET_INFO_SUCCESS = 'youth_branch/GET_INFO_SUCCESS';
const GET_INFO_ERROR = 'youth_branch/GET_INFO_ERROR';

export const getInfo = createPromiseThunkById(GET_INFO, BrsnchService.getInfo);
const initialState = {
  info: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_INFO:
    case GET_INFO_SUCCESS:
    case GET_INFO_ERROR:
      return handleAsyncActionsById(GET_INFO, 'info', true)(state, action);
    default:
      return state;
  }
}