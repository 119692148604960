import React from 'react';

const NominatedPlayersComponent = props => {
    const { isMobile } = props;

    return (
        /*<div className={`con-box`}>
            <div className={`youth-box`}>
                <div className={`youth-tit`}>
                    <h4>소개</h4>
                </div>
                <p>
                    지역 연고제 활성화 및 농구 유망주 발굴을 위한 제도로 KBL 각 구단이 해당 구단 산하 유소년 농구 클럽(유소년 농구 교실)에 등록한 (만 14세 이하) 선수를 대상으로 <br className={`desktop-only`} />
                    매년 최대 2명을 지명할 수 있으며 고등학교 졸업 후 별도의 드래프트 없이 구단으로 입단 가능
                </p>
            </div>
            <div className={`youth-box`}>
                <div className='player'>
                    <div className='video-container'>
                        <iframe src={`https://www.youtube.com/embed/JKmKgI8kOtk`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '675' : '200'}`} style={{borderRadius: '12px' }} />
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/nominated-players1.jpg`} style={{width: '-webkit-fill-available'}}/>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/nominated-players2.jpg`} style={{width: '-webkit-fill-available'}}/>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/nominated-players3.jpg`} style={{width: '-webkit-fill-available'}}/>
                </div>
            </div>
        </div>*/

        <div className={`youth-box`}>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    {/*<h4>영상</h4>*/}
                </div>
                <ul className={`youth-game-highlight-photo`}>
                    <li>
                        <div className='player'>
                            <div className='video-container'>
                                <iframe src={`https://www.youtube.com/embed/JKmKgI8kOtk`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '330' : '220'}`} style={{borderRadius: '12px' }} />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='player'>
                            <div className='video-container'>
                                <iframe src={`https://www.youtube.com/embed/_UCm6uWGv2A?start=62`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '330' : '220'}`} style={{borderRadius: '12px' }} />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>소개</h4>
                </div>
                <p>
                    지역 연고제 활성화 및 농구 유망주 발굴을 위한 제도로 KBL 각 구단이 해당 구단 산하 유소년 농구 클럽(유소년 농구 교실)에 등록한 (만 14세 이하) 선수를 대상으로 <br className={`desktop-only`} />
                    매년 최대 2명을 지명할 수 있으며 고등학교 졸업 후 별도의 드래프트 없이 구단으로 입단 가능
                </p>
                <ul className={`youth-game-photo`}>
                    <li style={{backgroundImage: `url(/files/youth/intro/nominated-players1.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/nominated-players2.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/nominated-players3.jpg)`}}></li>
                </ul>
            </div>
        </div>
    )
}

export default NominatedPlayersComponent;