import React, {useEffect, useState} from 'react';
import {getYouthMeasurementPartsPercent} from "../../../../service/youth/Measurement.service";

const MeasurementPercentComponent = props => {

    const [schoolCode, setSchoolCode] = useState('2');
    const [gradeList, setGradeList] = useState(null); //전체 등급, 기록 '조회' 결과
    const [gradeResult, setGradeResult] = useState(null); //수치에 따른 전체 등급 결과
    const [partsInput, setPartsInput] = useState(null); //

    const gradeListAsc = [ //높을수록 좋은 기록(sortType = true)
        {key: 1, value: 'A', minPer: 81, maxPer: 100},
        {key: 2, value: 'B', minPer: 61, maxPer: 81},
        {key: 3, value: 'C', minPer: 41, maxPer: 61},
        {key: 4, value: 'D', minPer: 21, maxPer: 41},
        {key: 5, value: 'E', minPer: 0,  maxPer: 21},
    ]
    const gradeListDesc = [ //낮을수록 좋은 기록(sortType = false)
        {key: 1, value: 'E', minPer: 0,  maxPer: 20},
        {key: 2, value: 'D', minPer: 21, maxPer: 40},
        {key: 3, value: 'C', minPer: 41, maxPer: 60},
        {key: 4, value: 'B', minPer: 61, maxPer: 80},
        {key: 5, value: 'A', minPer: 81, maxPer: 100},
    ]
    const partsGradeList = [
        {key: 'height',         value: '',  per: '0'},
        {key: 'arm_length',     value: '',  per: '0'},
        {key: 'standing_reach', value: '',  per: '0'},
        {key: 'grip_strength',  value: '',  per: '0'},
        {key: 'pushup_cnt',     value: '',  per: '0'},
        {key: 'v_jump',         value: '',  per: '0'},
        {key: 'run_v_jump',     value: '',  per: '0'},
        {key: 'lane_agility',   value: '',  per: '0'},
        {key: 'yard_sprint',    value: '',  per: '0'},
        {key: 'court_sprint',   value: '',  per: '0'},
        {key: 'shuttle_run',    value: '',  per: '0'},
    ]
    const partsInputList = [
        {key: 'height',         value: ''},
        {key: 'arm_length',     value: ''},
        {key: 'standing_reach', value: ''},
        {key: 'grip_strength',  value: ''},
        {key: 'pushup_cnt',     value: ''},
        {key: 'v_jump',         value: ''},
        {key: 'run_v_jump',     value: ''},
        {key: 'lane_agility',   value: ''},
        {key: 'yard_sprint',    value: ''},
        {key: 'court_sprint',   value: ''},
        {key: 'shuttle_run',    value: ''},
    ]

    const changeSchoolCode = e => setSchoolCode(e.target.value);

    const getRecordData = async () => {
        const record = await getYouthMeasurementPartsPercent({schoolCode : schoolCode});
        setGradeList(record);
    }

    const getPartsGradeAndPer = async (part, inputValue, sortType) => {
        if(gradeList){
            let list = [...gradeList];
            let partList = []; //해당 측정항목의 등급별 최대, 최소값
            let partGradeList = []; //해당 측정항목의 + 특정 등급의 최대, 최소값
            let gradeKey = "1"; //등급번호(1~5)
            let gradeValue = ""; //등급명(A~E)
            let percent = 0; //퍼센트(0~100%)

            list.map((item, idx) => item.part === part ? partList = [...item.stats] : null);
            if(partList.length > 0){
                for(let i=0; i<partList.length; i++){
                    if(partList[i].grade == 1 && inputValue >= partList[i].maxValue){
                        gradeKey = 1;
                    }else if(partList[i].grade == 5 && inputValue < partList[i].minValue){
                        gradeKey = 5;
                    }else if(inputValue < partList[i].maxValue && inputValue >= partList[i].minValue){
                            gradeKey = partList[i].grade;
                    }else if(partList[i].grade != 1 && partList[i].grade != 5 && inputValue > partList[i].maxValue && inputValue < partList[i-1].minValue){
                            gradeKey = partList[i-1].grade;
                    }
                }
            }

            sortType ? gradeValue = gradeListAsc.find(list => list.key == gradeKey).value
                     : gradeValue = gradeListDesc.find(list => list.key == gradeKey).value;

            partList.map((item, idx) => item.grade == gradeKey ? partGradeList.push(item) : null);
            if(partGradeList.length > 0){
                let minPer = sortType ? gradeListAsc.find(list => list.key == gradeKey).minPer : gradeListDesc.find(list => list.key == gradeKey).minPer;
                let maxPer = sortType ? gradeListAsc.find(list => list.key == gradeKey).maxPer : gradeListDesc.find(list => list.key == gradeKey).maxPer;

                if(sortType){
                    if(parseFloat(inputValue) == partGradeList[0].minValue){ //입력값 = 최소값 이면 해당 등급의 최소 퍼센트 return
                        percent = minPer;
                    }else if(gradeValue === 'A' && parseFloat(inputValue) >= partGradeList[0].maxValue){ //입력값 > 최대값 이면 100%
                        percent = 100;
                    }else{
                        percent = minPer + (20 * (inputValue - partGradeList[0].minValue) / (partGradeList[0].maxValue - partGradeList[0].minValue));
                    }
                }else if(!sortType){
                    if(parseFloat(inputValue) == partGradeList[0].maxValue){ //입력값 = 최대값 이면 해당 등급의 최소 퍼센트 return
                        percent = minPer - 1;
                    }else if(gradeValue === 'A' && parseFloat(inputValue) <= partGradeList[0].minValue){ //입력값 < 최대값 이면 100%
                        percent = 100;
                    }else{
                        percent = maxPer - (20 * (inputValue - partGradeList[0].minValue) / (partGradeList[0].maxValue - partGradeList[0].minValue))
                    }
                }
            }
            // console.log("# ### #### #####: ", inputValue, " / partGradeList : ", partGradeList,  " / percent : ", percent, " / round : ", Math.round(percent));

            return {
                gradeValue: gradeValue,
                gradePercent: percent < 0 ? 0 : (percent > 100) ? 100 : Math.round(percent)
            };
        }
    }

    const changeParams = (part, input, sortType) => {
        getPartsGradeAndPer(part, input, sortType).then(function(data){
            const gList = [...gradeResult];
            const iList = [...partsInput];

            gList.find(list => list.key === part).value = data.gradeValue;
            gList.find(list => list.key === part).per = data.gradePercent;
            setGradeResult(gList);

            iList.find(list => list.key === part).value = input;
            setPartsInput(iList);
        });

    }

    useEffect(() => {
        console.log('MeasurementPercentComponent::componentDidMount');

        setGradeResult(partsGradeList);
        setPartsInput(partsInputList);

        return function cleanup() {
            console.log('MeasurementPercentComponent::componentWillUnmount');
        }
    }, []);

    useEffect(() => {
        getRecordData();
        setGradeResult(partsGradeList); //초기화
        setPartsInput(partsInputList); //초기화
    }, [schoolCode]);

    return(
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>퍼센타일 확인</h2>
                <p>수치를 입력하여 퍼센타일을 확인할 수 있습니다. <br className={`mobile-only`}/>각 등급은 작년 측정 데이터 기준으로 적용됩니다.</p>
            </div>

            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>퍼센타일</h4>
                    <select onChange={changeSchoolCode}>
                        <option value={'2'}>중학교</option>
                        <option value={'21'}>중1</option>
                        <option value={'22'}>중2</option>
                        <option value={'23'}>중3</option>
                        <option value={'3'}>고등학교</option>
                        <option value={'31'}>고1</option>
                        <option value={'32'}>고2</option>
                        <option value={'33'}>고3</option>
                    </select>
                </div>
                <div className='scroll-table'>
                    <table className='physical-table1'>
                        <thead>
                            <tr>
                                <th>측정 항목</th>
                                <th>측정 목적</th>
                                <th>수치</th>
                                <th>등급</th>
                                <th>E(0%~20%)</th>
                                <th>D(21%~40%)</th>
                                <th>C(41%~60%)</th>
                                <th>B(61%~80%)</th>
                                <th>A(81%~100%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>신장</td>
                                <td>신체조성</td>
                                <td><input type='number' onChange={(e) => changeParams('height', e.currentTarget.value, true)} value={partsInput ? partsInput.find(list => list.key === 'height').value : ''}/></td>
                                <td>{gradeResult ? gradeResult.find(list => list.key === 'height').value : ''}</td>
                                <td colSpan={5}>
                                    <div className='physical-graph'>
                                        <p>{gradeResult ? gradeResult.find(list => list.key === 'height').per : '0'}%</p>
                                        <div><span style={{width: `${gradeResult ? gradeResult.find(list => list.key === 'height').per : '0'}%`}}></span></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>윙스펜</td>
                                <td>신체조성</td>
                                <td><input type='number' onChange={(e) => changeParams('arm_length', e.currentTarget.value, true)} value={partsInput ? partsInput.find(list => list.key === 'arm_length').value : ''}/></td>
                                <td>{gradeResult ? gradeResult.find(list => list.key === 'arm_length').value : ''}</td>
                                <td colSpan={5}>
                                    <div className='physical-graph'>
                                        <p>{gradeResult ? gradeResult.find(list => list.key === 'arm_length').per : '0'}%</p>
                                        <div><span style={{width: `${gradeResult ? gradeResult.find(list => list.key === 'arm_length').per : '0'}%`}}></span></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>스탠딩리치</td>
                                <td>신체조성</td>
                                <td><input type='number' onChange={(e) => changeParams('standing_reach', e.currentTarget.value, true)} value={partsInput ? partsInput.find(list => list.key === 'standing_reach').value : ''}/></td>
                                <td>{gradeResult ? gradeResult.find(list => list.key === 'standing_reach').value : ''}</td>
                                <td colSpan={5}>
                                    <div className='physical-graph'>
                                        <p>{gradeResult ? gradeResult.find(list => list.key === 'standing_reach').per : '0'}%</p>
                                        <div><span style={{width: `${gradeResult ? gradeResult.find(list => list.key === 'standing_reach').per : '0'}%`}}></span></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>악력</td>
                                <td>근력</td>
                                <td><input type='number' onChange={(e) => changeParams('grip_strength', e.currentTarget.value, true)} value={partsInput ? partsInput.find(list => list.key === 'grip_strength').value : ''}/></td>
                                <td>{gradeResult ? gradeResult.find(list => list.key === 'grip_strength').value : ''}</td>
                                <td colSpan={5}>
                                    <div className='physical-graph'>
                                        <p>{gradeResult ? gradeResult.find(list => list.key === 'grip_strength').per : '0'}%</p>
                                        <div><span style={{width: `${gradeResult ? gradeResult.find(list => list.key === 'grip_strength').per : '0'}%`}}></span></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>1분 푸쉬업</td>
                                <td>근지구력</td>
                                <td><input type='number' onChange={(e) => changeParams('pushup_cnt', e.currentTarget.value, true)} value={partsInput ? partsInput.find(list => list.key === 'pushup_cnt').value : ''}/></td>
                                <td>{gradeResult ? gradeResult.find(list => list.key === 'pushup_cnt').value : ''}</td>
                                <td colSpan={5}>
                                    <div className='physical-graph'>
                                        <p>{gradeResult ? gradeResult.find(list => list.key === 'pushup_cnt').per : '0'}%</p>
                                        <div><span style={{width: `${gradeResult ? gradeResult.find(list => list.key === 'pushup_cnt').per : '0'}%`}}></span></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>버티컬 점프</td>
                                <td>순발력 및 점프력</td>
                                <td><input type='number' onChange={(e) => changeParams('v_jump', e.currentTarget.value, true)} value={partsInput ? partsInput.find(list => list.key === 'v_jump').value : ''}/></td>
                                <td>{gradeResult ? gradeResult.find(list => list.key === 'v_jump').value : ''}</td>
                                <td colSpan={5}>
                                    <div className='physical-graph'>
                                        <p>{gradeResult ? gradeResult.find(list => list.key === 'v_jump').per : '0'}%</p>
                                        <div><span style={{width: `${gradeResult ? gradeResult.find(list => list.key === 'v_jump').per : '0'}%`}}></span></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>맥스버티컬 점프</td>
                                <td>순발력 및 점프력</td>
                                <td><input type='number' onChange={(e) => changeParams('run_v_jump', e.currentTarget.value, true)} value={partsInput ? partsInput.find(list => list.key === 'run_v_jump').value : ''}/></td>
                                <td>{gradeResult ? gradeResult.find(list => list.key === 'run_v_jump').value : ''}</td>
                                <td colSpan={5}>
                                    <div className='physical-graph'>
                                        <p>{gradeResult ? gradeResult.find(list => list.key === 'run_v_jump').per : '0'}%</p>
                                        <div><span style={{width: `${gradeResult ? gradeResult.find(list => list.key === 'run_v_jump').per : '0'}%`}}></span></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>레인 어질리티</td>
                                <td>방향전환, 민첩성</td>
                                <td><input type='number' onChange={(e) => changeParams('lane_agility', e.currentTarget.value, false)} value={partsInput ? partsInput.find(list => list.key === 'lane_agility').value : ''}/></td>
                                <td>{gradeResult ? gradeResult.find(list => list.key === 'lane_agility').value : ''}</td>
                                <td colSpan={5}>
                                    <div className='physical-graph'>
                                        <p>{gradeResult ? gradeResult.find(list => list.key === 'lane_agility').per : '0'}%</p>
                                        <div><span style={{width: `${gradeResult ? gradeResult.find(list => list.key === 'lane_agility').per : '0'}%`}}></span></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>10야드 스프린트</td>
                                <td>속도, 가속도</td>
                                <td><input type='number' onChange={(e) => changeParams('yard_sprint', e.currentTarget.value, false)} value={partsInput ? partsInput.find(list => list.key === 'yard_sprint').value : ''}/></td>
                                <td>{gradeResult ? gradeResult.find(list => list.key === 'yard_sprint').value : ''}</td>
                                <td colSpan={5}>
                                    <div className='physical-graph'>
                                        <p>{gradeResult ? gradeResult.find(list => list.key === 'yard_sprint').per : '0'}%</p>
                                        <div><span style={{width: `${gradeResult ? gradeResult.find(list => list.key === 'yard_sprint').per : '0'}%`}}></span></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>¾코트 스프린트</td>
                                <td>속도, 가속도</td>
                                <td><input type='number' onChange={(e) => changeParams('court_sprint', e.currentTarget.value, false)} value={partsInput ? partsInput.find(list => list.key === 'court_sprint').value : ''}/></td>
                                <td>{gradeResult ? gradeResult.find(list => list.key === 'court_sprint').value : ''}</td>
                                <td colSpan={5}>
                                    <div className='physical-graph'>
                                        <p>{gradeResult ? gradeResult.find(list => list.key === 'court_sprint').per : '0'}%</p>
                                        <div><span style={{width: `${gradeResult ? gradeResult.find(list => list.key === 'court_sprint').per : '0'}%`}}></span></div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>300야드 셔틀런</td>
                                <td>심폐능력</td>
                                <td><input type='number' onChange={(e) => changeParams('shuttle_run', e.currentTarget.value, false)} value={partsInput ? partsInput.find(list => list.key === 'shuttle_run').value : ''}/></td>
                                <td>{gradeResult ? gradeResult.find(list => list.key === 'shuttle_run').value : ''}</td>
                                <td colSpan={5}>
                                    <div className='physical-graph'>
                                        <p>{gradeResult ? gradeResult.find(list => list.key === 'shuttle_run').per : '0'}%</p>
                                        <div><span style={{width: `${gradeResult ? gradeResult.find(list => list.key === 'shuttle_run').per : '0'}%`}}></span></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className={`youth-box`}>
            </div>
        </div>
    );
}
export default MeasurementPercentComponent;