import React  from 'react';
import PropTypes from 'prop-types';

const PhoneAuthComponent = props => {
    const { isDisplay, findType, clickCloseButton, clickAuthRequest, clickConfirm,
        phoneNumber, authNumber, phoneNumberChange, authNumberChange, email, changeEmail } = props;

    return (
        <div className={`popup`} style={{ display: isDisplay ? 'block' : 'none' }}>
            <div className={`pop-wrap`}>
                <div className={`pop-tit`}>
                    <h4>
                        {
                            findType === 'FIND_ID' ? '아이디 찾기' : '비밀번호 찾기'
                        }
                    </h4>
                    <i className={`ic-pop-close-24`} onClick={clickCloseButton} />
                </div>
                <div className={`pop-con`}>
                    {
                        findType === 'FIND_ID'
                            ? <p>가입 당시 등록한 휴대폰 번호를 입력한 후<br/>'인증요청' 버튼을 눌러 인증번호를 등록해 주세요.</p>
                            : <p>가입 당시 등록한 아이디와 휴대폰 번호를 입력한 후<br/>'인증요청' 버튼을 눌러 인증번호를 등록해 주세요.</p>
                    }
                    <ul className={`auth-input`}>
                        {
                            findType !== 'FIND_ID' ?
                                <li>
                                    <input type='text' className='email cs' value={email || ''} onChange={changeEmail} placeholder={'아이디를 입력해 주세요.'} />
                                </li>
                                : null
                        }
                        <li className={`input-btn`}>
                            <input type='text' className='phone cs' value={phoneNumber} onChange={phoneNumberChange} placeholder={'휴대폰 번호를 입력해 주세요.'} />
                            <button className={`md gr`} onClick={clickAuthRequest}>인증요청</button>
                        </li>
                        <li><input type='text' className='auth cs' value={authNumber} onChange={authNumberChange} placeholder={'인증번호를 입력해주세요.'} /></li>
                    </ul>
                </div>
                <div className={`pop-btn`}>
                    <button className={`light-pb`} onClick={clickCloseButton}>취소</button>
                    <button className={`pb`} onClick={clickConfirm}>확인</button>
                </div>
            </div>
        </div>
    );
}

PhoneAuthComponent.propTypes = {
    isDisplay: PropTypes.bool.isRequired,
    clickCloseButton: PropTypes.func.isRequired,
};

export default PhoneAuthComponent;