import React, { useEffect, useState } from 'react';
import PaginationComponent from '../../../../commons/table/PaginationComponent';
import {updateYouthViewCount} from "../../../../../service/media/Media.service";
import {getSimpleDateFormat} from "../../../../../helpers/dateHelper";
import EmptyContentComponent from "../../../../commons/table/EmptyContentComponent";
import FilterComponent from "../../../../commons/filter/FilterComponent";
import {youthMediaTypeSelectList} from "../../../../../helpers/codeHelper";

const YouthVideoListComponent = props => {
    const { list, changeParams, pagingData, seasonList, season, gameList, game, videoType, topVideo, location, changeVideoId } = props;
    const [topContent, setTopContent] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    const changePage = e => changeParams('changePage', e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-value'));
    const moreView = e => changeParams('moreView', e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-value'));

    const changeVideo = e => {
        e.preventDefault();
        const id = Number(e.currentTarget.getAttribute('data-id'));
        if(!isMobile) {
            updateYouthViewCount(id);
            changeVideoId(id);
            window.scrollTo({ top: document.querySelector("#content-top").offsetTop });
        } else {
            let url = e.currentTarget.dataset.link;
            if(url) {
                let videoUrl = url.replace('/embed/','/watch?v=')
                window.open(videoUrl)
            }
        }
    }

    useEffect(() => {
        setTopContent(topVideo);
    }, [location]);

    const resizeCallback = () => {
        setIsMobile(window.innerWidth <= 768);
    }

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        resizeCallback();

        return () => {
            window.removeEventListener("resize", resizeCallback);
        }
    }, [topContent])

    return (
        <div className={`contents`} id='content-top'>
            <div className={`con-header`}>
                <h2>경기 다시보기</h2>
                <p>유소년 대회의 다양한 경기 영상을 보실 수 있습니다.</p>
            </div>
            <ul className={`filter-wrap`}>
                <li>
                    <h6>카테고리</h6>
                    <FilterComponent
                        type={'changeVideoType'}
                        selectedKey={videoType}
                        optionClass={'team pc'}
                        selectList={youthMediaTypeSelectList}
                        selectChange={changeParams}
                    />
                </li>
                <li>
                    <h6>시즌</h6>
                    <FilterComponent
                        type={'changeSeason'}
                        selectedKey={season}
                        optionClass={'team pc'}
                        selectList={seasonList}
                        selectChange={changeParams}
                    />
                </li>
                <li>
                    <h6>경기</h6>
                    <FilterComponent
                        type={'changeGame'}
                        selectedKey={game}
                        optionClass={'team pc'}
                        selectList={gameList}
                        selectChange={changeParams}
                    />
                </li>
            </ul>
            <div className={`con-box`}>
                <ul className={`media-list-grid-3`}>
                    {
                        topContent ?
                            <li className={`hilight desktop-only`}>
                                <div className='player'>
                                    <div className='video-container'>
                                        <iframe src={topContent.videoLink} allowFullScreen scrolling="no" allow="encrypted-media" style={{ width: '100%', height: '675px', borderRadius: '12px 12px 0 0' }} />
                                    </div>
                                </div>
                                <div className={`txt`}>
                                    <div className={`title`}>
                                        <span className={`tag`}>{topContent.boardCatSq === 1 ? '경기' : '기타'}</span>
                                        <h6>{topContent.artcTitle}</h6>
                                    </div>
                                    <ul>
                                        <li>{getSimpleDateFormat(topContent.regDttm)}</li>
                                        <li>조회수 {topContent.viewCnt}</li>
                                    </ul>
                                </div>
                            </li>
                            : null
                    }
                    {
                        list && list.length > 0 ?
                            list.map((item, index) =>
                                <li key={`hilist-list-${index}`} data-link={item.videoLink} onClick={changeVideo} data-id={item.artcSq}>
                                    <div className={`img`} style={{ backgroundImage: `url(${item.mediaListThumbPath})` }} />
                                    <div className={`txt`}>
                                        <div className={`title`}>
                                            <span className={`tag`}>{item.boardCatSq === 1 ? '경기' : '기타'}</span>
                                            <h6>{item.artcTitle}</h6>
                                        </div>
                                        <ul className={`flex-txt`}>
                                            <li>{getSimpleDateFormat(item.regDttm)}</li>
                                            <li>조회수 {item.viewCnt}</li>
                                        </ul>
                                    </div>
                                </li>
                            )
                            : null
                    }
                </ul>
                {
                    pagingData ?
                        <PaginationComponent
                            pagingData={pagingData}
                            changePage={changePage}
                            moreView={moreView}
                        />
                        : null
                }
                {
                    !list || list.length < 1 ?
                        <EmptyContentComponent/>
                        : null
                }
            </div>
        </div>
    );
}

export default YouthVideoListComponent;