import React from 'react';

const YouthBranchInfoMid = (props) => {
    const { info } = props;

    console.log(info);
    function moveInfoPage(gymSq){
        props.history.push(`/youth/club/info/${gymSq}`);
    }

    return(
        <div className={`con-box`}>
            <div className={`con-tit`}>
                <h4>지점 안내</h4>
            </div>
            <div className={`scroll-table`}>
                <table>
                    <colgroup>
                        <col style={{width: '15%'}} />
                        <col style={{width: 'auto'}} />
                        <col style={{width: '15%'}} />
                        <col style={{width: '15%'}} />
                    </colgroup>
                    <thead>
                    <tr>
                        <th>지점명</th>
                        <th>위치</th>
                        <th>문의전화</th>
                        <th>상세보기</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        info.map((item) => {
                            return (
                                <tr key={item.num.toString()}>
                                    <td>{item.title}</td>
                                    <td>{item.address}</td>
                                    <td>{item.tel}</td>
                                    <td><button className={`sm pb`} onClick={() => {moveInfoPage(item.infoSq)}}>바로가기</button></td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default YouthBranchInfoMid;