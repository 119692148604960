import React, { useEffect } from 'react';
import MyPageComponent from './MyPageComponent'

import { useSelector } from 'react-redux';


const MyPageContainer = props => {
  const { history } = props;
  const { isLogin } = useSelector(state => state.commonAuth);
  
  useEffect(_ => {
    console.log('SignUpContainer::componentDidMount');

    if (!isLogin) {
      history.push('/');
    }

    return function cleanup() {
      console.log('SignUpContainer::componentWillUnmount');

    }
  }, [])
  return (
    <MyPageComponent
      {...props}
    />
  );
}

export default MyPageContainer;