import { setCookie, getCookie, removeCookie } from './cookieHelper'

const accessTokenCookieKey = '__NEW_KBL_YOUTH_ACCESS_TOKEN';
const refreshTokenCookieKey = '__NEW_KBL_YOUTH_REFRESH_TOKEN';
const loginAccountCookieKey = '__NEW_KBL_YOUTH_LOGIN_ACCOUNT';

export const setLoginId = token => {
    setCookie(loginAccountCookieKey, token);
}

export const getLoginId = () => {
    return getCookie(loginAccountCookieKey);
}

export const setAccessToken = token => {
    setCookie(accessTokenCookieKey, token);
}

export const getAccessToken = () => {
    return getCookie(accessTokenCookieKey);
}

export const setRefreshToken = token => {
    setCookie(refreshTokenCookieKey, token);
}

export const getRefreshToken = () => {
    return getCookie(refreshTokenCookieKey);
}

export const removeAuthTokens = () => {
    removeCookie(accessTokenCookieKey);
    removeCookie(refreshTokenCookieKey);
}