import React, { useEffect, useState } from 'react';
import { getMobileAppHeaderData } from '../../../../helpers/apiHelper';
import FilterComponent from '../../../commons/filter/FilterComponent'
import { isValidPassword, getMobileNumberFormat } from '../../../../helpers/stringHelper';
import PostCodePopupComponent from '../../../commons/popup/PostCodePopupComponent';
import MypageResetPwdComponent from '../../../commons/popup/MypageResetPwdComponent';
import { getTeamCodeByDomain } from '../../../../helpers/commonHelper'
import * as MemberService from '../../../../service/member/Member.service'
import { sha256 } from 'js-sha256';
import { getTeamCodeList } from '../../../../service/code/Code.service';
import { isJsonString } from '../../../../helpers/objectHelper';
import { setIsLogin, setAuthUserInfo } from '../../../../store/common/auth';
import { removeAuthTokens } from '../../../../helpers/authHelper';
import { useDispatch, useSelector } from 'react-redux';
import AlertComponent from '../../../commons/popup/AlertComponent';
import ConfirmComponent from '../../../commons/popup/ConfirmComponent';
import {defaultCountries, parseCountry, PhoneInput} from "react-international-phone";
import {isValidPhoneNumber} from "react-phone-number-input";
import {TimerRenderer, useDelay} from "react-use-precision-timer";

const PrivateInfoComponent = props => {
    const { history, info, setInfo } = props;
    const { isApp } = useSelector(state => state.commonLayout);
    const [userInfo, setUserInfo] = useState(null);
    const [zipCode, setZipCode] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [sido, setSido] = useState('');
    const [sigungu, setSigungu] = useState('');
    const [email, setEmail] = useState(null);
    const [receiveSMS, setReceiveSMS] = useState(false);
    const [receiveEmail, setReceiveEmail] = useState(false);
    const [receiveAppPush, setReceiveAppPush] = useState(false);
    const [isDisplayPostCodePopup, setIsDisplayPostCodePopup] = useState(false);
    const [favoriteTeamCode, setFavoriteTeamCode] = useState('');
    const [teamList, setTeamList] = useState(null);
    const [isDisplayModifyPage, setIsDisplayModifyPage] = useState(true);
    const [quitReason, setQuitReason] = useState('');
    const [quitReasonSize, setQuitReasonSize] = useState(0);
    const [quitReasonType, setQuitReasonType] = useState('1');

    const [currPwd, setCurrPwd] = useState('');
    const [newPwd, setNewPwd] = useState('');
    const [newPwdConfirm, setNewPwdConfirm] = useState('');
    const [isDisplayPwdRestPopup, setIsDisplayPwdRestPopup] = useState(false);
    const [isValidPwd, setIsValidPwd] = useState(true);
    const [isSamePwd, setIsSamePwd] = useState(true);
    const [mobilePhoneNo, setMobilePhoneNo] = useState('');
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isConfirmPopupDisplay, setIsConfirmPopupDisplay] = useState(false);
    const [isQuitMember, setIsQuitMember] = useState(false);
    const [favoritePlayerCode, setFavoritePlayerCode] = useState(null);
    const [favoritePlayerName, setFavoritePlayerName] = useState('없음');
    const [favoritePlayerTeamName, setFavoritePlayerTeamName] = useState(null);

    const [isForeignerPhoneDisplay, setIsForeignerPhoneDisplay] = useState(false);
    const [phone, setPhone] = useState(''); //전화번호
    const [isValidPhoneNum, setIsValidPhoneNum] = useState(false); //전화번호 인증여부
    //const [isProcessingRequest, setIsProcessingRequest] = useState(false); //인증 진행중 (노출용)
    //const [smsCode, setSmsCode] = useState(null); //인증코드
    const [inputCode, setInputCode] = useState(null); //입력받은 인증코드
    //const [resend, setResend] = useState(false); //재전송 가능(true)

    const [requestButtonDisplay, setRequestButtonDisplay]       = useState(false);      // 인증 "요청" 버튼 display 여부
    const [authCheckButtonDisplay, setAuthCheckButtonDisplay]   = useState(false);      // 인증 "확인" 버튼 display 여부
    const [resendButtonDisplay, setResendButtonDisplay]         = useState(false);      // 재전송 버튼 display 여부
    const [isInputEditAble, setIsInputEditAble]                 = useState(true);       // 번호입력창 수정가능 여부
    const [isTimerOn, setIsTimerOn]                             = useState(false);      // 타이머동작여부(인증요청전까지 false)
    const [defaultTime, setDefaultTime]                         = useState(180000);      // 재전송 타이머 세팅.( default : 3min(180000))
    const [renderRate, setRenderRate]                           = useState(100);

    const dispatch = useDispatch();

    /*재전송 노출관련 타이머(progressBar)*/
    const callback = React.useCallback(() => timerEnd(), []);
    const onceTimer = useDelay(defaultTime, callback);
    /*재전송 노출관련 타이머*/

    const timerEnd = () =>
    {
        setIsTimerOn(false);
        setIsInputEditAble(true);
    }

    const changeCurrPwd = e => {
        const value = e.currentTarget.value;
        setCurrPwd(value)
    }

    const changeNewPwd = e => {
        const value = e.currentTarget.value;
        setIsValidPwd(isValidPassword(value));
        setNewPwd(value);
    }

    const changeNewPwdConfirm = e => {
        const value = e.currentTarget.value;
        setIsSamePwd(value === newPwd);
        setNewPwdConfirm(e.currentTarget.value)
    }

    // const dispatch = useDispatch();
    const changeReceiveSMS = e => setReceiveSMS(e.currentTarget.checked);
    const changeReceiveEmail = e => setReceiveEmail(e.currentTarget.checked);
    const changeReceiveAppPush = e => setReceiveAppPush(e.currentTarget.checked);
    const changeAddress2 = e => setAddress2(e.currentTarget.value)

    const changeQuitReason = e => {
        const value = e.currentTarget.value;
        const valueSize = value.length;
        if (valueSize > 200) {
            return
        }
        setQuitReasonSize(valueSize);
        setQuitReason(value);
    }

    const changeQuitReasonType = e => {
        const value = e.currentTarget.value;
        setQuitReasonType(value);
    }

    const changeEmail = e => {
        const value = e.currentTarget.value;
        setEmail(value);
    }
    const clickQuitMemberPage = e => {
        e.preventDefault();
        // window.scrollTo(0, 0);
        setIsDisplayModifyPage(false);
    }

    const cancelQuitMember = e => {
        e.preventDefault();
        // window.scrollTo(0, 0);
        setIsDisplayModifyPage(true);
    }

    const quitMember = async e => {
        e.preventDefault();
        if (isProcessing) {
            return;
        }

        setIsConfirmPopupDisplay(false);

        setIsProcessing(true);

        const params = {
            leaveType: quitReasonType,
            reason: quitReason,
            foreignerJoin: userInfo.foreignerJoin
        }

        const result = await MemberService.deleteMemberInfo(params);

        setIsProcessing(false);

        if (result.status === 200) {
            setIsQuitMember(true);
            openAlertPopup("탈퇴 처리되었습니다.");

            const mobileAppHeaerValues = getMobileAppHeaderData();

            // app 인 경우 웹뷰 종료 callback 호출
            if (mobileAppHeaerValues) {
                if (mobileAppHeaerValues.device.toUpperCase() === 'ANDROID') {
                    if (window.KblAndroid && window.KblAndroid.exitCallback) {
                        window.KblAndroid.exitCallback();
                    }
                } else {
                    if (window.webkit
                        && window.webkit.messageHandlers
                        && window.webkit.messageHandlers.exitCallback
                        && window.webkit.messageHandlers.exitCallback.postMessage) {
                        window.webkit.messageHandlers.exitCallback.postMessage('');
                    }
                }
            }

        } else {
            openAlertPopup(result.data.message);
        }
    }

    const confirmPwdRest = async e => {
        e.preventDefault();

        if (!(currPwd && newPwd && newPwdConfirm)) {
            openAlertPopup("비밀번호를 입력해 주세요.");
            return;
        }

        if (!isValidPwd) {
            openAlertPopup("사용할 수 없는 비밀번호입니다.");
            return;
        }

        if (!isSamePwd) {
            openAlertPopup("비밀번호가 일치하지 않습니다.");
            return;
        }

        if (isProcessing) {
            return;
        }

        setIsProcessing(true);


        const result = await MemberService.resetMemberPwd({ currPwd: sha256(currPwd), newPwd: sha256(newPwd), newPwdConfirm: sha256(newPwdConfirm) });

        setIsProcessing(false);

        if (result.status !== 200) {
            openAlertPopup(result.data.message);
        } else {
            openAlertPopup("비밀번호가 변경되었습니다. ")
            setIsDisplayPwdRestPopup(false);
            restPwdValues();
        }
    }

    const restPwdValues = _ => {
        setCurrPwd('');
        setNewPwd('');
        setNewPwdConfirm('');
    }

    const closePwdRetPopup = e => {
        e.preventDefault();
        setIsDisplayPwdRestPopup(false);
        restPwdValues();
    }

    const openPwdRetPopup = e => {
        e.preventDefault();
        setIsDisplayPwdRestPopup(true);
    }

    const saveMemberInfo = async e => {
        e.preventDefault();

        if (isProcessing) {
            return;
        }

        setIsProcessing(true);

        const params = {
            email: email,
            zipCode: zipCode,
            addr: address1,
            addrDetail: address2,
            sido: sido,
            sigungu: sigungu,
            favoriteTeamCode: favoriteTeamCode,
            favoritePlayerCode: favoritePlayerCode,
            receiveAgreeList: [
                {
                    receiveType: 'SMS',
                    isAgree: receiveSMS
                },
                {
                    receiveType: 'EMAIL',
                    isAgree: receiveEmail
                },
                {
                    receiveType: 'PUSH',
                    isAgree: receiveAppPush
                }
            ]
        }

        const result = userInfo.foreignerJoin ? await MemberService.updateMemberInfoForeigner(params) : await MemberService.updateMemberInfo(params);

        setIsProcessing(false);

        if (result.status === 200) {
            openAlertPopup("회원정보가 수정되었습니다.");

            const mobileAppHeaerValues = getMobileAppHeaderData();
            // app 인 경우 웹뷰 callback 호출
            if (mobileAppHeaerValues) {
                if (mobileAppHeaerValues.device.toUpperCase() === 'ANDROID') {
                    if (window.KblAndroid && window.KblAndroid.changeMemInfoCallback) {
                        window.KblAndroid.changeMemInfoCallback();
                    }
                } else {
                    if (window.webkit
                        && window.webkit.messageHandlers
                        && window.webkit.messageHandlers.changeMemInfoCallback
                        && window.webkit.messageHandlers.changeMemInfoCallback.postMessage) {
                        window.webkit.messageHandlers.changeMemInfoCallback.postMessage('');
                    }
                }
            }

            setInfo();
        } else {
            openAlertPopup(result.data.message);
        }
    }

    const closePostCodePopup = e => {
        e.preventDefault();
        setIsDisplayPostCodePopup(false);
    }

    const searchedAddress = data => {
        setZipCode(data.zonecode);
        setAddress1(data.roadAddress);
        setSido(data.sido);
        setSigungu(data.sigungu);
        setIsDisplayPostCodePopup(false);
    }

    const clickPostCodeSearch = e => {
        e.preventDefault()
        setIsDisplayPostCodePopup(true);
    }

    const changeTavoriteTeamCode = (key, value) => {
        setFavoriteTeamCode(key);
    }

    const clickFavoritePlayer = e => {
        e.preventDefault()
        window.open('/member/mypage/player', '_blank', 'top=100,left=350, height=1000,width=1000')
    }

    const setTeamSelectList = list => {
        const result = [];
        if (list) {
            list.map(item => {
                if (item.teamCode !== "29" && item.teamCode !== "73") {   // 선호구단 상무, 데이원 제거
                    result.push({key: item.teamCode, value: item.teamName});
                }
            })
        }

        return result;
    }

    const alertPopupClose = e => {
        e.preventDefault();
        setAlertMessage('');
        setIsAlertPopupDisplay(false);
        if (isQuitMember) {
            removeAuthTokens();
            dispatch(setIsLogin(false));
            dispatch(setAuthUserInfo({}));
            //window.logSetUserId('');
            history.push('/');
        }
    }

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }

    /**
     * 회원 전화번호 변경
     *
     * @param {*} e
     */
    const clickMobilePhoneModify = e => {
        e.preventDefault()

        /**
         * 1. 본인인증창 열기
         */
        // var x = new XMLHttpRequest();
        // x.onreadystatechange = () => {
        //     if (x.readyState === x.DONE) {
        //         if (x.status === 200 || x.status === 201) {
        //             console.log('@ Open Cert Window => ', x.responseText);
        //             var certWindow = window.open('', 'certWindow', 'width=100, height=100');
        //             certWindow.document.open();
        //             certWindow.document.write(x.responseText);
        //         } else {
        //             console.error('@ Open Cert Window => ', x.responseText);
        //             openAlertPopup('서버에 연결할 수 없습니다')
        //         }
        //     }
        // }
        // x.open('POST', `/auth/web-cert?certType=MODIFY_PHONE&adultYn=Y`);
        // x.setRequestHeader('Channel', 'WEB');
        // x.setRequestHeader('TeamCode', process.env.REACT_APP_ENV === "local" ? "LOCAL" : getTeamCodeByDomain()); // FIXME : local test를 위한 팀코드 임시 LOCAL 전송
        // x.setRequestHeader('Device', 'WEB');
        // x.setRequestHeader('Authorization', '')
        // x.send();

        window.open(`/auth/web-cert/phone/call?channel=WEB&device=WEB&certType=MODIFY_PHONE&teamCode=${process.env.REACT_APP_ENV === "local" ? "LOCAL" : getTeamCodeByDomain()}`, 'kbl-web', 'top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no');

    }

    const certedChangedMobileNumber = async data => {
        const result = await MemberService.certedMobilePhoneNumberChanged({ certedResponseNumber: data.responseNumber });
        if (result.status === 200) {
            openAlertPopup("휴대폰 번호가 수정되었습니다.");
            setInfo();
            //setMobilePhoneNo(data.certedMobilePhoneNo);
        } else {
            openAlertPopup(result.data.message)
        }
    }

    const openConfirmPopup = e => {
        e.preventDefault();
        setIsConfirmPopupDisplay(true);
    }

    const confirmPopupClose = e => {
        e.preventDefault();
        setIsConfirmPopupDisplay(false)
    }

    useEffect(() => {

        console.log('@ useEffect - message listener')

        window.addEventListener('message', (e) => {

            console.info('# post message => ', e);
            console.info('# post message (e.data) => ', e.data);

            if (e.data && isJsonString(e.data)) {
                const jsonParsedData = JSON.parse(e.data);
                if (jsonParsedData.successYn) {

                    if (jsonParsedData.successYn === 'Y') {
                        console.log(jsonParsedData)
                        certedChangedMobileNumber(jsonParsedData.data);

                        // TODO: 전화번호 변경 Api 호출

                    } else {
                        openAlertPopup(jsonParsedData.data.message); // TODO:
                    }
                } else {
                    if (jsonParsedData.data) {
                        openAlertPopup(jsonParsedData.data.message);
                    } else {
                        openAlertPopup('데이터가 올바르지 않습니다. 다시 시도해주세요.');
                    }
                }
            }
        });

        window.addEventListener('message', (e) => {
            if (e.data && e.data.sq) {
                setFavoritePlayerCode(e.data.sq);
                setFavoritePlayerName(e.data.name);
                setFavoritePlayerTeamName(e.data.teamName);
            }
        }, false);

    }, []);

    useEffect(() => {
        console.log('@ useEffect - info')

        const _info = Object.assign(info);

        setUserInfo(_info)
        setZipCode(_info.zipCode);
        setAddress1(_info.addr);
        setAddress2(_info.addrDetail);
        setEmail(_info.email);

        if(_info.receiveAgreeList.length > 0) {
            setReceiveSMS(_info.receiveAgreeList.find(item => item.receiveType === 'SMS').isAgree);
            setReceiveEmail(_info.receiveAgreeList.find(item => item.receiveType === 'EMAIL').isAgree);
            setReceiveAppPush(_info.receiveAgreeList.find(item => item.receiveType === 'PUSH').isAgree);
        }

        setFavoriteTeamCode(_info.favoriteTeamCode);
        if (_info.favoritePlayerInfo) {
            setFavoritePlayerCode(_info.favoritePlayerInfo.pcode)
            setFavoritePlayerName(_info.favoritePlayerInfo.pname)
            setFavoritePlayerTeamName(_info.favoritePlayerInfo.tname)
        }
        setMobilePhoneNo(_info.mobilePhoneNo);

        const getTeamList = async () => {
            setTeamList(setTeamSelectList(await getTeamCodeList({lang: 'ko'})));
        }

        getTeamList();
    }, [info]);


    /* 외국인 전화번호 변경 */
    const clickMobilePhoneModifyForeigner = e => {
        e.preventDefault();
        setIsForeignerPhoneDisplay(true);
    }
    const clickCloseButton = e => {
        e.preventDefault();
        setIsForeignerPhoneDisplay(false);
    }
    const changePhone = phone => {
        setPhone(phone);
        setIsValidPhoneNum(false);
        setRequestButtonDisplay(true);
        setResendButtonDisplay(false);
    }
    const countries = defaultCountries.filter((country) => {
        const { iso2 } = parseCountry(country);
        return !['kr'].includes(iso2);
    });
    const requestConfirmCode = e => {
        e.preventDefault();

        let buttonGbn = e.currentTarget.value;

        // 타이머시작
        setInputCode(null); //입력값 초기화
        setIsTimerOn(true);

        if(onceTimer.isRunning || onceTimer.isPaused) {
            onceTimer.stop();
        }
        onceTimer.start(); // Timer Start!!

        if(!phone){
            openAlertPopup('Input the new mobile phone number.');
            return;
        }
        else if(isValidPhoneNumber(phone) === false) {
            openAlertPopup('Please check your cell phone number.');
            return;
        }
        else{
            setRequestButtonDisplay(false);     // 인증요청 버튼은 최초 1회 인증 후 제거, 번호변경 시 인증버튼활성화, 번호변경이 없을경우 이후 인증요청은 "재전송" 버튼으로 사용.
            setIsInputEditAble(false);          // 인증요청 후 인증전까지 번호수정 불가능, 재인증시 활성화
            setResendButtonDisplay(true);
            pushSMS();
        }
    }
    const pushSMS = async () => {
        const params = ({
            phone: phone,
            type : 'U'
        })
        const result = await MemberService.pushForeignerSMS(params);

        if (result.status === 200){
            setAuthCheckButtonDisplay(true);
            //setIsProcessingRequest(true);
        } else{
            openAlertPopup('Failed to send');
            setRequestButtonDisplay(true);
            setIsInputEditAble(true);
        }
    }
    const confirmCode = async (e) => {

        e.preventDefault();
        //if(!isProcessingRequest || isValidPhoneNum){ return; }

        const params = ({
            phone: phone,
            inputCode : inputCode
        })

        const result = await MemberService.checkForeignerSMS(params);

        if(result.status === 200){
            if(onceTimer.isRunning) { onceTimer.stop()} // Timer STOP!!
            openAlertPopup('Authentication has been completed.');

            setInputCode(inputCode);            // 인증코드 저장
            setIsValidPhoneNum(true);           // 폰인증 확인.
            setRequestButtonDisplay(false);     // 인증요청 버튼 숨기기
            setAuthCheckButtonDisplay(false);   // 인증확인 버튼 숨기기
            setResendButtonDisplay(false);      // 재전송   버튼 숨기기

            setMobilePhoneNo(phone); //노출용 핸드폰번호 변경
            setIsForeignerPhoneDisplay(false); //팝업창 닫기
            certedChangedMobileNumberForeigner(phone); //데이터용 핸드폰번호 변경

        }else if(!inputCode){
            openAlertPopup('Enter authentication number.');
        }else{
            openAlertPopup('Invalid authentication number.');
            setIsInputEditAble(false);
            setResendButtonDisplay(true);
        }

        /*if(inputCode == smsCode){
            // openAlertPopup('Authentication has been completed.');
            setIsValidPhoneNum(true);
            setIsProcessingRequest(false);
            setResend(false); //초기화
            setSmsCode(false); //초기화
            setInputCode(false); //초기화
            setMobilePhoneNo(phone); //노출용 핸드폰번호 변경
            setIsForeignerPhoneDisplay(false); //팝업창 닫기
            certedChangedMobileNumberForeigner(phone); //데이터용 핸드폰번호 변경
        }else if(!inputCode){
            openAlertPopup('Enter authentication number.');
        }else{
            openAlertPopup('Invalid authentication number.');
        }*/
    }
    const certedChangedMobileNumberForeigner = async data => {
        const result = await MemberService.certedMobilePhoneNumberChangedForeigner({ certedResponseNumber: data });
        if (result.status === 200) {
            openAlertPopup('The mobile phone number is modified.');
            setInfo();
        } else {
            openAlertPopup(result.data.message)
        }
    }

    return (
        <>
            <div className={`con-box`} style={{ display: isDisplayModifyPage ? 'block' : 'none' }}>
                <div className={`con-tit`}>
                    <h4></h4>
                    <p>수정 시 변경 가능한 항목에 대해서 개인정보 수정이 가능합니다.</p>
                </div>
                <form>
                    {
                        info && userInfo ?
                            <div className={`modify-wrap`}>
                                <ul className={`modify-input`}>
                                    <li>
                                        <h6>아이디(이메일 주소 형식)</h6>
                                        <input type='text' className='cs' value={userInfo.id} readOnly />
                                    </li>
                                    <li>
                                        <h6>비밀번호</h6>
                                        <button className={`md pb`} onClick={openPwdRetPopup}>변경하기</button>
                                    </li>
                                    <li>
                                        <h6>이름</h6>
                                        <input type={`text`} value={userInfo.name} readOnly />
                                    </li>
                                    <li>
                                        <h6>생년월일</h6>
                                        <input type={`date`} value={userInfo.birthDate} readOnly />
                                    </li>
                                    <li>
                                        <h6>성별</h6>
                                        <input type={`text`} value={userInfo.genderType === 'M' ? "남자" : "여자"} readOnly />
                                    </li>
                                    <li>
                                        <h6>주소</h6>
                                        <ul className={`input-btn`}>
                                            <li>
                                                <input type={`text`} placeholder={"우편번호를 검색해 주세요."} value={zipCode || ''} readOnly/>
                                                <button className={`md gr`} onClick={clickPostCodeSearch}>우편번호 검색</button>
                                            </li>
                                            <li><input type={`text`} placeholder={"주소를 입력해 주세요."} value={address1 || ''} readOnly /></li>
                                            <li><input type={`text`} placeholder={"상세 주소를 입력해 주세요."} value={address2 || ''} onChange={changeAddress2} /></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h6>휴대폰 번호</h6>
                                        <ul className={`input-btn`}>
                                            <li>
                                                <input type={`text`}  value={getMobileNumberFormat(mobilePhoneNo)} readOnly />
                                                {
                                                    userInfo.foreignerJoin ?
                                                        <button className={`md gr`} onClick={clickMobilePhoneModifyForeigner} disabled={isValidPhoneNum ? true : false}>변경하기</button>
                                                    :
                                                        <button className={`md gr`} onClick={clickMobilePhoneModify}>변경하기</button>
                                                }
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h6>이메일 주소</h6>
                                        <input type={`text`} value={email || ''} onChange={changeEmail} />
                                    </li>
                                    {
                                        !isApp ?
                                            <>
                                            <li>
                                                <h6>선호 구단</h6>
                                                <FilterComponent
                                                    selectedKey={favoriteTeamCode}
                                                    selectList={teamList}
                                                    selectChange={changeTavoriteTeamCode}
                                                />
                                            </li>
                                            <li>
                                                <h6>응원선수</h6>
                                                <ul className={`input-btn`}>
                                                    <li>
                                                        <input type='text' value={favoritePlayerCode ? `[${favoritePlayerTeamName}] ${favoritePlayerName}` : '없음'} readOnly />
                                                        <button className={`md gr`} onClick={clickFavoritePlayer}>선수 검색</button>
                                                    </li>
                                                </ul>
                                            </li>
                                            </>
                                            : null
                                    }
                                    <li className={`marketing-agree`}>
                                        <h6>마케팅 수신동의</h6>
                                        <ul className={`agree-chk`}>
                                            <li><input type='checkbox' id={'receiveSMS'} checked={receiveSMS} onChange={changeReceiveSMS} /><label htmlFor={'receiveSMS'}><span>휴대전화 SMS 수신</span></label></li>
                                            <li><input type='checkbox' id={'receiveEmail'} checked={receiveEmail} onChange={changeReceiveEmail} /><label htmlFor={'receiveEmail'}><span>이메일 수신</span></label></li>
                                            <li><input type='checkbox' id={'receiveAppPush'} checked={receiveAppPush} onChange={changeReceiveAppPush} /><label htmlFor={'receiveAppPush'}><span>앱 알림 수신</span></label></li>
                                        </ul>
                                    </li>
                                    <li>
                                        {
                                            userInfo.foreignerJoin ?
                                                <button onClick={saveMemberInfo}>Save</button>
                                            :
                                                <button onClick={saveMemberInfo}>저장</button>
                                        }
                                    </li>
                                </ul>
                            </div>
                            : null
                    }
                </form>
                <div className={`withdrawal-info`}>
                    <h6 onClick={clickQuitMemberPage}>회원탈퇴하기</h6>
                    <p>회원탈퇴 후 동일한 아이디로 6개월간 재가입이 불가능합니다.</p>
                </div>
                <PostCodePopupComponent
                    {...props}
                    onComplete={searchedAddress}
                    isDisplay={isDisplayPostCodePopup}
                    clickCloseButton={closePostCodePopup}
                />
                <MypageResetPwdComponent
                    isDisplay={isDisplayPwdRestPopup}
                    clickConfirmButton={confirmPwdRest}
                    clickCloseButton={closePwdRetPopup}
                    currPwd={currPwd}
                    newPwd={newPwd}
                    newPwdConfirm={newPwdConfirm}
                    changeCurrPwd={changeCurrPwd}
                    changeNewPwd={changeNewPwd}
                    changeNewPwdConfirm={changeNewPwdConfirm}
                    isSamePwd={isSamePwd}
                    isValidPwd={isValidPwd}
                />
            </div>

            {/* 회원탈퇴 */}
            <div className={`con-box`} style={{ display: !isDisplayModifyPage ? 'block' : 'none' }}>
                <div className={`con-tit`}>
                    <h4>회원탈퇴</h4>
                    <p className={`align-right`}>회원 탈퇴 시 웹사이트 활동 내역 및 개인정보는 영구 일괄 삭제 처리 됩니다. <br className={`desktop-only`} />(포함내역 : 티켓예매, 이벤트 응모, 멤버십 관련 내역)</p>
                </div>
                <div className={`withdrawal-wrap`}>
                    <div className={`withdrawal-con`}>
                        <h6>탈퇴 사유 선택</h6>
                        <ul>
                            <li><input type='radio' name={'reason'} id={'reason1'} defaultChecked value='1' onChange={changeQuitReasonType} /><label htmlFor={'reason1'}><span>서비스 불만족</span></label></li>
                            <li><input type='radio' name={'reason'} id={'reason2'} value='2' onChange={changeQuitReasonType} /><label htmlFor={'reason2'}><span>사이트 이용 불편</span></label></li>
                            <li><input type='radio' name={'reason'} id={'reason99'} value='99' onChange={changeQuitReasonType} /><label htmlFor={'reason99'}><span>기타</span></label></li>
                        </ul>
                    </div>
                    <div className={`withdrawal-con`}>
                        <h6>탈퇴 사유 및 KBL에 바라는 점</h6>
                        <textarea id='secession-reason' className='cs' placeholder={'내용을 입력해주세요.'} value={quitReason} onChange={changeQuitReason}></textarea>
                        <p style={{textAlign: 'right'}}><strong id='secession-reason-word-count'>{quitReasonSize}/200</strong> (200자 미만)</p>
                    </div>
                </div>
                <div className={`btn-wrap`}>
                    <button className={`pb`} onClick={openConfirmPopup}>회원탈퇴 신청</button>
                    <button onClick={cancelQuitMember}>취소</button>
                </div>
            </div>
            {/* 외국인 회원 전화번호 변경 시작 */}
            <div className={`popup`} style={{ display: isForeignerPhoneDisplay ? 'block' : 'none' }}>
                <div className={`pop-wrap`}>
                    <div className={`pop-tit`}>
                        <h4>Mobile phone number</h4>
                        <i className={`ic-pop-close-24`} onClick={clickCloseButton} />
                    </div>
                    <div className={`join-box`} style={{paddingTop: '0rem'}}>
                        <ul className={`join-input`} style={{marginTop: '0rem'}}>
                            <li>
                                <PhoneInput
                                    defaultCountry="us"
                                    value={phone}
                                    placeholder="Input the new mobile phone number."
                                    onChange={(phone) => changePhone(phone)}
                                    countries={countries}
                                    disabled={ !isInputEditAble}
                                    inputStyle={{width: `100%`}}
                                />
                                {
                                    !isValidPhoneNum && requestButtonDisplay ?
                                        <button type={'button'} value='US' onClick={requestConfirmCode}>Authentication request</button> : null
                                }
                                {
                                    isValidPhoneNum  ? <p className={`font-blue`}>Authentication has been completed.</p> : null
                                }
                            </li>
                            {
                                !isValidPhoneNum && authCheckButtonDisplay && isTimerOn ?
                                    <li>
                                        <input type={`text`} placeholder={`Input the 6-character authentication number.`} value={inputCode || ''} onChange={(evt) => {setInputCode(evt.currentTarget.value)}} />
                                        <TimerRenderer
                                            timer={onceTimer}
                                            render={(t) => <div className={'progress-wrapper html5-progress-bar'}><div className={'progress-bar-wrapper'}><progress value={t.getElapsedRunningTime()} max={defaultTime}></progress></div></div>}
                                            renderRate={renderRate} // In milliseconds
                                        />
                                        <button type={'button'} onClick={confirmCode}>Authentication number</button>
                                    </li>
                                    : null
                            }
                            {
                                resendButtonDisplay && !isTimerOn ?
                                    <li>
                                        <button type={'button'} value='UR'onClick={requestConfirmCode}>Resend</button>
                                    </li>
                                    : null
                            }
                            <li>
                                <h6 className={'font-red'} style={{display: !isValidPhoneNum ? 'block' : 'none'}}>Please enter your mobile phone number and request authentication.</h6>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* 외국인 회원 전화번호 변경 끝 */}
            <AlertComponent
                isDisplay={isAlertPopupDisplay}
                clickCloseButton={alertPopupClose}
                content={alertMessage}
            />
            <ConfirmComponent
                isDisplay={isConfirmPopupDisplay}
                clickNoButton={confirmPopupClose}
                clickOkButton={quitMember}
                content={'정말 탈퇴하시겠습니까?'}
            />
        </>
    );
}

export default PrivateInfoComponent;