import React from 'react';
import * as CommonService from '../service/common/Common.service'

const teamInfoList = [
    {
        leagueKey: 'DefaultTeam',
        teamList: [
            {
                "tcode": "16",
                "tname": "원주DB",
                "tnameF": "원주 DB 프로미",
                "tnameEn": "Wonju DB"
            },
            {
                "tcode": "35",
                "tname": "서울삼성",
                "tnameF": "서울 삼성 썬더스",
                "tnameEn": "Seoul Samsung"
            },
            {
                "tcode": "55",
                "tname": "서울SK",
                "tnameF": "서울 SK 나이츠",
                "tnameEn": "Seoul SK"
            },
            {
                "tcode": "50",
                "tname": "창원LG",
                "tnameF": "창원 LG 세이커스",
                "tnameEn": "Changwon LG"
            },
            {
                "tcode": "66",
                "tname": "고양소노",
                "tnameF": "고양 소노 스카이거너스",
                "tnameEn": "Goyang Sono"
            },
            {
                "tcode": "60",
                "tname": "부산KCC",
                "tnameF": "부산 KCC 이지스",
                "tnameEn": "Busan KCC"
            },
            {
                "tcode": "70",
                "tname": "안양정관장",
                "tnameF": "안양 정관장 레드부스터스",
                "tnameEn": "Anyang Jeonggwanjang"
            },
            {
                "tcode": "06",
                "tname": "수원KT",
                "tnameF": "수원 KT 소닉붐",
                "tnameEn": "Suwon KT"
            },
            {
                "tcode": "64",
                "tname": "대구한국가스공사",
                "tnameF": "대구 한국가스공사 페가수스",
                "tnameEn": "Daegu Korea Gas Corporation"
            },
            {
                "tcode": "10",
                "tname": "울산현대모비스",
                "tnameF": "울산 현대모비스 피버스",
                "tnameEn": "Ulsan Hyundai Mobis"
            },
        ]
    }
];

export const defaultTeamInfoList = [
    {
        "tcode": "16",
        "tname": "원주DB",
        "tnameF": "원주 DB 프로미",
        "tfavicon": "/imgs/favicon/db/favicon.svg"
    },
    {
        "tcode": "35",
        "tname": "서울삼성",
        "tnameF": "서울 삼성 썬더스",
        "tfavicon": "/imgs/favicon/ss/favicon.svg"
    },
    {
        "tcode": "55",
        "tname": "서울SK",
        "tnameF": "서울 SK 나이츠",
        "tfavicon": "/imgs/favicon/sk/favicon.svg"
    },
    {
        "tcode": "50",
        "tname": "창원LG",
        "tnameF": "창원 LG 세이커스",
        "tfavicon": "/imgs/favicon/lg/favicon.svg"
    },
    {
        "tcode": "66",
        "tname": "고양소노",
        "tnameF": "고양 소노 스카이거너스",
        "tfavicon": "/imgs/favicon/or/favicon.svg"
    },
    {
        "tcode": "60",
        "tname": "부산KCC",
        "tnameF": "부산 KCC 이지스",
        "tfavicon": "/imgs/favicon/kcc/favicon.svg"
    },
    {
        "tcode": "70",
        "tname": "안양정관장",
        "tnameF": "안양 정관장 레드부스터스",
        "tfavicon": "/imgs/favicon/kgc/favicon.svg"
    },
    {
        "tcode": "06",
        "tname": "수원KT",
        "tnameF": "수원 KT 소닉붐",
        "tfavicon": "/imgs/favicon/kt/favicon.svg"
    },
    {
        "tcode": "64",
        "tname": "대구한국가스공사",
        "tnameF": "대구 한국가스공사 페가수스",
        "tfavicon": "/imgs/favicon/el/favicon.svg"
    },
    {
        "tcode": "10",
        "tname": "울산현대모비스",
        "tnameF": "울산 현대모비스 피버스",
        "tfavicon": "/imgs/favicon/hd/favicon.svg"
    },
];

export const teamClassInfo = [{
    "tcode": "16",
    "className": "db",
}, {
    "tcode": "35",
    "className": "ss",
}, {
    "tcode": "55",
    "className": "sk",
}, {
    "tcode": "50",
    "className": "lg",
}, {
    "tcode": "73",
    "className": "car",
}, {
    "tcode": "66",
    "className": "sono",
}, {
    "tcode": "64",
    "className": "gas",
}, {
    "tcode": "60",
    "className": "kcc",
}, {
    "tcode": "70",
    "className": "kgc",
}, {
    "tcode": "06",
    "className": "kt",
}, {
    "tcode": "10",
    "className": "hd",
}, {
    "tcode": "XX",
    "className": "kbl",
},{
    "tcode": "62",
    "className": "mong",
}, {
    "tcode": "63",
    "className": "gong",
},
];

export const teamInfoMap = {
    "XX": {
        "css": "kbl",
        "res": "kbl",
        "tname": "KBL",
        "tnameF": "KBL 연맹",
        "tnameEn": "KBL",
        "shopUrl": "http://kblstore.kbl.or.kr?accessToken=",
    },
    "06": {
        "css": "kt",
        "res": "kt",
        "tname": "수원KT",
        "tnameF": "수원 KT 소닉붐",
        "tnameEn": "Suwon KT Sonicboom",
        "shopUrl": "http://kblstore.kbl.or.kr/web/product/productList?categoryNo=233191&childCategoryNo=233192&accessToken=",
    },
    "10": {
        "css": "hd",
        "res": "hyundai",
        "tname": "울산현대모비스",
        "tnameF": "울산 현대모비스 피버스",
        "tnameEn": "Ulsan Hyundai Mobis Phoebus",
        "shopUrl": "https://www.phoebusmall.co.kr/",
    },
    "16": {
        "css": "db",
        "res": "db",
        "tname": "원주DB",
        "tnameF": "원주 DB 프로미",
        "tnameEn": "Wonju DB Promy",
        "shopUrl": "http://kblstore.kbl.or.kr/web/product/productList?categoryNo=233149&childCategoryNo=233150&accessToken=",
    },
    "22": {
        "css": "el",
        "tname": "대구한국가스공사",
        "tnameF": "대구 한국가스공사 페가수스",
        "tnameEn": "Daegu Korea Gas Corporation Pegasus",
        "shopUrl": "http://kblstore.kbl.or.kr?accessToken=",
    },
    "24": {
        "css": "kcc",
        "res": "kcc",
        "tname": "전주KCC",
        "tnameF": "전주 KCC 이지스",
        "tnameEn": "전주 KCC 이지스",
        "shopUrl": "https://smartstore.naver.com/ravona/category/5d967cb738634c69bd00cd9458411d5c?cp=1",
    },
    "26": {
        "css": "ss",
        "res": "samsung",
        "tname": "서울삼성",
        "tnameF": "서울 삼성 썬더스",
        "tnameEn": "Seoul Samsung Thunders",
        "shopUrl": "http://kblstore.kbl.or.kr/web/product/productList?categoryNo=233155&childCategoryNo=233156&accessToken=",
    },
    "35": {
        "css": "ss",
        "res": "samsung",
        "tname": "서울삼성",
        "tnameF": "서울 삼성 썬더스",
        "tnameEn": "Seoul Samsung Thunder",
        "shopUrl": "http://kblstore.kbl.or.kr/web/product/productList?categoryNo=233155&childCategoryNo=233156&accessToken=",
    },
    "36": {
        "css": "lg",
        "res": "lg",
        "tname": "창원LG",
        "tnameF": "창원 LG 세이커스",
        "tnameEn": "창원 LG 세이커스",
        "shopUrl": "http://kblstore.kbl.or.kr/web/product/productList?categoryNo=233167&childCategoryNo=233168&accessToken=",
    },
    "55": {
        "css": "sk",
        "res": "sk",
        "tname": "서울SK",
        "tnameF": "서울 SK 나이츠",
        "tnameEn": "Seoul SK Knights",
        "shopUrl": "http://kblstore.kbl.or.kr/web/product/productList?categoryNo=233161&childCategoryNo=233162&accessToken=",
    },
    "50": {
        "css": "lg",
        "res": "lg",
        "tname": "창원LG",
        "tnameF": "창원 LG 세이커스",
        "tnameEn": "Changwon LG Sakers",
        "shopUrl": "http://kblstore.kbl.or.kr/web/product/productList?categoryNo=233167&childCategoryNo=233168&accessToken=",
    },
    "66": {
        "css": "sono",
        "res": "sono",
        "tname": "고양소노",
        "tnameF": "고양 소노 스카이거너스",
        "tnameEn": "Goyang Sono Skygunners",
        "shopUrl": "http://kblstore.kbl.or.kr?accessToken=",
    },
    "73": {
        "css": "car",
        "res": "dayone",
        "tname": "고양 데이원",
        "tnameF": "고양 데이원 점퍼스",
        "tnameEn": "Goyang Day One Jumpers",
        "shopUrl": "https://smartstore.naver.com/atcshop/category/a6bc1c5c51c848938bd615c9ece1f865",
    },
    "30": {
        "css": "or",
        "res": "orions",
        "tname": "고양 데이원",
        "tnameF": "고양 데이원 점퍼스",
        "tnameEn": "Goyang Day One Jumpers",
        "shopUrl": "https://smartstore.naver.com/atcshop/category/a6bc1c5c51c848938bd615c9ece1f865",
    },
    "64": {
        "css": "gas",
        "res": "etland",
        "tname": "대구한국가스공사",
        "tnameF": "대구 한국가스공사 페가수스",
        "tnameEn": "Daegu Korea Gas Corporation Pegasus",
        "shopUrl": "http://kblstore.kbl.or.kr?accessToken=",
    },
    "65": {
        "css": "el",
        "res": "etland",
        "tname": "인천전자랜드",
        "tnameF": "인천 전자랜드 엘리펀츠",
        "tnameEn": "Incheon Electro Land Elephants",
        "shopUrl": "http://kblstore.kbl.or.kr?accessToken=",
    },
    "60": {
        "css": "kcc",
        "res": "kcc",
        "tname": "부산KCC",
        "tnameF": "부산 KCC 이지스",
        "tnameEn": "Busan KCC Egis",
        "shopUrl": "https://smartstore.naver.com/ravona/category/5d967cb738634c69bd00cd9458411d5c?cp=1",
    },
    "70": {
        "css": "kgc",
        "res": "kgc",
        "tname": "안양정관장",
        "tnameF": "안양 정관장 레드부스터스",
        "tnameEn": "Anyang Jeonggwanjang Red Boosters",
        "shopUrl": "http://kblstore.kbl.or.kr/web/product/productList?categoryNo=233185&childCategoryNo=233186&accessToken=",
    },
    "75": {
        "css": "kaf",
        "res": "kafac",
        "tname": "상무",
        "tnameF": "상무",
        "tnameEn": "Sangmu",
        "shopUrl": null,
    },
};

const youthSeasonInfoList = [];
export const youthLeagueInfo = {
    "1": {
        seasonKind: '1',
        title: '클럽대회'
    },
    "2": {
        seasonKind: '2',
        title: '주말리그'
    },
    "3": {
        seasonKind: '3',
        title: '스쿨리그'
    },
    "4": {
        seasonKind: '4',
        title: '농구최강전'
    }
};

export const youthGameType = {
    "club": "1",
    "weekend": "2",
    "school": "3",
    "final": "4"
};

export const youthPositionMap = {
    '0' : {
        key: '',
        value: ''
    },
    '1' : {
        key: 'G',
        value: '가드'
    },
    '2' : {
        key: 'P',
        value: '포워드'
    },
    '3' : {
        key: 'C',
        value: '센터'
    },
    '4' : {
        key: '',
        value: ''
    },
    '' : {
        key: '',
        value: ''
    },
    null : {
        key: '',
        value: ''
    }
}


let defaultGlKey = '';

export const setYouthSeasonList = async (params) => {
    let _seasonList = await CommonService.getYouthSeasonList(params);

    if (_seasonList) {
        youthSeasonInfoList.length = 0;
        _seasonList.map(value => youthSeasonInfoList.push(value));
    }
}


export const getTeamInfoSelectList = async (leagueKey = 'DefaultTeam', onlyTeamValue = false, lang = true) => {
    const result = [];
    let teamList = null

    if (teamInfoList.length > 0) {
        const findTeamInfo = teamInfoList.find(value => {
            return value.leagueKey === leagueKey
        })
        if (findTeamInfo) {
            teamList = findTeamInfo.teamList
        }
        //teamList.teamList;
    }

    if (!teamList) {
        teamList = await CommonService.getTeamList(leagueKey, 'ko');
        let obj = {};
        obj.leagueKey = leagueKey;
        obj.teamList = teamList;
        teamInfoList.push(obj);
    }

    if (!onlyTeamValue) {
        result.push({ key: 'XX', value: !lang ? 'KBL' : 'KBL연맹' });
    }

    if (teamList) {
        teamList.map(item => {
            let obj = {};
            obj.key = item.tcode;
            obj.value = lang === false ? (leagueKey != 'DefaultTeam' ? item.tname : item.tnameEn) : item.tname;
            result.push(obj);
        });
    }



    return result;
}

export const getYouthSeasonSelectList = async (params, useShortTitle = true, useAll = true) => {
    let result = [];
    await setYouthSeasonList(params);

    if(useAll) {
        result.push({key: 'all', value: '전체'})
    }

    youthSeasonInfoList.map(item => {
        let obj = {};
        obj.key = item.seasonCode;
        obj.value = useShortTitle ? item.shortTitle : item.title;
        obj.seasonKind = item.seasonKind;
        obj.imgPath = item.imgPath;
        result.push(obj);
    })

    return result;
}

export const getTeamCodeByDomain = () => {

    const domain = window.location.hostname;
    let teamCode = '';

    if (domain === process.env.REACT_APP_SUB_DOMAIN_DB) {
        teamCode = '16';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_SS) {
        teamCode = '35';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_SK) {
        teamCode = '55';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_LG) {
        teamCode = '50';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_SONO) {
        teamCode = '66';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_GAS) {
        teamCode = '64';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_KCC) {
        teamCode = '60'
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_KGC) {
        teamCode = '70';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_KT) {
        teamCode = '06';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_HD) {
        teamCode = '10';
    } else {
        teamCode = 'YH';
    }
    return teamCode;
}

export const getTeamClassByDomain = () => {

    const domain = window.location.hostname;
    let _class = '';

    if (domain === process.env.REACT_APP_SUB_DOMAIN_DB) {
        _class = 'db';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_SS) {
        _class = 'ss';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_SK) {
        _class = 'sk';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_LG) {
        _class = 'lg';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_SONO) {
        _class = 'or';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_GAS) {
        _class = 'el';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_KCC) {
        _class = 'kcc'
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_KGC) {
        _class = 'kgc';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_KT) {
        _class = 'kt';
    } else if (domain === process.env.REACT_APP_SUB_DOMAIN_HD) {
        _class = 'hd';
    } else {
        _class = 'youth';
    }
    
    // _class = 'el'; 
    return _class;
}


