const SET_TEAM_CLASS = 'common_layout/SET_TEAM_CLASS';
const SET_IS_APP = 'common_layout/SET_IS_APP';
const SET_TIME_STAMP = 'common/system/SET_TIME_STAMP';

export const setTeamClass = value => ({ type: SET_TEAM_CLASS, teamClass: value });
export const setIsApp = value => ({ type: SET_IS_APP, isApp: value });
export const setTimeStamp = value => ({ type: SET_TIME_STAMP, timeStamp: value });

const initialState = {
  teamClass: '',
  isApp: false,
  timeStamp: null
};


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEAM_CLASS:
      return {
        ...state,
        teamClass: action.teamClass
      };
    case SET_IS_APP:
      return {
        ...state,
        isApp: action.isApp
      };
    case SET_TIME_STAMP:
      return {
        ...state,
        timeStamp: action.timeStamp
      };
    default:
      return state;
  }
}