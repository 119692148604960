import React, { useState } from 'react';
import {youthLeagueInfo} from "../../../../helpers/commonHelper";
import AlertComponent from "../../../commons/popup/AlertComponent";

const YouthGameDetailComponent = props => {
    const { history, seasonKind, defaultInfo, homeRecord, awayRecord, homeTotal, awayTotal, listPage, isMobile } = props;
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토', '일'];

    const alertPopupClose = e => {
        e.preventDefault();
        setIsAlertPopupDisplay(false);
        setAlertMessage('');
    }

    const pageRender = e => {
        e.preventDefault();
        const id = e.currentTarget.dataset.id;
        const type = e.currentTarget.dataset.type;
        if(!id || id === undefined) {
            setAlertMessage('데이터가 없습니다.');
            setIsAlertPopupDisplay(true);
            return;
        }

        history.push(`/youth/contents/${type}/${id}`);
    }

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>{youthLeagueInfo[seasonKind]?.title}</h2>
                <p>유소년 대회의 일정 및 기록 정보를 보실 수 있습니다.</p>
            </div>
            <div className={`tab-con active`}>
                <div className={`con-box`}>
                    {/*경기 기록*/}
                    <div>
                        {
                            defaultInfo &&
                            <div className={`game-record-summary`}>
                                <div className={`con-tit`}>
                                    <h4>{defaultInfo.gdate}{` (${WEEKDAY[defaultInfo.gameWeek]}) / ${defaultInfo.gameTime2} / ${defaultInfo.jo}`}</h4>
                                </div>
                                <div className={`summary-wrap`}>
                                    <div className={`summary-con`}>
                                        <div className={`team`}>
                                            <div className={`info`}>
                                                {
                                                    defaultInfo.gameStatus > 2 ?
                                                        defaultInfo.homeScore > defaultInfo.awayScore
                                                            ? <span className={`tag`}>WIN</span>
                                                            : <span className={`tag line-gr`}>LOSE</span>
                                                    : null
                                                }
                                                <h6>
                                                    {
                                                        defaultInfo.seasonKind === 1
                                                            ? defaultInfo.tnameSH
                                                            : defaultInfo.tnameH
                                                    }
                                                </h6>
                                            </div>
                                            <img src={`/imgs/youth/emblem/em_KBL${defaultInfo.homeCode}.png`} style={{width: isMobile ? '6rem' : '12rem', height: isMobile ? '4rem' : '8rem'}}
                                                 onError={e => { e.target.onerror = null; e.target.src = `/imgs/youth/emblem/em_KBL${defaultInfo.homeCode2}.png`; }} />
                                            {/*<i className={`emblem-kcc xl`} />*/}
                                        </div>
                                        <div className={`versus`}>
                                            <ul className={`score`}>
                                                <li>{defaultInfo.homeScore}</li>
                                                <li>{defaultInfo.awayScore}</li>
                                            </ul>
                                            <ul className={`quick-link`}>
                                                <li onClick={pageRender} data-type={'video'} data-id={defaultInfo.videoSq}>경기 영상</li>
                                                <li onClick={pageRender} data-type={'photo'} data-id={defaultInfo.photoSq}>경기 사진</li>
                                            </ul>
                                        </div>
                                        <div className={`team`}>
                                            <img src={`/imgs/youth/emblem/em_KBL${defaultInfo.awayCode}.png`} style={{width: isMobile ? '6rem' : '12rem', height: isMobile ? '4rem' : '8rem'}}
                                                 onError={e => { e.target.onerror = null; e.target.src = `/imgs/youth/emblem/em_KBL${defaultInfo.awayCode2}.png`;}} />
                                            {/*<i className={`emblem-el xl`} />*/}
                                            <div className={`info`}>
                                                {
                                                    defaultInfo.gameStatus > 2 ?
                                                        defaultInfo.homeScore < defaultInfo.awayScore
                                                            ? <span className={`tag`}>WIN</span>
                                                            : <span className={`tag line-gr`}>LOSE</span>
                                                        : null
                                                }
                                                <h6>
                                                    {
                                                        defaultInfo.seasonKind === 1
                                                            ? defaultInfo.tnameSA
                                                            : defaultInfo.tnameA
                                                    }
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`summary-table`}>
                                        {
                                            (defaultInfo.seasonKind === 1 && defaultInfo.seasonCode >= 14) || (defaultInfo.seasonKind === 2 && defaultInfo.seasonCode >= 16) ?
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: 'auto'}} />
                                                        <col style={{width: '8rem'}} />
                                                        <col style={{width: '8rem'}} />
                                                        <col style={{width: '8rem'}} />
                                                        <col style={{width: '8rem'}} />
                                                        {
                                                            (defaultInfo.home5q != 0 || defaultInfo.away5q != 0) &&
                                                            <col style={{width: '8rem'}} />
                                                        }
                                                        <col style={{width: '8rem'}} />
                                                    </colgroup>
                                                    <thead>
                                                    <tr>
                                                        <th>팀</th>
                                                        <th>1Q</th>
                                                        <th>2Q</th>
                                                        <th>3Q</th>
                                                        <th>4Q</th>
                                                        {
                                                            (defaultInfo.home5q != 0 || defaultInfo.away5q != 0) &&
                                                            <th>연장전</th>
                                                        }
                                                        <th>TOTAL</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>{defaultInfo.tnameFH}</td>
                                                        <td>{defaultInfo.home1q}</td>
                                                        <td>{defaultInfo.home2q}</td>
                                                        <td>{defaultInfo.home3q}</td>
                                                        <td>{defaultInfo.home4q}</td>
                                                        {
                                                            (defaultInfo.home5q != 0 || defaultInfo.away5q != 0) &&
                                                            <td>{defaultInfo.home5q}</td>
                                                        }
                                                        <td>{defaultInfo.homeScore}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{defaultInfo.tnameFA}</td>
                                                        <td>{defaultInfo.away1q}</td>
                                                        <td>{defaultInfo.away2q}</td>
                                                        <td>{defaultInfo.away3q}</td>
                                                        <td>{defaultInfo.away4q}</td>
                                                        {
                                                            (defaultInfo.home5q != 0 || defaultInfo.away5q != 0) &&
                                                            <td>{defaultInfo.away5q}</td>
                                                        }
                                                        <td>{defaultInfo.awayScore}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                :
                                                <table>
                                                    <colgroup>
                                                        <col style={{width: 'auto'}} />
                                                        <col style={{width: '8rem'}} />
                                                        <col style={{width: '8rem'}} />
                                                        {
                                                            (defaultInfo.home3q != 0 || defaultInfo.away3q != 0) &&
                                                            <col style={{width: '8rem'}} />
                                                        }
                                                        <col style={{width: '8rem'}} />
                                                    </colgroup>
                                                    <thead>
                                                    <tr>
                                                        <th>팀</th>
                                                        <th>전반전</th>
                                                        <th>후반전</th>
                                                        {
                                                            (defaultInfo.home3q != 0 || defaultInfo.away3q != 0) &&
                                                            <th>연장전</th>
                                                        }
                                                        <th>TOTAL</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>{defaultInfo.tnameFH}</td>
                                                        <td>{defaultInfo.home1q}</td>
                                                        <td>{defaultInfo.home2q}</td>
                                                        {
                                                            (defaultInfo.home3q != 0 || defaultInfo.away3q != 0) &&
                                                            <td>{defaultInfo.home3q}</td>
                                                        }
                                                        <td>{defaultInfo.homeScore}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{defaultInfo.tnameFA}</td>
                                                        <td>{defaultInfo.away1q}</td>
                                                        <td>{defaultInfo.away2q}</td>
                                                        {
                                                            (defaultInfo.home3q != 0 || defaultInfo.away3q != 0) &&
                                                            <td>{defaultInfo.away3q}</td>
                                                        }
                                                        <td>{defaultInfo.awayScore}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {/*<div className={`con-box`}>*/}
                            {/*<div className={`con-tit`}>
                                <h4></h4>
                                <div className={`option-wrap`}>
                                    <FilterComponent
                                        type={'changeTab'}
                                        selectedKey={curTab}
                                        selectList={tabList}
                                        selectChange={changeParams}
                                    />
                                </div>
                            </div>*/}
                            {/*{
                                curTab === 'summary' ?
                                    <div className={`con-box`}>
                                        <div className={`con-tit`}>
                                            <h4>주요 기록</h4>
                                        </div>
                                        <div className={`scroll-table`}>
                                            <table>
                                                <colgroup>
                                                    <col style={{width: 'auto'}} />
                                                    <col style={{width: '9%'}} />
                                                    <col style={{width: '9%'}} />
                                                    <col style={{width: '9%'}} />
                                                    <col style={{width: '9%'}} />
                                                    <col style={{width: '9%'}} />
                                                    <col style={{width: '9%'}} />
                                                    <col style={{width: '9%'}} />
                                                    <col style={{width: '9%'}} />
                                                    <col style={{width: '9%'}} />
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th>팀명</th>
                                                    <th>득점</th>
                                                    <th>2점슛</th>
                                                    <th>3점슛</th>
                                                    <th>자유투</th>
                                                    <th>어시시트</th>
                                                    <th>리바운드</th>
                                                    <th>블록슛</th>
                                                    <th>파울</th>
                                                    <th>턴오버</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    record && record.home && record.away && defaultInfo ?
                                                        <>
                                                            <tr>
                                                                <td>{defaultInfo.tnameH}</td>
                                                                <td>{record.home.total}</td>
                                                                <td>{record.home.s2p}</td>
                                                                <td>{record.home.s3p}</td>
                                                                <td>{record.home.ft}</td>
                                                                <td>{record.home.ast}</td>
                                                                <td>{record.home.rtotal}</td>
                                                                <td>{record.home.bs}</td>
                                                                <td>{record.home.ptotal}</td>
                                                                <td>{record.home.sto}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{defaultInfo.tnameA}</td>
                                                                <td>{record.away.total}</td>
                                                                <td>{record.away.s2p}</td>
                                                                <td>{record.away.s3p}</td>
                                                                <td>{record.away.ft}</td>
                                                                <td>{record.away.ast}</td>
                                                                <td>{record.away.rtotal}</td>
                                                                <td>{record.away.bs}</td>
                                                                <td>{record.away.ptotal}</td>
                                                                <td>{record.away.sto}</td>
                                                            </tr>
                                                        </>
                                                        : null
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :*/}
                        {
                            defaultInfo &&
                            <>
                                <div className={`con-box`}>
                                    <div className={`con-tit`}>
                                        <h4>
                                            {
                                                defaultInfo.seasonKind === 1
                                                    ? defaultInfo.tnameSH
                                                    : defaultInfo.tnameH
                                            } 선수별 상세 기록</h4>
                                    </div>
                                    <div className={`scroll-table`}>
                                        <table>
                                            {/*<colgroup>
                                                <col style={{width: 'auto'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                            </colgroup>*/}
                                            <thead>
                                            <tr>
                                                <th rowSpan={2}>번호</th>
                                                <th rowSpan={2}>선수명</th>
                                                <th rowSpan={2}>득점</th>
                                                <th colSpan={3}>2점슛</th>
                                                <th colSpan={3}>3점슛</th>
                                                <th colSpan={3}>자유투</th>
                                                <th colSpan={3}>리바운드</th>
                                                <th rowSpan={2}>어시스트</th>
                                                <th rowSpan={2}>스틸</th>
                                                <th rowSpan={2}>블록슛</th>
                                                <th rowSpan={2}>턴오버</th>
                                            </tr>
                                            <tr>
                                                <th>성공</th>
                                                <th>시도</th>
                                                <th>성공률(%)</th>
                                                <th>성공</th>
                                                <th>시도</th>
                                                <th>성공률(%)</th>
                                                <th>성공</th>
                                                <th>시도</th>
                                                <th>성공률(%)</th>
                                                <th>공격</th>
                                                <th>수비</th>
                                                <th>합계</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                homeRecord && homeRecord.length > 0 ?
                                                    homeRecord.map((item, index) =>
                                                        <tr key={`player_${index}`}>
                                                            <td>{item.backNum}</td>
                                                            <td onClick={() => history.push(`/youth/player/${item.youthNo}`)} style={{cursor: 'pointer'}}>{item.name}</td>
                                                            <td>{item.score}</td>
                                                            <td>{item.s2p}</td>
                                                            <td>{item.s2pa}</td>
                                                            <td>{item.s2pp.toFixed(1)}</td>
                                                            <td>{item.s3p}</td>
                                                            <td>{item.s3pa}</td>
                                                            <td>{item.s3pp.toFixed(1)}</td>
                                                            <td>{item.ft}</td>
                                                            <td>{item.fta}</td>
                                                            <td>{item.fp.toFixed(1)}</td>
                                                            <td>{item.off}</td>
                                                            <td>{item.def}</td>
                                                            <td>{item.rtotal}</td>
                                                            <td>{item.ast}</td>
                                                            <td>{item.stl}</td>
                                                            <td>{item.bs}</td>
                                                            <td>{item.sto}</td>
                                                        </tr>
                                                    )
                                                    : null
                                            }
                                            {
                                                homeTotal &&
                                                <tr key={`home_total`}>
                                                    <td colSpan={`2`}>합계</td>
                                                    <td>{homeTotal.score}</td>
                                                    <td>{homeTotal.s2p}</td>
                                                    <td>{homeTotal.s2pa}</td>
                                                    <td>{homeTotal.s2pp.toFixed(1)}</td>
                                                    <td>{homeTotal.s3p}</td>
                                                    <td>{homeTotal.s3pa}</td>
                                                    <td>{homeTotal.s3pp.toFixed(1)}</td>
                                                    <td>{homeTotal.ft}</td>
                                                    <td>{homeTotal.fta}</td>
                                                    <td>{homeTotal.fp.toFixed(1)}</td>
                                                    <td>{homeTotal.off}</td>
                                                    <td>{homeTotal.def}</td>
                                                    <td>{homeTotal.rtotal}</td>
                                                    <td>{homeTotal.ast}</td>
                                                    <td>{homeTotal.stl}</td>
                                                    <td>{homeTotal.bs}</td>
                                                    <td>{homeTotal.sto}</td>
                                                </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className={`con-box`}>
                                    <div className={`con-tit`}>
                                        <h4>
                                            {
                                                defaultInfo.seasonKind === 1
                                                    ? defaultInfo.tnameSA
                                                    : defaultInfo.tnameA
                                            } 선수별 상세 기록</h4>
                                    </div>
                                    <div className={`scroll-table`}>
                                        <table>
                                            {/*<colgroup>
                                                <col style={{width: 'auto'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                                <col style={{width: '9%'}}/>
                                            </colgroup>*/}
                                            <thead>
                                            <tr>
                                                <th rowSpan={2}>번호</th>
                                                <th rowSpan={2}>선수명</th>
                                                <th rowSpan={2}>득점</th>
                                                <th colSpan={3}>2점슛</th>
                                                <th colSpan={3}>3점슛</th>
                                                <th colSpan={3}>자유투</th>
                                                <th colSpan={3}>리바운드</th>
                                                <th rowSpan={2}>어시스트</th>
                                                <th rowSpan={2}>스틸</th>
                                                <th rowSpan={2}>블록슛</th>
                                                <th rowSpan={2}>턴오버</th>
                                            </tr>
                                            <tr>
                                                <th>성공</th>
                                                <th>시도</th>
                                                <th>성공률(%)</th>
                                                <th>성공</th>
                                                <th>시도</th>
                                                <th>성공률(%)</th>
                                                <th>성공</th>
                                                <th>시도</th>
                                                <th>성공률(%)</th>
                                                <th>공격</th>
                                                <th>수비</th>
                                                <th>합계</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                awayRecord && awayRecord.length > 0 ?
                                                    awayRecord.map((item, index) =>
                                                        <tr key={`player_${index}`}>
                                                            <td>{item.backNum}</td>
                                                            <td onClick={() => history.push(`/youth/player/${item.youthNo}`)} style={{cursor: 'pointer'}}>{item.name}</td>
                                                            <td>{item.score}</td>
                                                            <td>{item.s2p}</td>
                                                            <td>{item.s2pa}</td>
                                                            <td>{item.s2pp.toFixed(1)}</td>
                                                            <td>{item.s3p}</td>
                                                            <td>{item.s3pa}</td>
                                                            <td>{item.s3pp.toFixed(1)}</td>
                                                            <td>{item.ft}</td>
                                                            <td>{item.fta}</td>
                                                            <td>{item.fp.toFixed(1)}</td>
                                                            <td>{item.off}</td>
                                                            <td>{item.def}</td>
                                                            <td>{item.rtotal}</td>
                                                            <td>{item.ast}</td>
                                                            <td>{item.stl}</td>
                                                            <td>{item.bs}</td>
                                                            <td>{item.sto}</td>
                                                        </tr>
                                                    )
                                                    : null
                                            }
                                            {
                                                awayTotal &&
                                                    <tr key={`away_total`}>
                                                        <td colSpan={`2`}>합계</td>
                                                        <td>{awayTotal.score}</td>
                                                        <td>{awayTotal.s2p}</td>
                                                        <td>{awayTotal.s2pa}</td>
                                                        <td>{awayTotal.s2pp.toFixed(1)}</td>
                                                        <td>{awayTotal.s3p}</td>
                                                        <td>{awayTotal.s3pa}</td>
                                                        <td>{awayTotal.s3pp.toFixed(1)}</td>
                                                        <td>{awayTotal.ft}</td>
                                                        <td>{awayTotal.fta}</td>
                                                        <td>{awayTotal.fp.toFixed(1)}</td>
                                                        <td>{awayTotal.off}</td>
                                                        <td>{awayTotal.def}</td>
                                                        <td>{awayTotal.rtotal}</td>
                                                        <td>{awayTotal.ast}</td>
                                                        <td>{awayTotal.stl}</td>
                                                        <td>{awayTotal.bs}</td>
                                                        <td>{awayTotal.sto}</td>
                                                    </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        }
                            {/*}*/}
                            {/*<div className={`con-box`}>*/}
                            {/*    <div className={`con-tit`}>*/}
                            {/*        <h4>경기 최고 기록</h4>*/}
                            {/*    </div>*/}
                            {/*    <div className={`youth-grid-3`}>*/}
                            {/*        <div>*/}
                            {/*            <h6>득점</h6>*/}
                            {/*            <div className={`youth-game-record-box`}>*/}
                            {/*                <div className={`name`}>*/}
                            {/*                    <h6>고찬석(35)</h6>*/}
                            {/*                    <p>DB(U-10)</p>*/}
                            {/*                </div>*/}
                            {/*                <div className={`score`}>*/}
                            {/*                    <i className={`ic-ball-32`} />*/}
                            {/*                    <p>8<span>점</span></p>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div>*/}
                            {/*            <h6>어시스트</h6>*/}
                            {/*            <div className={`youth-game-record-box`}>*/}
                            {/*                <div className={`name`}>*/}
                            {/*                    <h6>고찬석(35)</h6>*/}
                            {/*                    <p>DB(U-10)</p>*/}
                            {/*                </div>*/}
                            {/*                <div className={`score`}>*/}
                            {/*                    <i className={`ic-ball-32`} />*/}
                            {/*                    <p>8<span>점</span></p>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div>*/}
                            {/*            <h6>리바운드</h6>*/}
                            {/*            <div className={`youth-game-record-box`}>*/}
                            {/*                <div className={`name`}>*/}
                            {/*                    <h6>고찬석(35)</h6>*/}
                            {/*                    <p>DB(U-10)</p>*/}
                            {/*                </div>*/}
                            {/*                <div className={`score`}>*/}
                            {/*                    <i className={`ic-ball-32`} />*/}
                            {/*                    <p>8<span>점</span></p>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className={`btn-wrap`}>
                    <button className={`pb`} onClick={listPage}>목록으로</button>
                </div>
            </div>
            <AlertComponent
                clickCloseButton={alertPopupClose}
                isDisplay={isAlertPopupDisplay}
                content={alertMessage}
            />
        </div>
    )
}

export default YouthGameDetailComponent;