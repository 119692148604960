import React, { useState, useEffect } from 'react';
import YouthGameDetailComponent from "./YouthGameDetailComponent";
import {getYouthGameDetail, getYouthGameTeamPlayerRecord,} from "../../../../service/youth/Match.service";
import {youthGameType} from "../../../../helpers/commonHelper";

const YouthGameDetailContainer = props => {
    const { history} = props;
    const [seasonKind, setSeasonKind] = useState('1');
    const [defaultInfo, setDefaultInfo] = useState(null);
    const [game, setGame] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [homeRecord, setHomeRecord] = useState(null);
    const [homeTotal, setHomeTotal] = useState(null);
    const [awayRecord, setAwayRecord] = useState(null);
    const [awayTotal, setAwayTotal] = useState(null);

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        resizeCallback();

        return () => {
            window.removeEventListener("resize", resizeCallback);
        }
    }, [])

    const resizeCallback = () => {
        setIsMobile(window.innerWidth <= 768);
    }

    // tab
    const [curTab, setCurTab] = useState('summary');
    const tabList = [
        {
            key: 'summary',
            value: '경기 요약'
        },
        {
            key: 'home',
            value: `${defaultInfo ? defaultInfo.seasonKind === 1 ? defaultInfo.tnameSH : defaultInfo.tnameH : '홈팀'} 선수별 상세 기록`
        },
        {
            key: 'away',
            value: `${defaultInfo ? defaultInfo.seasonKind === 1 ? defaultInfo.tnameSA : defaultInfo.tnameA : '원정팀'} 선수별 상세 기록`
        },
    ];

    const changeParams = async (type, key, value) => {
        if (type === 'changeTab') {
            setCurTab(key);
        }
    }

    const listPage = (e) => {
        e.preventDefault();
        const path = history.location.pathname;
        const prevPath = path.split('/', 4).join('/');
        history.push(prevPath);
    }

    useEffect(_ => {
        let path = history.location.pathname.split('/');
        setSeasonKind(youthGameType[path[3] ? path[3] : 'club']);
        setGame(path[4]);
    }, []);

    useEffect(_ => {
        const getDefaultInfo = async _ => {
            if(!game) return
            const _defaultInfo = await getYouthGameDetail(game);
            setDefaultInfo(_defaultInfo);
        }
        getDefaultInfo();
    }, [game]);

    useEffect(_ => {
        const getRecordInfo = async _ => {
            if(!defaultInfo) return

            /*if(curTab === 'summary') { // 주요기록
                const _record = await getYouthGameTeamRecords(game);
                setRecord(_record);
            } else { // 선수별*/
                const _hrecord = await getYouthGameTeamPlayerRecord(game, {teamCode: defaultInfo.homeCode2});
                const _arecord = await getYouthGameTeamPlayerRecord(game, {teamCode: defaultInfo.awayCode2});

                setHomeTotal(countList(_hrecord));
                setAwayTotal(countList(_arecord));

                // setRecord(_record);
                setHomeRecord(_hrecord);
                setAwayRecord(_arecord);
            // }
        }
        getRecordInfo();
    }, [defaultInfo, curTab]);

    function countList(record) {
        var _record = record;
        var _score = 0;
        var _s2p = 0;
        var _s2pa = 0;
        var _s2pp = 0;
        var _s3p = 0;
        var _s3pa = 0;
        var _s3pp = 0;
        var _ft = 0;
        var _fta = 0;
        var _fp = 0;
        var _off = 0;
        var _def = 0;
        var _rtotal = 0;
        var _ast = 0;
        var _stl = 0;
        var _bs = 0;
        var _sto = 0;

        _record.map((item, index) => {
            _score  += parseInt(item.score);
            _s2p    += parseInt(item.s2p);
            _s2pa   += parseInt(item.s2pa);
            _s2pp   += parseInt(item.s2pp);
            _s3p    += parseInt(item.s3p);
            _s3pa   += parseInt(item.s3pa);
            _s3pp   += parseInt(item.s3pp);
            _ft     += parseInt(item.ft);
            _fta    += parseInt(item.fta);
            _fp     += parseInt(item.fp);
            _off    += parseInt(item.off);
            _def    += parseInt(item.def);
            _rtotal += parseInt(item.rtotal);
            _ast    += parseInt(item.ast);
            _stl    += parseInt(item.stl);
            _bs     += parseInt(item.bs);
            _sto    += parseInt(item.sto);
        })

        // 성공률
        _s2pp = _s2pa === 0 ? 0 : Math.round(_s2p/_s2pa*100);
        _s3pp = _s3pa === 0 ? 0 : Math.round(_s3p/_s3pa*100);
        _fp = _fta === 0 ? 0 : Math.round(_ft/_fta*100);

        var param = {
            score : _score,
            s2p : _s2p,
            s2pa : _s2pa,
            s2pp : _s2pp,
            s3p : _s3p,
            s3pa : _s3pa,
            s3pp : _s3pp,
            ft : _ft,
            fta : _fta,
            fp : _fp,
            off : _off,
            def : _def,
            rtotal : _rtotal,
            ast : _ast,
            stl : _stl,
            bs : _bs,
            sto : _sto,
        }

        return param;
    }

    return (
        <YouthGameDetailComponent
            {...props}
            changeParams={changeParams}
            seasonKind={seasonKind}
            defaultInfo={defaultInfo}
            // record={record}
            homeRecord={homeRecord}
            homeTotal={homeTotal}
            awayRecord={awayRecord}
            awayTotal={awayTotal}
            listPage={listPage}
            curTab={curTab}
            tabList={tabList}
            isMobile={isMobile}
        />
    )
}

export default YouthGameDetailContainer;