export const cloneObj = obj => JSON.parse(JSON.stringify(obj))

export const isEmptyObject = obj => {
    if(!obj) return
    return Object.keys(obj).length === 0;
}

export const isJsonString = str => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}