import React, { useEffect, useState } from 'react';
import { optAuthRequest, optAuthConfirm } from '../../../../service/auth/Auth.service';
import { resetPwd as resetPassword } from '../../../../service/member/Member.service';
import { getOnlyNumberValue, isValidPassword, isEmailAddress } from '../../../../helpers/stringHelper';
import PhoneAuthComponent from '../../../commons/popup/PhoneAuthComponent';
import AlertComponent from '../../../commons/popup/AlertComponent';
import ResetPasswordComponent from '../../../commons/popup/ResetPasswordComponent';
import { sha256 } from 'js-sha256';
import {getTeamClassByDomain} from '../../../../helpers/commonHelper';

const LoginComponent = props => {
    const { id, pwd, clickLoginButton, changePwdValue, changeIdValue, changeIsSaveLoginId, isSaveLoginId, setId, setPwd } = props;

    const [isPhoneAuthDisplay, setIsPhoneAuthDisplay] = useState(false);
    const [authNumber, setAuthNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [findType, setFindType] = useState('');
    const [resetPwd, setResetPwd] = useState('');
    const [resetPwdConfirm, setResetPwdConfirm] = useState('');
    const [isPwdResetDisplay, setIsPwdResetDisplay] = useState(false);
    const [confirmedId, setConfirmedId] = useState(false);
    const [isValidPwd, setIsValidPwd] = useState(true);
    const [isSamePwd, setIsSamePwd] = useState(true);
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [alertMessage, setAlertMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [domainClass, setDomainClass] = useState('');
    const renderSignup = () => {
        window.open('https://kbl.or.kr/join', '_blank')
    }

    const pwdResetClose = e => {
        e.preventDefault();
        setEmail('');
        setPhoneNumber('');
        setAuthNumber('');
        setResetPwd('');
        setResetPwdConfirm('');
        setIsPwdResetDisplay(false);
    }

    const pwdResetConfirm = async e => {
        e.preventDefault();

        if (isProcessing) {
            return;
        }

        if (!(resetPwd && resetPwdConfirm)) {
            openAlertPopup('비밀번호를 입력해 주세요.');
            return;
        }

        if (!isValidPwd) {
            openAlertPopup("사용할 수 없는 비밀번호입니다.");
            return;
        }

        if (!isSamePwd) {
            openAlertPopup("비밀번호가 일치하지 않습니다.");
            return;
        }
        setIsProcessing(true);
        const result = await resetPassword({ confirmedId: confirmedId, resetPwd: sha256(resetPwd), resetPwdConfirm: sha256(resetPwdConfirm) });
        setIsProcessing(false);

        if (result.status === 200) {
            setIsPwdResetDisplay(false);
            openAlertPopup("비밀번호가 수정되었습니다.");
        } else {
            openAlertPopup(result.data.message);
        }
    }

    const phoneAuthPopupClose = e => {
        e.preventDefault();
        setIsPhoneAuthDisplay(false);
        setPhoneNumber('');
        setAuthNumber('');
    }

    const changeEmail = e => {
        const value = e.currentTarget.value;
        setIsValidEmail(isEmailAddress(value));
        setEmail(value);
    }


    const alertPopupClose = e => {
        e.preventDefault();
        setIsAlertPopupDisplay(false);
        setAlertMessage('');
    }

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }

    const phoneNumberChange = e => {
        const value = getOnlyNumberValue(e.currentTarget.value);
        if (value.length > 12) {
            return
        }
        setPhoneNumber(value);
    }

    const resetPwdChange = e => {
        const value = e.currentTarget.value;
        if (value.length > 16) {
            return;
        }
        setIsValidPwd(isValidPassword(value));
        setResetPwd(value);
    }

    const resetPwdConfirmChange = e => {
        const value = e.currentTarget.value;

        setIsSamePwd(value === resetPwd);
        setResetPwdConfirm(value);
    }


    const authNumberChange = e => {
        const value = getOnlyNumberValue(e.currentTarget.value);
        setAuthNumber(value);
    }

    const clickAuthConfirm = async e => {
        e.preventDefault();
        if (isProcessing) {
            return;
        }

        setIsProcessing(true);
        const result = await optAuthConfirm({ authType: findType, mobilePhoneNo: phoneNumber, otp: authNumber });
        setIsProcessing(false);

        if (result.status !== 200) {
            openAlertPopup(result.data.message);
            return;
        } else {
            setIsPhoneAuthDisplay(false);
            setPhoneNumber('');
            setAuthNumber('');
            setEmail('');
            if (findType === 'FIND_ID') {
                openAlertPopup(`고객님의 아이디는 ${result.data.foundId} 입니다.`);
            } else {
                setConfirmedId(result.data.confirmedId);
                setIsPwdResetDisplay(true);
            }
        }
    }

    const clickAuthRequest = async e => {
        e.preventDefault();
        if (isProcessing) {
            return;
        }
        const params = { authType: findType, mobilePhoneNo: phoneNumber };
        if (findType === 'FIND_PW') {
            params.id = email;
        }
        setIsProcessing(true);
        const result = await optAuthRequest(params);
        setIsProcessing(false);
        if (result && result.status === 200) {
            openAlertPopup("인증번호가 전송되었습니다.")
        } else {
            openAlertPopup(result.data.message);
        }
    }

    const clickPhoneAuth = e => {
        e.preventDefault();
        setFindType(e.currentTarget.dataset.type);
        setIsPhoneAuthDisplay(true)
    }

    useEffect(() => {
        const teamClassValue = getTeamClassByDomain();
        setDomainClass(teamClassValue);
    }, []);

    const enterKeyPress = e => {
        if (e.key === 'Enter') {
            clickLoginButton();
        }
    }

    return (
        <div className={`contents auth`}>
            <div className={`login-box`}>
                <div className={`tit`}>
                    <i className={`logo-kbl-youth`} />
                    <h4>KBL YOUTH에 오신 것을 환영합니다.</h4>
                    <p>KBL 연맹 및 통합사이트 내 <br className={`mobile-only`} />모든 서비스를 이용할 수 있습니다.</p>
                </div>
                <ul className={`box`}>
                    <li>
                        <input type={`email`} placeholder={'통합아이디(이메일)를 입력해주세요.'} onChange={changeIdValue} value={id || ''} />
                        <button style={{display: id ? '' : 'none'}} onClick={()=> {setId('')}}>삭제</button>
                    </li>
                    <li>
                        <input type={`password`} placeholder={'비밀번호를 입력해 주세요.'} onChange={changePwdValue} value={pwd || ''} onKeyPress={enterKeyPress}/>
                        <button style={{display: pwd ? '' : 'none'}} onClick={() => {setPwd('')}}>삭제</button>
                    </li>
                    <li><input type='checkbox' id={`saveId`} checked={isSaveLoginId} onChange={changeIsSaveLoginId} /><label for={`saveId`}><span>아이디 저장</span></label></li>
                    <li><button onClick={clickLoginButton}>로그인</button></li>
                    <li>
                        <ul>
                            <li data-type='FIND_ID' onClick={clickPhoneAuth}>아이디 찾기</li>
                            <li data-type='FIND_PW' onClick={clickPhoneAuth}>비밀번호 찾기</li>
                            <li onClick={renderSignup}>통합 회원가입</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <PhoneAuthComponent
                isDisplay={isPhoneAuthDisplay}
                findType={findType}
                authNumber={authNumber}
                phoneNumber={phoneNumber}
                clickCloseButton={phoneAuthPopupClose}
                phoneNumberChange={phoneNumberChange}
                authNumberChange={authNumberChange}
                clickAuthRequest={clickAuthRequest}
                clickConfirm={clickAuthConfirm}
                email={email}
                isValidEmail={isValidEmail}
                changeEmail={changeEmail}
            />
            <ResetPasswordComponent
                isDisplay={isPwdResetDisplay}
                clickCloseButton={pwdResetClose}
                clickConfirm={pwdResetConfirm}
                resetPwdChange={resetPwdChange}
                resetPwdConfirmChange={resetPwdConfirmChange}
                resetPwd={resetPwd}
                resetPwdConfirm={resetPwdConfirm}
                isValidPwd={isValidPwd}
                isSamePwd={isSamePwd}

            />
            <AlertComponent
                isDisplay={isAlertPopupDisplay}
                clickCloseButton={alertPopupClose}
                content={alertMessage}
            />
        </div>
    );
}

export default LoginComponent;