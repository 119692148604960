import React, {useState, useEffect} from 'react';
import {Radar} from "react-chartjs-2";

const MeasurementMemberGraphComponent = props => {
    const { data, avgData, gapData } = props;

    const [chartData, setChartData] = useState(null);

    function measuterData(measureAvg, measureGap){
        if(measureAvg && measureGap){
            let arrays = [
                [ measureAvg.avgHeight - 1.5 * measureGap.gapHeight, measureAvg.avgHeight - 0.5 * measureGap.gapHeight, measureAvg.avgHeight + 0.5 * measureGap.gapHeight, measureAvg.avgHeight + 1.5 * measureGap.gapHeight],
                [ measureAvg.avgArmLength - 1.5 * measureGap.gapArmLength, measureAvg.avgArmLength - 0.5 * measureGap.gapArmLength, measureAvg.avgArmLength + 0.5 * measureGap.gapArmLength, measureAvg.avgArmLength + 1.5 * measureGap.gapArmLength],
                [ measureAvg.avgStandingReach - 1.5 * measureGap.gapStandingReach, measureAvg.avgStandingReach - 0.5 * measureGap.gapStandingReach, measureAvg.avgStandingReach + 0.5 * measureGap.gapStandingReach, measureAvg.avgStandingReach + 1.5 * measureGap.gapStandingReach],
                [ measureAvg.avgGripStrength - 1.5 * measureGap.gapGripStrength, measureAvg.avgGripStrength - 0.5 * measureGap.gapGripStrength, measureAvg.avgGripStrength + 0.5 * measureGap.gapGripStrength, measureAvg.avgGripStrength + 1.5 * measureGap.gapGripStrength],
                [ measureAvg.avgPushupCnt - 1.5 * measureGap.gapPushupCnt, measureAvg.avgPushupCnt - 0.5 * measureGap.gapPushupCnt, measureAvg.avgPushupCnt + 0.5 * measureGap.gapPushupCnt, measureAvg.avgPushupCnt + 1.5 * measureGap.gapPushupCnt],
                [ measureAvg.avgVJump - 1.5 * measureGap.gapVJump, measureAvg.avgVJump - 0.5 * measureGap.gapVJump, measureAvg.avgVJump + 0.5 * measureGap.gapVJump, measureAvg.avgVJump + 1.5 * measureGap.gapVJump],
                [ measureAvg.avgLaneAgility - 1.5 * measureGap.gapLaneAgility, measureAvg.avgLaneAgility - 0.5 * measureGap.gapLaneAgility, measureAvg.avgLaneAgility + 0.5 * measureGap.gapLaneAgility, measureAvg.avgLaneAgility + 1.5 * measureGap.gapLaneAgility],
                [ measureAvg.avgCourtSprint - 1.5 * measureGap.gapCourtSprint, measureAvg.avgCourtSprint - 0.5 * measureGap.gapCourtSprint, measureAvg.avgCourtSprint + 0.5 * measureGap.gapCourtSprint, measureAvg.avgCourtSprint + 1.5 * measureGap.gapCourtSprint],
                [ measureAvg.avgShuttleRun - 1.5 * measureGap.gapShuttleRun, measureAvg.avgShuttleRun - 0.5 * measureGap.gapShuttleRun, measureAvg.avgShuttleRun + 0.5 * measureGap.gapShuttleRun, measureAvg.avgShuttleRun + 1.5 * measureGap.gapShuttleRun]
            ];

            let records = [data.height, data.armLength, data.standingReach, data.gripStrength, data.pushupCnt, data.vjump, data.laneAgility, data.courtSprint, data.shuttleRun];
            let chartData = [0, 0, 0, 0, 0, 0, 0, 0, 0];
            for (var i = 0; i < 9; i++) {
                if(i > 5){
                    if(arrays[i][0] >= records[i]){
                        chartData[i] = 5;
                    } else if(arrays[i][1] >= records[i]){
                        chartData[i] = 4;
                    } else if(arrays[i][2] >= records[i]){
                        chartData[i] = 3;
                    } else if(arrays[i][3] >= records[i]){
                        chartData[i] = 2;
                    } else{
                        chartData[i] = 1;
                    }
                }else{
                    if (arrays[i][3]<=records[i]){
                        chartData[i] = 5;
                    } else if (arrays[i][2]<=records[i]){
                        chartData[i] = 4;
                    } else if (arrays[i][1]<=records[i]){
                        chartData[i] = 3;
                    } else if (arrays[i][0]<=records[i]){
                        chartData[i] = 2;
                    } else{
                        chartData[i] = 1;
                    }
                }
            }
            setChartData(chartData);
        }
    }

    const datas = {
        labels: ['신장', '윙스펜', '스탠딩리치', '근력', '근지구력', '순발력', '민첩성', '스피드', '심폐능력'],
        datasets: [
            {
                data: chartData,
                backgroundColor: 'rgba(252,120,65,0.2)',
                borderColor: 'rgb(252,120,65)',
                pointBackgroundColor: 'rgb(255, 96, 28)',
                borderWidth: 2
            },
        ],
    };
    const options = {
        legend: {
            display: false,
        },
        events: [],
        responsive: false,
        scale: {
            ticks: {
                // beginAtZero: true,
                max: 5,
                min: 0,
                stepSize: 1,
                fontSize: 14
            },
            pointLabels: {
                fontSize: 14
            }
        },
    };

    useEffect(() => {
        if(data && avgData && gapData){
            measuterData(avgData, gapData);
        }
    }, [data, avgData, gapData]);

    return(
        chartData ? <Radar data={datas} options={options} width={'300rem'} height={'300rem'}/> : null
    );
}

export default MeasurementMemberGraphComponent;