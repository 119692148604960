import React, { useState, useEffect } from 'react';
import HeaderContainer from '../header/HeaderContainer'
import FooterContainer from '../footer/FooterContainer'
import { getUA } from "react-device-detect";
import { useDispatch } from 'react-redux';
import {setTeamClass, setIsApp, setTimeStamp} from '../../../store/common/layout';
import { getTeamClassByDomain } from '../../../helpers/commonHelper'
import { appUserAgentKey } from '../../../helpers/apiHelper'
import {getTimeStamp} from "../../../helpers/dateHelper";



const LayoutComponent = props => {
  const { children } = props;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", resizeCallback);
    resizeCallback();

    return () => {
      window.removeEventListener("resize", resizeCallback);
    }
  }, [])

  const resizeCallback = () => {
    setIsMobile(window.innerWidth <= 768);
  }

  //const [isApp, setIsApp] = useState(false);
  const dispatch = useDispatch();

  const headlessList = new Set([
    '/auth/login-shop',
    '/member/signup-shop',
    '/member/mypage/player',
  ]);

  // appView 체크
  //setIsApp(getUA.includes("KblWebView"))

  const teamClassValue = getTeamClassByDomain();
  dispatch(setTeamClass(teamClassValue));

  const isApp = getUA.includes(appUserAgentKey);
  dispatch(setIsApp(isApp));
  dispatch(setTimeStamp(getTimeStamp()));

  let appClassValue = ''
  if (isApp || headlessList.has(window.location.pathname) || (window.location.pathname.includes('/media/shorts/') && isMobile) ) {
    appClassValue = 'mobile-view';
  }

  const teamClassName = `${teamClassValue} ${appClassValue}`;
  return (
    teamClassName ?
    <>
        <HeaderContainer {...props} children={children} />
        <div id="container" className={teamClassName}>
            {children}
        </div>
        <div className={appClassValue}>
          <FooterContainer {...props} />
        </div>
    </>
      : <React.Fragment>{children}</React.Fragment>
  );
}
export default LayoutComponent;