import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const PaginationComponent = props => {
  const { pagingData, changePage, moreView } = props;

  const [pageButtonCount, setPageButtonCount] = useState(0);
  const [isDisplay, setIsDisplay] = useState(true)

  useEffect(_ => {
    if(pagingData){
      if(pagingData.totalPage == 1){
        setIsDisplay(false);
      } else {
        setIsDisplay(true);
      }
    }

    setPageButtonCount(pagingData.to - pagingData.from + 1)
    return function cleanup() {
     
    }
  }, [pagingData])

  return (
    <>
      {
        pageButtonCount ?
          <ul className={`pagination desktop-only`} style={{ display: isDisplay ? '' : 'none'}}>
            <li className={`first ${pagingData?.currentPage == 1 ? 'disable' : ''}`} data-value={`1`} onClick={changePage}></li>
            <li className={`prev ${pagingData?.currentPage == 1 ? 'disable' : ''}`} data-value={`${pagingData.currentPage !== 1 ? pagingData.currentPage - 1 : pagingData.currentPage}`} onClick={changePage}></li>
            {
              pageButtonCount ?
                  [...Array(pageButtonCount)].map((x, i) =>
                      <li className={pagingData.from + i === pagingData.currentPage ? 'active' : ''} key={`paging-button-${i}`} data-value={`${pagingData.from + i}`} onClick={changePage}>
                        {pagingData.from + i}
                      </li>
                  )
                  : null
            }
            <li className={`next ${pagingData?.currentPage == pagingData?.totalPage ? 'disable' : ''}`} data-value={`${pagingData.currentPage !== pagingData.totalPage ? pagingData.currentPage + 1 : pagingData.totalPage}`} onClick={changePage}></li>
            <li className={`last ${pagingData?.currentPage == pagingData?.totalPage ? 'disable' : ''}`} data-value={`${pagingData.totalPage}`} onClick={changePage}></li>
          </ul>
          : null
      }
      {/* 모바일 */}
      {
        pageButtonCount && pagingData.currentPage !== pagingData.totalPage ?
            <div className={`btn-wrap`} style={{ display: isDisplay ? '' : 'none' }}>
              <button className="light-pb mobile-only" onClick={moreView} data-value={`${pagingData.currentPage !== pagingData.totalPage ? pagingData.currentPage + 1 : pagingData.totalPage}`} >더보기</button>
            </div>
            : null
      }
    </>
  );
}


PaginationComponent.propTypes = {
  pagingData: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
};

export default PaginationComponent;