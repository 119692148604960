import React from 'react';
import YouthDetailViewComponent from "../../../../commons/table/YouthDetailViewComponent";

const YouthNoticeViewComponent = props => {
    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>공지사항</h2>
                <p>KBL 유소년의 소식 및 공지 안내 페이지입니다.</p>
            </div>
            <YouthDetailViewComponent
                {...props}
                articleClass={'regular-bbs-info'}
                listUrl={`/youth/community/notice`}
            />
        </div>
    );
}

export default YouthNoticeViewComponent;