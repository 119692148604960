import React from 'react';

const PlayerDiscoveryComponent = props => {
    const { isMobile } = props;

    return (
        /*<div className={`con-box`}>
            <div className={`youth-box`}>
                <div className={`youth-tit`}>
                    <h4>소개</h4>
                </div>
                <p>만 10세부터 15세까지 KBL이 정한 신장 기준을 통과한 학생들이 대한민국농구협회 선수로 최초 등록할 경우, 선수 활동에 필요한 농구용품, 의료비, 트레이닝 비용 등 지원 사업</p>
            </div>
            <div className={`youth-box`}>
                <div className='player'>
                    <div className='video-container'>
                        <iframe src={`https://www.youtube.com/embed/6o1H_TP4Y98`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '675' : '200'}`} style={{borderRadius: '12px' }} />
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/player-discovery1.jpg`} style={{width: '-webkit-fill-available'}}/>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/player-discovery2.jpg`} style={{width: '-webkit-fill-available'}}/>
                </div>
            </div>
        </div>*/

        <div className={`youth-box`}>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    {/*<h4>영상</h4>*/}
                </div>
                <ul className={`youth-game-highlight-photo`}>
                    <li>
                        <div className='player'>
                            <div className='video-container'>
                                <iframe src={`https://www.youtube.com/embed/6o1H_TP4Y98`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '330' : '220'}`} style={{borderRadius: '12px' }} />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='player'>
                            <div className='video-container'>
                                <iframe src={`https://www.youtube.com/embed/KAyAU7Eo9bQ`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '330' : '220'}`} style={{borderRadius: '12px' }} />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>소개</h4>
                </div>
                <p>만 10세부터 15세까지 KBL이 정한 신장 기준을 통과한 학생들이 대한민국농구협회 선수로 최초 등록할 경우, 선수 활동에 필요한 농구용품, 의료비, 트레이닝 비용 등 지원 사업</p>
                <ul className={`youth-game-photo`}>
                    <li style={{backgroundImage: `url(/files/youth/intro/player-discovery1.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/player-discovery2.jpg)`}}></li>
                </ul>
            </div>
        </div>
    )
}

export default PlayerDiscoveryComponent;