import React, { useState, useEffect } from 'react';
import YouthNoticeViewComponent from './YouthNoticeViewComponent'
import { useSelector } from 'react-redux';
import {getYouthInfo, getYouthList} from '../../../../../service/media/Media.service'

const YouthNoticeViewContainer = props => {
  const { location, match } = props;
  const { params } = useSelector(state => state.youthContent);
  const [info, setInfo] = useState(null);
  const [prevNextList, setPrevNextList] = useState(null);
  const articleId = match.params.id;

  const getDetailInfo = async () => {
    const _info = await getYouthInfo(articleId)
    setInfo(_info);
    if (location.state && location.state.contentIdx) {
      if (Number(location.state.contentIdx) !== 0) {
        params.start = Number(location.state.contentIdx) - 1;
        params.limit = 3;
      } else {
        params.start = 0;
        params.limit = 2;
      }
      params.boardCode = _info.boardCode;

      setPrevNextList(await getYouthList(params));
    }
  }

  useEffect(_ => {
    console.log('NoticeViewContainer::componentDidMount');
    getDetailInfo();

    return function cleanup() {
      console.log('NoticeViewContainer::componentWillUnmount');
      window.scrollTo(0, 0);
    }
  }, [location])

  return (
    info ?
      <YouthNoticeViewComponent
        {...props}
        info={info}
        prevNextList={prevNextList}
      />
      : null
  );
}

export default YouthNoticeViewContainer;