import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const PasswordComponent = props => {
  const { clickCloseButton, clickConfirmButton, pwd, changePwd } = props;

  useEffect(_ => {
    console.log('PasswordComponent::componentDidMount');
    return function cleanup() {
      console.log('TermsComponent::componentWillUnmount');
    }
  })

  return (
    <div className='popup' style={{ display: 'block' }}>
      <div className={`pop-wrap`}>
        <div className={`pop-tit`}>
          <h4>로그인 비밀번호 입력</h4>
          <i className={`ic-pop-close-24`} onClick={clickCloseButton} />
        </div>
        <div className={`pop-con`}>
          <form>
            <input type='password' className='cs' placeholder={'비밀번호를 입력해주세요.'} value={pwd} onChange={changePwd} />
          </form>
        </div>
        <div className={`pop-btn`}>
          <button className={`light-pb`} onClick={clickCloseButton}>취소</button>
          <button className={`pb`} onClick={clickConfirmButton}>확인</button>
        </div>
      </div>
    </div>
  );
}

PasswordComponent.propTypes = {
  pwd: PropTypes.string.isRequired,
  clickCloseButton: PropTypes.func.isRequired,
  clickConfirmButton: PropTypes.func.isRequired,
  changePwd: PropTypes.func.isRequired,

};

export default PasswordComponent;