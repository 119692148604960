import React, { useEffect, useState, useContext } from 'react';
import { BranchContext } from "../YouthClubContainer";
import {getGymBranchInfo, getGymStaffList} from "../../../../../service/youth/Branch.service";
import YouthClubInfoDetail from "./YouthClubInfoDetail";
import YouthClubInfoStaff from "./YouthClubInfoStaff";
import EmptyPreparingComponent from "../../../../commons/table/EmptyPreparingComponent";

const YouthClubIntroComponent = props => {
    const value = useContext(BranchContext);
    console.log("##value : ", value);

    const [selectedInfoSq] = useState(props.selectedGymSq);
    const [gymInfo, setGymInfo] = useState([]); //선택된 지점의 상세정보 ex)문의전화, 위치안내
    // const [selectedBranchInfo, setSelectedBranchInfo] = useState(""); //선택된 팀의 지점 정보 ex)마포점, 합정점
    const [staffInfo, setStaffInfo] = useState([]); //선택된 지점의 스태프 정보

    useEffect(() => {
        if(selectedInfoSq != ""){
            //let gymSq = JSON.stringify(selectedInfoSq);
            (async () => {
                const getGymInfo = await getGymBranchInfo(selectedInfoSq); //info_sq(location table) == gym_sq(location_info table)
                let gymInfo = [];
                getGymInfo.map(item => {
                    gymInfo.push(item);
                });
                setGymInfo(gymInfo);

                // const gymTitle = value.gymList.filter(item => item.infoSq == gymSq);
                // setSelectedBranchInfo("gymTitle");

                const getGymStaff = await getGymStaffList(selectedInfoSq); //info_sq(staff table) == gym_sq(location_info table)
                let staffInfo = [];
                getGymStaff.map(item => {
                   staffInfo.push(item);
                });
                setStaffInfo(staffInfo);
            })();
        }
    }, []);


    return (
        <>
            {
                (!gymInfo || gymInfo.length < 1) && (!staffInfo || staffInfo.length < 1)?
                    <div className={`con-box`}>
                        <EmptyPreparingComponent />
                    </div>
                    :
                    <>
                        {
                            gymInfo && gymInfo.length > 0 ?
                                <YouthClubInfoDetail
                                    info={gymInfo}
                                /> : null
                        }
                        {
                            staffInfo &&
                            <YouthClubInfoStaff
                                info={staffInfo}
                            />
                        }
                    </>
            }
        </>
    )
}

export default YouthClubIntroComponent;