import React,  { useState, useEffect } from 'react';

const KaKaoMapSearch = (props) => {
    const gymList = {
        ...props.filterData
    }
    const [defaultGymList, setDefaultGymList] = useState(null);

    const [searchGymList, setSearchGymList] = useState(null);

    const [tempState, setTempState] = useState();

    const [_inputSearch, setInputSearch] = useState("");
    const onChange = (e) => {
        e.preventDefault();
        setInputSearch(e.target.value);
    }

    useEffect(()=>{
        setDefaultGymList(gymList);
        setTempState(false);
    },[props.addrlist, props.teamCd]);
    useEffect(()=>{
    },[defaultGymList, searchGymList]);
    useEffect(()=>{
    },[tempState]);


    const onClick = (e) => {
        gymSearchFilter(_inputSearch, props.teamCd);
    }
    async function gymSearchFilter (searchWord, teamCd){
        const values = Object.values(gymList);
        const gymBranch = values.filter((item) => (item.address.includes(searchWord) || item.title.includes(searchWord)));
        const firstAddr = gymBranch.find(item => item.address != null); //첫번째 주소 (center addr)
        const searchResult = {
            gymBranch: {...gymBranch},
            firstAddr: {...firstAddr},
        }
        setSearchGymList(gymBranch);
        await setTempState(true);
        props.setSearchGym(searchResult);
    }
    useEffect(()=>{
    },[onClick]);


    return (
        <>
            <div className={`chain-search desktop-only`} style={ { zIndex: '99'}} >
                <div className={`title`}>
                    <h4>지점검색</h4>
                </div>
                <div className={`search-wrap`}>
                    <div className={`search`}>
                        <input type={`text`} value={_inputSearch} onChange={onChange} />
                        <button onClick={onClick}><i className={`ic-search-20`} /></button>
                    </div>
                    <span>검색어 예시) 개포1동 → 개포, 압구정동 → 압구정(검색어 입력)</span>
                </div>

                <div className={`result`}>
                    {
                        tempState == true ?
                            <p className={`result-num`}>검색결과 <span className={`font-red`}>{Object.keys(searchGymList).length}</span>건</p>
                            :
                            <p className={`result-num`}>검색결과 <span className={`font-red`}>{Object.keys(props.filterData).length}</span>건</p>
                    }
                    <ul>
                        {
                            tempState==true ?
                                Object.values(searchGymList).map((item, index) => {
                                    return(
                                        item.num.toString() != '65' ?
                                        <li key={index.toString()} onClick={() => { props.setSelectedGym(index)}}>
                                            <h6>{item.title}</h6>
                                            <p>{item.address}</p>
                                        </li>
                                            :
                                        <li key={index.toString()}>
                                            <h6>{item.title}</h6>
                                            <p>{item.address}</p>
                                        </li>
                                    );
                                })
                                :
                                props.filterData.map((item, index) => {
                                    return(
                                        (item.num).toString() != '65' ?
                                            <li key={index.toString()} onClick={() => { props.setSelectedGym(index)}}>
                                                <h6>{item.title}</h6>
                                                <p>{item.address}</p>
                                            </li>
                                                :
                                            <li key={index.toString()}>
                                                <h6>{item.title}</h6>
                                                <p>{item.address}</p>
                                            </li>
                                    );
                                })
                        }
                    </ul>
                </div>
            </div>
        </>

    );
}

export default KaKaoMapSearch;