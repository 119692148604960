import React, { useState, useEffect, useRef } from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css'
import SwiperCore, {Autoplay, Navigation, Pagination, Thumbs, Grid} from "swiper";

import YouthClubContainer from "../youth/club/YouthClubContainer.js"

// install Swiper modules
SwiperCore.use([Autoplay ,Pagination, Navigation, Thumbs, Grid]);

const YouthMainComponent = props => {
    const { history, articles, banners} = props;
    const [movieContentList, setMovieContentList] = useState(null);
    const [newsContentList, setNewsContentList] = useState(null);
    const [noticeContentList, setNoticeContentList] = useState(null);
    const [photoContentList, setPhotoContentList] = useState(null);
    const [randumBanner, setRandumBanner] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        resizeCallback();

        return () => {
            window.removeEventListener("resize", resizeCallback);
        }
    }, [])

    const resizeCallback = () => {
        setIsMobile(window.innerWidth <= 768);
    }

    const pageRender = e => {
        e.preventDefault();
        const url = e.currentTarget.dataset.url;
        if (url) {
            const isOutlink = e.currentTarget.dataset.isOutlink;
            if (isOutlink === 'true') {
                window.open(url, '_blank', `top=100,left=350 , scrollbars=1, resizable=yes`)
            } else {
                history.push(url);
            }
        }
    }

    useEffect(_ => {
        if (banners) {
            setRandumBanner(banners[100]);
        }
    }, [banners])

    // main banner swiper
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const mainBannerListPrev = useRef(null);
    const mainBannerListNext = useRef(null);

    // movieclip swiper
    const [thumbsSwiper02, setThumbsSwiper02] = useState(null);

    useEffect(_ => {
        setMovieContentList(articles.movieContentList);
        setPhotoContentList(articles.photoContentList);
        setNoticeContentList(articles.noticeContentList);
        setNewsContentList(articles.newsContentList);

        return function cleanup() {
            // window.scrollTo(0, 0);
        }
    }, [])



    return (
        // kbl youth 메인
        <>
        <div className={`main-banner-desktop-swiper01`}>
            {
                randumBanner && randumBanner.length > 0 ?
                    <>
                        <Swiper
                            thumbs={{ swiper: thumbsSwiper }}
                            className={`main-banner-wrap`}
                            centeredSlides={true}
                            loop={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                        >
                            {
                                randumBanner.map((item, index) =>
                                    <SwiperSlide onClick={pageRender} data-url={item.link} data-is-outlink={item.isOutLink} style={{cursor:'pointer'}} key={`banner_${index}`}>
                                        <div className={`main-banner-con`}>
                                            <div className={`img`} style={{backgroundImage: `url(${item.webMainThumbPathX3})`}}></div>
                                            <div className={`txt`}></div>
                                        </div>
                                    </SwiperSlide>
                                )
                            }
                        </Swiper>
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            className={`main-banner-list`}
                            slidesPerView={`auto`}
                            loopFillGroupWithBlank={true}
                            navigation={{
                                prevEl: mainBannerListPrev.current,
                                nextEl: mainBannerListNext.current,
                            }}
                            watchSlidesProgress={true}
                        >
                            {
                                randumBanner.map((item, index) =>
                                    <SwiperSlide key={`t_banner_${index}`}>
                                        <div className={`main-banner-list-con`}>
                                            <span className={`progress`}></span>
                                            {item.bannerDesc ? <h6>{item.bannerDesc}</h6> : null}
                                        </div>
                                    </SwiperSlide>
                                )
                            }
                        </Swiper>
                    </>

                    : null
            }
            <div className="main-banner-list-nav">
                <div className="main-banner-list-nav-prev" ref={mainBannerListPrev} />
                <div className="main-banner-list-nav-next" ref={mainBannerListNext} />
            </div>
        </div>
        <div className={`contents main`}>
            <div className={`main-banner-mobile-swiper01`}>
                <Swiper
                    className={`main-banner-mobile-wrap`}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    initialSlide={"1"}
                >
                    {
                        randumBanner && randumBanner.length > 0 ?
                            randumBanner.map((item, index) =>
                                <SwiperSlide onClick={pageRender} data-url={item.link} data-is-outlink={item.isOutLink} key={`m_banner_${index}`}>
                                    <div className={`img`} style={{backgroundImage: `url(${item.appMainThumbPathX3})`}}></div>
                                    <div className={`txt`}>
                                        {(item.link || item.isOutLink) && <a onClick={pageRender} data-url={item.link} data-is-outlink={item.isOutLink}>바로가기</a>}
                                    </div>
                                </SwiperSlide>
                            ) : null
                    }
                </Swiper>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4><i className={`ic-spot-24`} />지점 찾기</h4>
                    <span>찾고 싶은 지점의 구단을 선택하시면 상세 주소를 지도에서 확인하실 수 있습니다. </span>
                </div>
                <YouthClubContainer
                    mainTab = {true}
                    history = {history}
                />
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4><i className={`ic-news-24`} />뉴스</h4>
                    <span className={`more`} onClick={pageRender} data-url='/youth/community/news'>더보기</span>
                </div>
                <Swiper
                    className={`main-notice-grid`}
                    slidesPerView={1}
                    spaceBetween={16}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        1025: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                            pagination: false,
                        },
                        821: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                            pagination: false,
                        },
                    }}
                >
                    {
                        newsContentList ?
                            newsContentList.map((item, index) =>
                                <SwiperSlide onClick={pageRender} data-url={item.detailUrl} data-is-outlink={item.isOutLink} style={{cursor:'pointer'}} key={`notice_${index}`}>
                                    <h6>{item.newYn == 'Y' ? <span className={`new`}>N</span> : ''}{item.artcTitle.length >= 30 ? item.artcTitle.substring(0, 30) : item.artcTitle}</h6>
                                    <span>{item.regDttm.substring(0, 10).replaceAll("-", ".")}</span>
                                </SwiperSlide>
                            ) : null
                    }
                </Swiper>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4><i className={`ic-notice-24`} />공지사항</h4>
                    <span className={`more`} onClick={pageRender} data-url='/youth/community/notice'>더보기</span>
                </div>
                <Swiper
                    className={`main-notice-grid`}
                    slidesPerView={1}
                    spaceBetween={16}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        1025: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                            pagination: false,
                        },
                        821: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                            pagination: false,
                        },
                    }}
                >
                    {
                        noticeContentList ?
                            noticeContentList.map((item, index) =>
                                <SwiperSlide onClick={pageRender} data-url={item.detailUrl} data-is-outlink={item.isOutLink} style={{cursor:'pointer'}} key={`notice_${index}`}>
                                    <h6>{item.newYn == 'Y' ? <span className={`new`}>N</span> : ''}{item.artcTitle.length >= 30 ? item.artcTitle.substring(0, 30) : item.artcTitle}</h6>
                                    <span>{item.regDttm.substring(0, 10).replaceAll("-", ".")}</span>
                                </SwiperSlide>
                            ) : null
                    }
                </Swiper>
            </div>
            <div className={`con-box main-movieclip-con`}>
                <div className={`con-tit`}>
                    <h4><i className={`ic-movieclip-24`} />KBL YOUTH VIDEO</h4>
                    <span className={`more`} onClick={pageRender} data-url='/youth/contents/video'>더보기</span>
                </div>
                <div className={`movieclip-swiper01`}>
                    <Swiper
                        thumbs={{ swiper: thumbsSwiper02 }}
                        className={`movieclip-con`}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                    >
                        {
                            movieContentList && movieContentList.length > 0 ?
                                movieContentList.map((item, index) =>
                                    <SwiperSlide key={`movie_${index}`}>
                                        <div className={`img cursor-link`} style={{backgroundImage: `url("${item.mediaListThumbPath}")`}} onClick={pageRender} data-url={item.detailUrl}></div>
                                        <div className={`txt cursor-link`} onClick={pageRender} data-url={item.detailUrl}>
                                            <span className={`tag`}>{item.boardCatName}</span>
                                            <h6>{item.artcTitle}</h6>
                                            <ul className={`flex-txt`}>
                                                <li>{item.regDttm.substring(0, 10).replaceAll("-", ".")}</li>
                                                <li>조회수 {item.viewCnt}</li>
                                            </ul>
                                        </div>
                                    </SwiperSlide>
                                ) : null
                        }
                    </Swiper>
                    <Swiper
                        onSwiper={setThumbsSwiper02}
                        className={`movieclip-list`}
                        slidesPerView={3}
                        spaceBetween={12}
                    >
                        {
                            movieContentList && movieContentList.length > 0 ?
                                movieContentList.map((item, index) =>
                                    <SwiperSlide key={`t_movie_${index}`}>
                                        <div className={`img`} style={{backgroundImage: `url("${item.mediaListThumbPath}")`}}></div>
                                    </SwiperSlide>
                                ): null
                        }
                    </Swiper>
                </div>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4><i className={`ic-photo-24`}/>KBL YOUTH PHOTO</h4>
                    <span className={`more`} onClick={pageRender} data-url='/youth/contents/photo'>더보기</span>
                </div>
                <div className={`main-hot-photo`}>
                    <Swiper
                        className={`main-hot-photo-swiper01`}
                        slidesPerView={`auto`}
                        centeredSlides={true}
                        loop={photoContentList && photoContentList.length > 0 ? true : false}
                        spaceBetween={16}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            821: {
                                spaceBetween: 20,
                            },
                        }}
                    >
                        {
                            photoContentList && photoContentList.length > 0 ?
                                photoContentList.map((item, index) =>
                                    <SwiperSlide onClick={pageRender} data-url={item.detailUrl} style={{ cursor: 'pointer' }} key={`photo_${index}`}>
                                        <div className={`img`} style={{backgroundImage: `url("${item.mediaPath || item.mediaListThumbPath}")`}}></div>
                                        <div className={`txt`}>
                                            <span className={`tag line-wh`}>{item.boardCatName}</span>
                                            <h6>{item.artcTitle}</h6>
                                            <span>{item.regDttm.substring(0, 10).replaceAll("-", ".")}</span>
                                        </div>
                                    </SwiperSlide>
                                ) : null
                        }
                    </Swiper>
                </div>
            </div>
        </div>
        </>
    );
}

export default YouthMainComponent;
