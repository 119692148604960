import React, { useEffect, useState } from 'react';
import clubData from '../../../../../json/youth/record/record-club.json'
import weekendData from '../../../../../json/youth/record/record-weekend.json'
import finalData from '../../../../../json/youth/record/record-final.json'
import FilterComponent from "../../../../commons/filter/FilterComponent";
import EmptyContentComponent from "../../../../commons/table/EmptyContentComponent";

const YouthGameRecordComponent = props => {
    const { seasonKind } = props;
    const [recordList, setRecordList] = useState(null);
    const [curTab, setCurTab] = useState(null);
    const [tabList, setTabList] = useState(null);

    const clubTabList = [
        {key: '17', value: '2024 KBL YOUTH CLUB 농구대회'},
        {key: '16', value: '2023 KBL 유소년 클럽 농구대회'},
        {key: '15', value: '2022 KBL 유소년 클럽 농구대회'},
        {key: '14', value: '2020-2021 KBL 유소년 클럽 농구대회'},
        {key: '13', value: '2019 KBL 유소년 클럽 농구대회'},
        {key: '12', value: '2018 KBL 유소년 클럽 농구대회'},
        {key: '11', value: '2017 KBL 유소년 클럽 농구대회'},
        {key: '10', value: '2016 KBL 유소년 클럽 농구대회'},
        {key: '9', value: '2015 KBL 유소년 클럽 농구대회'},
        {key: '8', value: '2014 KBL 유소년 클럽 농구대회'},
        {key: '7', value: '2013 KBL 유소년 클럽 농구대회'},
        {key: '6', value: '2012 KBL 유소년 클럽 농구대회'},
        {key: '5', value: '2011 KBL 유소년 클럽 농구대회'},
        {key: '4', value: '2010 KBL 유소년 클럽 농구대회'},
        {key: '3', value: '2009 KBL 유소년 클럽 농구대회'},
        {key: '2', value: '2008 KBL 유소년 클럽 농구대회'},
        {key: '1', value: '2007 KBL 유소년 클럽 농구대회'},
    ]

    const weekendTabList = [
        {key: '3', value: '2020~2021 KBL 유소년 주말리그'},
        {key: '2', value: '2019~2020 KBL 유소년 주말리그'},
        {key: '1', value: '2018~2019 KBL 유소년 주말리그'}
    ]

    const finalTabList = [
        {key: '1', value: '2020 KBL 유소년 농구 최강전'}
    ]

    const setDataList = () => {
        if(!seasonKind || !tabList) return
        let target = tabList.find(item=> item.key === curTab).value;
        if(seasonKind == 1) { // 클럽대회
            setRecordList(clubData.find(item => item.name === target));
        } else if (seasonKind == 2) { // 주말리그
            setRecordList(weekendData.find(item => item.name === target));
        } else if (seasonKind == 4) { // 농구최강전
            setRecordList(finalData.find(item => item.name === target));
        }
    }

    const getDefaultInfo = () => {
        if(!seasonKind) return
        if(seasonKind == 1) { // 클럽대회
            setTabList(clubTabList);
            setCurTab(clubTabList[0].key);
        } else if (seasonKind == 2) { // 주말리그
            setTabList(weekendTabList);
            setCurTab(weekendTabList[0].key);
        } else if (seasonKind == 4) { // 농구최강전
            setTabList(finalTabList);
            setCurTab(finalTabList[0].key);
        }
    }

    useEffect(() => {
        setDataList();
    }, [curTab]);

    useEffect(() => {
        getDefaultInfo();
    }, [seasonKind]);

    const changeParams = (type, key, value) => {
        if (type === 'filterSeason') {
            setCurTab(key);
        }
    }

    return (
        <div className={`tab-con active`}>
            <div className={'con-box'}>
                <div className={`con-tit`}>
                    <p>{seasonKind == 1 ? '※ 2007 ~ 2017 대회는 일정 결과 등 정보가 제공되지 않습니다.' : ''}</p>
                    <div className={`option-wrap fit-select`}>
                        <FilterComponent
                            type={'filterSeason'}
                            selectedKey={curTab}
                            selectList={tabList}
                            selectChange={changeParams}
                        />
                    </div>
                </div>
            </div>
            {
                recordList ?
                    <>
                        <div className={`youth-box youth-match-info`}>
                            <div className='img' style={{ backgroundImage: `url(${recordList.emblemImg})`}}></div>
                        </div>
                        <div className={`youth-box`}>
                            {
                                recordList && recordList.values ?
                                    recordList.values.map((item, index) =>
                                        <div className={`con-box`} key={`recordList-${index}`}>
                                            <table className={'youth-record'}>
                                                <thead>
                                                <tr>
                                                    <th>년도</th>
                                                    {
                                                        item.header ?
                                                            item.header.map((header, i) =>
                                                                <th key={`recordList-header-${index}-${i}`}>{header}</th>
                                                            )
                                                            : null
                                                    }
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    item.data ?
                                                        item.data.map((data, idx) =>
                                                            <tr key={`recordList-data-${index}}-${idx}`}>
                                                                {idx == 0 &&  <td rowSpan={item.data.length}>{item.year}</td>}
                                                                {
                                                                    data ?
                                                                        data.map((value, i) =>
                                                                            <td key={i}>{value}</td>
                                                                        )
                                                                        : null
                                                                }
                                                            </tr>
                                                        )
                                                        : null
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : null
                            }
                        </div>
                        {
                            recordList.leagueImg != '' ?
                                <div className={`youth-box youth-match-info-club${seasonKind === '2' && curTab === '3' ? '-2' : ''}`}>
                                    <div className='img' style={{ backgroundImage: `url(${recordList.leagueImg})`}}></div>
                                </div> : null
                        }
                    </>
                    :
                        seasonKind === '2' && curTab === '2' ?
                            <div className={'con-box'}>
                                <div className={`con-tit`}>
                                    {/*<h4></h4>*/}
                                    <p>{`* 코로나로 인해 중단`}</p>
                                </div>
                            </div>
                        :
                            <EmptyContentComponent />
            }
        </div>
    )
}

export default YouthGameRecordComponent;