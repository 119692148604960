import React from 'react';
import YouthKblYouthComponent from './YouthKblYouthComponent'


const YouthKblYouthContainer = props => {
    return (
        <YouthKblYouthComponent
            {...props}
        />
    )
}

export default YouthKblYouthContainer;