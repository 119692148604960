import React, {useEffect} from 'react';
import EmptyPreparingComponent from "../../commons/table/EmptyPreparingComponent";

const PreparingComponent = props => {

    useEffect(_ => {
        // window.ntmEvent();
    }, [])

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>안내</h2>
                {/*<p>사회공헌활동 내역을 확인할 수 있습니다.</p>*/}
            </div>
             <EmptyPreparingComponent />
            {/*<div className={`con-empty`}>*/}
            {/*    <i className={`img-empty01`} />*/}
            {/*    <h6>준비 중입니다.</h6>*/}
            {/*    <p>보다나은 서비스 제공을 위하여 페이지 준비 중에 있습니다.<br/>빠른 시일 내에 준비하여 찾아뵙겠습니다.</p>*/}
            {/*</div>*/}
        </div>
    );
}

export default PreparingComponent;