import React, {useEffect, useState} from 'react';
import MeasurementManageListComponent from "./MeasurementManageListComponent";
import {useDispatch, useSelector} from "react-redux";
import queryString from "query-string";
import {isEmptyObject} from "../../../../helpers/objectHelper";
import {setIsMore, setYouthMeasureParams} from "../../../../store/youth/content";
import {getQueryStringFromObject} from "../../../../helpers/apiHelper";
import AlertComponent from "../../../commons/popup/AlertComponent";

const MeasurementManageListContainer = props => {
    const { history, location } = props;
    const { isLogin, userInfo } = useSelector(state => state.commonAuth);

    const {measureList, measureParams, measurePagingData, measurePageSize } = useSelector(state => state.youthContent);
    const [searchType, setSearchType] = useState(null);
    const [searchWord, setSearchWord] = useState('');
    const dispatch = useDispatch();

    const [alertMessage, setAlertMessage] = useState('');
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);

    const searchParams = {
        start: 0,
        limit: measurePageSize,
        searchType: 'name',
        searchWord: ''
    }

    const setDefaultParams = () => {
        const _queryString = queryString.parse(location.search);
        if (!isEmptyObject(_queryString)) {
            dispatch(setYouthMeasureParams(_queryString));
            setSearchType(_queryString.searchType);
            setSearchWord(_queryString.searchWord);
        } else {
            dispatch(setYouthMeasureParams(searchParams));
            setSearchType('name');
            setSearchWord('');
        }
    }

    const changeParams = (type, key, value) => {
        if (type === 'changePage') {
            if (value === measurePagingData.currentPage) {
                return
            }
            dispatch(setIsMore(false));
            measureParams.start = (value - 1) * measurePageSize;
            measureParams.limit = measurePageSize;
        } else if (type === 'moreView') {
            if (value === measurePagingData.currentPage) {
                return
            }
            dispatch(setIsMore(true));
            measureParams.start = (value - 1) * measurePageSize;
            measureParams.limit = measurePageSize;
        } else if (type === 'clickSearch') {
            measureParams.searchWord = searchWord;
            measureParams.searchType = searchType;
            measureParams.start = 0;
            measureParams.limit = measurePageSize;
        }
        history.push(`${history.location.pathname}${getQueryStringFromObject(measureParams)}`)

        dispatch(setYouthMeasureParams(measureParams));
    }

    const changeSearchWord = e => {
        setSearchWord(e.currentTarget.value);
    }

    const changeSearchType = (type, key, value) => {
        setSearchType(key);
    }

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }
    const closeAlertPopup = e => {
        e.preventDefault();
        setAlertMessage('');
        setIsAlertPopupDisplay(false);
        if (!isLogin || !userInfo.isYouthAdmin) {
            history.push({ pathname: '/', state: { from: history.location.pathname} });
        }
    }

    useEffect(_ => {
        console.log('NewsListContainer::componentDidMount');

        if (!isLogin || !userInfo.isYouthAdmin) {
            openAlertPopup('접근 권한이 없습니다.');
        }else{
            setDefaultParams();
        }

        return function cleanup() {
            console.log('NewsListContainer::componentWillUnmount');
            // window.scrollTo(0, 0);
        }
    }, [location]);

  return (
      <>
          {
              measureList.data ?
                  <MeasurementManageListComponent
                      {...props}
                      history={history}
                      changeParams={changeParams}
                      list={measureList.data}
                      pagingData={measurePagingData}
                      params={measureParams}
                      searchType={searchType}
                      searchWord={searchWord}
                      changeSearchType={changeSearchType}
                      changeSearchWord={changeSearchWord}
                      startIndex={measureParams.start}
                  />
              :
                  null
          }
          <AlertComponent
              isDisplay={isAlertPopupDisplay}
              clickCloseButton={closeAlertPopup}
              content={alertMessage}
          />
      </>
  );
}

export default MeasurementManageListContainer;