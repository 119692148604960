import React from 'react';
import PaginationComponent from '../../../../commons/table/PaginationComponent';
import { bbsSearchTypeSelectList } from '../../../../../helpers/codeHelper';
import FilterComponent from "../../../../commons/filter/FilterComponent";
import YouthBBSTableComponent from "../../../../commons/table/YouthBBSTableComponent";

const YouthEduListComponent = props => {
    const { history, list, searchType, searchWord, changeParams, pagingData,
        changeSearchWord, changeSearchType, params } = props;

    //const filterTeam = e => changeParams('filterTeam', e);
    const changePage = e => changeParams('changePage', e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-value'));
    const moreView = e => changeParams('moreView', e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-value'));
    const clickSearch = e => changeParams('clickSearch', e);
    const enterKeyPress = e => {
        if (e.key === 'Enter') {
            changeParams('clickSearch', e);
        }
    }

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>교육자료</h2>
                <p>유소년 대회의 다양한 교육 동영상을 보실 수 있습니다.</p>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4></h4>
                    <div className={`option-wrap`}>
                        <FilterComponent
                            type={'changeSearchType'}
                            selectedKey={searchType}
                            optionClass={'team pc'}
                            selectList={bbsSearchTypeSelectList}
                            selectChange={changeSearchType}
                        />
                        <div className={`search-input`}>
                            <input type='text' value={searchWord} onChange={changeSearchWord} placeholder='검색어를 입력해주세요.' onKeyPress={enterKeyPress}/>
                            <button onClick={clickSearch}><i className={`ic-search-20`} /></button>
                        </div>
                    </div>
                </div>
                {
                    list ?
                        <YouthBBSTableComponent
                            tableClass={'regular-bbs-list'}
                            history={history}
                            startIndex={params.start}
                            {...props}
                        />
                        : null
                }
                {
                    pagingData ?
                        <PaginationComponent
                            pagingData={pagingData}
                            changePage={changePage}
                            moreView={moreView}
                        />
                        : null
                }
            </div>
        </div>
    );
}

export default YouthEduListComponent;