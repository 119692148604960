import React from 'react';
import NotFoundComponent from './PreparingComponent'


const PreparingContainer = props => {

    return (
        <NotFoundComponent {...props} />
    );
}

export default PreparingContainer;