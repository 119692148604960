import * as ApiService from '../Api.service';

const preFix = '/web';

export const getYouthMainArticles = async (params = {}) => {
    console.log('Main.service:getYouthMainArticles');
    const url = '/contents/youth/mainarticle';

    return ApiService.get(url, params);
}

export const getYouthBannerList = async () => {
    console.log('Main.service:getYouthBannerList');
    const url = preFix + '/banners/youth';

    return ApiService.get(url, {bannerTypeCodeList: [100]});
}
