import React, {useEffect} from 'react';

const NotFoundComponent = props => {
    //const { history } = props;

    const renderMainPage = () => {
        console.log("# : ", `${process.env.REACT_APP_DOMAIN_PREFIX}${process.env.REACT_APP_DOMAIN}`);
        window.location.href = `${process.env.REACT_APP_DOMAIN_PREFIX}${process.env.REACT_APP_DOMAIN}`;
    }

    useEffect(() => {
        // window.ntmEvent();
    }, []);

    return (
        <div className={`contents`}>
            <div className={`not-found`}>
                <img src={`/imgs/ico/img/img-error-404.svg`} />
                <h4>페이지를 찾을 수 없습니다.</h4>
                <p>존재하지 않는 주소를 입력하셨거나,<br/>요청하신 페이지의 주소가 변경/삭제되어 찾을 수 없습니다.</p>
                <button className={`pb`} onClick={renderMainPage}>홈으로 이동</button>
            </div>
        </div>
    );
}

export default NotFoundComponent;