import React from 'react';

const GameManagementComponent = props => {
    const { isMobile } = props;

    return (
        /*<div className={`con-box`}>
            <div className={`youth-box`}>
                <div className={`con-tit`}>
                    <h4>유소년 클럽 농구대회</h4>
                </div>
                <p>우수선수 발굴 및 비 연고 지역 농구 저변 확대를 위한 KBL 소속 10개구단 유소년 농구 클럽이 참가하는 국내 최대 규모 아마추어 농구 대회</p>
                <div style={{textAlign: 'center'}}>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/club1.jpg`} style={{width: '-webkit-fill-available'}}/>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/club2.jpg`} style={{width: '-webkit-fill-available'}}/>
                </div>
            </div>
            <div className={`youth-box`}>
                <div className={`con-tit`}>
                    <h4>유소년 주말리그</h4>
                </div>
                <p>학업과 운동을 병행하는 클럽 학생들의 주말 활용을 통해 구단 간 지속적인 유소년 농구리그</p>
                <div style={{textAlign: 'center'}}>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/weekend1.jpg`} style={{width: '-webkit-fill-available'}}/>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/weekend2.jpg`} style={{width: '-webkit-fill-available'}}/>
                </div>
            </div>
        </div>*/
        /*<div className={`youth-box`}>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>유소년 클럽 농구대회</h4>
                </div>
                <p>우수선수 발굴 및 비 연고 지역 농구 저변 확대를 위한 KBL 소속 10개구단 유소년 농구 클럽이 참가하는 국내 최대 규모 아마추어 농구 대회</p>
                <ul className={`youth-game-photo`}>
                    <li style={{backgroundImage: `url(/files/youth/intro/club1.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/club2.jpg)`}}></li>
                </ul>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>유소년 주말리그</h4>
                </div>
                <p>학업과 운동을 병행하는 클럽 학생들의 주말 활용을 통해 구단 간 지속적인 유소년 농구리그</p>
                <ul className={`youth-game-photo`}>
                    <li style={{backgroundImage: `url(/files/youth/intro/weekend1.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/weekend2.jpg)`}}></li>
                </ul>
            </div>
        </div>*/
        <div className={`youth-box`}>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>KBL 유소년 대회 명장면</h4>
                </div>
                <ul className={`youth-game-highlight-photo`}>
                    <li>
                        <div className='player'>
                            <div className='video-container'>
                                <iframe src={`https://www.youtube.com/embed/qtYKaagMaUQ`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '330' : '220'}`} style={{borderRadius: '12px' }} />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='player'>
                            <div className='video-container'>
                                <iframe src={`https://www.youtube.com/embed/dZUxyUPgpYU`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '330' : '220'}`} style={{borderRadius: '12px' }} />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>KBL 유소년 주요 대회</h4>
                </div>
                <p>
                    <div className={`font600`}>유소년 클럽 농구대회</div>
                    우수선수 발굴 및 비 연고 지역 농구 저변 확대를 위한 KBL 소속 10개구단 유소년 농구 클럽이 참가하는 국내 최대 규모 아마추어 농구 대회 <br className={`desktop-only`} /> <br className={`desktop-only`} />
                    <div className={`font600`}>유소년 주말리그</div>
                    학업과 운동을 병행하는 클럽 학생들의 주말 활용을 통해 구단 간 지속적인 유소년 농구리그
                </p>
                <ul className={`youth-game-photo`}>
                    <li style={{backgroundImage: `url(/files/youth/intro/club1.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/club2.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/weekend1.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/weekend2.jpg)`}}></li>
                </ul>
            </div>
        </div>
    )
}

export default GameManagementComponent;