import * as ApiService from '../Api.service'

export const login = async (params = {}) => {
    console.log('Auth.service:login');
    const url = '/auth/login';

    return ApiService.post(url, params);
}

export const logout = async () => {
    console.log('Auth.service:login');
    const url = '/auth/logout';

    return ApiService.post(url);
}


export const refreshToekn = async (params = {}) => {
    console.log('Auth.service:refreshToken');
    const url = '/auth/accessToken';

    return ApiService.post(url, params);
}

export const optAuthRequest = async (params) => {
    console.log('Auth.service:optAuthRequest');
    const url = '/auth/otp';

    return ApiService.post(url, params);
}

export const optAuthConfirm = async (params) => {
    console.log('Auth.service:optAuthConfirm');
    const url = '/auth/otp/confirm';

    return ApiService.post(url, params);
}

export const optAuthMailRequest = async (params) => {
    console.log('Auth.service:optAuthMailRequest');
    const url = '/auth/mail/otp';

    return ApiService.post(url, params);
}

export const optAuthMailConfirm = async (params) => {
    console.log('Auth.service:optAuthMailConfirm');
    const url = '/auth/mail/otp/confirm';

    return ApiService.post(url, params);
}

export const checkTerms7 = async (params = {}) => {
    console.log('Auth.service:login');
    const url = '/auth/terms/7';

    return ApiService.post(url, params);
}







