import * as MemberService from '../../service/member/Member.service';
import {
  createPromiseThunk,
  reducerUtils,
  handleAsyncActions,
  createPromiseThunkById,
  handleAsyncActionsById
} from '../../helpers/asyncHelper';
import { getPagingData } from '../../helpers/apiHelper';

// /* 액션 타입 */
const SET_IS_MORE = 'member_mypage/SET_IS_MORE';

const SET_PRODUCT_PARAMS = 'member_mypage/SET_PRODUCT_PARAMS'
const SET_PRODUCT_PAGING_DATA = 'member_mypage/SET_PRODUCT_PAGING_DATA';
const GET_PRODUCT_LIST = 'member_mypage/GET_PRODUCT_LIST';
const GET_PRODUCT_LIST_SUCCESS = 'member_mypage/GET_PRODUCT_LIST_SUCCESS'; // 요청 성공
const GET_PRODUCT_LIST_ERROR = 'member_mypage/GET_PRODUCT_LIST_ERROR'; // 요청 실패

const SET_TICKET_PARAMS = 'member_mypage/SET_TICKET_PAGING_DATA'
const SET_TICKET_PAGING_DATA = 'member_mypage/SET_TICKET_PAGING_DATA';
const GET_TICKET_LIST = 'member_mypage/GET_TICKET_LIST';
const GET_TICKET_LIST_SUCCESS = 'member_mypage/GET_TICKET_LIST_SUCCESS'; // 요청 성공
const GET_TICKET_LIST_ERROR = 'member_mypage/GET_TICKET_LIST_ERROR'; // 요청 실패

const SET_COUPON_PARAMS = 'member_mypage/SET_COUPON_PAGING_DATA'
const SET_COUPON_PAGING_DATA = 'member_mypage/SET_COUPON_PAGING_DATA';
const GET_COUPON_LIST = 'member_mypage/GET_COUPON_LIST';
const GET_COUPON_LIST_SUCCESS = 'member_mypage/GET_COUPON_LIST_SUCCESS'; // 요청 성공
const GET_COUPON_LIST_ERROR = 'member_mypage/GET_COUPON_LIST_ERROR'; // 요청 실패

const SET_INQUIRY_PARAMS = 'member_mypage/SET_INQUIRY_PARAMS'
const SET_INQUIRY_PAGING_DATA = 'member_mypage/SET_INQUIRY_PAGING_DATA';
const GET_INQUIRY_LIST = 'member_mypage/GET_INQUIRY_LIST';
const GET_INQUIRY_LIST_SUCCESS = 'member_mypage/GET_INQUIRY_LIST_SUCCESS'; // 요청 성공
const GET_INQUIRY_LIST_ERROR = 'member_mypage/GET_INQUIRY_LIST_ERROR'; // 요청 실패


const GET_INFO = 'member_mypage/GET_INFO';
const GET_INFO_SUCCESS = 'member_mypage/GET_INFO_SUCCESS';
const GET_INFO_ERROR = 'member_mypage/GET_INFO_ERROR';

const GET_REFUND_ACCOUNT_INFO = 'member_mypage/GET_REFUND_ACCOUNT_INFO';
const GET_REFUND_ACCOUNT_INFO_SUCCESS = 'member_mypage/GET_REFUND_ACCOUNT_INFO_SUCCESS';
const GET_REFUND_ACCOUNT_INFO_ERROR = 'member_mypage/GET_REFUND_ACCOUNT_INFO_ERROR';

export const getProductList = createPromiseThunk(GET_PRODUCT_LIST, MemberService.getProductList, SET_PRODUCT_PAGING_DATA);
export const getTicketList = createPromiseThunk(GET_TICKET_LIST, MemberService.getTicketList, SET_TICKET_PAGING_DATA);
export const getCouponList = createPromiseThunk(GET_COUPON_LIST, MemberService.getCouponList, SET_COUPON_PAGING_DATA);
export const getInquiryList = createPromiseThunk(GET_INQUIRY_LIST, MemberService.getInquiryList, SET_INQUIRY_PAGING_DATA, 'questions');

export const setProductParams = params => {
  return dispatch => {
    dispatch({ type: SET_PRODUCT_PARAMS, params });
    dispatch(getProductList(params))
  }
}

export const setTicketParams = params => {
  return dispatch => {
    dispatch({ type: SET_TICKET_PARAMS, params });
    dispatch(getTicketList(params))
  }
}

export const setCouponParams = params => {
  return dispatch => {
    dispatch({ type: SET_COUPON_PARAMS, params });
    dispatch(getCouponList(params))
  }
}

export const setInquiryParams = params => {
  return dispatch => {
    dispatch({ type: SET_INQUIRY_PARAMS, params });
    dispatch(getInquiryList(params))
  }
}

export const setIsMore = value => ({ type: SET_IS_MORE, isMore: value });
export const getInfo = createPromiseThunkById(GET_INFO, MemberService.getMemberInfo);
//export const getRefundAccountInfo = createPromiseThunkById(GET_REFUND_ACCOUNT_INFO, MemberService.getRefundAccountInfo);

const initialState = {
  productParams: {},
  ticketParams: {},
  couponParams: {},
  inquiryParams: {},
  productList: reducerUtils.initial(),
  ticketList: reducerUtils.initial(),
  couponList: reducerUtils.initial(),
  inquiryList: reducerUtils.initial(),
  productPagingData: {},
  ticketPagingData: {},
  couponPagingData: {},
  inquiryPagingData: {},
  pageSize: 10,
  refundAccount: {},
  info: {},
};


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_PARAMS:
      return {
        ...state,
        productParams: action.params
      };
    case GET_PRODUCT_LIST:
    case GET_PRODUCT_LIST_SUCCESS:
    case GET_PRODUCT_LIST_ERROR:
      return handleAsyncActions(GET_PRODUCT_LIST, 'productList')(state, action);
    case SET_PRODUCT_PAGING_DATA:
      return {
        ...state,
        productPagingData: getPagingData(state.productParams.start, action.pageInfo, state.pageSize)
      }

    case SET_TICKET_PARAMS:
      return {
        ...state,
        ticketParams: action.params
      };
    case GET_TICKET_LIST:
    case GET_TICKET_LIST_SUCCESS:
    case GET_TICKET_LIST_ERROR:
      return handleAsyncActions(GET_TICKET_LIST, 'ticketList')(state, action);
    case SET_TICKET_PAGING_DATA:
      return {
        ...state,
        ticketPagingData: getPagingData(state.ticketParams.start, action.pageInfo, state.pageSize)
      }
    case SET_COUPON_PARAMS:
      return {
        ...state,
        couponParams: action.params
      };
    case GET_COUPON_LIST:
    case GET_COUPON_LIST_SUCCESS:
    case GET_COUPON_LIST_ERROR:
      return handleAsyncActions(GET_COUPON_LIST, 'couponList')(state, action);
    case SET_COUPON_PAGING_DATA:
      return {
        ...state,
        couponPagingData: getPagingData(state.couponParams.start, action.pageInfo, state.pageSize)
      }
    case SET_INQUIRY_PARAMS:
      return {
        ...state,
        inquiryParams: action.params
      };
    case GET_INQUIRY_LIST:
    case GET_INQUIRY_LIST_SUCCESS:
    case GET_INQUIRY_LIST_ERROR:
      return handleAsyncActions(GET_INQUIRY_LIST, 'inquiryList')(state, action);
    case SET_INQUIRY_PAGING_DATA:
      return {
        ...state,
        inquiryPagingData: getPagingData(state.inquiryParams.start, action.pageInfo, state.pageSize)
      }
    case SET_IS_MORE:
      return {
        ...state,
        isMore: action.isMore
      }
    case GET_INFO:
    case GET_INFO_SUCCESS:
    case GET_INFO_ERROR:
      return handleAsyncActionsById(GET_INFO, 'info', true)(state, action);
    case GET_REFUND_ACCOUNT_INFO:
    case GET_REFUND_ACCOUNT_INFO_SUCCESS:
    case GET_REFUND_ACCOUNT_INFO_ERROR:
      return handleAsyncActionsById(GET_REFUND_ACCOUNT_INFO, 'refundAccount', true)(state, action);
    default:
      return state;
  }
}