import React, { useEffect, useState } from 'react';

const MeasurementIntroComponent = props => {
    //const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        console.log('MeasurementIntroComponent::componentDidMount');

        return function cleanup() {
            console.log('MeasurementIntroComponent::componentWillUnmount');
        }
    }, []);

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>항목 소개</h2>
                <p>KBL은 유소년 농구 선수들의 올바른 신체 성장과 기량 향상을 위하여 유소년 선수 육성 프로젝트를 시행하고 있습니다.</p>
            </div>

            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>유소년 피지컬 측정</h4>
                </div>
                <p>초, 중, 고, 대 아마추어 선수들을 대상으로 한 신체 피지컬 및 운동능력 측정</p>
                <ul className='physical-step'>
                    <li><i className='ic-physical-01'/><p>선수 퍼포먼스 측정</p></li>
                    <li><i className='ic-physical-02'/><p>측정 데이터 분석</p></li>
                    <li><i className='ic-physical-03'/><p>분석 데이터 평가</p></li>
                    <li><i className='ic-physical-04'/><p>데이터 피드백</p></li>
                </ul>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>유소년 피지컬 측정 목적</h4>
                </div>
                <ul className='physical-purpose'>
                    <li><div>1</div><p>종목 특성상 신체 피지컬 및 운동능력은 경기력을 결정하는 중요한 요소 </p></li>
                    <li><div>2</div><p>주기적 피지컬 테스트는 선수의 운동능력에 대한 객관적인 자료</p></li>
                    <li><div>3</div><p>개인의 신체적 특성 및 운동능력에 따른 강점과 약점을 파악 </p></li>
                    <li><div>4</div><p>선수에게 새로운 목표를 제시하기 위한 동기부여</p></li>
                </ul>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>측정 항목 안내</h4>
                </div>
                <div className='scroll-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>측정 항목</th>
                                <th>측정 목적</th>
                                <th>내용</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>신장/체중</td>
                                <td rowSpan={4}>신체 조성</td>
                                <td>신발을 신지 않은 상태에서 신장과 체중측정</td>
                            </tr>
                            <tr>
                                <td>윙스펜</td>
                                <td>좌우로 최대한 벌린 양팔의 길이 측정</td>
                            </tr>
                            <tr>
                                <td>스탠딩리치</td>
                                <td>위로 최대한 높인 한팔의 높이(신발 착용)를 측정</td>
                            </tr>
                            <tr>
                                <td>근육량/체지방량</td>
                                <td>측정 기계(INbody)를 통해 체지방과 근육량 측정</td>
                            </tr>
                            <tr>
                                <td>악력</td>
                                <td>근력</td>
                                <td>악력기(TKK-5401)를 이용해 오른팔 왼팔 중 최대 수치를 측정</td>
                            </tr>
                            <tr>
                                <td>1분 푸시업</td>
                                <td>근지구력</td>
                                <td>푸시업을 1분간 진행하여 최대 횟수를 측정</td>
                            </tr>
                            <tr>
                                <td>버티컬 점프 /<br/>맥스 버티컬 점프</td>
                                <td>순발력, 점프력</td>
                                <td>VERTEC을 이용해 수직점프(서전트) / 런닝 점프를 통해 도약한 리치 수치에서 자신의 스탠딩 리치를 뺀 수치 측정</td>
                            </tr>
                            <tr>
                                <td>레인 어질리티</td>
                                <td>민첩성, 방향전환</td>
                                <td>가로 약 4.87m 세로 5.79m의 직사각형을 뛰어 왕복한 기록을 측정</td>
                            </tr>
                            <tr>
                                <td>10 Yard 스프린트 /<br/>¾스프린트</td>
                                <td>속도, 가속도</td>
                                <td>코트의 약 9.1m(10yard) 와 22.8m(3/4코트) 거리를 전력 질주하는 시간을 측정</td>
                            </tr>
                            <tr>
                                <td>300yard shuttle run</td>
                                <td>심폐능력</td>
                                <td>전력 질주 하여 약 22m를 6번 왕복한 기록을 측정</td>
                            </tr>
                            <tr>
                                <td>부상 방지/퍼포먼스 측정</td>
                                <td></td>
                                <td>Y balance Test / FMS test /스파르타 사이언스 / VALD 등 전문화 및 기계화된 장비를 통한 <br/>선수들의 퍼포먼스 및 약화 부위 파악 및 측정</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default MeasurementIntroComponent;