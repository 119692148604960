import React, {  useEffect } from 'react';
import RuleComponent from './RuleComponent'

const RuleContainer = props => {

  useEffect(_ => {
    console.log('RuleContainer::componentDidMount');
  
    return function cleanup() {
      console.log('RuleContainer::componentWillUnmount');
    }
  }, [])
  return (
    <RuleComponent
      {...props}
    />
  );
}

export default RuleContainer;