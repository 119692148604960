import React from 'react';

const YouthOverseasProgramComponent = props => {
    const { isMobile } = props;

    return (
        /*<div className={`con-box`}>
            <div className={`youth-box`}>
                <div className={`youth-tit`}>
                    <h4>소개</h4>
                </div>
                <p>차세대 스타플레이어 자질을 갖은 선수를 세밀히 평가하여 최고의 잠재력을 갖춘 유망선수를 선발, 해외 전문 트레이닝 기관을 통해 선진 농구 기술 습득 및 해외 진출의 토대 마련을 위한 프로젝트</p>
            </div>
            <div className={`youth-box`}>
                <div className='player'>
                    <div className='video-container'>
                        <iframe src={`https://www.youtube.com/embed/cXPNJYjLlbM`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '675' : '200'}`} style={{borderRadius: '12px' }} />
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/overseas-program1.jpg`} style={{width: '-webkit-fill-available'}}/>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/overseas-program2.jpg`} style={{width: '-webkit-fill-available'}}/>
                </div>
            </div>
        </div>*/

        <div className={`youth-box`}>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    {/*<h4>영상</h4>*/}
                </div>
                <ul className={`youth-game-highlight-photo`}>
                    <li>
                        <div className='player'>
                            <div className='video-container'>
                                <iframe src={`https://www.youtube.com/embed/cXPNJYjLlbM`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '330' : '220'}`} style={{borderRadius: '12px' }} />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='player'>
                            <div className='video-container'>
                                <iframe src={`https://www.youtube.com/embed/AVdDkZClPdA?start=65`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '330' : '220'}`} style={{borderRadius: '12px' }} />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>소개</h4>
                </div>
                <p>차세대 스타플레이어 자질을 갖은 선수를 세밀히 평가하여 최고의 잠재력을 갖춘 유망선수를 선발, 해외 전문 트레이닝 기관을 통해 선진 농구 기술 습득 및 해외 진출의 토대 마련을 위한 프로젝트</p>
                <ul className={`youth-game-photo`}>
                    <li style={{backgroundImage: `url(/files/youth/intro/overseas-program1.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/overseas-program5.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/overseas-program3.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/overseas-program4.jpg)`}}></li>
                </ul>
            </div>
        </div>
    )
}

export default YouthOverseasProgramComponent;