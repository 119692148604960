import React, { useEffect } from 'react';
import MeasurementMemberPhysicalComponent from "./MeasurementMemberPhysicalComponent";
import MeasurementMemberSchoolComponent from "./MeasurementMemberSchoolComponent";
import MeasurementMemberGraphComponent from "./MeasurementMemberGraphComponent";

const MeasurementMemberComponent = props => {
    const{ personalData, physicalData, bySchoolData, schoolGradeList, schoolGrade, setSchoolGrade, avgData, gapData } = props;

    const changeSchoolGrade = e => setSchoolGrade(e.target.value);

    const schoolGradeNameList = [
        {key: 1, value: "초등학교"},
        {key: 2, value: "중학교"},
        {key: 3, value: "고등학교"},
    ]

    useEffect(() => {
        console.log('MeasurementMemberComponent::componentDidMount');

        return function cleanup() {
            console.log('MeasurementMemberComponent::componentWillUnmount');
        }
    }, []);

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>내 기록 확인</h2>
                <p>측정 데이터 종합 및 개인 피지컬 측정 데이터를 확인해 볼 수 있습니다. </p>
            </div>
            {
                personalData ?
                    <div className={`con-box`}>
                        <div className={`con-tit`}>
                            <h4>기본 정보</h4>
                        </div>
                        <div className='physical-record'>
                            <div className='info'>
                                <h5>{personalData[0].name}</h5>
                                <ul>
                                    <li><h6>학교명</h6><p>{personalData[0].school}</p></li>
                                    <li><h6>포지션</h6><p>{personalData[0].position}</p></li>
                                    <li><h6>생년월일</h6><p>{personalData[0].birth}</p></li>
                                    <li><h6>나이</h6><p>{personalData[0].age}세</p></li>
                                </ul>
                            </div>
                            <div className='graph'>
                                <MeasurementMemberGraphComponent
                                    data={personalData[personalData.length - 1]}
                                    avgData={avgData}
                                    gapData={gapData}
                                />
                            </div>
                        </div>
                    </div>
                : null
            }
            {
                personalData && physicalData && bySchoolData ?
                    <div className='con-box'>
                        <div className='con-tit'>
                            <h4>측정 데이터 종합</h4>
                        </div>
                        <div className='youth-box'>
                            <div className='youth-tit'>
                                <h5>개인 기록</h5>
                            </div>
                            <div className='scroll-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>학교</th>
                                            <th>측정 날짜</th>
                                            <th>신장</th>
                                            <th>체중</th>
                                            <th>체지방</th>
                                            <th>근육량</th>
                                            <th>윙스펜</th>
                                            <th>스탠딩리치</th>
                                            <th>악력</th>
                                            <th>1분 푸쉬업</th>
                                            <th>버티컬 점프</th>
                                            <th>맥스버티컬 점프</th>
                                            <th>레인 어질리티</th>
                                            <th>10야드 스프린트</th>
                                            <th>¾코트 전력질주</th>
                                            <th>300야드 셔틀런</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        personalData ?
                                            personalData.map((item, idx) =>
                                                <tr>
                                                    <td>{idx + 1}차</td>
                                                    <td>{item.schoolInfo}</td>
                                                    <td>{item.regDttm}</td>
                                                    <td>{item.height}</td>
                                                    <td>{item.weight}</td>
                                                    <td>{item.bodyFatPer}</td>
                                                    <td>{item.muscleMass}</td>
                                                    <td>{item.armLength}</td>
                                                    <td>{item.standingReach}</td>
                                                    <td>{item.gripStrength}</td>
                                                    <td>{item.pushupCnt}</td>
                                                    <td>{item.vjump}</td>
                                                    <td>{item.runVJump}</td>
                                                    <td>{item.laneAgility}</td>
                                                    <td>{item.yardSprint}</td>
                                                    <td>{item.courtSprint}</td>
                                                    <td>{item.shuttleRun}</td>
                                                </tr>
                                            )
                                        :
                                            <tr>
                                                <td colSpan={16}>측정 데이터가 없습니다.</td>
                                            </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='youth-box'>
                            <div className='youth-tit'>
                                <h5>개인 피지컬 측정 데이터</h5>
                                <ul className='color-desc'>
                                    <li><span style={{backgroundColor: '#0B2157'}}></span>팀 평균</li>
                                    <li><span style={{backgroundColor: '#63709A'}}></span>{physicalData && physicalData.length > 0 ? schoolGradeNameList.find(list => list.key === physicalData[0].schoolGrade).value : ''} 평균</li>
                                    <li><span style={{backgroundColor: '#FF601C'}}></span>내 기록</li>
                                </ul>
                            </div>

                            <MeasurementMemberPhysicalComponent
                                physicalData={physicalData}
                                schoolGradeName={physicalData && physicalData.length > 0 ? schoolGradeNameList.find(list => list.key === physicalData[0].schoolGrade).value : ''}
                            />

                            <div className={`con-tit`}>
                                <h4></h4>
                                <select onChange={changeSchoolGrade} value={schoolGrade}>
                                    {
                                        schoolGradeList ?
                                            schoolGradeList.map((item) =>
                                                <option value={item}>{schoolGradeNameList.find(list => list.key == item).value}</option>
                                            )
                                        : <option>선택</option>
                                    }
                                </select>
                            </div>

                            <MeasurementMemberSchoolComponent
                                schoolData={bySchoolData}
                                schoolGradeName={physicalData && physicalData.length > 0 ? schoolGradeNameList.find(list => list.key == schoolGrade).value : ''}
                            />
                        </div>
                    </div>
                :

                    <div className={`con-empty`}>
                        <i className={`img-empty02`} />
                        <h6>측정 데이터가 없습니다.</h6>
                    </div>
            }
        </div>
    )
}

export default MeasurementMemberComponent;



