import React, {useState, useEffect} from 'react';

import YouthMemberSearchContainer from "./popup/YouthMemberSearchContainer";
import RegistYouthSchoolComponent from "./RegistYouthSchoolComponent";
import YouthRegistSuccess from "./YouthRegistSuccess";

import moment from "moment/moment";
import AlertComponent from "../../../commons/popup/AlertComponent";
import FilterComponent from "../../../commons/filter/FilterComponent";

import {getAllGymList, getAllList} from "../../../../service/youth/Branch.service";
import {youthRegist} from "../../../../service/youth/Signup.service";
import {getPhysicalNumberValue,} from "../../../../helpers/stringHelper";


const RegistYouthStep1Component = (props) => {
    const {userInfo, history} = props;

    const year = moment().format('YYYY');

    const [memberType, setMemberType] = useState("J");
    const [id, setId] = useState("");
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');

    const [schoolGrade, setSchoolGrade] = useState('1'); //1:초 2:중 3:고
    const changeSchoolGrade = e => {
        const value = e.currentTarget.value;
        setSchoolGrade(value);
    }

    const [schoolNum, setSchoolNum] = useState(''); //학년
    const changeSchoolNum = e => {
        const value = e.currentTarget.value;
        setSchoolNum(value);
    }

    const [school, setSchool] = useState(""); //학교명

    const [clubList, setClubList] = useState(null);
    const [teamCode, setTeamCode] = useState('XX');
    const changeTeamCode = (key, value) => setTeamCode(key);

    const [allBranchList, setAllBranchList] = useState(null);
    const [branchList, setBranchList] = useState(null);
    const [branchCode, setBranchCode] = useState('');
    const changeBranchCode = (key, value) => setBranchCode(key);

    const [schoolOpt, setSchoolOpt] = useState("XX");
    const changeSchoolOpt = (key, value) => setSchoolOpt(key);

    const [sidoOpt, setSidoOpt] = useState("XX");
    const changeSidoOpt = (key, value) => setSidoOpt(key);

    const [gugunOpt, setGugunOpt] = useState("XX");
    const changeGugunOpt = (key, value) => setGugunOpt(value);

    const [level, setLevel] = useState(1);

    const [alertMessage, setAlertMessage] = useState('');
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [isDisplaySearchMemberPopup, setIsDisplaySearchMemberPopup] = useState(false);
    const [isRegistPopupDisplay, setIsRegistPopupDisplay] = useState(false);

    const changeMembeType = e => {
        const value = e.currentTarget.value;
        setMemberType(value);
    }

    const changeLevel = level => setLevel(level);
    useEffect(() => {
        setLevel(1);
    }, [isDisplaySearchMemberPopup]);

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }
    const alertPopupClose = e => {
        e.preventDefault();
        setAlertMessage('');
        setIsAlertPopupDisplay(false)
    }

    let numberRegex = new RegExp("^([0-9]{1,3})(\.[0-9]{1,1})?$");
    const isNumber = value => numberRegex.test(value);

    const changeHeight = e => {
        const value = getPhysicalNumberValue(e.currentTarget.value);
        if(value.length > 5){
            return
        }
        setHeight(value);
    }
    const changeWeight = e => {
        const value = getPhysicalNumberValue(e.currentTarget.value);
        if(value.length > 5){
            return
        }
        setWeight(value);
    }

    const setClubSelectList = list => {
        const result = [];
        if(list){
            result.push({ key: "XX", value: "클럽명"});
            list.map(item => {
                result.push({ key: item.code, value: item.teamName});
            })
        }
        return result;
    }
    function branchListFilter(list){
        const result = [];
        if(list){
            result.push({ key: "XX", value: "지점명"});
            const filtered = list.filter(item => item.code == teamCode);
            filtered.map(item => {
                result.push({ key: item.num, value: item.title});
            })
        }
        return result;
    }

    useEffect(() => {
        console.log('RegistYouthStep1Component::componentDidMount');
        const getClubList = async () => { //모든 클럽 목록
            setClubList(setClubSelectList(await getAllGymList()));
        }
        getClubList();

        const getAllBranchList = async () => { //선택된 클럽의 지점, 모든 지점 목록 세팅
            setBranchList(branchListFilter(await getAllList()));
            setAllBranchList(await getAllList());
        }
        getAllBranchList();

        return function cleanup() {
            console.log('RegistYouthStep1Component::componentWillUnmount');
        }
    }, []);

    useEffect(() => {
        if(allBranchList) { //선택된 클럽의 지점 목록
            setBranchList(branchListFilter(allBranchList));
            setBranchCode('');
        }
    }, [teamCode]);

    const clickRegist = async e => { //등록 버튼 클릭
        e.preventDefault();

        if(memberType == 'J'){ //클럽 선수
            if(teamCode=='XX'){
                openAlertPopup('클럽명을 선택해 주세요.');
                return;
            }
            if(branchCode==''){
                openAlertPopup('지점명을 선택해 주세요.');
                return;
            }
        }

        if(height != '' && !isNumber(height)){
            openAlertPopup('키는 소수점 첫째자리까지의 숫자만 입력 가능합니다.');
            return;
        }
        if(weight != '' && !isNumber(weight)){
            openAlertPopup('몸무게는 소수점 첫째자리까지의 숫자만 입력 가능합니다.');
            return;
        }

        if (memberType == 'E') { // 엘리트 선수
            if(!sidoOpt || !gugunOpt || !school){
                openAlertPopup('학교 정보를 선택해 주세요.');
                return;
            }
            if(!schoolNum) {
                openAlertPopup('학년을 선택해 주세요.');
                return;
            }
        }

        let isSchoolReg = false;
        if(school && school != '' && Array.isArray(school)) {
            isSchoolReg = true;
        }

        const params = {
            type : memberType,
            kind : 'h',
            schoolKind : 'N', //스쿨대회여부
            clubCode : teamCode,
            placeCode : branchCode,
            name : userInfo.name,
            email : userInfo.email,
            tel : userInfo.mobilePhoneNo,
            school1 : isSchoolReg ? sidoOpt : '',
            school2 : isSchoolReg ? gugunOpt : '',
            schoolGrade : schoolGrade, //초중고
            school : isSchoolReg ? school[0]?.value : '',
            schoolYear : year,
            schoolNum : schoolNum,
            schoolCode : schoolGrade + '' + schoolNum,
            schoolNo : isSchoolReg ? school[0]?.key : '',
            birth : userInfo.birthDate,
            gender : userInfo.genderType,
            height : height,
            weight : weight,
            state : 'y',
            step : 1,
            joinId : id, //기존회원계정 등록시
            joinState : (id!="" ? 'y' : ''),
            memberSq : userInfo.memberSq,
            kblId : userInfo.id
        }

        const result = await youthRegist(params);

        if(result.status === 200){
            setIsRegistPopupDisplay(true);
        }else{
            openAlertPopup(result.data.message);
        }
    }

    return(
        <div className={`contents auth`}>
            <div className={`join-box`}>
                <div className={`tit`}>
                    <i className={`logo-kbl-youth`} />
                    <h4>KBL 유소년 등록하기</h4>
                    <p>유소년 정보 등록하고 데이터를 확인해보세요.</p>
                </div>
                <ul className={`join-input`}>
                    <li>
                        <h6>구분</h6>
                        <ul className={`flex-input`}>
                            <li><input type={`radio`} name={'type'} id={'type_J'} value={'J'} checked={memberType === 'J'} onChange={changeMembeType}/><label htmlFor={'type_J'}><span>클럽 선수</span></label></li>
                            <li><input type={`radio`} name={'type'} id={'type_E'} value={'E'} checked={memberType === 'E'} onChange={changeMembeType}/><label htmlFor={'type_E'}><span>엘리트 선수</span></label></li>
                        </ul>
                    </li>
                    {
                        memberType === 'E' &&
                        <li>
                            <h6 className={'font-gr'}>*엘리트 선수의 경우 클럽 및 지점 등록을 안하셔도 됩니다.</h6>
                        </li>
                    }
                    <li>
                        <h6>{memberType === 'J' &&<span className={`font-red`}>*</span>}클럽명</h6>
                        <FilterComponent
                            selectedKey={teamCode}
                            selectList={clubList}
                            selectChange={changeTeamCode}
                        />
                    </li>
                    <li>
                        <h6>{memberType === 'J' &&<span className={`font-red`}>*</span>}지점명</h6>
                        <FilterComponent
                            selectedKey={branchCode}
                            selectList={branchList}
                            selectChange={changeBranchCode}
                        />
                    </li>
                    <li className={`half`}>
                        <div>
                            <h6>키</h6>
                            <input type={`text`} onChange={changeHeight} value={height} placeholder={'cm'}/>
                        </div>
                        <div>
                            <h6>몸무게</h6>
                            <input type={`text`} onChange={changeWeight} value={weight} placeholder={'kg'}/>
                        </div>
                    </li>
                    <RegistYouthSchoolComponent
                        memberType={memberType}
                        schoolGrade={schoolGrade}
                        changeSchoolGrade={changeSchoolGrade}
                        year={year}
                        changeSchoolOpt={changeSchoolOpt}
                        schoolOpt={schoolOpt}
                        setSchoolOpt={setSchoolOpt}
                        setSchool={setSchool}
                        changeSchoolNum={changeSchoolNum}
                        schoolNum={schoolNum}
                        sidoOpt={sidoOpt}
                        setSidoOpt={setSidoOpt}
                        changeSidoOpt={changeSidoOpt}
                        gugunOpt={gugunOpt}
                        setGugunOpt={setGugunOpt}
                        changeGugunOpt={changeGugunOpt}
                    />
                    {/*<li>*/}
                    {/*    <h6>계정 등록</h6>*/}
                    {/*    <input type={`text`} value={id} readOnly/>*/}
                    {/*    <button className={`pb`} onClick={() => setIsDisplaySearchMemberPopup(true)}>기존 회원 계정 등록</button>*/}
                    {/*    <ul className={`desc`}>*/}
                    {/*        <li>기존 유소년 홈페이지 회원 등록을 하셔야 기존 데이터를 확인 할 수 있습니다.</li>*/}
                    {/*    </ul>*/}
                    {/*</li>*/}
                    <li className={`half`}>
                        <button className={`light-pb`} onClick={() => history.goBack()}>취소</button>
                        <button className={`pb`} onClick={clickRegist}>등록</button>
                    </li>

                    {/* 팝업 */}
                    <YouthMemberSearchContainer
                        isDisplaySearchMemberPopup={isDisplaySearchMemberPopup}
                        setIsDisplaySearchMemberPopup={setIsDisplaySearchMemberPopup}
                        openAlertPopup={openAlertPopup}
                        setId={setId}
                        changeLevel={changeLevel}
                        level={level}
                    />

                </ul>
            </div>

            <AlertComponent
                isDisplay={isAlertPopupDisplay}
                clickCloseButton={alertPopupClose}
                content={alertMessage}
            />
            <YouthRegistSuccess
                isRegistPopupDisplay={isRegistPopupDisplay}
                setIsRegistPopupDisplay={setIsRegistPopupDisplay}
                history={history}
            />

        </div>
    );
}

export default RegistYouthStep1Component;