import React from 'react';

const YouthClubInfoDetail = props => {
    const { info } = props;

    return (
        <div className={`con-box`}>
            <div className={`con-tit`}>
                <h4>{info[0].title}</h4>
            </div>
            <ul className={`youth-branch-info`}>
                {
                    info.map((item, index) => {
                        return (
                            <li key={item.titleOrd.toString()}>
                                <h6>{item.infoTitle}</h6>
                                <p>
                                    {
                                        item.info.map((it, i) =>
                                            <div key={it.infoSq.toString()}>{it.infoContent}</div>
                                        )
                                    }
                                </p>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
}

export default YouthClubInfoDetail;