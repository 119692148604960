import * as ApiService from '../Api.service'

const preFix = '/web';
export const getYouthMeasurementReport = async (params = {}) => {
    const url = preFix + '/youth/measurement/report';

    return ApiService.get(url, params);
}

export const getYouthMeasurementYearList = async () => {
    console.log('Measurement.service:getYouthMeasurementYearList');
    const url = preFix + '/youth/measurement/year';
    return ApiService.get(url);
}

export const getYouthMeasurementAvgRecord = async (param) => {
    console.log('Measurement.service:getYouthMeasurementAvgRecord');
    const url = preFix + '/youth/measurement/avg';
    return ApiService.get(url, param);
}

export const getYouthMeasurementPartsPercent = async (param) => {
    console.log('Measurement.service:getYouthMeasurementPartsPercent');
    const url = preFix + '/youth/measurement/parts/percent'
    return ApiService.get(url, param);
}

export const getYouthMeasurementMemberRecords = async (param) => {
    console.log('Measurement.service:getYouthMeasurementMemberRecords');
    const url = preFix + '/youth/measurement/record'
    return ApiService.get(url, param);
}

export const getYouthMeasurementRecordsBySchoolGrade = async (param) => {
    console.log('Measurement.service:getYouthMeasurementRecordsBySchoolGrade');
    const url = preFix + '/youth/measurement/record/school'
    return ApiService.get(url, param);
}

export const getYouthMeasurementPersonalRecords = async (param) => {
    console.log('Measurement.service:getYouthMeasurementPersonalRecords');
    const url = preFix + '/youth/measurement/record/manage'
    return ApiService.get(url, param);
}

export const getYouthMeasurementMemberRecordsByMemberSq = async (memberSq) => {
    console.log('Measurement.service:getYouthMeasurementMemberRecordsByMemberSq');
    const url = preFix + `/youth/measurement/record/manage/${memberSq}`;
    return ApiService.get(url);
}

export const getYouthMeasurementRecordsBySchoolGradeByMemberSq = async (param) => {
    console.log('Measurement.service:getYouthMeasurementRecordsBySchoolGradeByMemberSq');
    const url = preFix + '/youth/measurement/record/school/manage'
    return ApiService.get(url, param);
}