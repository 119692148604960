import React, { useState, useEffect } from 'react';
import FooterComponent from './FooterComponent';
import { getList as getSocialList } from '../../../service/social/Social.service'

const FooterContainer = props => {
  const [youtubueInfo, setYoutubueInfo] = useState(null);
  const [facebookInfo, setFacebookInfo] = useState(null);
  const [instagramInfo, setInstaGramInfo] = useState(null);
  const [naverPostInfo, setNaverPostInfo] = useState(null);
  const [naverTvInfo, setNaverTvInfo] = useState(null);

  const setSnsInfo = list => {
    if (!list) {
      return;
    }
    setYoutubueInfo(list.find(item => item.snsName === '유튜브'));
    setFacebookInfo(list.find(item => item.snsName === '페이스북'));
    setInstaGramInfo(list.find(item => item.snsName === '인스타그램'));
    setNaverPostInfo(list.find(item => item.snsName === '네이버 포스트'));
    setNaverTvInfo(list.find(item => item.snsName === '네이버 TV' || item.snsName === '네이버TV'));
  }

  useEffect(_ => {
    const getSnsInfoList = async _ => {
      const result = await getSocialList({ socialType: 'SNS_IMG' });
      
      if (result && result.sns &&  result.sns.length > 0)
        setSnsInfo(result.sns[0].snsInfo);
    }

    console.log('FooterContainer::componentDidMount');
    getSnsInfoList();

    return function cleanup() {
      console.log('FooterContainer::componentWillUnmount');
    }
  }, [])
  return (
    <FooterComponent
      {...props}
      youtubueInfo={youtubueInfo}
      facebookInfo={facebookInfo}
      instagramInfo={instagramInfo}
      naverPostInfo={naverPostInfo}
      naverTvInfo={naverTvInfo}
    />
  );
}
export default FooterContainer;