import React from 'react';
import UsageTermsComponent from './UsageTermsComponent'

const UsageTermsContainer = props => {
  const { history } = props
  return (
    <UsageTermsComponent history={history} />
  );
}

export default UsageTermsContainer;