import React from 'react';
import YouthDetailViewComponent from "../../../../commons/table/YouthDetailViewComponent";

const YouthPhotoViewComponent = props => {
    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>사진자료</h2>
                <p>유소년 대회의 다양한 사진 자료를 보실 수 있습니다.</p>
            </div>
            <YouthDetailViewComponent
                {...props}
                articleClass={'regular-bbs-info'}
                listUrl={`/youth/contents/photo`}
            />
        </div>
    );
}

export default YouthPhotoViewComponent;