import React, { useState} from 'react';

const MeasurementMemberSchoolComponent = props => {
    const { schoolData, schoolGradeName } = props;

    const [isMoreData, setIsMoreData] = useState(false);
    const changeIsMoreData = () => setIsMoreData(!isMoreData);

    const part1stList = [
        {key: "height",         value: "신장"},
        {key: "weight",         value: "체중"},
        {key: "arm_length",     value: "윙스펜"},
        {key: "standing_reach", value: "스탠딩리치"},
    ]
    const part2ndList = [
        {key: "grip_strength",  value: "악력"},
        {key: "pushup_cnt",     value: "1분 푸쉬업"},
        {key: "v_jump",         value: "버티컬 점프"},
        {key: "run_v_jump",     value: "맥스버티컬 점프"},
        {key: "lane_agility",   value: "레인 어질리티"},
        {key: "yard_sprint",    value: "10야드 스프린트"},
        {key: "court_sprint",   value: "¾코트 전력질주"},
        {key: "shuttle_run",    value: "300야드 셔틀런"},
    ]

/*    const getSchoolDataByPart = (part) => { //항목(height, weight)
        let data = null;
        let record = 0;

        if(schoolData){
            schoolData.map((item) => item.part === part ? data = item.stats : null);
        }
    }*/

    return(
        <div className='physical-data'>
            {
                part1stList && schoolData ?
                    part1stList.map((item) =>
                        <div>
                            <h6>{item.value}</h6>
                            <div>
                                <table>
                                    <thead>
                                    <tr>
                                        <th colSpan={2}>내 기록</th>
                                        <th colSpan={3}>{schoolGradeName}</th>
                                    </tr>
                                    <tr>
                                        <th>측정일자</th>
                                        <th>내 기록</th>
                                        <th>최저</th>
                                        <th>평균</th>
                                        <th>최대</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        schoolData.map((list) => list.part === item.key ?
                                            list.stats.map((record) =>
                                                <tr>
                                                    <td>{record.regDttm}</td>
                                                    <td>{record.partValue}</td>
                                                    <td>{record.minValue}</td>
                                                    <td>{record.avgValue}</td>
                                                    <td>{record.maxValue}</td>
                                                </tr>
                                            )
                                            : null
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                :
                    <div>
                        <h6></h6>
                        <div>
                            <table>
                                <thead>
                                <tr>
                                    <th colSpan={2}>내 기록</th>
                                    <th colSpan={3}>{schoolGradeName}</th>
                                </tr>
                                <tr>
                                    <th>측정일자</th>
                                    <th>내 기록</th>
                                    <th>최저</th>
                                    <th>평균</th>
                                    <th>최대</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={5}>측정 데이터가 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
            }

            {
                part2ndList && schoolData && isMoreData ?
                    part2ndList.map((item) =>
                        <div>
                            <h6>{item.value}</h6>
                            <div>
                                <table>
                                    <thead>
                                    <tr>
                                        <th colSpan={2}>내 기록</th>
                                        <th colSpan={3}>{schoolGradeName}</th>
                                    </tr>
                                    <tr>
                                        <th>측정일자</th>
                                        <th>내 기록</th>
                                        <th>최저</th>
                                        <th>평균</th>
                                        <th>최대</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        schoolData.map((list) => list.part === item.key ?
                                            list.stats.map((record) =>
                                                <tr>
                                                    <td>{record.regDttm}</td>
                                                    <td>{record.partValue}</td>
                                                    <td>{record.minValue}</td>
                                                    <td>{record.avgValue}</td>
                                                    <td>{record.maxValue}</td>
                                                </tr>
                                            )
                                            : null
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                    : null
            }
            {
                !schoolData ? null :
                    <button onClick={changeIsMoreData}>{!isMoreData ? `더보기` : `접기`}<i className={!isMoreData ? `ic-arrow-down-16-gr` : `ic-arrow-up-16-gr`} /></button>
            }
        </div>
    );
}

export default MeasurementMemberSchoolComponent;