import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { setInquiryParams, setIsMore } from '../../../../store/member/mypage';
import { isEmptyObject } from '../../../../helpers/objectHelper'
import {getInquireInfo, getInquiryList, deleteRequestItem, updateInquireReplyScore} from '../../../../service/member/Member.service';
import ReactHtmlParser from 'react-html-parser';
import PaginationComponent from '../../../commons/table/PaginationComponent'
import { getQueryStringFromObject } from '../../../../helpers/apiHelper';
import { getSimpleDateFormat } from '../../../../helpers/dateHelper';
import ConfirmComponent from '../../../commons/popup/ConfirmComponent';
import AlertComponent from '../../../commons/popup/AlertComponent';
import EmptyContentComponent from "../../../commons/table/EmptyContentComponent";

const RequestListComponent = props => {
    const { history, location, match } = props;
    const { inquiryParams, inquiryList, pageSize, inquiryPagingData } = useSelector(state => state.memberMypage);
    const [isListType, setIsListType] = useState(true);
    const [prevNextList, setPrevNextList] = useState(null);
    const [requestInfo, setRequestInfo] = useState(null);
    const detailId = match.params.id;
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [isConfirmPopupDisplay, setIsConfirmPopupDisplay] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [score, setScore] = useState(0);
    const dispatch = useDispatch();

    const defaultParams = {
        start: 0,
        limit: pageSize,
    }

    const changePage = e => {
        e.preventDefault();
        const value = e.currentTarget.getAttribute('data-value');
        //const key = e.currentTarget.getAttribute('data-key');

        if (value === inquiryPagingData.currentPage) {
            return
        }
        dispatch(setIsMore(false));
        inquiryParams.start = (value - 1) * pageSize
        inquiryParams.limit = pageSize;

        history.push(`${history.location.pathname}${getQueryStringFromObject(inquiryParams)}`)

        dispatch(setInquiryParams(inquiryParams));
    }

    const moreView = e => {
        e.preventDefault();
        const value = e.currentTarget.getAttribute('data-value');
        //const key = e.currentTarget.getAttribute('data-key');

        if (value === inquiryPagingData.currentPage) {
            return
        }
        dispatch(setIsMore(true));
        inquiryParams.start = (value - 1) * pageSize
        inquiryParams.limit = pageSize;

        history.push(`${history.location.pathname}${getQueryStringFromObject(inquiryParams)}`)
        dispatch(setInquiryParams(inquiryParams));
    }

    const setDefaultParmas = async () => {

        if (detailId) {
            setIsListType(false);
            const result = await getInquireInfo(detailId);
            setRequestInfo(result);

            if (result) {
                if (location.state && location.state.contentIdx) {
                    const prevNextParams = {};
                    if (Number(location.state.contentIdx) !== 0) {
                        prevNextParams.start = Number(location.state.contentIdx) - 1;
                        prevNextParams.limit = 3;
                    } else {
                        prevNextParams.start = 0;
                        prevNextParams.limit = 2;
                    }

                    setPrevNextList(await getInquiryList(prevNextParams));
                }
            }
        } else {
            setIsListType(true);
            const _queryString = queryString.parse(location.search);


            if (!isEmptyObject(_queryString)) {
                dispatch(setInquiryParams(_queryString));
            } else {
                dispatch(setInquiryParams(defaultParams));
            }
        }
    }

    const deleteItem = async e => {
        e.preventDefault();
        setIsConfirmPopupDisplay(false);

        if (isProcessing) {
            return;
        }

        setIsProcessing(true);

        const result = await deleteRequestItem(detailId);

        setIsProcessing(false);

        if (result.status === 200) {
            history.push('/member/mypage/request');
        } else {
            openAlertPopup(result.data.message);
        }
    }

    const alertPopupClose = e => {
        e.preventDefault();
        setIsAlertPopupDisplay(false)
    }

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }

    const listPage = e => {
        e.preventDefault();
        history.push('/member/mypage/request');
    }

    const detailRender = async e => {
        e.preventDefault();

        if(detailId === e.currentTarget.dataset.key) {
            history.push({
                pathname: `/member/mypage/request/`
            });
        } else {
            history.push({
                pathname: `/member/mypage/request/${e.currentTarget.dataset.key}`,
                state: {
                    contentIdx: e.currentTarget.getAttribute('data-index') ? e.currentTarget.getAttribute('data-index') : null,
                }
            });
        }
    }

    const renderPrevNextButton = () => {
        const result = [];
        if (prevNextList.questions) {
            if (prevNextList.questions.length === 3) {
                result.push(
                    <>
                        <button className={`prev line-gr`} onClick={detailRender} data-key={prevNextList.questions[2].qnaSq} data-index={Number(location.state.contentIdx) + 1}>
                            <i className={`ic-arrow-left-16-gr`} />이전글
                        </button>
                        <div>
                            <button className={`pb`} onClick={listPage}>목록으로</button>
                            <button onClick={openDeleteConfirmPopup}>삭제</button>
                        </div>
                        <button className={`next line-gr`} onClick={detailRender} data-key={prevNextList.questions[0].qnaSq} data-index={Number(location.state.contentIdx) - 1}>
                            다음글<i className={`ic-arrow-right-16-gr`} />
                        </button>
                    </>
                )
            } else if (prevNextList.questions.length === 2) {
                if (Number(detailId) === prevNextList.questions[0].qnaSq) {
                    result.push(
                        <>
                            <button className={`prev line-gr`} onClick={detailRender} data-key={prevNextList.questions[1].qnaSq} data-index={Number(location.state.contentIdx) + 1}>
                                <i className={`ic-arrow-left-16-gr`} />이전글
                            </button>
                            <div>
                                <button className={`pb`} onClick={listPage}>목록으로</button>
                                <button onClick={openDeleteConfirmPopup}>삭제</button>
                            </div>
                            <button className={`next line-gr`} disabled>다음글<i className={`ic-arrow-right-16-gr`} /></button>
                        </>
                    )
                } else {
                    result.push(
                        <>
                            <button className={`prev line-gr`} disabled><i className={`ic-arrow-left-16-gr`} />이전글</button>
                            <div>
                                <button className={`pb`} onClick={listPage}>목록으로</button>
                                <button onClick={openDeleteConfirmPopup}>삭제</button>
                            </div>
                            <button className={`next line-gr`} onClick={detailRender} data-key={prevNextList.questions[0].qnaSq} data-index={Number(location.state.contentIdx) - 1}>
                                다음글<i className={`ic-arrow-right-16-gr`} />
                            </button>
                        </>
                    )
                }
            }
        }

        return result;

    }

    const openDeleteConfirmPopup = e => {
        e.preventDefault();
        setIsConfirmPopupDisplay(true);
    }

    const confirmPopupClose = e=> {
        e.preventDefault();
        setIsConfirmPopupDisplay(false)
    }

    useEffect(() => {
        setDefaultParmas();

        return function cleanup() {
            console.log('RequestListComponent::componentWillUnmount');
            setPrevNextList(null);
            // window.scrollTo(0, 0);
        }

    }, [location]);

    const clickSubmit = async e => {
        e.preventDefault();

        if(!detailId) return;

        const result = await updateInquireReplyScore({qnaSq : detailId,  score : score});
        if (result.status === 200) {
            history.push('/member/mypage/request');
            setScore(0);
        } else {
            openAlertPopup(result.data.message);
        }
    }

    return (
        <>
            <ConfirmComponent
                isDisplay={isConfirmPopupDisplay}
                clickNoButton={confirmPopupClose}
                clickOkButton={deleteItem}
                content={'삭제하시겠습니까?'}
            />
            <AlertComponent
                isDisplay={isAlertPopupDisplay}
                clickCloseButton={alertPopupClose}
                content={alertMessage}
            />
            <div className={`con-box`}>
                {/* 데스크탑 */}
                <div className={`mypage-table01 table-style01 desktop-only`}>
                    <table>
                        <colgroup>
                            <col style={{width: '8%'}} />
                            <col style={{width: '12%'}} />
                            <col style={{width: 'auto'}} />
                            <col style={{width: '12%'}} />
                            <col style={{width: '12%'}} />
                            <col style={{width: '10%'}} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>구분</th>
                                <th>제목</th>
                                <th>상태</th>
                                <th>등록일자</th>
                                <th>답변 평점</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            inquiryList && inquiryList.data ?
                                inquiryList.data.map((item, index) =>
                                <>
                                    <tr key={`mypage-request-list-${index}`}>
                                        <td>{item.qnaSq}</td>
                                        <td>{item.qnaCategory ? item.qnaCategory : 'QnA'}</td>
                                        <td className={`align-left`} onClick={detailRender} data-key={item.qnaSq} data-index={Number(inquiryParams.start) + index}>{item.qnaTitle}</td>
                                        <td>{Number(item.isReplied) === 1 ? '답변완료' : '대기'}</td>
                                        <td>{getSimpleDateFormat(item.regDttm)}</td>
                                        <td>
                                            {
                                                item.scoreDttm && item.score >= 0 && item.score < 6 ?
                                                    <ul className={`grade-wrap`}>
                                                        {[...Array(5)].map((n, index) => {
                                                            return (
                                                                <li><i className={`${item.score <= index ? 'ic-star' : 'ic-star-ov'}`} /></li>
                                                            )
                                                        })}
                                                    </ul>
                                                    : null
                                            }
                                        </td>
                                    </tr>
                                    {/* 데스크탑 - 답변 */}
                                    <tr>
                                        <td colSpan={6} style={{ display: isListType || (item.qnaSq != detailId) ? 'none' : '' }}>
                                            {
                                                requestInfo ?
                                                    <>
                                                        <div className={`inquiry q`}>
                                                            <div className={`ic`}>Q</div>
                                                            <div className={`desc`}>
                                                                <p>{ReactHtmlParser(requestInfo.content)}</p>
                                                                <span>{requestInfo.regDttm}</span>
                                                            </div>
                                                        </div>
                                                        {/*{Number(item.isReplied) === 1 ? '답변완료' : '대기'}*/}
                                                        {
                                                            requestInfo.answer ?
                                                                <>
                                                                    <div className={`inquiry a`}>
                                                                        <div className={`ic`}>A</div>
                                                                        <div className={`desc`}>
                                                                            <p>{ReactHtmlParser(requestInfo.answer.content)}</p>
                                                                            <span>{requestInfo.answer.regDttm}</span>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        !requestInfo.answer.scoreDttm
                                                                            ?
                                                                            <div className={`assess`}>
                                                                                <h6>답변 만족도 평가</h6>
                                                                                <div>
                                                                                    <p>답변에 대해 얼마나 만족하시나요?</p>
                                                                                    <ul className={`grade-wrap`}>
                                                                                        {[...Array(5)].map((n, index) => {
                                                                                            return (
                                                                                                <li onClick={() =>{
                                                                                                    if(score === index + 1) {
                                                                                                        setScore(0)
                                                                                                    } else {
                                                                                                        setScore(index + 1)
                                                                                                    }
                                                                                                }}><i className={`${score <= index ? 'ic-star' : 'ic-star-ov'}`} /></li>
                                                                                            )
                                                                                        })}
                                                                                    </ul>
                                                                                </div>
                                                                                <button className={`md pb`} onClick={clickSubmit}>평가하기</button>
                                                                            </div>
                                                                            : <div className={`assess`}></div>
                                                                    }
                                                                </>
                                                                : null
                                                        }
                                                    </>
                                                    : null
                                            }
                                        </td>
                                    </tr>
                                </>
                                )
                                : null
                        }
                        </tbody>
                    </table>
                    {
                        inquiryPagingData ?
                            <PaginationComponent
                                pagingData={inquiryPagingData}
                                changePage={changePage}
                                moreView={moreView}
                            />
                            : null
                    }
                    {
                        !inquiryList || !inquiryList.data ?
                            <EmptyContentComponent content={'문의 내역이 없습니다.'} />
                            : null
                    }
                </div>
                {/* 모바일 */}
                <div className={`mobile-only`}>
                    <ul className={`inquiry-list`}>
                        {
                            inquiryList && inquiryList.data ?
                                inquiryList.data.map((item, index) =>
                                    <li>
                                        <div className={`q`} onClick={detailRender} data-key={item.qnaSq}>
                                            <div className={`ic`}>Q</div>
                                            <div className={`desc`}>
                                                <p>{item.qnaTitle}</p>
                                                <div>
                                                    <ul className={`flex-txt`}>
                                                        <li>{getSimpleDateFormat(item.regDttm)}</li>
                                                        <li>{Number(item.isReplied) === 1 ? '답변완료' : '대기'}</li>
                                                    </ul>
                                                    {item.scoreDttm && item.score >= 0 && item.score < 6 ?
                                                        <div className={`grade-cont`}>
                                                            <i className={`ic-star-ov`} /><p>{item.score}.0</p>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`a`} style={{ display: isListType || (item.qnaSq != detailId) ? 'none' : '' }}>
                                            {
                                                requestInfo ?
                                                    <>
                                                        <p>{ReactHtmlParser(requestInfo.content)}</p>
                                                        <span>{requestInfo.regDttm}</span>
                                                        {
                                                            requestInfo.answer ?
                                                                <>
                                                                    <div className={`a-cont`}>
                                                                        <div className={`ic`}>A</div>
                                                                        <div className={`desc`}>
                                                                            <p>{ReactHtmlParser(requestInfo.answer.content)}</p>
                                                                            <span>{requestInfo.answer.regDttm}</span>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        !requestInfo.answer.scoreDttm
                                                                            ?
                                                                            <div className={`assess`}>
                                                                                <h6>답변 만족도 평가</h6>
                                                                                <div>
                                                                                    <p>답변에 대해 얼마나 만족하시나요?</p>
                                                                                    <ul className={`grade-wrap`}>
                                                                                        {[...Array(5)].map((n, index) => {
                                                                                            return (
                                                                                                <li onClick={() =>{
                                                                                                    if(score === index + 1) {
                                                                                                        setScore(0)
                                                                                                    } else {
                                                                                                        setScore(index + 1)
                                                                                                    }
                                                                                                }}><i className={`${score <= index ? 'ic-star' : 'ic-star-ov'}`} /></li>
                                                                                            )
                                                                                        })}
                                                                                    </ul>
                                                                                </div>
                                                                                <button className={`md pb`} onClick={clickSubmit}>평가하기</button>
                                                                            </div>
                                                                            : <div className={`assess`}></div>
                                                                    }
                                                                </>
                                                                : null
                                                        }
                                                    </>
                                                    : null
                                            }
                                        </div>
                                    </li>
                                )
                                : null
                        }
                    </ul>
                    {
                        inquiryPagingData ?
                            <PaginationComponent
                                pagingData={inquiryPagingData}
                                changePage={changePage}
                                moreView={moreView}
                            />
                            : null
                    }
                </div>
            </div>
        </>
    );
}

export default RequestListComponent;