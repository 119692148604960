import React, { useEffect, useState } from 'react';
import PlayerRuleComponent from './PlayerRuleComponent'
import OperationMethodComponent from './OperationMethodComponent'
import GameRuleComponent from './GameRuleComponent'
import RegulationComponent from './RegulationComponent'

import FilterComponent from '../../../commons/filter/FilterComponent'
import AntiDopingComponent from "./AntiDopingComponent";

const RuleComponent = props => {
    const [selectedTab, setSelectedTab] = useState('regulation');

    const tabSelectList = [
        {
            key: 'regulation',
            value: '정관 및 규약'
        },
        {
            key: 'game-rule',
            value: '경기규칙'
        },
        {
            key: 'player-rule',
            value: '선수선발 및 출전 규정'
        },
        {
            key: 'operation-method',
            value: '운영방식'
        },
        {
            key: 'anti-doping',
            value: '도핑방지규정'
        },
    ];

/*    const changeTab = e => {
        e.preventDefault();
        const tabKey = e.currentTarget.getAttribute('data-key');
        setSelectedTab(tabKey);
    }*/

    const changeParams = (key, value) => {
        setSelectedTab(key);
    }

    useEffect(() => {
        // setSelectedTab(queryString.parse(location.search).type);
    }, []);

    useEffect(_ => {
        // window.ntmEvent();
    }, [])

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>제도 및 규칙</h2>
                <p>KBL은 공정하고 투명한 프로농구 경기 환경을 조성합니다.</p>
            </div>
            <ul className={`filter-wrap`}>
                <li>
                    <h6>구분</h6>
                    <FilterComponent
                        selectChange={changeParams}
                        selectList={tabSelectList}
                        selectedKey={selectedTab}
                    />
                </li>
            </ul>
            <div className={`con-box`}>
                {
                    selectedTab === 'regulation' ?
                        <RegulationComponent /> :
                        selectedTab === 'game-rule' ?
                            <GameRuleComponent /> :
                            selectedTab === 'player-rule' ?
                                <PlayerRuleComponent /> :
                                selectedTab === 'operation-method' ?
                                    <OperationMethodComponent /> :
                                    selectedTab === 'anti-doping' ?
                                        <AntiDopingComponent />
                                    : null
                }
            </div>
        </div>
    );
}

export default RuleComponent;