import React, { useEffect, useState } from 'react';
import {getYouthOperationStatus} from "../../../../service/youth/Branch.service";

const YouthKblYouthComponent = props => {
    const [data, setData] = useState(null);

    const setDataList = async () => {
        const result = await getYouthOperationStatus();
        if(result) {
            setData(result);
        }
    }

    useEffect(() => {
        setDataList();
    }, []);

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>KBL YOUTH</h2>
                <p>KBL 유소년을 소개하는 페이지입니다.</p>
            </div>

            <div className={`con-box`}>
                <div className={`youth-box`}>
                    <div className={`youth-tit`}>
                        <h4>소개</h4>
                    </div>
                    <p>KBL 유소년은 "꿈꾸는 아이들의 즐거운 도전"을 슬로건으로 한국농구의 미래를 가꾸는 핵심사업입니다. 농구를 통해 아이들의 희망찬 내일을 응원하겠습니다.</p>
                    <ul className={`youth-img-wrap`}>
                        <li><img src={`/imgs/youth/youth-img01.jpg`} /></li>
                        <li><img src={`/imgs/youth/youth-img02.jpg`} /></li>
                        <li><img src={`/imgs/youth/youth-img03.jpg`} /></li>
                    </ul>
                </div>
                <div className={`youth-box`}>
                    <div className={`youth-tit`}>
                        <h4>사업목표</h4>
                    </div>
                    <img className={`img-youth-value desktop-only`} src={`/imgs/img-youth-value.png`} />
                    <img className={`img-youth-value mobile-only`} src={`/imgs/img-youth-value-mb.png`} />
                </div>
                <div className={`youth-box`}>
                    <div className={`youth-tit`}>
                        <h4>핵심 가치</h4>
                    </div>
                    <ul className={`youth-core-value`}>
                        <li>
                            <i className={`ic-youth-value-01`} />
                            <h6>GROW</h6>
                            <p>신체발달</p>
                        </li>
                        <li>
                            <i className={`ic-youth-value-02`} />
                            <h6>TOGETHER</h6>
                            <p>협동</p>
                        </li>
                        <li>
                            <i className={`ic-youth-value-03`} />
                            <h6>COMPETITION</h6>
                            <p>선의의 경쟁</p>
                        </li>
                        <li>
                            <i className={`ic-youth-value-04`} />
                            <h6>ENJOY</h6>
                            <p>즐거움</p>
                        </li>
                    </ul>
                </div>
                {
                    data &&
                    <div className={`youth-box`}>
                        <div className={`youth-tit`}>
                            <h4>운영현황</h4>
                        </div>
                        <div className={`youth-oper-status`}>
                            <ul>
                                <li>
                                    <i className={`ic-club-wh-60`} />
                                    <h6>{data.clubCnt}</h6>
                                    <p>CLUB</p>
                                </li>
                                <li>
                                    <i className={`ic-spot-wh-60`} />
                                    <h6>{data.locationCnt}</h6>
                                    <p>LOCATION</p>
                                </li>
                                <li>
                                    <i className={`ic-teacher-wh-60`} />
                                    <h6>{data.teacherCnt}</h6>
                                    <p>TEACHER</p>
                                </li>
                                <li>
                                    <i className={`ic-player-wh-60`} />
                                    <h6>{data.playerCnt.toLocaleString('ko-KR')}</h6>
                                    <p>PLAYER</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default YouthKblYouthComponent;