import React from 'react';
import PaginationComponent from '../../../../commons/table/PaginationComponent';
import { bbsSearchTypeSelectList } from '../../../../../helpers/codeHelper';
import FilterComponent from "../../../../commons/filter/FilterComponent";
import YouthBBSTableComponent from "../../../../commons/table/YouthBBSTableComponent";

const YouthNoticeListComponent = props => {
    const { history, list, searchType, searchWord, teamCode, changeParams, pagingData,
        changeSearchWord, changeSearchType, params } = props;

    const teamCodeList = [
        {key: '', value: '전체'},
        {key: '16', value: '원주DB'},
        {key: '35', value: '서울삼성'},
        {key: '55', value: '서울SK'},
        {key: '50', value: '창원LG'},
        {key: '66', value: '고양소노'},
        {key: '60', value: '부산KCC'},
        {key: '70', value: '안양정관장'},
        {key: '06', value: '수원KT'},
        {key: '64', value: '한국가스공사'},
        {key: '10', value: '울산현대모비스'},
    ];

    const filterTeam = e => changeParams('filterTeam', e);
    const changePage = e => changeParams('changePage', e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-value'));
    const moreView = e => changeParams('moreView', e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-value'));
    const clickSearch = e => changeParams('clickSearch', e);
    const enterKeyPress = e => {
        if (e.key === 'Enter') {
            changeParams('clickSearch', e);
        }
    }

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>공지사항</h2>
                <p>KBL 유소년의 소식 및 공지 안내 페이지입니다.</p>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4></h4>
                    <div className={`option-wrap`}>
                        <FilterComponent
                            type={'filterTeam'}
                            selectedKey={teamCode}
                            selectList={teamCodeList}
                            selectChange={changeParams}
                        />
                        <FilterComponent
                            type={'changeSearchType'}
                            selectedKey={searchType}
                            optionClass={'team pc'}
                            selectList={bbsSearchTypeSelectList}
                            selectChange={changeSearchType}
                        />
                        <div className={`search-input`}>
                            <input type='text' value={searchWord} onChange={changeSearchWord} placeholder='검색어를 입력해주세요.' onKeyPress={enterKeyPress}/>
                            <button onClick={clickSearch}><i className={`ic-search-20`} /></button>
                        </div>
                    </div>
                </div>
                {
                    list ?
                        <YouthBBSTableComponent
                            tableClass={'regular-bbs-list'}
                            history={history}
                            startIndex={params.start}
                            {...props}
                        />
                        : null
                }
                {
                    pagingData ?
                        <PaginationComponent
                            pagingData={pagingData}
                            changePage={changePage}
                            moreView={moreView}
                        />
                        : null
                }
            </div>
        </div>
    );
}

export default YouthNoticeListComponent;