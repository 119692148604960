const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
const emailRegex2 = /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/i;
const pwdRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+])[A-Za-z\d$`~!@$!%*#^?&\\(\\)\-_=+]{8,15}$/i;
const accountRegex = /^[0-9][0-9\-]*[0-9]$/;
const telNumberRegex = /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/;
const mobileTelNumberRegex = /^(?:(010-\d{4})|(01[1|6|7|8|9]-\d{3,4}))-(\d{4})$/;

export const getOnlyNumberValue = value => value.replace(/[^0-9]/g, '');
export const getPhysicalNumberValue = value => value.replace(/[^.0-9]/g, '');
export const isEmailAddress = value => emailRegex2.test(value);
export const isValidPassword = value => pwdRegex.test(value);
export const isValidAccountNumber = value => accountRegex.test(value);
export const isValidTelNumber = value => telNumberRegex.test(value);
export const isValidMobileTelNumber = value => mobileTelNumberRegex.test(value);

export const getMobileNumberFormat = value => {
    if (!value) {
        return '';
    }

    return value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
}

export const getNumberWithComma = value => {
    if (!value) {
        return value;
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getFloatFixed = (value, fixed, unit='') => {
    return value ? parseFloat(Math.round(value * 100) / 100).toFixed(fixed) + unit : '-';
};