import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './views/Routes';
import {  setIsLogin, setAuthUserInfo } from './store/common/auth';
import * as AxiosInterceptor from './service/Axios.interceptor'
import { getAccessToken, getRefreshToken } from './helpers/authHelper';
import { useDispatch } from 'react-redux';
import { getMemberInfo } from './service/member/Member.service'
import { getTeamCodeByDomain, defaultTeamInfoList } from './helpers/commonHelper';
import { getMobileAppHeaderData} from './helpers/apiHelper';
import queryString from 'query-string';
import {MaintenanceContainer} from "./views/pages/maintenance";
import { setCookie} from "./helpers/cookieHelper";
import moment from "moment";
import {Helmet} from "react-helmet-async";

const browserHistory = createBrowserHistory();

function App() {

  const dispatch = useDispatch();
  const [isDisplay, setIsDisplay] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [isLoginChecked, setIsLoginChecked] = useState(false);
  const teamCode = getTeamCodeByDomain();

  // 점검 시간
  const time = {
    open : moment('2023-06-08 10:00:00', 'YYYY-MM-DD HH:mm:ss'),
    close: moment('2023-06-08 14:00:00', 'YYYY-MM-DD HH:mm:ss'),
  }

/*  const setMaintenanceStatus = () => {
    const openDiffSec = moment.duration(moment().diff(time.open)).asSeconds();
    const closeDiffSec = moment.duration(moment().diff(time.close)).asSeconds();
    const maintenanceStatus = openDiffSec > 0 && closeDiffSec < 0;
    setIsDisplay(maintenanceStatus);
  }*/

  const setPageHeaderData = () => {

    const teamCode = getTeamCodeByDomain();
    let teamName = defaultTeamInfoList.find(item => item.tcode === teamCode);
    document.title = 'KBL 유소년 홈페이지'

    // favicon
    if (typeof window === 'undefined') return;
    const link = window.document.querySelector("link[rel*='icon']") || window.document.createElement("link");
    link.type = "image/svg+xml"
    link.href = teamName ? teamName.tfavicon : teamCode === 'YH'? '/imgs/favicon/kbl/favicon.svg' : '/imgs/favicon/kbl/favicon.svg';
  }

  useEffect(_ => {
    const initAxios = async () => {
      await AxiosInterceptor.init();
      setIsInit(true);

      if ((getAccessToken() && getRefreshToken()) || getMobileAppHeaderData()) {
        const logInCheckedReuslt = await getMemberInfo();
        console.log(999, logInCheckedReuslt)
        if (logInCheckedReuslt) {
          dispatch(setIsLogin(true));
          dispatch(setAuthUserInfo(logInCheckedReuslt));
          //window.logSetUserId(logInCheckedReuslt.id);
          setIsLoginChecked(true);
        } else {
          dispatch(setIsLogin(false));
          dispatch(setAuthUserInfo({}));
          //window.logSetUserId('');
          setIsLoginChecked(true);
        }
      } else {
        dispatch(setIsLogin(false));
        setIsLoginChecked(true);
      }
    }

    initAxios();
    setPageHeaderData();
    if(teamCode && teamCode === 'YH'){
      // setMaintenanceStatus();
    }
    // Translate.init();

    return function cleanup() {
    }
  }, [dispatch])

  useEffect(_ => {
    browserHistory.listen(location => {
      const params = queryString.parse(location.search);
      const mobileAppHeaerValues = getMobileAppHeaderData();
      if (mobileAppHeaerValues) {
        params.device = mobileAppHeaerValues.device;
      } else {
        params.device = 'WEB'
      }

      if(teamCode && teamCode === 'YH'){
        // setMaintenanceStatus();
      }
      window.ntmEvent();
      // if(location.pathname.includes('/ticket/')) {
      //   window.ntmEvent();
      // }
    });
  }, [browserHistory])

  const _queryString = queryString.parse(window.location.search);
  if (_queryString.whitelist) {
    setCookie("whitelist", _queryString.whitelist)
  }

  return (
      !isDisplay ?
          isInit && isLoginChecked ?
              <>
                <Helmet>
                  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
                </Helmet>
                <Router history={browserHistory}>
                  <Routes />
                </Router>
              </>
              : null
      :   <MaintenanceContainer />
  );
}

export default App;