import React, {useState, useEffect} from 'react';

import YouthClubInfoComponent from './YouthClubInfoComponent';
import FilterComponent from "../../../../commons/filter/FilterComponent";

const YouthClubInfoContainer = props => {
    const { match } = props;

    const [selectedGymSq, setSelectedGymSq] = useState('');

    const tabSelectList = [
        {
            key: 'info',
            value: '지점 안내'
        }
    ];

    useEffect( () => {
        setSelectedGymSq(match.params.id);
    }, []);
    useEffect( () => {
    }, [selectedGymSq]);

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>클럽 소개</h2>
                <p>클럽의 지점 위치 및 상세 정보를 확인하실 수 있는 페이지입니다.</p>
            </div>
            <ul className={`filter-wrap`}>
                <li>
                    <h6>구분</h6>
                    <FilterComponent
                        selectList={tabSelectList}
                    />
                </li>
            </ul>
        {
            selectedGymSq &&
            <YouthClubInfoComponent
                selectedGymSq = {selectedGymSq}
            />
        }
        </div>
    );
}

export default YouthClubInfoContainer;