import React, { useState } from 'react';
import EmptyGameComponent from "../../../../commons/table/EmptyGameComponent";
import AlertComponent from "../../../../commons/popup/AlertComponent";

const YouthScheduleListComponent = props => {
    const { history, changeParams, gameList } = props;
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const changeGame = e => changeParams('changeGame', e);

    const alertPopupClose = e => {
        e.preventDefault();
        setIsAlertPopupDisplay(false);
        setAlertMessage('');
    }

    const pageRender = e => {
        e.preventDefault();
        const id = e.currentTarget.dataset.id;
        const type = e.currentTarget.dataset.type;
        if(!id || id === undefined) {
            setAlertMessage('데이터가 없습니다.');
            setIsAlertPopupDisplay(true);
            return;
        }

        history.push(`/youth/contents/${type}/${id}`);
    }

    return (
        <>
            <AlertComponent
                clickCloseButton={alertPopupClose}
                isDisplay={isAlertPopupDisplay}
                content={alertMessage}
            />
            {
                gameList && gameList.length > 0 ?
                    gameList.map((item, index) =>
                        <div key={`game_${index}`} className={`con-box`}
                            style={{borderTop: index === 0 ? '1px solid var(--black)' : '', padding: '4rem'}}>
                            <div key={`game_${index}`}>
                                <div className={`con-tit`}>
                                    <ul className={`flex-tit`}>
                                        <li>{item.gameDateForDisp} {item.weekDay}</li>
                                    </ul>
                                </div>
                                <ul className={`game-schedule-list-wrap`}>
                                    {
                                        item.list && item.list.length > 0 ?
                                            item.list.map((i, idx) =>
                                                <li key={`game_${i.gmkey}`}>
                                                    <div className={`tit`}>
                                                        <span className={`tag ${i.gameLevel === '2'? 'red' : i.gameLevel === '3' ? 'pb' : 'gr'}`}>{i.gameLevelTitle}</span>
                                                        <h6>{i.gameTime?.substring(0,5)}</h6>
                                                        {
                                                            i.gameCode === '01' ?
                                                                <>
                                                                    <p>{`${i.school} / ${i.jo}`}</p>
                                                                    <p>{i.place}</p>
                                                                </>
                                                                : <p>{`${i.school} / ${i.place}`}</p>
                                                        }
                                                    </div>
                                                    <ul className={`score-wrap`}>
                                                        <li>
                                                            <div className={`team`}>
                                                            {
                                                                i.homeCode == 'X1' || i.homeCode == 'X2' ? null :
                                                                <img src={`/imgs/youth/emblem/em_KBL${i.homeCode}.png`} style={{width: 'auto', height:'4rem'}}
                                                                     onError={e => { e.target.onerror = null; e.target.src = `/imgs/youth/emblem/em_KBL${i.homeCode2}.png`;}} />
                                                            }
                                                                {/*<i className={`emblem-kcc`} />*/}
                                                                <p>{i.tnameFH}</p>
                                                            </div>
                                                            <div className={`score font-red`}>{i.homeScore}</div>
                                                        </li>
                                                        <li>
                                                            <div className={`team`}>
                                                                {
                                                                    i.awayCode == 'X1' || i.awayCode == 'X2' ? null :
                                                                    <img src={`/imgs/youth/emblem/em_KBL${i.awayCode}.png`} style={{width: 'auto', height:'4rem'}}
                                                                         onError={e => { e.target.onerror = null; e.target.src = `/imgs/youth/emblem/em_KBL${i.awayCode2}.png`;}} />
                                                                }
                                                                {/*<i className={`emblem-el`} />*/}
                                                                <p>{i.tnameFA}</p>
                                                            </div>
                                                            <div className={`score`}>{i.awayScore}</div>
                                                        </li>
                                                    </ul>
                                                    <div className={`button`}>
                                                        <button className={`pb`} disabled={i.gameStatus > 0? false : true} onClick={()=>{changeGame(i.gmkey)}}>
                                                            {i.gameStatus > 0? '경기 기록' : '경기 예정'}
                                                        </button>
                                                        <ul className={`youth-game-btn cursor-link`}>
                                                            <li onClick={pageRender} data-type={'video'} data-id={i.videoSq}>경기 영상</li>
                                                            <li onClick={pageRender} data-type={'photo'} data-id={i.photoSq}>경기 사진</li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            )
                                        : null
                                    }
                                </ul>
                            </div>
                        </div>
                    )
                    : <EmptyGameComponent />
            }
        </>
    )
}

export default YouthScheduleListComponent;