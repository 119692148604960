import React from 'react';
import PaginationComponent from '../../../../commons/table/PaginationComponent';
import {bbsSearchTypeSelectList, getYouthPhotoTypeValueFromKey, youthMediaTypeSelectList} from '../../../../../helpers/codeHelper';
import FilterComponent from "../../../../commons/filter/FilterComponent";
import YouthPhotoBBSComponent from "../../../../commons/table/YouthPhotoBBSComponent";

const YouthPhotoListComponent = props => {
    const { searchType, searchWord, changeParams, pagingData, seasonList, season, gameList, game,
        changeSearchWord, changeSearchType, photoType, params } = props;

    const changePage = e => changeParams('changePage', e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-value'));
    const moreView = e => changeParams('moreView', e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-value'));
    const clickSearch = e => changeParams('clickSearch', e);
    const enterKeyPress = e => {
        if (e.key === 'Enter') {
            changeParams('clickSearch', e);
        }
    }

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>사진자료</h2>
                <p>유소년 대회의 다양한 사진 자료를 보실 수 있습니다.</p>
            </div>
            <ul className={`filter-wrap`}>
                <li>
                    <h6>카테고리</h6>
                    <FilterComponent
                        type={'changePhotoType'}
                        selectedKey={photoType}
                        optionClass={'team pc'}
                        selectList={youthMediaTypeSelectList}
                        selectChange={changeParams}
                    />
                </li>
                <li>
                    <h6>시즌</h6>
                    <FilterComponent
                        type={'changeSeason'}
                        selectedKey={season}
                        optionClass={'team pc'}
                        selectList={seasonList}
                        selectChange={changeParams}
                    />
                </li>
                <li>
                    <h6>경기</h6>
                    <FilterComponent
                        type={'changeGame'}
                        selectedKey={game}
                        optionClass={'team pc'}
                        selectList={gameList}
                        selectChange={changeParams}
                    />
                </li>
            </ul>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4></h4>
                    <div className={`option-wrap`}>
                        <FilterComponent
                            type={'changeSearchType'}
                            selectedKey={searchType}
                            optionClass={'team pc'}
                            selectList={bbsSearchTypeSelectList}
                            selectChange={changeSearchType}
                        />
                        <div className={`search-input`}>
                            <input type='text' value={searchWord} onChange={changeSearchWord} placeholder='검색어를 입력해주세요.' onKeyPress={enterKeyPress}/>
                            <button onClick={clickSearch}><i className={`ic-search-20`} /></button>
                        </div>
                    </div>
                </div>
                <YouthPhotoBBSComponent
                    {...props}
                    photoType={getYouthPhotoTypeValueFromKey(photoType)}
                    startIndex={params.start}
                />
                {
                    pagingData ?
                        <PaginationComponent
                            pagingData={pagingData}
                            changePage={changePage}
                            moreView={moreView}
                        />
                        : null
                }
            </div>
        </div>
    );
}

export default YouthPhotoListComponent;