import React, { useEffect, useState } from 'react';
import {
    getYouthPlayer, getYouthPlayerLeagueRecordBySeasonCode,
    getYouthPlayerSeasons,
    getYouthPlayerStatsLeagueAll
} from "../../../../../service/youth/YouthPlayer.service";
import {getFormattedDateValue} from "../../../../../helpers/dateHelper";
import {youthPositionMap} from "../../../../../helpers/commonHelper";
import FilterComponent from "../../../../commons/filter/FilterComponent";

const YouthPlayerRecordComponent = props => {
    const { playerNo } = props;
    const [defaultInfo, setDefaultInfo] = useState(null);
    const [clubInfo, setClubInfo] = useState(null);
    const [weekendInfo, setWeekendInfo] = useState(null);
    const [schoolInfo, setSchoolInfo] = useState(null);
    const [seasonList, setSeasonList] = useState(null);
    const [seasonCode, setSeasonCode] = useState(null);
    const [seasonTitle, setSeasonTitle] = useState(null);
    const [seasonInfo, setSeasonInfo] = useState(null);

    const changeParams = (type, key, value) => {
        if (type === 'filterSeason') {
            setSeasonCode(key);
            setSeasonTitle(value);
        }
    }

    useEffect(() => {
        const getDefaultInfo = async _ => {
            if(!playerNo) return;
            const [_defaultInfo, _leaguesInfo, _seasonList] = await Promise.all([getYouthPlayer(playerNo), getYouthPlayerStatsLeagueAll(playerNo), getYouthPlayerSeasons(playerNo)]);
            setDefaultInfo(_defaultInfo);
            if(_leaguesInfo) {
                setClubInfo(_leaguesInfo.clubLeague);
                setWeekendInfo(_leaguesInfo.weekendLeague);
                setSchoolInfo(_leaguesInfo.schoolLeague);
            }
            if(_seasonList && _seasonList.length > 0){
                let result = [];
                _seasonList.map(item => {
                    let obj = {};
                    obj.key = item.seasonCode;
                    obj.value = item.title;
                    obj.seasonKind = item.seasonKind;
                    result.push(obj);
                })
                setSeasonList(result);
                setSeasonCode(_seasonList[0].seasonCode);
                setSeasonTitle(_seasonList[0].title);
            }
        }
        getDefaultInfo();
    }, [playerNo]);

    useEffect(() => {
        const getLeagueInfo = async _ => {
            if(!playerNo || !seasonCode) return;
            let target = seasonList.find(item => item.key == seasonCode);
            const _seasonInfo = await getYouthPlayerLeagueRecordBySeasonCode({playerNo: playerNo, seasonKind: target ? target.seasonKind : 1, seasonCode: seasonCode})
            setSeasonInfo(_seasonInfo);
        }
        getLeagueInfo();
    }, [seasonCode]);

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>대회정보</h2>
                <p>유소년 대회의 과거부터 현재 정보를 보실 수 있습니다.</p>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>선수 정보</h4>
                </div>
                {
                    defaultInfo ?
                        <div className={`youth-view-wrap`}>
                            <div className={`img`}>
                                {/*<i className={`emblem-db`} />*/}
                                {
                                    defaultInfo.profilePath && defaultInfo.profilePath != '' ?
                                        <img src={`${defaultInfo.profilePath}`}/>
                                        : <img src={`/files/youth/profile/img_temp_player2.gif`}/>
                                }
                            </div>
                            <div className={`txt`}>
                                <ul>
                                    <li><h5>{defaultInfo.name}</h5></li>
                                    <li><h5></h5><img src={`/imgs/youth/emblem/em_KBL${defaultInfo.clubCode}.png`} style={{width: '12rem', height:'8rem'}} /></li>
                                    <li><h6>생년월일</h6><p>{getFormattedDateValue(defaultInfo.birth, 'YYYY.MM.DD')}</p></li>
                                    <li><h6>소속 클럽</h6><p>{defaultInfo.clubName}</p></li>
                                    <li><h6>신장 / 체중</h6><p>{defaultInfo.height}cm / {defaultInfo.weight}kg</p></li>
                                    <li><h6>출신 학교</h6><p>{defaultInfo.school}</p></li>
                                    <li><h6>포지션 / 배번</h6><p>{youthPositionMap[defaultInfo.position]?.value} / {defaultInfo.num}</p></li>
                                </ul>
                            </div>
                        </div>
                        : null
                }
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>주말리그(평균)</h4>
                </div>
                <div className={`scroll-table`}>
                    <table>
                        <thead>
                            <tr>
                                <th rowSpan={2}>경기</th>
                                <th rowSpan={2}>소속</th>
                                <th rowSpan={2}>경기수</th>
                                {/*<th rowSpan={2}>출전시간</th>*/}
                                <th rowSpan={2}>득점</th>
                                <th colSpan={3}>2점슛</th>
                                <th colSpan={3}>3점슛</th>
                                <th colSpan={3}>자유투</th>
                                <th colSpan={3}>리바운드</th>
                                <th rowSpan={2}>어시스트</th>
                                <th rowSpan={2}>스틸</th>
                                <th rowSpan={2}>블록슛</th>
                                <th rowSpan={2}>턴오버</th>
                            </tr>
                            <tr>
                                <th>성공</th>
                                <th>시도</th>
                                <th>성공률(%)</th>
                                <th>성공</th>
                                <th>시도</th>
                                <th>성공률(%)</th>
                                <th>성공</th>
                                <th>시도</th>
                                <th>성공률(%)</th>
                                <th>공격</th>
                                <th>수비</th>
                                <th>합계</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            weekendInfo && weekendInfo.leagues && weekendInfo.leagues.length > 0 ?
                                weekendInfo.leagues.map((item, index) =>
                                    <tr key={`weekend_league_${index}`}>
                                        <td>{item.title}</td>
                                        <td>{item.tnameMH}</td>
                                        <td>{item.cnt}</td>
                                        {/*<td>{item.min.substring(0,4).replace('.',':')}</td>*/}
                                        <td>{item.total.toFixed(1)}</td>
                                        <td>{item.s2p.toFixed(1)}</td>
                                        <td>{item.s2pa.toFixed(1)}</td>
                                        <td>{item.s2pp.toFixed(1)}</td>
                                        <td>{item.s3p.toFixed(1)}</td>
                                        <td>{item.s3pa.toFixed(1)}</td>
                                        <td>{item.s3pp.toFixed(1)}</td>
                                        <td>{item.ft.toFixed(1)}</td>
                                        <td>{item.fta.toFixed(1)}</td>
                                        <td>{item.fp.toFixed(1)}</td>
                                        <td>{item.off.toFixed(1)}</td>
                                        <td>{item.def.toFixed(1)}</td>
                                        <td>{item.rtotal.toFixed(1)}</td>
                                        <td>{item.ast.toFixed(1)}</td>
                                        <td>{item.stl.toFixed(1)}</td>
                                        <td>{item.bs.toFixed(1)}</td>
                                        <td>{item.sto.toFixed(1)}</td>
                                    </tr>
                                )
                                :
                                <tr>
                                    <td colSpan={19}>시즌 기록이 없습니다.</td>
                                </tr>
                        }
                        {
                            weekendInfo && weekendInfo.avg ?
                                <tr className={`point`}>
                                    <td colSpan={'2'}>평균</td>
                                    <td>{weekendInfo.avg.cnt}</td>
                                    {/*<td>{weekendInfo.avg.min.substring(0,4).replace('.',':')}</td>*/}
                                    <td>{weekendInfo.avg.total.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.s2p.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.s2pa.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.s2pp.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.s3p.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.s3pa.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.s3pp.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.ft.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.fta.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.fp.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.off.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.def.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.rtotal.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.ast.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.stl.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.bs.toFixed(2)}</td>
                                    <td>{weekendInfo.avg.sto.toFixed(2)}</td>
                                </tr>
                                : null
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>클럽대회(평균)</h4>
                </div>
                <div className={`scroll-table`}>
                    <table>
                        <thead>
                            <tr>
                                <th rowSpan={2}>경기</th>
                                <th rowSpan={2}>소속</th>
                                <th rowSpan={2}>경기수</th>
                                {/*<th rowSpan={2}>출전시간</th>*/}
                                <th rowSpan={2}>득점</th>
                                <th colSpan={3}>2점슛</th>
                                <th colSpan={3}>3점슛</th>
                                <th colSpan={3}>자유투</th>
                                <th colSpan={3}>리바운드</th>
                                <th rowSpan={2}>어시스트</th>
                                <th rowSpan={2}>스틸</th>
                                <th rowSpan={2}>블록슛</th>
                                <th rowSpan={2}>턴오버</th>
                            </tr>
                            <tr>
                                <th>성공</th>
                                <th>시도</th>
                                <th>성공률(%)</th>
                                <th>성공</th>
                                <th>시도</th>
                                <th>성공률(%)</th>
                                <th>성공</th>
                                <th>시도</th>
                                <th>성공률(%)</th>
                                <th>공격</th>
                                <th>수비</th>
                                <th>합계</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            clubInfo && clubInfo.leagues && clubInfo.leagues.length > 0 ?
                                clubInfo.leagues.map((item, index) =>
                                    <tr key={`club_league_${index}`}>
                                        <td>{item.title}</td>
                                        <td>{item.tnameMH}</td>
                                        <td>{item.cnt}</td>
                                        {/*<td>{item.min.substring(0,4).replace('.',':')}</td>*/}
                                        <td>{item.total.toFixed(1)}</td>
                                        <td>{item.s2p.toFixed(1)}</td>
                                        <td>{item.s2pa.toFixed(1)}</td>
                                        <td>{item.s2pp.toFixed(1)}</td>
                                        <td>{item.s3p.toFixed(1)}</td>
                                        <td>{item.s3pa.toFixed(1)}</td>
                                        <td>{item.s3pp.toFixed(1)}</td>
                                        <td>{item.ft.toFixed(1)}</td>
                                        <td>{item.fta.toFixed(1)}</td>
                                        <td>{item.fp.toFixed(1)}</td>
                                        <td>{item.off.toFixed(1)}</td>
                                        <td>{item.def.toFixed(1)}</td>
                                        <td>{item.rtotal.toFixed(1)}</td>
                                        <td>{item.ast.toFixed(1)}</td>
                                        <td>{item.stl.toFixed(1)}</td>
                                        <td>{item.bs.toFixed(1)}</td>
                                        <td>{item.sto.toFixed(1)}</td>
                                    </tr>
                                )
                                :
                                <tr>
                                    <td colSpan={19}>시즌 기록이 없습니다.</td>
                                </tr>
                        }
                        {
                            clubInfo && clubInfo.avg ?
                                <tr className={`point`}>
                                    <td colSpan={'2'}>평균</td>
                                    <td>{clubInfo.avg.cnt}</td>
                                    {/*<td>{clubInfo.avg.min.substring(0,4).replace('.',':')}</td>*/}
                                    <td>{clubInfo.avg.total.toFixed(2)}</td>
                                    <td>{clubInfo.avg.s2p.toFixed(2)}</td>
                                    <td>{clubInfo.avg.s2pa.toFixed(2)}</td>
                                    <td>{clubInfo.avg.s2pp.toFixed(2)}</td>
                                    <td>{clubInfo.avg.s3p.toFixed(2)}</td>
                                    <td>{clubInfo.avg.s3pa.toFixed(2)}</td>
                                    <td>{clubInfo.avg.s3pp.toFixed(2)}</td>
                                    <td>{clubInfo.avg.ft.toFixed(2)}</td>
                                    <td>{clubInfo.avg.fta.toFixed(2)}</td>
                                    <td>{clubInfo.avg.fp.toFixed(2)}</td>
                                    <td>{clubInfo.avg.off.toFixed(2)}</td>
                                    <td>{clubInfo.avg.def.toFixed(2)}</td>
                                    <td>{clubInfo.avg.rtotal.toFixed(2)}</td>
                                    <td>{clubInfo.avg.ast.toFixed(2)}</td>
                                    <td>{clubInfo.avg.stl.toFixed(2)}</td>
                                    <td>{clubInfo.avg.bs.toFixed(2)}</td>
                                    <td>{clubInfo.avg.sto.toFixed(2)}</td>
                                </tr>
                                : null
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            {
                schoolInfo && schoolInfo.avg &&
                <div className={`con-box`}>
                    <div className={`con-tit`}>
                        <h4>스쿨리그(평균)</h4>
                    </div>
                    <div className={`scroll-table`}>
                        <table>
                            <thead>
                            <tr>
                                <th rowSpan={2}>경기</th>
                                <th rowSpan={2}>경기수</th>
                                {/*<th rowSpan={2}>출전시간</th>*/}
                                <th rowSpan={2}>득점</th>
                                <th colSpan={3}>2점슛</th>
                                <th colSpan={3}>3점슛</th>
                                <th colSpan={3}>자유투</th>
                                <th colSpan={3}>리바운드</th>
                                <th rowSpan={2}>어시스트</th>
                                <th rowSpan={2}>스틸</th>
                                <th rowSpan={2}>블록슛</th>
                                <th rowSpan={2}>턴오버</th>
                            </tr>
                            <tr>
                                <th>성공</th>
                                <th>시도</th>
                                <th>성공률(%)</th>
                                <th>성공</th>
                                <th>시도</th>
                                <th>성공률(%)</th>
                                <th>성공</th>
                                <th>시도</th>
                                <th>성공률(%)</th>
                                <th>공격</th>
                                <th>수비</th>
                                <th>합계</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                schoolInfo && schoolInfo.leagues && schoolInfo.leagues.length > 0 ?
                                    schoolInfo.leagues.map((item, index) =>
                                        <tr key={`school_league_${index}`}>
                                            <td>{item.title}</td>
                                            <td>{item.cnt}</td>
                                            {/*<td>{item.min.substring(0,4).replace('.',':')}</td>*/}
                                            <td>{item.total.toFixed(1)}</td>
                                            <td>{item.s2p.toFixed(1)}</td>
                                            <td>{item.s2pa.toFixed(1)}</td>
                                            <td>{item.s2pp.toFixed(1)}</td>
                                            <td>{item.s3p.toFixed(1)}</td>
                                            <td>{item.s3pa.toFixed(1)}</td>
                                            <td>{item.s3pp.toFixed(1)}</td>
                                            <td>{item.ft.toFixed(1)}</td>
                                            <td>{item.fta.toFixed(1)}</td>
                                            <td>{item.fp.toFixed(1)}</td>
                                            <td>{item.off.toFixed(1)}</td>
                                            <td>{item.def.toFixed(1)}</td>
                                            <td>{item.rtotal.toFixed(1)}</td>
                                            <td>{item.ast.toFixed(1)}</td>
                                            <td>{item.stl.toFixed(1)}</td>
                                            <td>{item.bs.toFixed(1)}</td>
                                            <td>{item.sto.toFixed(1)}</td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={19}>시즌 기록이 없습니다.</td>
                                    </tr>
                            }
                            {
                                schoolInfo && schoolInfo.avg ?
                                    <tr className={`point`}>
                                        <td>평균</td>
                                        <td>{schoolInfo.avg.cnt}</td>
                                        {/*<td>{schoolInfo.avg.min.substring(0,4).replace('.',':')}</td>*/}
                                        <td>{schoolInfo.avg.total.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.s2p.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.s2pa.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.s2pp.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.s3p.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.s3pa.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.s3pp.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.ft.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.fta.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.fp.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.off.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.def.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.rtotal.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.ast.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.stl.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.bs.toFixed(2)}</td>
                                        <td>{schoolInfo.avg.sto.toFixed(2)}</td>
                                    </tr>
                                    : null
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>{seasonTitle} 기록</h4>
                    <div className={`option-wrap fit-select`}>
                        <FilterComponent
                            type={'filterSeason'}
                            selectedKey={seasonCode}
                            selectList={seasonList}
                            selectChange={changeParams}
                        />
                    </div>
                </div>
                <div className={`scroll-table`}>
                    <table>
                        <thead>
                            <tr>
                                <th>일자</th>
                                <th>상대팀</th>
                                <th>구분</th>
                                <th>득점</th>
                                <th>2점슛</th>
                                <th>3점슛</th>
                                <th>자유투</th>
                                <th>리바운드</th>
                                <th>어시스트</th>
                                <th>스틸</th>
                                <th>블록슛</th>
                                <th>턴오버</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            seasonInfo && seasonInfo.length > 0 ?
                                seasonInfo.map((item, index) =>
                                    <tr key={`league_${index}`}>
                                        <td>{item.playDate}</td>
                                        <td>{item.homeAway === '1' ? item.awayTitle : item.homeTitle}</td>
                                        <td>{item.groupTitle}</td>
                                        <td>{item.score}</td>
                                        <td>{item.s2p}/{item.s2pa}</td>
                                        <td>{item.s3p}/{item.s3pa}</td>
                                        <td>{item.ft}/{item.fta}</td>
                                        <td>{item.rtotal}</td>
                                        <td>{item.ast}</td>
                                        <td>{item.stl}</td>
                                        <td>{item.bs}</td>
                                        <td>{item.sto}</td>
                                    </tr>
                                )
                                :
                                <tr>
                                    <td colSpan={12}>시즌 기록이 없습니다.</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {/* pagination 추가 */}
            </div>
        </div>
    )
}

export default YouthPlayerRecordComponent;
