import React from 'react';
import { useSelector } from 'react-redux';

const YouthInquiryComponent = props => {
    const { title, content, changeValues, clickSubmit, clickCancel, setCategory } = props;
    const { isApp } = useSelector(state => state.commonLayout);


    const categoryChanged = e => {
        const key = e.target.value;
        setCategory(key);
    }

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>문의‧제안</h2>
                <p>KBL 유소년 관련 문의 및 제안 사항을 남길 수 있습니다.</p>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4></h4>
                    <p>문의 및 제안 게시판에 접수된 내용은 필요에 따라 답변을 드립니다.</p>
                </div>
                <form>
                    <div className={`inquiry-wrap`}>
                        <div className={`inquiry-box`}>
                            <h6>제목</h6>
                            <input className='cs' type='text' placeholder="제목을 입력해주세요." value={title || ''} onChange={changeValues} data-type='title' />
                        </div>
                        <div className={`inquiry-box`}>
                            <h6>구분</h6>
                            <select onChange={categoryChanged}>
                                <option data-key={'경기'}>경기</option>
                                <option data-key={'클럽'}>클럽</option>
                                {/*<option data-key={'티켓'}>티켓</option>*/}
                                {/*<option data-key={'MD'}>MD</option>*/}
                                {/*<option data-key={'포인트 및 쿠폰'}>포인트 및 쿠폰</option>*/}
                                <option data-key={'웹/앱'}>웹/앱</option>
                                {/*<option data-key={'스폰서/제휴'}>스폰서/제휴</option>*/}
                                <option data-key={'기타'}>기타</option>
                            </select>
                        </div>
                        <div className={`inquiry-box`}>
                            <h6>내용</h6>
                            <textarea className='cs' placeholder="내용을 입력해주세요." value={content || ''} onChange={changeValues} data-type='content'></textarea>
                        </div>
                    </div>
                    <div className={`btn-wrap`}>
                        {
                            !isApp ?
                                <button className={`light-pb`} onClick={clickCancel}>취소</button>
                                : null
                        }
                        <button className={`pb`} onClick={clickSubmit}>문의‧제안하기</button>
                    </div>
                </form>
            </div>
        </div>
    );
}



export default YouthInquiryComponent;