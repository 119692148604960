import React from 'react';

const YouthEliteCampComponent = props => {
    const { isMobile } = props;

    return (
        /*<div className={`con-box`}>
            <div className={`youth-box`}>
                <div className={`youth-tit`}>
                    <h4>소개</h4>
                </div>
                <p>중, 고등부 우수 기량 선수를 선발하여 정상급 코치진과 다양한 트레이닝을 통해 선수의 실력 향상 및 동기 부여를 제공하는 국내 최고 수준의 농구 캠프</p>
            </div>
            <div className={`youth-box`}>
                <div className='player'>
                    <div className='video-container'>
                        <iframe src={`https://www.youtube.com/embed/Ii4jOAwoQGQ`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '675' : '200'}`} style={{borderRadius: '12px' }} />
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/elite-camp1.jpg`} style={{width: '-webkit-fill-available'}}/>
                    <img src={`https://youth.kbl.ddfactory.kr/files/youth/intro/elite-camp2.jpg`} style={{width: '-webkit-fill-available'}}/>
                </div>
            </div>
        </div>*/

        <div className={`youth-box`}>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    {/*<h4>영상</h4>*/}
                </div>
                <ul className={`youth-game-highlight-photo`}>
                    <li>
                        <div className='player'>
                            <div className='video-container'>
                                <iframe src={`https://www.youtube.com/embed/Ii4jOAwoQGQ`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '330' : '220'}`} style={{borderRadius: '12px' }} />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='player'>
                            <div className='video-container'>
                                <iframe src={`https://www.youtube.com/embed/Q3GyRFk58aU`} allowFullScreen allow="encrypted-media" width='100%' height={`${!isMobile ? '330' : '220'}`} style={{borderRadius: '12px' }} />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>소개</h4>
                </div>
                <p>중, 고등부 우수 기량 선수를 선발하여 정상급 코치진과 다양한 트레이닝을 통해 선수의 실력 향상 및 동기 부여를 제공하는 국내 최고 수준의 농구 캠프</p>
                <ul className={`youth-game-photo`}>
                    <li style={{backgroundImage: `url(/files/youth/intro/elite-camp1.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/elite-camp2.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/elite-camp3.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/elite-camp4.jpg)`}}></li>
                </ul>
            </div>
        </div>
    )
}

export default YouthEliteCampComponent;