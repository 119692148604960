const menuLink = [
  {
    teamClass: 'youth',
    list: [
      {
        title: 'KBL YOUTH',
        path: '/youth',
        subList: [
          {
            title: 'KBL YOUTH 소개',
            path: '/youth/kblyouth',
          }
        ]
      },
      {
        title: '유소년 사업',
        path: '/youth/business',
        subList: [
          {
            title: '연고선수 제도',
            path: '/youth/business?type=nominated-players',
          },
          {
            title: '장신 농구선수 발굴사업',
            path: '/youth/business?type=player-discovery',
          },
          {
            title: '클럽대회 / 주말리그',
            path: '/youth/business?type=game-management',
          },
          {
            title: '유소년 신체 측정사업',
            path: '/youth/business?type=measurement',
          },
          {
            title: '유소년 캠프',
            path: '/youth/business?type=youth-elite-camp',
          },
          {
            title: '유망선수 해외연수 프로젝트',
            path: '/youth/business?type=youth-overseas-program',
          },
          {
            title: '찾아가는 농구 클래스',
            path: '/youth/business?type=basketball-class',
          },
        ]
      },
      {
        title: '클럽 소개',
        path: '/youth/club?type=branch',
        subList: [
          {
            title: '지점 찾기',
            path: '/youth/club?type=branch',
          },
          {
            title: '구단 클럽 운영 현황',
            path: '/youth/club?type=operation',
          }
        ]
      },
      {
        title: '유소년 대회',
        path: '/youth/game',
        subList: [
          {
            title: '클럽대회',
            path: '/youth/game/club?type=schedule',
          },
          {
            title: '주말리그',
            path: '/youth/game/weekend',
          }
        ]
      },
      {
        title: '측정데이터',
        path: '/youth/measurement',
        subList: [
          {
            title: '항목 소개',
            path: '/youth/measurement/intro',
          },
          {
            title: '평균 기록',
            path: '/youth/measurement/record',
          },
          {
            title: '퍼센타일 확인',
            path: '/youth/measurement/percent',
          },
          {
            title: '내 기록 확인',
            path: '/youth/measurement/member',
          },
          {
            title: '내 기록 확인 관리',
            path: '/youth/measurement/member/manage',
            admin: true,
          },
        ]
      },
      {
        title: '커뮤니티',
        path: '/youth/community',
        subList: [
          {
            title: '공지사항',
            path: '/youth/community/notice',
          },
          {
            title: '1:1문의',
            path: '/youth/community/inquiry',
          },
          {
            title: '유소년 뉴스',
            path: '/youth/community/news',
          },
        ]
      },
      {
        title: '자료실',
        path: '/youth/contents',
        subList: [
          {
            title: '경기 다시보기',
            path: '/youth/contents/video',
          },
          {
            title: '교육자료',
            path: '/youth/contents/edu',
          },
          {
            title: '사진자료',
            path: '/youth/contents/photo'
          }
        ]
      },
      {
        title: '유소년등록',
        path: '/youth/member/regist',
        subList: [
          {
            title: '유소년 등록하기',
            path: '/youth/member/regist',
          }
        ]
      },
      {
        title: '',
        path: '/',
        subList: [
          {
            title: '',
            path: '/',
            hide: true,
          },
        ]
      },
    ]
  },
]

export const getMenuByTeamClass = (teamClass) => {
  const menulistInfo = menuLink.find(item => item.teamClass === teamClass);
  return menulistInfo ? menulistInfo.list : null;
}