import React, { useState, useEffect } from 'react';
import YouthEduListComponent from './YouthEduListComponent'

import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { getQueryStringFromObject } from '../../../../../helpers/apiHelper';
import {isEmptyObject} from "../../../../../helpers/objectHelper";
import {setIsMore, setYouthParams} from "../../../../../store/youth/content";

const YouthEduListContainer = props => {
  const { history, location } = props;
  const { list, params, pagingData, pageSize } = useSelector(state => state.youthContent);
  const [searchType, setSearchType] = useState(null);
  const [searchWord, setSearchWord] = useState('');
  const dispatch = useDispatch();

  const searchParams = {
    boardCode: 'EDU',
    boardCatSq: '',
    start: 0,
    limit: pageSize,
    searchType: 'title',
    searchWord: ''
  }

  const setDefaultParams = () => {
    const _queryString = queryString.parse(location.search);
    if (!isEmptyObject(_queryString)) {
      dispatch(setYouthParams(_queryString));
      setSearchType(_queryString.searchType);
      setSearchWord(_queryString.searchWord);
    } else {
      dispatch(setYouthParams(searchParams));
      setSearchType('title');
      setSearchWord('');
    }
  }

  const changeSearchWord = e => {
    setSearchWord(e.currentTarget.value);
  }

  const changeSearchType = (type, key, value) => {
    setSearchType(key);
  }

  const changeParams = (type, key, value) => {
    if (type === 'changePage') {
      if (value === pagingData.currentPage) {
        return
      }
      dispatch(setIsMore(false));
      params.start = (value - 1) * pageSize
      params.limit = pageSize;
    } else if (type === 'moreView') {
      if (value === pagingData.currentPage) {
        return
      }
      dispatch(setIsMore(true));
      params.start = (value - 1) * pageSize;
      params.limit = pageSize;
    } else if (type === 'clickSearch') {
      params.searchWord = searchWord;
      params.searchType = searchType;
      params.start = 0;
      params.limit = pageSize;
    }
    history.push(`${history.location.pathname}${getQueryStringFromObject(params)}`)

    dispatch(setYouthParams(params));
  }

  useEffect(_ => {
    console.log('NoticeListContainer::componentDidMount');
    setDefaultParams();
    return function cleanup() {
      console.log('NoticeListContainer::componentWillUnmount');
      // window.scrollTo(0, 0);
    }
  }, [location])

  return (
    list.data ?
      <YouthEduListComponent
        history={history}
        searchType={searchType}
        searchWord={searchWord}
        changeParams={changeParams}
        list={list.data}
        pagingData={pagingData}
        changeSearchWord={changeSearchWord}
        changeSearchType={changeSearchType}
        params={params}
      />
      : null
  );
}

export default YouthEduListContainer;