import * as ApiService from '../Api.service'

export const getYouthSeasonList = async (params = {}) => {
    console.log('Common.service:getYouthSeasonList');
    const url = '/web/youth/seasons';
    return ApiService.get(url, params);
}

export const getTeamList = async (leagueKey, lang) => {
    console.log('Common.service:getTeamList');
    const url = '/teams';

    return ApiService.get(url, {leagueKey: leagueKey, lang: lang});
}

export const getInfo = async (id) => {
    console.log('Series.service:getList');

//    return ApiService.get(url);
}

export const update = async (id, parmas) => {
    console.log('Series.service:getList');
  //  const url = `${baseUrl}/${id}`;

    //return ApiService.put(url, parmas);
}

export const regist = async (id) => {
    console.log('Series.service:regist');

    //return ApiService.post(`${baseUrl}`, {id: Number(id)});
}
