import React, { useEffect } from 'react';
import FilterComponent from "../../../../commons/filter/FilterComponent";
import YouthTournamentBracketComponent from "./YouthTournamentBracketComponent";
import YouthScheduleListComponent from "./YouthScheduleListComponent";

const YouthGameScheduleComponent = props => {
    const { changeParams, scheduleTypeList, scheduleType, game, schoolList, school, groupList, group, teamList, team } = props;

    useEffect(() => {
    }, [game]);

    return (
        <div className={`tab-con active`}>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4></h4>
                    <div className={`option-wrap`}>
                        {
                            !game ?
                                <>
                                    <FilterComponent
                                        type={'changeScheduleType'}
                                        selectedKey={scheduleType}
                                        optionClass={'team pc'}
                                        selectList={scheduleTypeList}
                                        selectChange={changeParams}
                                    />
                                    {
                                        scheduleType !== 'tournamentBracket' &&
                                        <FilterComponent
                                            type={'changeSchool'}
                                            selectedKey={school}
                                            selectList={schoolList}
                                            selectChange={changeParams}
                                        />
                                    }
                                    {
                                        scheduleType === 'league' &&
                                        <>
                                            <FilterComponent
                                                type={'changeGroup'}
                                                selectedKey={group}
                                                selectList={groupList}
                                                selectChange={changeParams}
                                            />
                                            <FilterComponent
                                                type={'changeTeam'}
                                                selectedKey={team}
                                                selectList={teamList}
                                                selectChange={changeParams}
                                            />
                                        </>
                                    }
                                </>
                                : null
                        }
                    </div>
                </div>
                {/* 토너먼트 대진표 / 예선 리그, 결승 리그(토너먼트) */}
                {
                    scheduleType === 'tournamentBracket'
                        ? <YouthTournamentBracketComponent {...props} />
                        : <YouthScheduleListComponent {...props} />
                }
            </div>
        </div>
    )
}

export default YouthGameScheduleComponent;