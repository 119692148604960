import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../../style/reset.css'
import '../../../style/font.css'
import '../../../style/ico.css'
import '../../../style/common.css'
import '../../../style/style.css'
import '../../../style/youth.css'
import '../../../style/club.css'
import '../../../style/app.css'

const RouteWithLayout = props => {
    const { layout: Layout, component: Component, ...rest } = props;

    return (
        <Route
            {...rest}
            render={matchProps => (
                Layout ?
                <Layout>
                    <Component {...matchProps} />
                </Layout>
                : 
                <Component {...matchProps} />
            )}
        />
    );
};

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    path: PropTypes.string
};

export default RouteWithLayout;
