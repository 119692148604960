import * as MainService from '../../service/main/Main.service';
import {
  reducerUtils,
} from '../../helpers/asyncHelper';
import {
  createPromiseThunk,
  handleAsyncActions,
} from '../../helpers/asyncHelper';

const GET_YOUTH_MAIN_ARTICLES_INFO = 'main/GET_YOUTH_MAIN_ARTICLES_INFO';
const GET_YOUTH_MAIN_ARTICLES_INFO_SUCCESS = 'main/GET_YOUTH_MAIN_ARTICLES_INFO_SUCCESS';
const GET_YOUTH_MAIN_ARTICLES_INFO_ERROR = 'main/GET_YOUTH_MAIN_ARTICLES_INFO_ERROR';

const GET_YOUTH_BANNER_LIST = 'main/GET_YOUTH_BANNER_LIST';
const GET_YOUTH_BANNER_LIST_SUCCESS = 'main/GET_YOUTH_BANNER_LIST_SUCCESS';
const GET_YOUTH_BANNER_LIST_ERROR = 'main/GET_YOUTH_BANNER_LIST_ERROR';

export const getYouthMainArticles = createPromiseThunk(GET_YOUTH_MAIN_ARTICLES_INFO, MainService.getYouthMainArticles);
export const getYouthBannerList = createPromiseThunk(GET_YOUTH_BANNER_LIST, MainService.getYouthBannerList);

const initialState = {
  topPlayer: reducerUtils.initial(),
  events: reducerUtils.initial(),
  articles: reducerUtils.initial(),
  sponsors: reducerUtils.initial(),
  banners: reducerUtils.initial(),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_YOUTH_MAIN_ARTICLES_INFO:
    case GET_YOUTH_MAIN_ARTICLES_INFO_SUCCESS:
    case GET_YOUTH_MAIN_ARTICLES_INFO_ERROR:
      return handleAsyncActions(GET_YOUTH_MAIN_ARTICLES_INFO, 'articles')(state, action);
    case GET_YOUTH_BANNER_LIST:
    case GET_YOUTH_BANNER_LIST_SUCCESS:
    case GET_YOUTH_BANNER_LIST_ERROR:
      return handleAsyncActions(GET_YOUTH_BANNER_LIST, 'banners')(state, action);
    default:
      return state;
  }
}