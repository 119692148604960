import React from 'react';

const EmptyPreparingComponent = props => {
    const { content } = props;

    return(
        <div className={`con-empty`}>
            <i className={`img-empty01`} />
            <h6>준비 중입니다.</h6>
            <p>{content ? content : '보다나은 서비스 제공을 위하여 페이지 준비 중에 있습니다.'}</p>
        </div>
    )
}

export default EmptyPreparingComponent;