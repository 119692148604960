import React from 'react';
import PropTypes from 'prop-types';

const AlertComponent = props => {
    const { isDisplay, content, clickCloseButton, isTop } = props;

    return (
        <div className={`popup ${isTop ? 'popup-top' : ''}`} style={{ display: isDisplay ? `block` : `none` }}>
            <div className={`pop-wrap`}>
                <div className={`pop-con`}>
                    {
                        content
                            ? content.split('\n').map((line, idx) => {
                                return (<p key={`terms-${idx}`}>{line}</p>)
                            })
                            : <React.Fragment>오류가 발생했습니다. </React.Fragment>
                    }
                </div>
                <div className={`pop-btn`}>
                    <button className={`pb`} onClick={clickCloseButton}>확인</button>
                </div>
            </div>
        </div>
  );
}


AlertComponent.propTypes = {
  isDisplay: PropTypes.bool.isRequired,
  //title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  clickCloseButton: PropTypes.func.isRequired,
};

export default AlertComponent;