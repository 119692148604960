import React from 'react';
import YouthBusinessComponent from './YouthBusinessComponent'


const YouthBusinessContainer = props => {

    return (
        <YouthBusinessComponent
            {...props}
        />
    )
}

export default YouthBusinessContainer;