export const bbsSearchTypeSelectList = [
    {
        key: 'title',
        value: '제목',
    },
    {
        key: 'content',
        value: '내용',
    }
]

export const mediaPhotoTypeSelectList = [
    {
        key: 'all',
        value: '전체',
    },
    {
        key: '5',
        value: '경기장면',
    },
    {
        key: '6',
        value: '응원장면',
    },
    {
        key: '7',
        value: '이모저모',
    },
]

export const getPhotoTypeValueFromKey = key => {
    const obj = mediaPhotoTypeSelectList.find(item => {
      return item.key === key;
    });

    return obj ? obj.value : '';
  }

export const youthMediaTypeSelectList = [
    {
        key: '1',
        value: '경기',
    },
    {
        key: '2',
        value: '기타',
    },
]

export const getYouthPhotoTypeValueFromKey = key => {
    const obj = youthMediaTypeSelectList.find(item => {
      return item.key === key;
    });

    return obj ? obj.value : '';
}

/*  export const regular =
    {
        key: 'regular',
        value: '정규리그',
        valueEn: 'Regular league'
    }*/


/*export const playoff =
    {
        key: 'playoff',
        value: '플레이오프',
        valueEn: 'Playoff'
    }*/

