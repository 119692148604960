import React from 'react';

const BasketballClassComponent = props => {
    //const { isMobile } = props;

    return (
        <div className={`youth-box`}>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>소개</h4>
                </div>
                <p>유소년, 소외계층 등 농구를 좋아하고 배우고 싶은 아이들을 위해 KBL 현역 및 은퇴선수들이 직접 찾아가서 교육하는 농구 클래스</p>
                <ul className={`youth-game-photo`}>
                    <li style={{backgroundImage: `url(/files/youth/intro/basketball-class1.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/basketball-class2.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/basketball-class3.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/basketball-class4.jpg)`}}></li>
                    <li style={{backgroundImage: `url(/files/youth/intro/basketball-class5.jpg)`}}></li>
                </ul>
            </div>
        </div>
    )
}

export default BasketballClassComponent;