import React, { useEffect, useState } from 'react';
import UsageTermsComponent from './PrivacyTermsComponent';
import { getTermsList } from '../../../../service/member/Member.service';

const UsageTermsContainer = props => {
  const [content, setContent] = useState('');

  const getContentInfo = async _ => {
    const result = await getTermsList({ termsKind: '' });

    if (result) {
      const privacyTerms = result.find(item => item.termsType === '8');

      setContent(privacyTerms ? privacyTerms.contents : '');
    }
  }

  useEffect(_ => {
    getContentInfo();

    return function cleanup() {
      window.scrollTo(0, 0);
    }

  }, []);

  return (
    <UsageTermsComponent
      {...props}
      content={content}
    />
  );
}

export default UsageTermsContainer;