import * as MediaService from '../../service/media/Media.service';
import {getYouthMeasurementPersonalRecords} from '../../service/youth/Measurement.service';
import {
  createPromiseThunk,
  reducerUtils,
  handleAsyncActions,
  createPromiseThunkById,
  handleAsyncActionsById
} from '../../helpers/asyncHelper';
import { getPagingData } from '../../helpers/apiHelper';

// /* 액션 타입 */
const SET_IS_MORE = 'youth_content/SET_IS_MORE';

const SET_PARAMS = 'youth_content/SET_PARAMS';
const SET_PAGING_DATA = 'youth_content/SET_PAGING_DATA';
const GET_LIST = 'youth_content/GET_LIST';
const GET_LIST_SUCCESS = 'youth_content/GET_LIST_SUCCESS'; // 요청 성공
const GET_LIST_ERROR = 'youth_content/GET_LIST_ERROR'; // 요청 실패

const SET_PHOTO_PARAMS = 'youth_content/SET_PHOTO_PARAMS';
const SET_PHOTO_PAGING_DATA = 'youth_content/SET_PHOTO_PAGING_DATA';
const GET_PHOTO_LIST = 'youth_content/GET_PHOTO_LIST';
const GET_PHOTO_LIST_SUCCESS = 'youth_content/GET_PHOTO_LIST_SUCCESS'; // 요청 성공
const GET_PHOTO_LIST_ERROR = 'youth_content/GET_PHOTO_LIST_ERROR'; // 요청 실패

const SET_VIDEO_PARAMS = 'youth_content/SET_VIDEO_PARAMS';
const SET_VIDEO_PAGING_DATA = 'youth_content/SET_VIDEO_PAGING_DATA';
const GET_VIDEO_LIST = 'youth_content/GET_VIDEO_LIST';
const GET_VIDEO_LIST_SUCCESS = 'youth_content/GET_VIDEO_LIST_SUCCESS'; // 요청 성공
const GET_VIDEO_LIST_ERROR = 'youth_content/GET_VIDEO_LIST_ERROR'; // 요청 실패

const GET_INFO = 'youth_content/GET_INFO';
const GET_INFO_SUCCESS = 'youth_content/GET_INFO_SUCCESS';
const GET_INFO_ERROR = 'youth_content/GET_INFO_ERROR';

const SET_TOP_VIDEO_ID = 'youth_content/SET_TOP_VIDEO_ID';
const SET_TOP_VIDEO_EMPTY = 'youth_content/SET_TOP_VIDEO_EMPTY';

const SET_MEASURE_PARAMS = 'youth_content/SET_MEASURE_PARAMS';
const SET_MEASURE_PAGING_DATA = 'youth_content/SET_MEASURE_PAGING_DATA';
const GET_MEASURE_LIST = 'youth_content/GET_MEASURE_LIST';
const GET_MEASURE_LIST_SUCCESS = 'youth_content/GET_MEASURE_LIST_SUCCESS';
const GET_MEASURE_LIST_ERROR = 'youth_content/GET_MEASURE_LIST_ERROR';

export const getInfo = createPromiseThunkById(GET_INFO, MediaService.getYouthInfo);
export const getList = createPromiseThunk(GET_LIST, MediaService.getYouthList, SET_PAGING_DATA);
export const getPhotoList = createPromiseThunk(GET_PHOTO_LIST, MediaService.getYouthList, SET_PHOTO_PAGING_DATA);
export const getVideoList = createPromiseThunk(GET_VIDEO_LIST, MediaService.getYouthList, SET_VIDEO_PAGING_DATA);
export const getMeasureList = createPromiseThunk(GET_MEASURE_LIST, getYouthMeasurementPersonalRecords, SET_MEASURE_PAGING_DATA, 'records');

export const setYouthParams = params => {
  return dispatch => {
    dispatch({ type: SET_PARAMS, params });
    dispatch(getList(params))
  }
}

export const setYouthPhotoParams = params => {
  return dispatch => {
    dispatch({ type: SET_PHOTO_PARAMS, params });
    dispatch(getPhotoList(params))
  }
}

export const setYouthVideoParams = params => {
  return dispatch => {
    dispatch({ type: SET_VIDEO_PARAMS, params });
    dispatch(getVideoList(params))
  }
}

export const setTopVideoId = topVideoId => {
  return dispatch => {
    dispatch({ type: SET_TOP_VIDEO_ID, topVideoId });
    dispatch(getInfo(topVideoId))
  }
}

export const setYouthMeasureParams = params => {
  return dispatch => {
    dispatch({ type: SET_MEASURE_PARAMS, params });
    dispatch(getMeasureList(params))
  }
}

export const setIsMore = value => ({ type: SET_IS_MORE, isMore: value });
export const setTopVideoEmpty = _ => ({ type: SET_TOP_VIDEO_EMPTY });

const initialState = {
  params: {},
  photoParams: {},
  videoParams: {},
  measureParams: {},
  list: reducerUtils.initial(),
  photoList: reducerUtils.initial(),
  videoList: reducerUtils.initial(),
  measureList: reducerUtils.initial(),
  pagingData: {},
  photoPagingData: {},
  videoPagingData: {},
  measurePagingData: {},
  pageSize: 10,
  photoPageSize: 12,
  videoPageSize: 15,
  measurePageSize: 15,
  isMore: false,
  info: {},
  topVideoId: null,
  topVideo: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PHOTO_PARAMS:
      return {
        ...state,
        photoParams: action.params
      };
    case GET_PHOTO_LIST:
    case GET_PHOTO_LIST_SUCCESS:
    case GET_PHOTO_LIST_ERROR:
      return handleAsyncActions(GET_PHOTO_LIST, 'photoList')(state, action);
    case SET_PHOTO_PAGING_DATA:
      return {
        ...state,
        photoPagingData: getPagingData(state.photoParams.start, action.pageInfo, state.photoPageSize)
      }
    case SET_PARAMS:
      return {
        ...state,
        params: action.params
      };
    case GET_LIST:
    case GET_LIST_SUCCESS:
    case GET_LIST_ERROR:
      return handleAsyncActions(GET_LIST, 'list')(state, action);
    case SET_PAGING_DATA:
      return {
        ...state,
        pagingData: getPagingData(state.params.start, action.pageInfo, state.pageSize)
      }
    case SET_IS_MORE:
      return {
        ...state,
        isMore: action.isMore
      }
    case GET_INFO:
    case GET_INFO_SUCCESS:
      return {
        ...state,
        topVideo: action.payload ? action.payload : {}
      }
    case GET_INFO_ERROR:
      return handleAsyncActionsById(GET_INFO, 'info', true)(state, action);
    case SET_TOP_VIDEO_EMPTY:
      return {
        ...state,
        topVideoId: null,
        topVideo: {},
      };
    case SET_TOP_VIDEO_ID:
      return {
        ...state,
        topVideoId: action.topVideoId
      };
    case SET_VIDEO_PARAMS:
      return {
        ...state,
        videoParams: action.params
      };
    case GET_VIDEO_LIST:
    case GET_VIDEO_LIST_SUCCESS:
    case GET_VIDEO_LIST_ERROR:
      return handleAsyncActions(GET_VIDEO_LIST, 'videoList', "topVideo",state.topVideoId === null)(state, action);
    case SET_VIDEO_PAGING_DATA:
      return {
        ...state,
        videoPagingData: getPagingData(state.videoParams.start, action.pageInfo, state.videoPageSize)
      };
    case SET_MEASURE_PARAMS:
      return {
        ...state,
        measureParams: action.params
      };
    case GET_MEASURE_LIST:
    case GET_MEASURE_LIST_SUCCESS:
    case GET_MEASURE_LIST_ERROR:
      return handleAsyncActions(GET_MEASURE_LIST, 'measureList')(state, action);
    case SET_MEASURE_PAGING_DATA:
      return {
        ...state,
        measurePagingData: getPagingData(state.measureParams.start, action.pageInfo, state.measurePageSize)
      };
    default:
      return state;
  }
}