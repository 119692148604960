import cookie from 'react-cookies';

export const setCookie = (key, value, expires) => {
    if (!expires) {
        expires = new Date()
        expires.setDate(expires.getDate() + 1);
    }
    const options = {}
    options.expires = expires;
    options.path = '/';

    if (process.env.REACT_APP_ENV !== 'local') {
        options.domain = `.${process.env.REACT_APP_COOKIE_DOMAIN}`;
    }

    cookie.save(key, value, options);
}

export const getCookie = key => {
    return cookie.load(key)
}

export const removeCookie = key => {
    const options = {}
    options.path = '/';
    if (process.env.REACT_APP_ENV !== 'local') {
        options.domain = `.${process.env.REACT_APP_COOKIE_DOMAIN}`;
    }
    return cookie.remove(key, options)
}