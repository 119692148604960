import React, { useEffect, useState } from 'react';
import queryString from "query-string";
import PlayerDiscoveryComponent from "./PlayerDiscoveryComponent";
import MeasurementComponent from "./MeasurementComponent";
import YouthEliteCampComponent from "./YouthEliteCampComponent";
import YouthOverseasProgramComponent from "./YouthOverseasProgramComponent";
import BasketballClassComponent from "./BasketballClassComponent";
import NominatedPlayersComponent from "./NominatedPlayersComponent";
import GameManagementComponent from "./GameManagementComponent";
import FilterComponent from "../../../commons/filter/FilterComponent";

const YouthBusinessComponent = props => {
    const { history, location } = props;
    const [selectedTab, setSelectedTab] = useState('nominated-players');
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        resizeCallback();

        return () => {
            window.removeEventListener("resize", resizeCallback);
        }
    }, [])

    const resizeCallback = () => {
        setIsMobile(window.innerWidth <= 768);
    }

    const tabSelectList = [
        {
            key: 'nominated-players',
            value: '연고선수 제도'
        },
        {
            key: 'player-discovery',
            value: '장신 농구선수 발굴사업'
        },
        {
            key: 'game-management',
            value: '클럽대회 / 주말리그'
        },
        {
            key: 'measurement',
            value: '유소년 신체 측정사업'
        },
        {
            key: 'youth-elite-camp',
            value: '유소년 캠프'
        },
        {
            key: 'youth-overseas-program',
            value: '유망선수 해외연수 프로젝트'
        },
        {
            key: 'basketball-class',
            value: '찾아가는 농구 클래스'
        },
    ];

    const changeParams = (key, value) => {
        // setSelectedTab(key);
        history.push(`/youth/business?type=${key}`);
    }

    useEffect(() => {
        if(window.location.search) {
            setSelectedTab(queryString.parse(window.location.search).type);
        }
    }, [window.location.search]);

    useEffect(_ => {
        return function cleanup() {
            window.scrollTo(0, 0);
        }
    }, [])

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>유소년 사업</h2>
                <p>발굴, 측정, 캠프 등 다양한 유소년 사업에 관한 페이지입니다.</p>
            </div>
            <ul className={`filter-wrap`}>
                <li>
                    <h6>구분</h6>
                    <FilterComponent
                        selectChange={changeParams}
                        selectList={tabSelectList}
                        selectedKey={selectedTab}
                    />
                </li>
            </ul>
            <div className={`con-box`}>
                {
                    selectedTab === 'player-discovery' ?
                    <PlayerDiscoveryComponent isMobile={isMobile} /> :
                        selectedTab === 'measurement' ?
                        <MeasurementComponent isMobile={isMobile} /> :
                            selectedTab === 'youth-elite-camp' ?
                            <YouthEliteCampComponent isMobile={isMobile} /> :
                                selectedTab === 'youth-overseas-program' ?
                                <YouthOverseasProgramComponent isMobile={isMobile} /> :
                                    selectedTab === 'basketball-class' ?
                                    <BasketballClassComponent isMobile={isMobile} /> :
                                        selectedTab === 'nominated-players' ?
                                        <NominatedPlayersComponent isMobile={isMobile} /> :
                                            selectedTab === 'game-management' ?
                                            <GameManagementComponent isMobile={isMobile} />
                                            : null
                }
            </div>
        </div>
    )
}

export default YouthBusinessComponent;