import React from 'react';

const OperationMethodComponent = props => {

    return (
        <>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>주요규칙</h4>
                </div>
                <ul className={`story-operation-method-wrap`}>
                    <li>
                        <div className={`num`}>1</div>
                        <div className={`txt`}>
                            <p>삼심제(Crew of three officials)적용</p>
                            <span>주심(Referee), 제1부심(1st Umpire), 제2부심(2nd Umpire)</span>
                        </div>
                    </li>
                    <li>
                        <div className={`num`}>2</div>
                        <div className={`txt`}>
                            <p>한 경기는 10분씩 4쿼터로 진행</p>
                        </div>
                    </li>
                    <li>
                        <div className={`num`}>3</div>
                        <div className={`txt`}>
                            <p>팀파울은 쿼터 당 4개까지 허용, 5개째부터 프리드로(Free Throws)</p>
                        </div>
                    </li>
                    <li>
                        <div className={`num`}>4</div>
                        <div className={`txt`}>
                            <p>선수의 고유번호 1번에서 99번까지의 모든 번호와 "0"번 그리고 "00"번을 사용할 수 있다.</p>
                        </div>
                    </li>
                    <li>
                        <div className={`num`}>5</div>
                        <div className={`txt`}>
                            <p>각 팀은 KBL에 등록된 17명 이상의 선수로 구성(국내선수 15명 이상, 외국선수 2명)</p>
                            <span>12명의 선수만 출전 선수로 등록 가능</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>리그운영방식</h4>
                </div>
                <ul className={`story-operation-method-wrap`}>
                    <li>
                        <div className={`num`}>1</div>
                        <div className={`txt`}>
                            <p>정규경기</p>
                            <span>10개 팀이 6회의 Round-Robin 방식으로 진행 (Round-Robin 방식 : 참가팀들의 모두 한번씩 대결하는 방식)<br/>각 팀 총 경기수 : 54경기(홈경기 27, 어웨이경기 27)</span>
                        </div>
                    </li>
                    <li>
                        <div className={`num`}>2</div>
                        <div className={`txt`}>
                            <p>올스타전 : 매년 1월 또는 2월 개최</p>
                            <span>팬 투표 및 감독 추천으로 선발</span>
                        </div>
                    </li>
                    <li>
                        <div className={`num`}>3</div>
                        <div className={`txt`}>
                            <p>플레이오프</p>
                            <span>정규경기 성적 상위 6개 팀이 실시, 1회전(5전 3선 승제) / 2회전(5전 3선 승제) / 챔피언결정전(7전 4선 승제)</span>
                        </div>
                    </li>
                    <li>
                        <div className={`num`}>4</div>
                        <div className={`txt`}>
                            <p>플레이오프 및 챔피언 결정전 진행방식</p>
                            <span>경기일정은 상황에 따라 변동 가능</span>
                        </div>
                    </li>
                    <li className={`img`}>
                        <img src={`/imgs/img-oper-method01.png`} className={`desktop-only`} />
                        <img src={`/imgs/img-oper-method01-mb.png`} className={`mobile-only`} />
                    </li>
                </ul>
            </div>
        </>
    );
}

export default OperationMethodComponent;