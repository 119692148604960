import React, { useEffect } from 'react'
import YouthPlayerRecordComponent from "./YouthPlayerRecordComponent";

const YouthPlayerRecordContainer = props => {
    const { match } = props;
    const playerNo = match.params.id;

    useEffect(_ => {
        return function cleanup() {
            window.scrollTo(0, 0);
        }
    }, [match])

    return (
        <YouthPlayerRecordComponent
            {...props}
            playerNo={playerNo}
        />
    )
}

export default YouthPlayerRecordContainer;;