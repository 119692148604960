import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {getCookie, setCookie} from "../../../helpers/cookieHelper";

const FooterComponent = props => {
    const { youtubueInfo, facebookInfo, instagramInfo, naverPostInfo, naverTvInfo } = props;
    //const { teamClass } = useSelector(state => state.commonLayout);
    const [theme, setTheme] = useState('light');

    const clickThemeBtn = () => {
        if (theme == 'light') {
            setCookie('color-theme', 'dark');
            document.documentElement.setAttribute('data-theme', "dark");
            setTheme('dark');
        } else {
            setCookie('color-theme', 'light');
            document.documentElement.setAttribute('data-theme', "light");
            setTheme('light');
        }
    }

    useEffect(_ => {
        const isUserColorTheme = getCookie('color-theme');
        const isOsColorTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

        let status =  isUserColorTheme && isUserColorTheme === isOsColorTheme ? isUserColorTheme : isOsColorTheme;
        setTheme(status)
    }, [])

    useEffect(_ => {
        return function cleanup() {
        }
    }, [])

    const openWindow = (e) => {
        const value = e.target.value;
        if(value != ''){
            window.open(value, '_blank');
        }
    }

    return (
        <footer>
            <div className={`view-mode-change desktop-only`} onClick={clickThemeBtn}>
                {theme && <><i className={`${theme === 'dark' ? 'ic-light-16' : 'ic-dark-16'}`} /><p>{theme === 'dark' ? '라이트 ' : '다크 '}모드로 보기</p></>}
            </div>
            <div className={`footer-wrap`}>
                <div className={`logo`}><i className={'logo-kbl-youth-gray'}/></div>
                <div className={`info-wrap`}>
                    <ul className={`page-link`}>
                        <li style={{fontWeight:'bold'}}><Link to='/terms/privacy'>개인정보처리방침</Link></li>
                        <li><Link to='/terms/usage'>이용약관</Link></li>
                        <li><Link to='/story/rule'>운영제도</Link></li>
                        <li><Link to='/intro/greeting#map-info'>찾아오시는 길</Link></li>
                    </ul>
                    <p>KBL 및 KBL 소속 10개 구단의 팀 로고, 엠블럼과 KBL 소속 선수의 사진, 초상, 영상, 캐릭터, 시안(KBL 지적재산권)등을 무단으로 사용하는 것은 상표법 및 저작권법에 의거하여 금지됩니다.</p>
                    <ul className='info' key={`info`}>
                        <li>T 02 - 2106 - 3035</li>
                    </ul>
                    <span>Copyright ⓒ Korean Basketball League. All rights reserved.</span>
                </div>
                <div className={`sns-wrap`}>
                    <select onChange={openWindow}>
                        <option value={''}>Family Site</option>
                        <option value={'https://kbl.or.kr/'}>KBL 공식 홈페이지</option>
                    </select>
                    <ul>
                    {
                        facebookInfo ?
                            <li>
                                <a target='_blank' rel='noopener noreferrer' href={facebookInfo.snsUrl}>
                                    <i className={`ic-footer-facebook`} />
                                </a>
                            </li>
                            : null
                    }
                    {
                        instagramInfo ?
                            <li>
                                <a target='_blank' rel='noopener noreferrer' href={instagramInfo.snsUrl}>
                                    <i className={`ic-footer-instagram`} />
                                </a>
                            </li>
                            : null
                    }
                    {
                        youtubueInfo ?
                            <li>
                                <a target='_blank' rel='noopener noreferrer' href={youtubueInfo.snsUrl}>
                                    <i className={`ic-footer-youtube`} />
                                </a>
                            </li>
                            : null
                    }
                    {
                        naverPostInfo ?
                            <li>
                                <a target='_blank' rel='noopener noreferrer' href={naverPostInfo.snsUrl}>
                                    <i className={`ic-footer-naver`} />
                                </a>
                            </li>
                            : null
                    }
                    {
                        naverTvInfo ?
                            <li>
                                <a target='_blank' rel='noopener noreferrer' href={naverTvInfo.snsUrl}>
                                    <i className={`ic-footer-navertv`} />
                                </a>
                            </li>
                            : null
                    }
                    </ul>
                </div>
            </div>
        </footer>
    );
}
export default FooterComponent;