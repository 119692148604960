import React, { useEffect, useState } from 'react';
import {getYouthGameTeamList, getYouthGameTeamPlayerList} from "../../../../../service/youth/Match.service";
import FilterComponent from "../../../../commons/filter/FilterComponent";

const YouthPlayerInfoComponent = props => {
    const { history, seasonCode, seasonKind, groupCode, changeParams } = props;
    const [teamGroup, setTeamGroup] = useState(null);
    const [teamGroupList, setTeamGroupList] = useState(null);
    const [teamPlayerList, setTeamPlayerList] = useState(null);

    const [isPopupDisplay, setIsPopupDisplay] = useState(false);
    const [selectedInfo, setSelectedInfo] = useState(null);

    const getDefaultInfo = async () => {
        if (!seasonKind || !seasonCode) return
        const _teamGroupList = await getYouthGameTeamList({seasonKind: seasonKind, seasonCode: seasonCode})

        if(_teamGroupList && _teamGroupList.length > 0){
            let result = [];
            _teamGroupList.map(item => {
                let obj = {};
                obj.key = item.code;
                obj.value = item.name;
                result.push(obj);
            })
            setTeamGroupList(result);
            if(!groupCode) setTeamGroup(result[0].key)
        }
    }

    const getDataList = async () => {
        if (!seasonKind || !seasonCode || !teamGroup) return
        const _teamPlayerList = await getYouthGameTeamPlayerList({seasonKind: seasonKind, seasonCode: seasonCode, groupCode: teamGroup})
        setTeamPlayerList(_teamPlayerList);
    }

    useEffect( () => {
        if(groupCode) {
            setTeamGroup(groupCode)
        }
    }, [groupCode]);

    useEffect( () => {
        getDefaultInfo();
    }, [seasonKind, seasonCode]);

    useEffect( () => {
        getDataList();
    }, [seasonKind, seasonCode, teamGroup]);

    const positionList = [
        {key: 1, value: '감독'},
        {key: 2, value: '코치'},
        {key: 3, value: '매니저'},
    ];

    const onClickPopupOpen = (e, data) => {
        e.preventDefault();
        setIsPopupDisplay(true);
        setSelectedInfo(data);
    }

    const onClickPopupClose = e => {
        e.preventDefault();
        setIsPopupDisplay(false);
        setSelectedInfo(null);
    };

    return (
        <div className={`tab-con active`}>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4></h4>
                    <div className={`option-wrap`}>
                        <FilterComponent
                            type={'changeTeamGroup'}
                            selectedKey={teamGroup}
                            optionClass={'team pc'}
                            selectList={teamGroupList}
                            selectChange={changeParams}
                        />
                    </div>
                </div>
                <ul className={`youth-player-list`}>
                    {
                        teamPlayerList && teamPlayerList.length > 0 ?
                            teamPlayerList.map((item, index) =>
                                <li key={`team_group_${index}`}>
                                    <div className={`team`}>
                                        <img src={`/imgs/youth/emblem/em_KBL${item.clubCode}.png`} style={{width: '6rem', height:'4rem'}} />
                                        {/*<i className={`emblem-db`}></i>*/}
                                        <div>
                                            <h4>{item.title}</h4>
                                            <p>{item.titleLong}</p>
                                        </div>
                                    </div>

                                    <ul className={'list-ul'}>
                                        {
                                            item.staffList && item.staffList.length > 0 ?
                                                <li style={{marginBottom: '3rem'}}>
                                                    <div className={'list'}>
                                                        <h4 style={{marginRight: '2.5rem'}}>지도자</h4>
                                                        <ul className={`player`}>
                                                            {
                                                                // item.staffList && item.staffList.length > 0 ?
                                                                    item.staffList.map((i, idx) =>
                                                                        <li key={`team_staff_${idx}`} onClick={(e) => {onClickPopupOpen(e, i)}}>
                                                                            {positionList.find(list => list.key === i.staffPosition).value}. {i.staffName}
                                                                        </li>
                                                                    )
                                                                // :
                                                                    // <li key={`team_staff_no`}>미등록</li>
                                                                    // null
                                                            }
                                                        </ul>
                                                    </div>
                                                </li>
                                            : null

                                        }
                                        <li>
                                            <div className={'list'}>
                                                <h4 style={{marginRight: '3.8rem'}}>선수</h4>
                                                <ul className={`player`}>
                                                    {
                                                        item.playerList && item.playerList.length > 0 ?
                                                            item.playerList.map((i, idx) =>
                                                                <li key={`team_player_${idx}`} onClick={()=> {history.push(`/youth/player/${i.playerNo}`)}}>
                                                                    {i.num}. {i.name}
                                                                </li>
                                                            )
                                                            : null
                                                    }
                                                </ul>
                                            </div>
                                        </li>
                                        {/*<li style={{marginBottom: '3rem'}}>
                                            <h4 style={{marginBottom: '1.2rem', fontSize: '1.6rem', fontWeight: '500', color: 'var(--gray800)'}}>지도자</h4>
                                            <ul className={`player`}>
                                                {
                                                    item.staffList && item.staffList.length > 0 ?
                                                        item.staffList.map((i, idx) =>
                                                            <li key={`team_staff_${idx}`} onClick={(e) => {onClickPopupOpen(e, i)}}>
                                                                {positionList.find(list => list.key === i.staffPosition).value}. {i.staffName}
                                                            </li>
                                                        )
                                                        : null
                                                }
                                            </ul>
                                        </li>
                                        <li>
                                            <h4 style={{marginBottom: '1.2rem', fontSize: '1.6rem', fontWeight: '500', color: 'var(--gray800)'}}>선수</h4>
                                            <ul className={`player`}>
                                                {
                                                    item.playerList && item.playerList.length > 0 ?
                                                        item.playerList.map((i, idx) =>
                                                            <li key={`team_player_${idx}`} onClick={()=> {history.push(`/youth/player/${i.playerNo}`)}}>
                                                                {i.num}. {i.name}
                                                            </li>
                                                        )
                                                        : null
                                                }
                                            </ul>
                                        </li>*/}
                                    </ul>

                                    {/*<ul className={`player`}>
                                        {
                                            item.playerList && item.playerList.length > 0 ?
                                                item.playerList.map((i, idx) =>
                                                    <li key={`team_player_${idx}`} onClick={()=> {history.push(`/youth/player/${i.playerNo}`)}}>
                                                        {i.num}. {i.name}
                                                    </li>
                                                )
                                                : null
                                        }
                                    </ul>*/}
                                </li>
                            )
                            : null
                    }
                </ul>
            </div>
            <div className={`popup`} style={{display: isPopupDisplay ? 'block' : 'none'}}>
                {
                    selectedInfo ?
                        <div className={`pop-wrap large`}>
                            <div className={`pop-tit`}>
                                <h4>지도자 정보</h4>
                                <i className={`ic-pop-close-24`} onClick={onClickPopupClose} />
                            </div>
                            <div className={`pop-con`}>
                                <div className={`pop-box`}>
                                    <div className={`pop-box-tit`}>
                                        <h6>기본 정보</h6>
                                    </div>
                                    <div className={`staff-info`}>
                                        <div className={`img`} style={{backgroundImage: `url(${selectedInfo.imgPath ? selectedInfo.imgPath : '/imgs/player/img-player-no.png'}), linear-gradient(180deg, #E3E6ED 0%, #BAC0D4 100%)`,
                                                                       backgroundPosition: `${selectedInfo.imgPath ? 'center top 0rem, center' : ''}`}} />
                                        <div className={`txt`}>
                                            <div className={`name`}>
                                                <h6>{selectedInfo.staffName} {positionList.find(list => list.key === selectedInfo.staffPosition).value}</h6>
                                                <span style={{whiteSpace: 'pre-line'}}>{selectedInfo.titleLong}</span>
                                            </div>
                                            <ul className={`info`}>
                                                {
                                                    selectedInfo.staffBirth ?
                                                        <li>
                                                            <h6>생년월일</h6>
                                                            <p>{selectedInfo.staffBirth}</p>
                                                        </li>
                                                        : null
                                                }
                                                {
                                                    selectedInfo.staffSchool ?
                                                        <li>
                                                            <h6>출신교</h6>
                                                            <p>{selectedInfo.staffSchool}</p>
                                                        </li>
                                                        : null
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {
                                    selectedInfo.careerInfo && selectedInfo.careerInfo.length > 0 ?
                                        <div className={`pop-box line`}>
                                            <div className={`pop-box-tit`}>
                                                <h6>주요 경력</h6>
                                            </div>
                                            <ul className={`staff-career`}>
                                                {
                                                    selectedInfo.careerInfo.map((item, index) =>
                                                        <li key={`info-career-${index}`}>
                                                            <h6>{item.infoYear}</h6>
                                                            <ul>
                                                                <li>{item.infoContent}</li>
                                                            </ul>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className={`pop-btn`}>
                                <button className={`pb`} onClick={onClickPopupClose}>확인</button>
                            </div>
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default YouthPlayerInfoComponent;