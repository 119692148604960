import React from 'react';
import DaumPostcode from 'react-daum-postcode';

const PostCodePopupComponent = props => {
  const { isDisplay, onComplete, clickCloseButton } = props;

  return (
    <div className='popup' style={{ display: isDisplay ? 'block' : 'none' }}>
      <div className={`pop-wrap`}>
        <div className={`pop-tit`}>
          <h4>주소 찾기</h4>
          <i className={`ic-pop-close-24`} onClick={clickCloseButton} />
        </div>
        <div className={`pop-con`}>
          <DaumPostcode
              onComplete={onComplete}
              {...props}
          />
        </div>
      </div>
    </div>
  );
}


PostCodePopupComponent.propTypes = {
  // isDisplay: PropTypes.bool.isRequired,
  // onComplete: PropTypes.func.isRequired,
  // clickCloseButton: PropTypes.func.isRequired,
};

export default PostCodePopupComponent;