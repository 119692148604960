import React from 'react';

const EmptyGameComponent = props => {
    const { content } = props;

    return(
        <div className={`con-empty`}>
            <i className={`img-no-game`} />
            <h6>{content ? content : '경기가 없습니다.'}</h6>
        </div>
    )
}

export default EmptyGameComponent;