import React from 'react';

const MypageResetPwdComponent = props => {
  const { isDisplay, clickConfirmButton, clickCloseButton,
    currPwd, newPwd, newPwdConfirm,
    changeCurrPwd, changeNewPwd, changeNewPwdConfirm,
    isValidPwd, isSamePwd
  } = props;

  return (
    <div className='popup' style={{ display: isDisplay ? 'block' : 'none' }}>
      <div className={`pop-wrap`}>
        <div className={`pop-tit`}>
          <h4>비밀번호 변경</h4>
          <i className={`ic-pop-close-24`} onClick={clickCloseButton} />
        </div>
        <div className={`pop-con`}>
          <form>
            <ul className={`auth-input`}>
              <li>
                <input type='password' className='cs' placeholder={"현재 비밀번호를 입력해주세요."} value={currPwd} onChange={changeCurrPwd} />
              </li>
              <li>
                <input type='password' className='cs' placeholder={"변경할 비밀번호를 입력해주세요."} value={newPwd} onChange={changeNewPwd}/>
              </li>
              <li>
                <span className={'font-red'} style={{ display: !isValidPwd ? 'block' : 'none' }}>사용할 수 없는 비밀번호입니다.</span>
              </li>
              <li>
                <input type='password' className='cs' placeholder={"비밀번호를 재입력해주세요."} value={newPwdConfirm} onChange={changeNewPwdConfirm}/>
              </li>
              <li>
                <span className={'font-red'} style={{ display: !isSamePwd ? 'block' : 'none' }}>비밀번호가 일치하지 않습니다.</span>
              </li>
            </ul>
          </form>
        </div>
        <div className={`pop-btn`}>
          <button className={`light-pb`} onClick={clickCloseButton}>취소</button>
          <button className={`pb`} onClick={clickConfirmButton}>확인</button>
        </div>
      </div>
    </div>
  );
}

MypageResetPwdComponent.propTypes = {
  // isDisplay: PropTypes.bool.isRequired,
  // onComplete: PropTypes.func.isRequired,
  // clickCloseButton: PropTypes.func.isRequired,
};

export default MypageResetPwdComponent;