import * as ApiService from '../Api.service'

export const getList = async params => {
    console.log('Player.service:getTemrsInfo');
    const url = `/web/players`;

    return ApiService.get(url, params);
}





