import React, { useEffect, useState } from 'react';
import {getYouthPlayerRankList} from "../../../../../service/youth/Match.service";
import FilterComponent from "../../../../commons/filter/FilterComponent";

const YouthPlayerRankComponent = props => {
    const { seasonCode, seasonKind } = props;
    const [rankList, setRankList] = useState(null);
    const [gameGroup, setGameGroup] = useState('1'); // 권역

    const gameGroupList = [
        {key: '1' , value: 'A권역'},
        {key: '2' , value: 'B권역'},
        {key: '3' , value: 'C권역'}
    ]

    const changeParams = (type, key, value) => {
        if (type === 'changeGameGroup') {
            if (gameGroup === key) {
                return;
            }
            setGameGroup(key);
            return;
        }
    }

    const getDataList = async () => {
        if (!seasonKind || !seasonCode) return
        const _rankList = await getYouthPlayerRankList({seasonKind: seasonKind, seasonCode: seasonCode, gameGroup: gameGroup})
        setRankList(_rankList)
    }
    useEffect( () => {
        getDataList();
    }, [seasonKind, seasonCode, gameGroup])

    return (
        <div className={`tab-con active`}>
            <div className={`con-box`}>
                {
                    seasonKind == 2 &&
                    <div className={`con-tit`}>
                        <h4></h4>
                        <div className={`option-wrap`}>
                            <FilterComponent
                                type={'changeGameGroup'}
                                selectedKey={gameGroup}
                                selectList={gameGroupList}
                                selectChange={changeParams}
                            />
                        </div>
                    </div>
                }
            {
                rankList && rankList.length > 0 ?
                    rankList.map((item, index) =>
                        <div className={`con-box`} key={`player_rank_group_${index}`}>

                            <div className={`con-tit`}>
                                <h4>{item.groupName}</h4>
                                <p>{index === 0 && '※ 현재 개인 순위는 상위 10명까지만 출력하고 있습니다.'}</p>
                            </div>
                            <div className={`youth-grid-3`}>
                                <div>
                                    <h6>득점</h6>
                                    <div>
                                        <table className={`date-table`}>
                                            <colgroup>
                                                <col style={{width: '15%'}} />
                                                <col style={{width: 'auto'}} />
                                                <col style={{width: 'auto'}} />
                                                <col style={{width: '20%'}} />
                                            </colgroup>
                                            <thead>
                                            <tr>
                                                <th>순위</th>
                                                <th>팀명</th>
                                                <th>선수명</th>
                                                <th>기록</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                item.scoreList && item.scoreList.length > 0 ?
                                                    item.scoreList.map((i, idx) =>
                                                        <tr key={`player_rank_${idx}`}>
                                                            <td className={`font500`}>{i.rank}</td>
                                                            <td>{i.team2}</td>
                                                            <td>{i.name}(#{i.num})</td>
                                                            <td>{i.value} P</td>
                                                        </tr>
                                                    )
                                                    : null
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <h6>리바운드</h6>
                                    <div>
                                        <table className={`date-table`}>
                                            <colgroup>
                                                <col style={{width: '15%'}} />
                                                <col style={{width: 'auto'}} />
                                                <col style={{width: 'auto'}} />
                                                <col style={{width: '20%'}} />
                                            </colgroup>
                                            <thead>
                                            <tr>
                                                <th>순위</th>
                                                <th>팀명</th>
                                                <th>선수명</th>
                                                <th>기록</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                item.rbList && item.rbList.length > 0 ?
                                                    item.rbList.map((i, idx) =>
                                                        <tr key={`player_rank_${idx}`}>
                                                            <td className={`font500`}>{i.rank}</td>
                                                            <td>{i.team2}</td>
                                                            <td>{i.name}(#{i.num})</td>
                                                            <td>{i.value} R</td>
                                                        </tr>
                                                    )
                                                    : null
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <h6>어시스트</h6>
                                    <div>
                                        <table className={`date-table`}>
                                            <colgroup>
                                                <col style={{width: '15%'}} />
                                                <col style={{width: 'auto'}} />
                                                <col style={{width: 'auto'}} />
                                                <col style={{width: '20%'}} />
                                            </colgroup>
                                            <thead>
                                            <tr>
                                                <th>순위</th>
                                                <th>팀명</th>
                                                <th>선수명</th>
                                                <th>기록</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                item.astList && item.astList.length > 0 ?
                                                    item.astList.map((i, idx) =>
                                                        <tr key={`player_rank_${idx}`}>
                                                            <td className={`font500`}>{i.rank}</td>
                                                            <td>{i.team2}</td>
                                                            <td>{i.name}(#{i.num})</td>
                                                            <td>{i.value} A</td>
                                                        </tr>
                                                    )
                                                    : null
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    : null
            }
            </div>
        </div>
    )
}

export default YouthPlayerRankComponent;