import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setRuleParams, setIsMore } from '../../../../store/story/rule';
import PaginationComponent from '../../../commons/table/PaginationComponent';
import { getNumberWithComma } from '../../../../helpers/stringHelper';
import { updateDownloadCount } from '../../../../service/story/Story.service';

const GameRuleComponent = props => {
    const { ruleParams, ruleList, rulePagingData, pageSize } = useSelector(state => state.storyRule);
    const dispatch = useDispatch();

    const getDefaultParams = _ => {
        dispatch(setRuleParams({ start: 0, limit: pageSize }));
    }

    const changePage = e => {
        e.preventDefault();
        const value = e.currentTarget.getAttribute('data-value');
        if (value === rulePagingData.currentPage) {
            return;
        }
        dispatch(setIsMore(false));
        ruleParams.start = (value - 1) * pageSize;
        ruleParams.limit = pageSize;

        dispatch(setRuleParams(ruleParams));
    }

    const moreView = e => {
        e.preventDefault();
        const value = e.currentTarget.getAttribute('data-value');
        if (value === rulePagingData.currentPage) {
            return;
        }
        dispatch(setIsMore(true));
        ruleParams.start = (value - 1) * pageSize;
        ruleParams.limit = pageSize;

        dispatch(setRuleParams(ruleParams));
    }
    const updateDownloadCnt = async e => {
        const result = await updateDownloadCount(e.currentTarget.dataset.key);
        dispatch(setRuleParams(ruleParams));
    }

    useEffect(() => {
        getDefaultParams();
    }, []);

    return (
        <>
            <div className={`con-box`}>
            <ul className={`rule-list-wrap`}>
                {
                    ruleList.data && !ruleList.loading ?
                        ruleList.data.map((item, index) =>
                            <li>
                                <div className={`img`} style={{backgroundImage: `url(${item.mediaListThumbPath})`}} />
                                <div className={`txt`}>
                                    <h6>{item.title}</h6>
                                    <ul className={`flex-txt`}>
                                        <li><h6>다운로드</h6><p>{getNumberWithComma(item.downCnt)}</p></li>
                                    </ul>
                                </div>
                                <button>
                                    <a href={item.mediaPath} target={"_blank"} onClick={updateDownloadCnt} data-key={item.mediaSq}>
                                        PDF 다운로드
                                    </a>
                                </button>
                            </li>
                        )
                        :
                        null
                }
            </ul>
            {
                rulePagingData ?
                    <PaginationComponent
                        pagingData={rulePagingData}
                        changePage={changePage}
                        moreView={moreView}
                    />
                    : null
            }
            </div>

            <div className={`con-box`}>
                <div className='con-tit'>
                    <h4>경기 규칙 변화</h4>
                </div>
                <table>
                    <colgroup>
                        <col style={{width: '20%'}}/>
                        <col style={{width: '80%'}}/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>시즌</th>
                        <th>경기규칙</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>2020-2021</td>
                        <td className='desc'>
                            <dl>
                                <dt>
                                    ㆍ 선수: 부상과 도움
                                </dt>
                                <dd>
                                    - 부상당한 선수가 즉시(약 15초 이내) 경기를 할 수 없거나 치료를 받는 경우 또는, 자신의 감독, 어시스턴트 코치, 팀 선수들과 동반한 팀의 인원들에게 도움을 받으면, 그 선수는 교체되어야 한다. 다만 팀에 출전 가능한 선수가 5명 이내인 경우는 예외로 한다.
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    ㆍ 슛 동작 중에 있는 선수
                                </dt>
                                <dd>
                                    - 심판이 판단하기에 선수가 상대팀 바스켓을 향해 볼을 들어 올릴 때 시작된다. 선수의 손에서 볼이 떠나거나, 만약 완전히 새로운 슛 동작이 이루어질 때 그리고 공중에 있는 슛터의 경우 양발이 코트에 착지하면 끝난다.
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    ㆍ 스포츠 정신에 위배되는 파울
                                </dt>
                                <dd>
                                    - 스포츠 정신에 위배되는 파울은 선수의 접촉파울이며, 심판의 판단에 의해 다음의 경우 부과된다:<br/>
                                    → 상대방과의 접촉이며 규칙의 정신과 의도 내에서 직접적으로 볼에 대한 플레이를 합법적으로 시도하지 않는 경우<br/>
                                    → 선수가 볼 또는 상대방에 대한 플레이를 하기 위한 노력 중 지나치거나 심한 접촉을 유발하는 경우<br/>
                                    → 공격을 전개하는 팀의 진행을 멈추기 위해 수비선수가 불필요한 신체접촉을 유발하는 경우 이것은 공격 선수가 슛 동작을 시작할때까지 적용된다.<br/>
                                    → 진행 중인 선수, 볼과 바스켓 사이에 상대팀 선수가 없고 상대팀 바스켓을 향해 진행 중일 때, 상대선수가 뒤쪽 또는 측면에서 불법적인 접촉을 유발하는 경우 이것은 공격 선수가 슛 동작을 시작할 때까지 적용된다.<br/>
                                    → 경기 시간이 4쿼터 또는 연장전 2:00 또는 그 미만을 나타낼 때, 드로우 인을 위해 볼이 경계선 밖의 심판의 손에 있거나 또는 드로우 인 하는 선수에게 있을 때 수비선수가 경기코트의 상대선수에 접촉을 하는 경우<br/>
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>2019-2020</td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ A팀(공격팀)의 프론트 코트에서 A팀 선수 또는 벤치에서 T파울이 발생한 경우,</dt>
                                <dd>
                                    - 샷클락이 14초 이상일 때 → 잔여시간<br/>
                                    - 샷클락이 14초 미만일 때 → 잔여시간
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>2018-2019</td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 드로우인</dt>
                                <dd>
                                    - 4Q 또는 연장 쿼터 2분 이내 드로우인 시 상대팀이 라인을 넘어 수비 시 T파울 부과<br/>
                                    - 언스포츠맨라이크 파울, 디스퀄링 파울, 파이팅 파울로 인한 드로우 인은 팀의 프론트 코트에서 시행한다. 이와 같은 모든상황에서샷클락은 14초로 되어 진다.
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 드리블</dt>
                                <dd>- 백보드에 볼을 던지는 것은더 이상 드리블로 간주하지않는다. 드리블 이후 “백보드덩크”는 허용된다.</dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 24초룰</dt>
                                <dd>
                                    - 공격 팀의 파울 또는 바이얼레이션 상황 (아웃 오브 바운드 포함)<br/>
                                    → 백코트드로우인 : 24초<br/>
                                    → 프론트코트드로우인 : 14초<br/>
                                    <br/>
                                    - 4Q 또는 연장전 2분 이내 작전타임 후 드로우 인 지점선택 가능<br/>
                                    ※프론트코트 드로우인 시<br/>
                                    → 14초 이상일 경우 : 14초 리셋<br/>
                                    → 13초 이하인 경우 : 잔여시간<br/>
                                    ※백코트 드로우 인 시<br/>
                                    → 24초 리셋 또는 잔여시간<br/>
                                    <br/>
                                    - 볼이 링과 백보드 사이에 끼는 경우 링에 터치된 것으로 간주<br/>
                                    → 14초 또는 24초 리셋<br/>
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 테크니컬 파울 패널티</dt>
                                <dd>
                                    - 기존 소유한 팀 소유권<br/>
                                    - 벌칙 자유투 먼저 시행<br/>
                                    [싸움 발생 시 벤치 이탈]<br/>
                                    - 벤치를 이탈하여 싸움에 가담한 모든 인원 실격 퇴장<br/>
                                    - 벤치를 이탈하였지만 싸움에 가담하지 않은 인원은 실격퇴장 되지 않고, 경기 종료 후 비디오 분석을 통하여 징계결정<br/>
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 페이크 파울</dt>
                                <dd>
                                    - 경기 종료 후 비디오 분석을통해 1회 발생 시 경고, 2회부터는 벌금 부과 1~2회 : 20만원, 3~4회 : 30만원,5~6회 :50만원, 7회 ~ : 100만원<br/>
                                    ※ 일반 테크니컬 파울과는 별개로 누적하여 적용
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ U파울 확대적용</dt>
                                <dd>
                                    - 볼 플레이가 아닌 접촉(파울 작전 포함)에 대해서는 U파울 선언
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 드로우인</dt>
                                <dd>
                                    - 4Q 또는 연장 쿼터 2분 이내 드로우인 시 상대팀이 라인을 넘어 수비 시 T파울 부과<br/>
                                    - 언스포츠맨라이크 파울, 디스퀄링 파울, 파이팅 파울로 인한 드로우 인은 팀의 프론트 코트에서 시행한다. 이와 같은 모든상황에서 샷클락은 14초로 되어 진다.
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>2017-2018</td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 경기에서의 퇴장</dt>
                                <dd>
                                    - 개정된 규칙에 따라 한 개인에 테크니컬 파울 1개와 언스포츠맨라이크 파울 1개가 부과되면 경기에서 퇴장이 된다.
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 파울의 벌칙</dt>
                                <dd>
                                    - 벤치인원이 퇴장이 되면 감독에게 벤치파울로 부과되고 벌칙으로 상대팀에 자유투 2개가 주어진다.
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 슛 동작에 있는 선수</dt>
                                <dd>
                                    - 슛 동작에 있는 선수가 파울을 당하고 볼을 패스를 하면 슛 동작으로 간주하지 않는다.
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 출전선수 명단 (감독의 의무)</dt>
                                <dd>
                                    - 경기시작 40분 전까지 팀의 출전선수 명단(12명)을 기록원에게 제출하여야 한다.<br/>
                                    - 스타팅 5 선수는 경기시작 20분전까지 기록원에게 제출한다.
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 팀 유니폼 및 착용물품</dt>
                                <dd>
                                    - 셔츠와 반바지는 같은 류의 색깔이어야 한다.<br/>
                                    - 반팔셔츠는 팔꿈치 윗부분까지로 제한한다. 긴 팔 셔츠는 허용되지 않는다.<br/>
                                    - 양말은 신발 밖으로 보여야 하고, 운동화는 양쪽이 같아야 한다.<br/>
                                    - 팔 다리 압박보호, 발목보호대는 흰색, 검정색, 또는 유니폼과 같은 류의 색깔이어야 하며 팀의 전 선수가 한가지 색깔이어야 한다.
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 벤치좌석의 수</dt>
                                <dd>
                                    - 팀의 벤치는 총 16석으로 한다.
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>2016-2017</td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 변경사항 없음</dt>
                                <dd></dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>2015-2016</td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 언스포츠맨라이크 파울</dt>
                                <dd>
                                    - 공격선수와 상대 바스켓 사이에 수비선수가 없는 속공 상황에서, 공격선수의 측면 또는 뒤에서의
                                    <br/>(속공을 저지하기 위한) 모든 파울.(시행: 백코트의 자유투 연장선부터 슛 동작까지 적용)<br/>
                                    - 볼 플레이가 아닌 비합법적 신체접촉<br/>
                                    - 볼 플레이 중 지나치거나 심한 파울<br/>
                                    - 4쿼터 또는 연장쿼터 마지막 2분 이내에 드로우 인 이전 발생하는 모든 파울<br/>
                                    - 자유투 2개 + 소유권
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 비디오 판독 규칙</dt>
                                <dd>
                                    - 경기 중 감독이 1회에 한하여 비디오 판독을 요청할 수 있음<br/>
                                    비디오 판독 결과 판정이 바뀌는 경우,해당 팀에게 비디오 판독요청 기회 1회 추가 부여
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 하프타임 시간</dt>
                                <dd>
                                    - 하프타임 준비시간 12분
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 야투에 의해 경기시계 계시기를 멈추는 시점</dt>
                                <dd>
                                    -  야투 득점시 경기시계 매쿼터 및연장쿼터 종료 2분이내에 정지
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 재정신청</dt>
                                <dd>
                                    - 경기종료 직후 주장의 서명<br/>
                                    - 현장 경기기술위원회에서 결정<br/>
                                    - 현장 경기기술위원회의 결정에 불복하는 경우 1시간 이내에 서면으로 재정신청(공탁금 200만원)<br/>
                                    - 재정위원회 개최 후 총재 최종 결정
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>2014-2015</td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 타임아웃 요청</dt>
                                <dd>
                                    - 해당 팀 감독이 요청(선수요청 불가)
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 심판에 대한 질의</dt>
                                <dd>
                                    - 주장이 데드볼 상황에서 심판에게 예의를 갖춰 질의(감독 질의 불가)
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 작전타임</dt>
                                <dd>
                                    - 전반 2개, 후반 3개 각 90초 작전타임 유지 / 20초 작전타임 폐지(FIBA 작전타임 60초)
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 하프타임</dt>
                                <dd>
                                    - 2쿼터 종료 후 하프타임 15분 휴식(종전 12분 휴식)
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 24초 규정</dt>
                                <dd>
                                    - 볼이 링에 터치되고 같은 공격팀이 그 볼을 다시 소유하면 14초로 리셋됨(종전 24초 리셋)
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 트래블링</dt>
                                <dd>
                                    - 슛한 볼이 링이나 백보드에 닿지 않아도 슛을 한 선수가 그 볼을 첫 번째로 터치할 수 있음.<br/>
                                    - 볼을 가진 선수가 넘어지고 난 후 그 볼을 잡고 일어설 수 없음.
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 언스포츠맨라이크 파울Ⅰ</dt>
                                <dd>
                                    - 속공을 막기 위해 (1) 볼을 가진 공격 선수와 상대 바스켓 사이에 수비선수가 없을 때 수비선수가 상대방의 뒤 또는 측면에서의도적으로 접촉하는
                                    경우, 또는 (2) 볼을 가진 공격선수와 상대 바스켓 사이에 수비선수가 있을 때 발생하는 파울.<br/>
                                    - 파울 발생 시, 자유투 1개 / 볼 소유권은 원 소유 팀 유지<br/>
                                    ※ KBL 속공파울 규칙 폐지
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 언스포츠맨라이크 파울Ⅱ</dt>
                                <dd>
                                    - 경기 중 발생하는 모든 과도하고 심한 접촉을 유발하는 파울.- 파울 발생 시, 자유투 2개 / 소유권 변경, 2개이상 부과 시 실격 퇴장<br/>
                                    ※ U-파울 시, 개인 파울에 포함(종전 미포함)
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 테크니컬 파울</dt>
                                <dd>
                                    벌칙으로 자유투 1개에 소유권이 주어짐(종전 자유투 1개)<br/>※ 테크니컬 파울 시, 개인 파울에 포함(종전 미포함)
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 시뮬레이션 액션</dt>
                                <dd>
                                    - 파울을 유도하기 위해 넘어지는 행위는 테크니컬 파울 부과<br/>
                                    - 심판 판정을 유도 혹은 심판을 속이기 위한 행위 중 (1) 과도한 경우 테크니컬 파울 부과,<br/>
                                    (2) 경미한 경우 일반파울 부과, 또는 경고(1회 경고 후에는 테크니컬 파울 부과)
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 비디오 판독</dt>
                                <dd>
                                    4쿼터 종료 2분 이내, 감독이 1회에 한해 심판에게 비디오판독을 요청할 수 있음<br/>
                                    (단, 심판 판단 하에 적절할 경우 시행)
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2013-2014
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 24초 룰</dt>
                                <dd>
                                    - 슛한 볼이 링에 터치되지 않은채24초시계의 신호가 울릴 경우,<br/>
                                    - 수비 팀이 즉시 볼을 컨트롤하게 되었다면, 신호를 무시하고 경기 진행
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 볼이 백보드 뒤로 넘어가는 상황 규칙</dt>
                                <dd>
                                    - 볼이 백보드 뒤로 넘어가도 지지대 등 시설물에 닿지 않으면 바이얼레이션이 아님.
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2012-2013
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 수비자 3초룰 폐지</dt>
                                <dd>
                                    - 국제 경쟁력 강화를 위하여 수비자 3초룰 폐지
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 속공파울 신설 (기존 인텐셔널 파울 개정)</dt>
                                <dd>
                                    - 고의적으로 속공을 저지하는 파울
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 스포츠 정신에 위배되는 파울 (기존 플레그런트 1 파울)</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 실격 퇴장되는 파울 (기존 플레그런트 2 파울)</dt>
                                <dd></dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2011-2012
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 공식 사용구 변경</dt>
                                <dd>
                                    - 국제대회 경쟁력 증대를 위해 몰텐 제품과 유사 재질 사용구로 신규 제작
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 비디오 판독 全 경기 시행</dt>
                                <dd>
                                    - 매 쿼터 종료시점 및 매 연장전 경기종료 상황에 득점이 되고 그 득점이 경기결과에 영향이 있는 상황에서 이용되며 정규리그, 플레이오프에서 모두 시행됨
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 자동제세동기 설치</dt>
                                <dd>
                                    - 선수 안전 강화를 위해 응급 심폐소생술을 시행할 수 있는 자동 제세동기를 각 구단 홈경기장에 설치
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2010-2011
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 유니폼 규정 개정</dt>
                                <dd>
                                    - 각 구단 고유의 색을 연고지역 팬들에게 어필하기 위해 기존과 반대로 홈팀이 진한 유니폼,원정팀이 밝은 유니폼을 입는 것으로 함
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 수비팀에 대한 3초 제한룰을 강화</dt>
                                <dd>
                                    - 수비선수가 정당한 위치로 급히 벗어나고 있을 때 : 기존 예외 허용 → 3초 룰 적용으로 변경
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2009-2010
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 페인트 존 : 사다리형에서 직사각형으로 변경</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 3점슛 거리 : 6.25m에서 6.75m로 연장</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ No Charging Zone 신설</dt>
                                <dd>
                                    - 바스켓을 중심으로 1.25m 반원
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2008-2009
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 플레이오프</dt>
                                <dd>
                                    - 6강 플레이오프 5전 3선승제로 변경
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 시뮬레이션 액션 규칙 신설 (헐리우드 액션)</dt>
                                <dd>
                                    - 상대선수의 파울을 유도하기 위해 과장된 행동을 하여 심판의 판단을 흐리게 하는 경우,테크니컬 파울과 20만원의 벌과금을 부과하기로 함
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 트래블링 파울 규칙 강화</dt>
                                <dd>
                                    - 지난 2008 베이징 올림픽 최종예선에서 문제점으로 나타났던 트래블링 규칙을 국제경쟁력 강화를 위해 기본 규정에 맞게 적용
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2007-2008
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 페어플레이를 위한 테크니컬 파울 반칙금 기준 강화</dt>
                                <dd>
                                    - 테크니컬 파울 1회~6회 : 1회당 반칙금 20만원<br/>
                                    - 테크니컬 파울 7회~13회 : 1회당 반칙금50만원<br/>
                                    - 테크니컬 파울 14회 이상 : 1회당 반칙금 100만원
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 인텐셔널 파울 기준 강화</dt>
                                <dd>
                                    - 자유투 2회+공격권 (단, 첫 번째 자유투 성공 시 두 번째 자유투 없음)
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 선수 유니폼에 성명 대신 애칭(별명) 기명 가능</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 응원북 사용 금지</dt>
                                <dd></dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2006-2007
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 14초룰 적용</dt>
                                <dd></dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2005-2006
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 시범 경기 각 팀당 2경기씩 총 10경기 축소 개최</dt>
                                <dd></dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2004-2005
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 선수 이적 제한</dt>
                                <dd>
                                    - 이적 등록 후 1년이 경과하지 않으면 전 소속 구단으로 재 이적 금지
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 시범 경기 우승팀, 프로-아마추어 최강전 참가</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 금요일 공식 개막전 개최 및 금요일 경기 실시</dt>
                                <dd></dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2003-2004
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 선수 이적 제한</dt>
                                <dd>
                                    - 5라운드 시작일부터 플레이오프 종료시까지<br/>
                                    - 자유계약선수의 구단 선택기간
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 시범 경기 부활, 각 팀당 4경기씩 총 20경기 개최</dt>
                                <dd></dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2002-2003
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 지역방어 허용</dt>
                                <dd></dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2001-2002
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 정규리그</dt>
                                <dd>
                                    - 6라운드 270경기<br/>
                                    - 팀탕 54경기<br/>
                                    - 홈, 원정 각각 27경기로 변경
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 서울 연고 구단(삼성, SK) 지명</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 1~2쿼터, 3~4쿼터 사이 휴식시간 120초로 늘림</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 작전 타임 시간 : 90초로 늘림</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 최초로 허재, 강동희 등 총 20명 FA 자격 획득</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 선수 이적 마감 : 4라운드 종료일</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 스프링 리그 개최로 시범 경기 취소</dt>
                                <dd></dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            2000-2001
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 플레이오프</dt>
                                <dd>
                                    - 6강 플레이오프 3전 2선승제로 변경
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 주말 이틀동안 10개 연고지에서 홈 개막전 실시</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 하프 타임 : 10분에서 12분으로 늘림</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 20초 작전 타임 : 전.후반 각 1회로 확대</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 포스터 업 바이레이션 신설, 핸드체킹 강화</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 선수 이적 마감 : 3라운드 종료일</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 시범 경기 개최</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 시즌 종료 후 스프링 리그 개최</dt>
                                <dd>
                                    - 각 팀 식스맨들로 구성된 10개팀 및 상무 참가
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            1999-2000
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 20초 작전 타임 신설 : 4쿼터에 한해 팀당 1회 실시</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 하프라인 바이레이션 : 10초에서 8초로 단축</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 선수 이적 마감 : 1999년 12월 25일</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 챔피언십 대회 개최</dt>
                                <dd>
                                    - 제주, 여수 등 비연고지역에서 경기
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            1998-1999
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 경기 시작 시간 : 평일 7시, 주말 및 공휴일 3시로 변경</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 서울 중립경기 하루에 한 경기 개최</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 선수 이적 마감 : 1999년 1월 3일</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 농구대잔치 참가</dt>
                                <dd>
                                    - 두조 나눠 예선을 치른 후 각 조 1위 결승전
                                </dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            1997-1998
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 정규리그</dt>
                                <dd>
                                    - 5라운드 225경기<br/>
                                    - 팀탕 45경기<br/>
                                    - 홈, 원정 각 18경기, 중립 9경기
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 플레이오프</dt>
                                <dd>
                                    - 6강, 4강 플레이오프 5전 3선승제로 변경
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 타임 아웃 : 전반 2번, 후반 3번으로 변경</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 경기종료 3분전 필드골 성공시 경기 시간 정지</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 시범 경기 각 팀당 4경기씩 총 20경기 개최</dt>
                                <dd></dd>
                            </dl>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            1997
                        </td>
                        <td className='desc'>
                            <dl>
                                <dt>ㆍ 정규리그</dt>
                                <dd>
                                    - 3라운드 84경기<br/>
                                    - 팀당 21경기, 홈, 원정, 중립 각각 9경기
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 플레이오프</dt>
                                <dd>
                                    - 6강, 4강 및 챔피언 결정전 7전 4선승제
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 경기시간 : 10분 4쿼터제</dt>
                                <dd>
                                    - 1~2쿼터, 3~4쿼터 사이 90초 휴식<br/>
                                    - 하프 타임 10분<br/>
                                    - 연장전 5분(90초 휴식 후 실시)<br/>
                                    - 4쿼터 또는 연장전 종료 2분전 필드골 성공시 시간정지
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 공격 제한 시간 : 24초</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 지역 방어 금지</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 팀파울 5개부터 자유투 허용</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 타임 아웃 : 70초</dt>
                                <dd>
                                    - 전.후반 두 차례<br/>
                                    - 4쿼터 및 연장전 종료 2분전 공격선수 요청가능
                                </dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 심판 : 3심제</dt>
                                <dd></dd>
                            </dl>
                            <dl>
                                <dt>ㆍ 경기 시작 시간 : 평일 6시 30분, 주말 및 공휴일 2시</dt>
                                <dd></dd>
                            </dl>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default GameRuleComponent;