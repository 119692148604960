import * as ApiService from '../Api.service'

export const getYouthPlayer = async  (playerNo) => {
    const url = `/web/youth/players/${playerNo}`;

    return ApiService.get(url);
}

export const getYouthPlayerStatsLeagueAll = async  (playerNo) => {
    const url = `/web/youth/players/${playerNo}/stats/leagues`;

    return ApiService.get(url);
}

export const getYouthPlayerSeasons = async  (playerNo) => {
    const url = `/web/youth/players/${playerNo}/seasons`;

    return ApiService.get(url);
}

export const getYouthPlayerLeagueRecordBySeasonCode = async  (params = {}) => {
    const url = `/web/youth/players/${params.playerNo}/stats/matches`;

    return ApiService.get(url, params);
}