import React, { useEffect, useState } from 'react';
import PrivateInfoComponent from './PrivateInfoComponent'
import YouthPrivateInfoComponent from './YouthPrivateInfoComponent'
import RequestListComponent from './RequestListComponent'
import * as MemberService from '../../../../service/member/Member.service';
import PasswordComponent from '../../../commons/popup/PasswordComponent';
import AlertComponent from '../../../commons/popup/AlertComponent';
import {getYouthMemberInfo} from "../../../../service/youth/Signup.service";

const MyPageComponent = props => {
    const { history, location } = props;
    const [selectedTab, setSelectedTab] = useState('modify');
    const [memberInfo, setMemberInfo] = useState(null);
    //const [refundInfo, setRefundInfo] = useState(null);
    const [isCheckedPwd, setIsCheckedPwd] = useState(true);
    const [pwd, setPwd] = useState('');
    //const [bank, setBank] = useState('');
    //const [account, setAccount] = useState('');
    //const [holder, setHolder] = useState('');
    //const [isDisplayRefundAccountPopup, setIsDisplayRefundAccountPopup] = useState(false);
    //const [isDisplaymembershipNumberPopup, setIsDisplaymembershipNumberPopup] = useState(false);
    //const [bankCodeList, setBankCodeList] = useState(null);
    const [bankCode, setBankCode] = useState('');
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCheckedTerms, setIsCheckedTerms] = useState(false);
    const [youthMemberInfo, setYouthMemberInfo] = useState(null);

    const tabList = [
        {
            key: 'modify',
            value: '개인정보 관리'
        },
        {
            key: 'youth modify',
            value: '유소년 정보 관리'
        },
        {
            key: 'inquiry',
            value: '문의 내역'
        },
    ];

/*    const clickRefundAccountClosButton = e => {
        e.preventDefault();
        setIsProcessing(false);
        if (!refundInfo) {
            setBank('');
            setBankCode('');
            setHolder('');
            setAccount('');
        } else {
            setBankCode(refundInfo.bankCode);
            setBank(refundInfo.bank);
            setHolder(refundInfo.accountHolder);
            setAccount(refundInfo.accountNumber);
        }

        setIsDisplayRefundAccountPopup(false)
    }
    const openRefundAccountPopup = e => {
        e.preventDefault();
        setIsDisplayRefundAccountPopup(true)
    }
    const clickRefundAccountConfirm = async e => {
        e.preventDefault();
        if (isProcessing) {
            return;
        }

        setIsProcessing(true);

        if (!bankCode) {
            openAlertPopup('은행을 입력해 주세요.');
            setIsProcessing(false);
            return;
        }

        if (!account) {
            openAlertPopup('계좌번호를 입력해 주세요.');
            setIsProcessing(false);
            return;
        }

        if (!isValidAccountNumber(account) || account.includes('--')) {
            openAlertPopup('계좌번호를 확인해 주세요.');
            setIsProcessing(false);
            return;
        }

        if (!holder) {
            openAlertPopup('예금주를 입력해 주세요.');
            setIsProcessing(false);
            return;
        }

        if(!isCheckedTerms) {
            openAlertPopup('개인정보 수집 및 이용에 동의해주세요.');
            setIsProcessing(false);
            return;
        }

        const params = { accountHolder: holder, bank: bank, accountNumber: account, bankCode: bankCode };

        let result = null;

        if (!refundInfo) {
            result = await MemberService.registRefundAccount(params);
        } else {
            result = await MemberService.updateRefundAccount(params);
        }

        setIsProcessing(false);

        if (result.status === 200) {
            //getRefundInfo();
            //setIsDisplayRefundAccountPopup(false);
            openAlertPopup('환불계좌 정보가 저장되었습니다.')
        } else {
            openAlertPopup(result.data.message)
        }
    }

    const getRefundInfo = async _ => {
        const _refundInfo = await MemberService.getRefundAccountInfo();
        setRefundInfo(_refundInfo);
        if (_refundInfo) {
            setBankCode(_refundInfo.bankCode);
            setBank(_refundInfo.bank);
            setHolder(_refundInfo.accountHolder);
            setAccount(_refundInfo.accountNumber);
        }
    }*/

/*    const closeMembershipNumberPopup = e => {
        e.preventDefault()
        setIsDisplaymembershipNumberPopup(false)
    }*/

/*    const changeAccount = e => setAccount(e.currentTarget.value.replace(/^[-]|[^0-9-]/gi, ""));
    const changeHolder = e => setHolder(e.currentTarget.value);
    const changeCheckTerms = e => setIsCheckedTerms(e.currentTarget.checked);*/

    const setInfo = async () => {
        if (location.pathname.includes('request')) {
            setSelectedTab('inquiry');
        } else if (location.pathname.includes('ticket')) {
            setSelectedTab('ticket list');
        } else if (location.pathname.includes('youth modify')) {
            setSelectedTab('youth modify');
        }

        setMemberInfo(await MemberService.getMemberInfo());
        //getRefundInfo();
        //const _bankCodeList = await getBankCodeList();
        //setBankCodeList(_bankCodeList);
    }
    const changePwd = e => setPwd(e.currentTarget.value);

    const changeTab = e => {
        e.preventDefault();
        setSelectedTab(e.currentTarget.getAttribute('data-key'));
        history.push({
            pathname: `/member/mypage/`
        });
    }
    const changeParams = e => {
        const key = e.target.children[e.target.selectedIndex].getAttribute('data-key');
        setSelectedTab(key);
    }

    // const changeBankCode = (key, value) => {
    //     setBankCode(key);
    //     setBank(value);
    // }

    const clickPopupCloseButton = e => {
        e.preventDefault();
        history.goBack();
    }

    const checkPwd = async e => {
        e.preventDefault();
        if (isProcessing) {
            return;
        }
        setIsProcessing(true);
        const result = await MemberService.confirmPwd({ confirmPwd: pwd });
        setIsProcessing(false);

        if (result.status === 200) {
            setIsCheckedPwd(true);
        } else {
            openAlertPopup(result.data.message);
        }

    }

    const alertPopupClose = e => {
        e.preventDefault();
        setAlertMessage('');
        setIsAlertPopupDisplay(false);
    }

    const openAlertPopup = (message) => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }

    const setYouthInfo = async () => {
        setYouthMemberInfo(await getYouthMemberInfo(memberInfo.memberSq));
    }

/*    const pageRender = e => {
        e.preventDefault();
        const url = e.currentTarget.dataset.url;
        if (url) {
            history.push(url);
        }
    }*/

    useEffect(() => {
        setInfo()
    }, []);

    useEffect(() => {
        if(memberInfo != null){
            setYouthInfo();
        }
    }, [memberInfo]);

/*    useEffect(_ => {
        // window.ntmEvent();
    }, [])*/

    return (
        <div className={`contents`} style={{paddingBottom: 0}}>
            <div className={`con-header`}>
                <h2>마이페이지</h2>
                <p>홈페이지 이용에 필요한 관리 및 내역을 확인할 수 있습니다.</p>
            </div>
            {
                isCheckedPwd ?
                    <>
                        {
                            memberInfo ?
                                <div className={`con-box`}>
                                    <ul className={`mypage-summary`}>
                                        <li className={`name`}>
                                            <h4><span className={`font600`}>{memberInfo.name}</span> 님<br/>방문을 환영합니다.</h4>
                                        </li>
                                   {/*     {<li>
                                            <h6>등급</h6>
                                            <p className={`font-red`}>{memberInfo.grade}</p>
                                        </li>
                                        <li>
                                            <h6>포인트</h6>
                                            <p>{getNumberWithComma(memberInfo.point)} P</p>
                                        </li>}*/}
                                    </ul>
                                    {/*{<ul className={`mypage-num`}>
                                        <li>
                                            <h6><i className={`ic-member-32`}/>멤버십 번호</h6>
                                            <p>
                                                {`${memberInfo.membershipNumber.substring(0, 4)}
                                                -${memberInfo.membershipNumber.substring(4, 8)}
                                                -${memberInfo.membershipNumber.substring(8, 12)}
                                                -${memberInfo.membershipNumber.substring(12, 16)}`}
                                            </p>
                                        </li>
                                        {
                                            refundInfo ?
                                                <li>
                                                    <h6><i className={`ic-refund-32`}/>환불계좌 정보</h6>
                                                    <p className={'cursor-link'} onClick={openRefundAccountPopup}> {`${refundInfo.bank} ${refundInfo.accountNumber}`} <span>{`${refundInfo.accountHolder}`}</span></p>
                                                </li>
                                                :
                                                <li>
                                                    <h6><i className={`ic-refund-32`}/>환불계좌 정보</h6>
                                                    <p className={'cursor-link'} onClick={openRefundAccountPopup}>환불계좌를 등록해주세요</p>
                                                </li>
                                        }
                                    </ul>}*/}
                                </div>
                                : null
                        }
                        <div className={`mypage-wrap`}>
                            <ul className={`tab-style01`}>
                                {
                                    tabList.map((item, index) =>
                                        item.key === 'youth modify' ?
                                            memberInfo && memberInfo.isYouth == false ?
                                                null
                                                : <li key={`mypage-tab-list-${index}`} data-key={item.key} className={selectedTab === item.key ? 'active' : ''} onClick={changeTab}>{item.value}</li>
                                            :
                                        <li key={`mypage-tab-list-${index}`} data-key={item.key} className={selectedTab === item.key ? 'active' : ''} onClick={changeTab}>{item.value}</li>
                                    )
                                }
                            </ul>
                            {
                                selectedTab === 'modify' && memberInfo ? <PrivateInfoComponent {...props} info={memberInfo} setInfo={setInfo} /> : null
                            }
                            {
                                selectedTab === 'youth modify' && memberInfo ? <YouthPrivateInfoComponent {...props} info={youthMemberInfo} /> : null
                            }
                            {
                                selectedTab === 'inquiry' ? <RequestListComponent  {...props} /> : null
                            }

  {/*                          <RefundAccountComponent
                                isDisplay={isDisplayRefundAccountPopup}
                                clickCloseButton={clickRefundAccountClosButton}
                                clickConfirmButton={clickRefundAccountConfirm}
                                account={account}
                                changeAccount={changeAccount}
                                changeCheckTerms={changeCheckTerms}
                                holder={holder}
                                changeHolder={changeHolder}
                                bankCodeList={bankCodeList}
                                changeBankCode={changeBankCode}
                                bankCode={bankCode}
                            />*/}
                            <AlertComponent
                                isDisplay={isAlertPopupDisplay}
                                clickCloseButton={alertPopupClose}
                                content={alertMessage}
                            />
{/*                            {
                                memberInfo && memberInfo.membershipNumber ?
                                    <MembershipNumberComponent
                                        isDisplay={isDisplaymembershipNumberPopup}
                                        membershipNumber={memberInfo.membershipNumber}
                                        clickCloseButton={closeMembershipNumberPopup}
                                    />
                                    : null
                            }*/}
                        </div>
                    </>
                    :
                    <PasswordComponent
                        pwd={pwd}
                        clickCloseButton={clickPopupCloseButton}
                        clickConfirmButton={checkPwd}
                        changePwd={changePwd}
                    />
            }
        </div>

    );
}

export default MyPageComponent;