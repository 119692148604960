import React, {useEffect} from 'react';
import MeasurementRecordComponent from "./MeasurementRecordComponent";

const MeasurementRecordContainer = props => {

    useEffect(() => {
        console.log('MeasurementRecordContainer::componentDidMount');

        return function cleanup() {
            console.log('MeasurementRecordContainer::componentWillUnmount');
        }
    }, []);

    return(
        <MeasurementRecordComponent
            {...props}
        />
    );
}
export default MeasurementRecordContainer;