import React from 'react';
import PropTypes from 'prop-types';

const ConfirmComponent = props => {
    const { isDisplay, content, clickOkButton, clickNoButton } = props;

    return (
        <div className={`popup`} style={{ display: isDisplay ? 'block' : 'none' }}>
            <div className={`pop-wrap`}>
                <div className={`pop-tit`}>
                    <i className={`ic-pop-close-24`} onClick={clickNoButton} />
                </div>
                <div className={`pop-con`}>
                    {content.split('\n').map((line, idx) => (<p key={`terms-${idx}`}>{line}<br /></p>))}
                </div>
                <div className={`pop-btn`}>
                    <button className={`light-pb`} onClick={clickNoButton}>취소</button>
                    <button className={`pb`} onClick={clickOkButton}>확인</button>
                </div>
            </div>
        </div>
    );
}


ConfirmComponent.propTypes = {
  isDisplay: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  clickOkButton: PropTypes.func.isRequired,
  clickNoButton: PropTypes.func.isRequired,
};

export default ConfirmComponent;