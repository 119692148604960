import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout, MainLayout } from './commons';
import ErrorBoundary from './ErrorBoundary'

import {
    Main as MainPage, // 메인
    Preparing as PreparingPage, // 준비중
    NotFound as NotFoundPage, // 404

    Login as LoginPage, // 로그인

    PrivacyTerms as PrivacyTermsPage, //개인정보처리방침
    UsageTerms as UsageTermsPage, //운영제도
    Rule as RulePage, //운영제도
    IntroGreeting as IntroGreetingPage, //찾아오시는 길

    MyPage as MyPage, // 마이페이지
    FavoritePlayerList as FavoritePlayerListPage,

    YouthKblYouth as YouthKblYouthPage,

    YouthBusiness as YouthBusinessPage,

    YouthClub as YouthClubPage,
    YouthClubInfo as YouthClubInfoPage,

    YouthGame as YouthGamePage,
    YouthGameDetail as YouthGameDetailPage,
    YouthPlayerRecord as YouthPlayerRecordPage,

    YouthMeasurementIntro as YouthMeasurementIntroPage,
    YouthMeasurementRecord as YouthMeasurementRecordPage,
    YouthMeasurementPercent as YouthMeasurementPercentPage,
    YouthMeasurementMember as YouthMeasurementMemberPage,
    YouthMeasurementListManage as YouthMeasurementListManagePage,
    YouthMeasurementViewManage as YouthMeasurementViewManagePage,

    YouthNoticeList as YouthNoticeListPage,
    YouthNoticeView as YouthNoticeViewPage,
    YouthNewsList as YouthNewsListPage,
    YouthNewsView as YouthNewsViewPage,
    YouthInquiry as YouthInquiryPage,

    YouthVideoList as YouthVideoListPage,
    YouthEduList as YouthEduListPage,
    YouthEduView as YouthEduViewPage,
    YouthPhotoList as YouthPhotoListPage,
    YouthPhotoView as YouthPhotoViewPage,

    YouthRegist as YouthRegistPage,
} from './pages';

const Routes = (props) => {
    return (
        <ErrorBoundary>
            <Switch>
                {/* 기존앱에서 들어오면 신규페이지로 이동 */}
                <RouteWithLayout
                    exact
                    component={MainPage}
                    layout={MainLayout}
                    path='/default_app.asp'
                />
                {/* 기존앱에서 들어오면 신규페이지로 이동 */}
                <RouteWithLayout
                    exact
                    component={MainPage}
                    layout={MainLayout}
                    path='/Default.aspx'
                />

                <RouteWithLayout
                    exact
                    component={MainPage} //메인
                    layout={MainLayout}
                    path='/'
                />
                <RouteWithLayout
                    component={PreparingPage} //준비중
                    exact
                    layout={MainLayout}
                    path='/preparing'
                />
                <RouteWithLayout
                    component={NotFoundPage} //404
                    exact
                    path="/not-found"
                />

                <RouteWithLayout
                    component={LoginPage} //로그인
                    exact
                    layout={MainLayout}
                    path="/auth/login"
                />
                <RouteWithLayout
                    component={MyPage} //마이페이지
                    exact
                    layout={MainLayout}
                    path="/member/mypage"
                />
                <RouteWithLayout
                    component={MyPage}
                    exact
                    layout={MainLayout}
                    path="/member/mypage/request/:id"
                />
                <RouteWithLayout
                    component={MyPage}
                    exact
                    layout={MainLayout}
                    path="/member/mypage/request"
                />
                <RouteWithLayout
                    component={MyPage}
                    exact
                    layout={MainLayout}
                    path="/member/mypage/ticket"
                />
                <RouteWithLayout
                    component={FavoritePlayerListPage}
                    exact
                    layout={MainLayout}
                    path="/member/mypage/player"
                />

                <RouteWithLayout
                    component={PrivacyTermsPage} //개인정보처리방침
                    exact
                    layout={MainLayout}
                    path='/terms/privacy' />
                <RouteWithLayout
                    component={UsageTermsPage} //이용약관
                    exact
                    layout={MainLayout}
                    path='/terms/usage' />
                <RouteWithLayout
                    component={RulePage} //운영제도
                    exact
                    layout={MainLayout}
                    path="/story/rule"
                />
                <RouteWithLayout
                    component={IntroGreetingPage} //찾아오시는 길
                    exact
                    layout={MainLayout}
                    path='/intro/greeting'
                />

                {/* KBL YOUTH */}
                <RouteWithLayout
                    component={YouthKblYouthPage}
                    exact
                    layout={MainLayout}
                    path="/youth/kblyouth"
                />

                {/* 유소년 사업 */}
                <RouteWithLayout
                    component={YouthBusinessPage}
                    exact
                    layout={MainLayout}
                    path="/youth/business"
                />

                {/* 클럽 소개 */}
                <RouteWithLayout
                    component={YouthClubPage}
                    exact
                    layout={MainLayout}
                    path="/youth/club"
                />
                <RouteWithLayout
                    component={YouthClubInfoPage}
                    exact
                    layout={MainLayout}
                    path="/youth/club/info/:id" />

                {/* 유소년 대회 */}
                <RouteWithLayout
                    component={YouthGamePage}
                    exact
                    layout={MainLayout}
                    path="/youth/game/:gameType" />
                <RouteWithLayout
                    component={YouthGameDetailPage}
                    exact
                    layout={MainLayout}
                    path="/youth/game/:gameType/:gameCode" />
                <RouteWithLayout
                    component={YouthPlayerRecordPage}
                    exact
                    layout={MainLayout}
                    path="/youth/player/:id" />

                {/* 측정데이터 */}
                <RouteWithLayout
                    component={YouthMeasurementIntroPage}
                    exact
                    layout={MainLayout}
                    path="/youth/measurement/intro"
                />
                <RouteWithLayout
                    component={YouthMeasurementRecordPage}
                    exact
                    layout={MainLayout}
                    path="/youth/measurement/record"
                />
                <RouteWithLayout
                    component={YouthMeasurementPercentPage}
                    exact
                    layout={MainLayout}
                    path="/youth/measurement/percent"
                />
                <RouteWithLayout
                    component={YouthMeasurementMemberPage}
                    exact
                    layout={MainLayout}
                    path="/youth/measurement/member"
                />
                <RouteWithLayout
                    component={YouthMeasurementListManagePage}
                    exact
                    layout={MainLayout}
                    path="/youth/measurement/member/manage"
                />
                <RouteWithLayout
                    component={YouthMeasurementViewManagePage}
                    exact
                    layout={MainLayout}
                    path="/youth/measurement/member/manage/:memberSq"
                />

                {/* 커뮤니티 */}
                <RouteWithLayout
                    component={YouthNoticeListPage}
                    exact
                    layout={MainLayout}
                    path="/youth/community/notice"
                />
                <RouteWithLayout
                    component={YouthNoticeViewPage}
                    exact
                    layout={MainLayout}
                    path="/youth/community/notice/:id"
                />
                <RouteWithLayout
                    component={YouthNewsListPage}
                    exact
                    layout={MainLayout}
                    path="/youth/community/news"
                />
                <RouteWithLayout
                    component={YouthNewsViewPage}
                    exact
                    layout={MainLayout}
                    path="/youth/community/news/:id"
                />
                <RouteWithLayout
                    component={YouthInquiryPage}
                    exact
                    layout={MainLayout}
                    path="/youth/community/inquiry"
                />

                {/* 자료실 */}
                <RouteWithLayout
                    component={YouthVideoListPage}
                    exact
                    layout={MainLayout}
                    path="/youth/contents/video"
                />
                <RouteWithLayout
                    component={YouthVideoListPage}
                    exact
                    layout={MainLayout}
                    path="/youth/contents/video/:id"
                />
                <RouteWithLayout
                    component={YouthEduListPage}
                    exact
                    layout={MainLayout}
                    path="/youth/contents/edu"
                />
                <RouteWithLayout
                    component={YouthEduViewPage}
                    exact
                    layout={MainLayout}
                    path="/youth/contents/edu/:id"
                />
                <RouteWithLayout
                    component={YouthPhotoListPage}
                    exact
                    layout={MainLayout}
                    path="/youth/contents/photo"
                />
                <RouteWithLayout
                    component={YouthPhotoViewPage}
                    exact
                    layout={MainLayout}
                    path="/youth/contents/photo/:id"
                />

                {/* 유소년등록 */}
                <RouteWithLayout
                    component={YouthRegistPage}
                    exact
                    layout={MainLayout}
                    path="/youth/member/regist"
                />

              <Redirect to="/not-found" />
            </Switch>
        </ErrorBoundary>
    );
};

export default Routes;
