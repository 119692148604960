import React, { useState, useEffect } from 'react';
import FavoritePlayerListComponent from './FavoritePlayerListComponent'
import { getList as getPlayerList } from '../../../../service/playerStaff/Player.service'
import { getTeamCodeByDomain } from '../../../../helpers/commonHelper'
import * as Hangul from 'hangul-js';

const FavoritePlayerListContainer = props => {
  const domainTeamCode = getTeamCodeByDomain();
  const [playerList, setPlayerList] = useState(null);
  const [displayList, setDisplayList] = useState(null);
  const [searchOption, setSearchOption] = useState('active');
  const [teamCode, setTeamCode] = useState(null);
  const [playerFlag, setPlayerFlag] = useState(null);
  const [keyword, setKeyword] = useState('');
  const hangulFilterList = [
    {
      key: 'ㄱ',
      value: '가'
    },
    {
      key: 'ㄴ',
      value: '나'
    },
    {
      key: 'ㄷ',
      value: '다'
    },
    {
      key: 'ㄹ',
      value: '라'
    },
    {
      key: 'ㅁ',
      value: '마'
    },
    {
      key: 'ㅂ',
      value: '바'
    },
    {
      key: 'ㅅ',
      value: '사'
    },
    {
      key: 'ㅇ',
      value: '아'
    },
    {
      key: 'ㅈ',
      value: '자'
    },
    {
      key: 'ㅊ',
      value: '차'
    },
    {
      key: 'ㅋ',
      value: '카'
    },
    {
      key: 'ㅌ',
      value: '타'
    },
    {
      key: 'ㅍ',
      value: '파'
    },
    {
      key: 'ㅎ',
      value: '하'
    },
    {
      key: 'etc',
      value: '기타'
    },
  ];


  const clickSearch = _ => {
    const result = [];
    if (playerList) {
      playerList.map(item => {
        if (Hangul.search(item.pname, keyword) >= 0) {
          result.push(item);
        }
      })
    }

    setDisplayList(setDatalist(result));
  }

  const changeKeyword = e => {
    e.preventDefault();
    setKeyword(e.currentTarget.value)
  }

  const setDatalist = list => {
    if (!list) {
      return null
    }

    const result = Object.assign(hangulFilterList)

    list.map(item => {
      const choSeong = Hangul.disassemble(item.pname)[0];
      const choSeongGroup = result.find(item => item.key === choSeong);
      if (choSeongGroup) {
        if (!choSeongGroup.list) {
          choSeongGroup.list = [item];
        } else {
          choSeongGroup.list.push(item);
        }
      } else {
        const ectGroup = result.find(item => item.key === 'etc');
        if (!ectGroup.list) {
          ectGroup.list = [item];
        } else {
          ectGroup.list.push(item);
        }
      }
    });

    return result;
  }

  const changeFilters = (type, key, value) => {
    const params = {};
    if (type === 'searchOption') {
      setSearchOption(key);
      params.searchOption = key;
      params.playerFlag = playerFlag;
      params.tcodeList = teamCode === 'XX' || teamCode === 'YH' ? 'all' : teamCode;
    } else if (type === 'teamCode') {
      setTeamCode(key);
      params.searchOption = searchOption;
      params.playerFlag = playerFlag;
      params.tcodeList = key === 'XX' || teamCode === 'YH' ? 'all' : key;
    }
    setKeyword('');
    getPlayerDataList(params);
  }

  const getPlayerDataList = async params => {
    if (params.searchOption === 'active' && params.tcodeList === 'all') {
      params.tcodeList = '06,10,16,30,35,50,55,60,64,70,66' //73 캐롯 제외, 66 소노 추가 (231020)
    }
    // 임시 삼성만 제외
    const _playerList = await getPlayerList(params);
    setPlayerList(_playerList);
    setDisplayList(setDatalist(_playerList));
  }

  useEffect(_ => {
    console.log('PlayerListContainer::componentDidMount');
    setTeamCode(domainTeamCode)

    getPlayerDataList({ searchOption: searchOption, playerFlag: playerFlag, tcodeList: domainTeamCode === 'XX' || domainTeamCode === 'YH' ? 'all' : domainTeamCode });

    return function cleanup() {
      console.log('PlayerListContainer::componentWillUnmount');
      window.scrollTo(0, 0);
    }
  }, [])
  return (

    <FavoritePlayerListComponent
      {...props}
      domainTeamCode={domainTeamCode}
      displayList={displayList}
      searchOption={searchOption}
      teamCode={teamCode}
      playerFlag={playerFlag}
      keyword={keyword}
      changeKeyword={changeKeyword}
      clickSearch={clickSearch}
      changeFilters={changeFilters}
    />

  );
}

export default FavoritePlayerListContainer;