import { combineReducers } from 'redux';

import {
    auth as commonAuth,
    layout as commonLayout,
} from './common'

import {
    bbs as youthBbs,
    branch as youthBranch,
    content as youthContent,
} from './youth'

import {
    mypage as memberMypage
} from './member'

import {
    rule as storyRule,
} from './story'

import {
    main as mainPage,
} from './main'

const rootReducer = combineReducers({
    mainPage,

    commonAuth,
    commonLayout,

    memberMypage,

    storyRule,

    youthContent,
    youthBbs,
    youthBranch,
});

export default rootReducer;