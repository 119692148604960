import React, {useEffect, useState} from 'react';
import { getSimpleDateFormat } from '../../../helpers/dateHelper'
import {updateYouthViewCount} from "../../../service/media/Media.service";
import EmptyContentComponent from "./EmptyContentComponent";

const YouthBBSTableComponent = props => {
    const { tableClass, hasHeader = true, history, list, startIndex, type, pagingData } = props;
    const [isDisplayNo, setIsDisplayNo] = useState(true);

    const teamNameList = [
        {key: '16',   value: 'DB'},
        {key: '35',   value: '삼성'},
        {key: '55',   value: 'SK'},
        {key: '50',   value: 'LG'},
        {key: '66',   value: '소노'},
        {key: '60',   value: 'KCC'},
        {key: '70',   value: '정관장'},
        {key: '06',   value: 'KT'},
        {key: '64',   value: '한국가스공사'},
        {key: '10',   value: '모비스'}
    ]
    function getTeamName(teamCode){
        let teamName = '';

        if(teamCode !== '' && teamCode != null){
            teamName = `[${teamNameList.find((item) => item.key == teamCode).value}] `
        }

        return teamName;
    }
    const detailPage = e => {
        e.preventDefault();
        const isOutlink = e.currentTarget.dataset.isOutlink;
        if (isOutlink === 'true') {
            updateYouthViewCount(e.currentTarget.getAttribute('data-id'))
            window.open(e.currentTarget.dataset.outlinkUrl, '_blank', `top=100,left=350 , scrollbars=1`)
        } else {
            history.push({
                pathname: `${history.location.pathname}/${e.currentTarget.getAttribute('data-id')}`,
                state: {
                    from: history.location.pathname,
                    contentIdx: e.currentTarget.getAttribute('data-index') ? e.currentTarget.getAttribute('data-index') : null,
                    type: type ? type : null
                }
            });
        }
    }

    useEffect(() => {
        if(list && list.length > 0) {
            if(list[0].boardCode === 'NOTICE') {
                setIsDisplayNo(false)
            } else {
                setIsDisplayNo(true)
            }
        }
    }, [list])

    return (
        <>
            <div className={`desktop-only`}>
                <table className={`table-style01 ${tableClass}`}>
                    <colgroup>
                        {
                            isDisplayNo &&
                            <col style={{width: '10%'}} />
                        }
                        <col style={{width: 'auto'}} />
                        <col style={{width: '15%'}} />
                        <col style={{width: '10%'}} />
                    </colgroup>
                    <thead>
                    {
                        hasHeader ?
                            <tr>
                                {
                                    isDisplayNo &&
                                    <th>번호</th>
                                }
                                <th>제목</th>
                                <th>작성일</th>
                                <th>조회수</th>
                            </tr>
                            : null
                    }
                    </thead>
                    <tbody>
                    {
                        !list || list.length < 1 ?
                            <tr>
                                <td colSpan={isDisplayNo ? 4 : 3}>
                                    <EmptyContentComponent />
                                </td>
                            </tr>
                            :
                            hasHeader ?
                                list.map((item, index) =>
                                    <tr key={`bbs-list-${index}`}>
                                        {
                                            isDisplayNo &&
                                            <td>
                                                {
                                                    pagingData && pagingData.totalCount
                                                        ? (startIndex ? startIndex === 0 ? pagingData.totalCount : pagingData.totalCount - startIndex : pagingData.totalCount) - index
                                                        : item.artcSq
                                                }
                                            </td>
                                        }
                                        <td className={`align-left`}>
                                            <p className={`title-ellipsis`} onClick={detailPage} data-id={item.artcSq} data-index={Number(startIndex) + index} data-is-outlink={item.isOutLink} data-outlink-url={item.link} style={{ cursor: 'pointer' }}>
                                                {!isDisplayNo ? getTeamName(item.teamCode) : ''}{item.artcTitle}
                                            </p>
                                        </td>
                                        <td className={`font-gr`}>{getSimpleDateFormat(item.regDttm)}</td>
                                        <td className={`font-gr`}>{item.viewCnt}</td>
                                    </tr>
                                )
                                :
                                list.map((item, index) =>
                                    <tr key={`bbs-list-${index}`}>
                                        {
                                            isDisplayNo &&
                                            <td>
                                                {
                                                    pagingData && pagingData.totalCount
                                                        ? (startIndex ? startIndex === 0 ? pagingData.totalCount : pagingData.totalCount - startIndex : pagingData.totalCount) - index
                                                        : item.artcSq
                                                }
                                            </td>
                                        }
                                        <td className={`align-left`}>
                                            <p className={`title-ellipsis`} onClick={detailPage} data-id={item.artcSq} data-index={Number(startIndex) + index} data-is-outlink={item.isOutLink} data-outlink-url={item.link}>
                                                {item.artcTitle}
                                            </p>
                                        </td>
                                        <td className={`font-gr`}>{getSimpleDateFormat(item.regDttm)}</td>
                                        <td className={`font-gr`}>{item.viewCnt}</td>
                                    </tr>
                                )
                    }
                    </tbody>
                </table>
            </div>
            <div className={`mobile-only`}>
                <table className={`table-style01`}>
                    <colgroup>
                        <col style={{width: '25%'}} />
                        <col style={{width: 'auto'}} />
                    </colgroup>
                    <thead>
                    {
                        hasHeader ?
                            <tr>
                                {
                                    isDisplayNo &&
                                    <th>번호</th>
                                }
                                <th>제목</th>
                            </tr>
                            : null
                    }
                    </thead>
                    <tbody>
                    {
                        !list || list.length < 1 ?
                            <tr>
                                <td colSpan={isDisplayNo ? 2 : 1}>
                                    <EmptyContentComponent />
                                </td>
                            </tr>
                            :
                            hasHeader ?
                                list.map((item, index) =>
                                    <tr key={`bbs-list-${index}`}>
                                        {
                                            isDisplayNo &&
                                            <td>
                                                {
                                                    pagingData && pagingData.totalCount
                                                        ? (startIndex && startIndex === 0 ? pagingData.totalCount - startIndex : pagingData.totalCount) - index
                                                        : item.artcSq
                                                }
                                            </td>
                                        }
                                        <td className={`align-left`}>
                                            <p className={`title-ellipsis`} style={{whiteSpace: isDisplayNo ? '' : 'break-spaces', width: isDisplayNo ? '' : 'fit-content'}} onClick={detailPage} data-id={item.artcSq} data-index={Number(startIndex) + index} data-is-outlink={item.isOutLink} data-outlink-url={item.link}>
                                                {!isDisplayNo ? getTeamName(item.teamCode) : ''}{item.artcTitle}
                                            </p>
                                            <ul className={`flex-txt`}>
                                                <li><h6>등록일</h6><p>{getSimpleDateFormat(item.regDttm)}</p></li>
                                                <li><h6>조회수</h6><p>{item.viewCnt}</p></li>
                                            </ul>
                                        </td>
                                    </tr>
                                )
                                :
                                list.map((item, index) =>
                                    <tr key={`bbs-list-${index}`}>
                                        {
                                            isDisplayNo &&
                                            <td>
                                                {
                                                    pagingData && pagingData.totalCount
                                                        ? (startIndex && startIndex === 0 ? pagingData.totalCount - startIndex : pagingData.totalCount) - index
                                                        : item.artcSq
                                                }
                                            </td>
                                        }
                                        <td className={`align-left`}>
                                            <p className={`title-ellipsis`} onClick={detailPage} data-id={item.artcSq} data-index={Number(startIndex) + index} data-is-outlink={item.isOutLink} data-outlink-url={item.link}>
                                                {item.artcTitle}
                                            </p>
                                            <ul className={`flex-txt`}>
                                                <li><h6>등록일</h6><p>{getSimpleDateFormat(item.regDttm)}</p></li>
                                                <li><h6>조회수</h6><p>{item.viewCnt}</p></li>
                                            </ul>
                                        </td>
                                    </tr>
                                )
                    }
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default YouthBBSTableComponent;