import * as ApiService from '../Api.service'

const preFix = '/web';
export const getYouthGameList = async  (params = {}) => {
    const url = preFix + '/youth/matches';

    return ApiService.get(url, params);
}

export const getYouthGameTeamList = async  (params = {}) => {
    const url = preFix + '/youth/matches/teams';

    return ApiService.get(url, params);
}

export const getYouthGameTeamPlayerList = async  (params = {}) => {
    const url = preFix + '/youth/matches/teams/players';

    return ApiService.get(url, params);
}

export const getYouthGameCodeList = async  (params = {}) => {
    const url = preFix + '/youth/matches/codes';

    return ApiService.get(url, params);
}

export const getYouthTeamRankList = async  (params = {}) => {
    const url = preFix + '/youth/matches/teams/rank';

    return ApiService.get(url, params);
}

export const getYouthPlayerRankList = async  (params = {}) => {
    const url = preFix + '/youth/matches/players/rank';

    return ApiService.get(url, params);
}

export const getYouthGameScheduleList = async  (params = {}) => {
    const url = preFix + '/youth/matches/schedule';

    return ApiService.get(url, params);
}

export const getYouthGameDetail = async (gmkey) => {
    const url = preFix + `/youth/matches/${gmkey}`;

    return ApiService.get(url);
}

export const getYouthGameTeamRecords = async (gmkey) => {
    const url = preFix + `/youth/matches/${gmkey}/teams`;

    return ApiService.get(url);
}

export const getYouthGameTeamPlayerRecord = async (gmkey, params = {}) => {
    const url = preFix + `/youth/matches/${gmkey}/players`;

    return ApiService.get(url, params);
}