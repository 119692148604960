import React, { useState, useEffect } from 'react';
import '../../../style/reset.css'
import '../../../style/font.css'
import '../../../style/ico.css'
import '../../../style/common.css'
import '../../../style/style.css'
import '../../../style/youth.css'
import '../../../style/club.css'
import '../../../style/app.css'

const MaintenanceContainer = props => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        window.addEventListener("resize", resizeCallback);
        resizeCallback();

        return () => {
            window.removeEventListener("resize", resizeCallback);
        }
    }, [])

    const resizeCallback = () => {
        setIsMobile(window.innerWidth <= 768);
    }

    useEffect(_ => {
        // window.ntmEvent();
    }, [])

  return (
      <div id='container' className='kbl'>
          <div className={`contents`}>
              <div className={`con-box`}>
                  <div className={`board-view-wrap`} id={'board-wrap'}>
                      <div className={`board-con`}>
                          {/*<img src={`/imgs/kbl_notice.png`} />*/}
                          {
                              !isMobile
                                  ?  <img src={`/imgs/youth/youth_notice_pc.jpg`} />
                                  :  <img src={`/imgs/youth/youth_notice.jpg`} />
                          }
                      </div>
                  </div>
              </div>

              {/*<div className={`con-empty brand-shape`}>*/}
              {/*    <div className={`logo`} />*/}
              {/*    <div className={'notice'}>*/}
              {/*        <h6>KBL Web/App 서버 점검 안내</h6>*/}
              {/*        <p>*/}
              {/*            안정적인 서비스를 제공하기 위하여 <br className={`mobile-only`} /> 아래의 기간 동안 서버 점검 작업이 진행될 예정입니다.<br/>*/}
              {/*            작업기간 동안 모든 서비스가 일시적으로 중단되오니,<br className={`mobile-only`} /> 양해 부탁드립니다.<br/>*/}
              {/*        </p>*/}
              {/*        <p>*/}
              {/*            기간: 2022년 12월 19일(월) 오후 10시<br className={'mobile-only'}/> ~ 2022년 12월 20일(화) 오전 7시<br/>*/}
              {/*        </p>*/}
              {/*        <p>*/}
              {/*            더욱 편리하고 안정적인 서비스 제공을 위해<br className={`mobile-only`} /> 최선을 다하겠습니다.<br/>*/}
              {/*            감사합니다.<br/>*/}
              {/*        </p>*/}
              {/*    </div>*/}
              {/*</div>*/}
          </div>
      </div>
  );
}

export default MaintenanceContainer;