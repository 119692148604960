import React, {useState, useEffect} from 'react';

import FilterComponent from "../../../commons/filter/FilterComponent";
import {getSchoolList} from '../../../../service/youth/Signup.service';

const RegistYouthSchoolComponent = props => {
    const {memberType, schoolGrade, changeSchoolGrade, year, changeSchoolOpt, schoolOpt, setSchoolOpt, setSchool, changeSchoolNum, schoolNum, sidoOpt, setSidoOpt, changeSidoOpt, gugunOpt, setGugunOpt, changeGugunOpt} = props;

    const [allSchoolList, setAllSchoolList] = useState(null);
    const [allGugunList, setAllGugunList] = useState(null);

    const [schoolList, setSchoolList] = useState(null);

    const [sidoList, setSidoList] = useState(null);

    const [gugunList, setGugunList] = useState(null);

    const allSchoolListFilter = list => {
        //const result = [];
        const sido = [];
        const gugun = [];

        if(list){
            sido.push({key : "XX", value: "시/도"});
            gugun.push({key : "XX", value: "구/군"});
            list.map(item => {
                sido.push({key : item.sido, value: item.sido});
                gugun.push({key : item.sido, value: item.gugun});
            })

            const setSido = sido.filter(
                (fValue, index, self) =>
                    index === self.findIndex((e) => fValue.key == e.key && fValue.value == e.value)
            );
            const setGugun = gugun.filter(
                (fValue, index, self) =>
                    index === self.findIndex((e) => fValue.key == e.key && fValue.value == e.value)
            );

            setSidoList(setSido);
            setAllGugunList(setGugun);
            setSidoOpt(sidoOpt);
            setGugunOpt(gugunOpt);
            setSchoolOpt(schoolOpt);
        }
        return list;
    }
    function schoolKindFilter(list){
        const result = [];
        if(list){
            result.push({key : "XX", value: "학교명을 선택해 주세요."})
            const filtered = list.filter(item => item.kind == schoolGrade && item.sido == sidoOpt && item.gugun == gugunOpt);
            filtered.map(item => {
               result.push({key : item.no, value: item.school});
            });
            setSchoolList(result);
        }
    }
    function gugunFilter(list){
        const result = [];
        if(list){
            result.push({key : "XX", value: "구/군"})
            const filtered = list.filter(item => item.key == sidoOpt);
            filtered.map((item, index) => {
               result.push({key: item.value , value: item.value});
            });
            setGugunList(result);
        }
    }
    function schoolFilter(list){
        const result = [];
        if(list){
            const filtered = list.filter(item => item.key == schoolOpt);
            filtered.map(item => {
                result.push({key: item.key , value: item.value});
            });
        }
        return result;
    }

    useEffect(() => {
        const getAllSchoolList = async () => { //모든 학교 목록
            setAllSchoolList(allSchoolListFilter(await getSchoolList()));
        }
        getAllSchoolList();
    }, []);
    useEffect(() => {
        if(schoolOpt!='XX'){
            schoolKindFilter(allSchoolList);
        }
    }, [allSchoolList]);
    useEffect(() => {
        if(schoolOpt!='XX'){
            setSchool(schoolFilter(schoolList));
        }
    }, [schoolList]);
    useEffect(() => {
            setSchoolOpt("XX"); setSidoOpt("XX"); setGugunOpt("XX");
    }, [schoolGrade]);
    useEffect(() => {
        if(sidoOpt!="XX"){ gugunFilter(allGugunList); }
    }, [sidoOpt]);
    useEffect(() => {
        if(sidoOpt!="XX" && gugunOpt!="XX"){ schoolKindFilter(allSchoolList); }
    }, [gugunOpt]);
    useEffect(() => {
        if(sidoOpt!="XX" && gugunOpt!="XX" && schoolOpt!="XX"){ setSchool(schoolFilter(schoolList));}
    }, [schoolOpt]);

    return(
        <>
            <li>
                <h6>{memberType === 'E' &&<span className={`font-red`}>*</span>}학교</h6>
                <ul className={`flex-input`}>
                    <li><input type={`radio`} name={'s_grade'} id={'s_grade_1'} value={'1'} checked={schoolGrade === '1'} onChange={changeSchoolGrade}/><label htmlFor={'s_grade_1'}><span>초등학교</span></label></li>
                    <li><input type={`radio`} name={'s_grade'} id={'s_grade_2'} value={'2'} checked={schoolGrade === '2'} onChange={changeSchoolGrade}/><label htmlFor={'s_grade_2'}><span>중학교</span></label></li>
                    <li><input type={`radio`} name={'s_grade'} id={'s_grade_3'} value={'3'} checked={schoolGrade === '3'} onChange={changeSchoolGrade}/><label htmlFor={'s_grade_3'}><span>고등학교</span></label></li>
                </ul>
            </li>
            <li>
                <h6>{memberType === 'E' &&<span className={`font-red`}>*</span>}학교 정보</h6>
                <ul className={`academic-wrap`}>
                    <li>
                        <FilterComponent
                            selectedKey={sidoOpt}
                            selectList={sidoList}
                            selectChange={changeSidoOpt}
                        />
                    </li>
                    <li>
                        {
                            sidoOpt == "XX" ?
                                <select>
                                    <option>구/군</option>
                                </select>
                                :
                                <FilterComponent
                                    selectedKey={gugunOpt}
                                    selectList={gugunList}
                                    selectChange={changeGugunOpt}
                                />
                        }
                    </li>
                    <li>
                        {
                            (sidoOpt == "XX" || gugunOpt == "XX") ?
                                <select>
                                    <option>학교명을 선택해 주세요.</option>
                                </select>
                                :
                                <FilterComponent
                                    selectedKey={schoolOpt}
                                    selectList={schoolList}
                                    selectChange={changeSchoolOpt}
                                />
                        }

                    </li>
                </ul>
            </li>
            <li>
                <h6>{memberType === 'E' &&<span className={`font-red`}>*</span>}{year}</h6>
                {
                    schoolGrade === '1' ?
                        <select onChange={changeSchoolNum} value={schoolNum}>
                            <option>학년을 선택해 주세요.</option>
                            <option value={'1'}>1학년</option>
                            <option value={'2'}>2학년</option>
                            <option value={'3'}>3학년</option>
                            <option value={'4'}>4학년</option>
                            <option value={'5'}>5학년</option>
                            <option value={'6'}>6학년</option>
                        </select>
                        :
                        <select onChange={changeSchoolNum} value={schoolNum}>
                            <option>학년을 선택해 주세요.</option>
                            <option value={'1'}>1학년</option>
                            <option value={'2'}>2학년</option>
                            <option value={'3'}>3학년</option>
                        </select>
                }
            </li>
        </>
    );
}

export default RegistYouthSchoolComponent;