
const SET_IS_LOGIN = 'common_auth/SET_IS_LOGIN';
const SET_AUTH_USESR_INFO = 'common_auth/SET_AUTH_USESR_INFO';
//const CHECK_IS_LOGIN = 'common_auth/CHECK_IS_LOGIN';
const CHECK_IS_LOGIN_SUCCESS = 'common_auth/CHECK_IS_LOGIN_SUCCESS';



export const setIsLogin = value => ({ type: SET_IS_LOGIN, isLogin: value });
export const setAuthUserInfo = value => ({ type: SET_AUTH_USESR_INFO, userInfo: value });
//export const checkIsLogin = createPromiseThunk(CHECK_IS_LOGIN, MemberService.getRefundAccountInfo);

const initialState = {
  isLogin: false,
  userInfo: {},
};


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_LOGIN:
      return {
        ...state,
        isLogin: action.isLogin
      };
    case SET_AUTH_USESR_INFO:
      return {
        ...state,
        userInfo: action.userInfo
      };
    case CHECK_IS_LOGIN_SUCCESS:
      return {
        ...state,
        isLogin: action.payload ? true : false
      };

    default:
      return state;
  }
}