import React, { useEffect, useState } from 'react';
import FilterComponent from '../../../commons/filter/FilterComponent';
import AlertComponent from '../../../commons/popup/AlertComponent';
import { getTeamInfoSelectList } from '../../../../helpers/commonHelper';
import { isIE } from "react-device-detect";
import {useSelector} from "react-redux";
import {getMobileAppHeaderData} from "../../../../helpers/apiHelper";

const FavoritePlayerListComponent = props => {
    const { domainTeamCode, displayList, teamCode, keyword, changeKeyword, clickSearch, changeFilters } = props;

    const { isLogin } = useSelector(state => state.commonAuth);
    const [selectedGangul, setSelectedGangul] = useState('ㄱ');
    const [teamList, setTeamList] = useState(null);
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isPhotoType, setIsPhotoType] = useState(true);

/*    const searchOptionSelectList = [
        {
            key: 'active',
            value: '등록선수'
        },
        {
            key: 'inactive',
            value: '역대선수'
        },
        {
            key: 'army',
            value: '군입대'
        },
    ];
    const searchOptionSelectListEn = [
        {
            key: 'active',
            value: 'Registered players'
        },
        {
            key: 'inactive',
            value: 'Past players'
        },
        {
            key: 'army',
            value: 'Joined the military'
        },
    ];
    const playerFlagSelectList = [
        {
            key: '0',
            value: '국내선수'
        },
        {
            key: '1',
            value: '외국선수'
        },
        {
            key: '2',
            value: '아시아선수'
        },
    ];
    const playerFlagSelectListEn = [
        {
            key: '0',
            value: 'Domestic players'
        },
        {
            key: '1',
            value: 'Overseas player'
        },
        {
            key: '2',
            value: 'Asia player'
        },
    ];
    const changeSearchOption = (key, value) => changeFilters('searchOption', key, value);
    const changePlayerFlag = (key, value) => changeFilters('playerFlag', key, value);*/

    const changeTeamCode = (key, value) => changeFilters('teamCode', key, value);

    const clickSearchButton = e => {
        e.preventDefault()
        clickSearch();
    }

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }

    const alertPopupClose = e => {
        e.preventDefault();
        setAlertMessage('');
        setIsAlertPopupDisplay(false)
    }

    const clickHangul = e => {
        e.preventDefault();
        const key = e.currentTarget.dataset.key;
        const value = e.currentTarget.dataset.value;
        const targetEl = document.querySelector(`#hangul-${key}`);

        if (!targetEl) {
            openAlertPopup(`'${value}' 행 데이터가 없습니다.`);
            return;
        }

        setSelectedGangul(key);
        const parentOffset = targetEl.offsetParent.offsetTop - (document.querySelector(`header`) ? document.querySelector(`header`).offsetHeight : 0);

        if (isIE) {
            document.documentElement.scrollTop = parentOffset + targetEl.offsetTop;
        } else {
            window.scrollTo({ top: parentOffset + targetEl.offsetTop });
        }
    }

    const onPlayerClicked = e => {
        e.preventDefault();

        if (isLogin) {
            const sq = e.currentTarget.dataset.pcode;
            const name = e.currentTarget.dataset.name;
            const img = e.currentTarget.dataset.img;
            const teamName = e.currentTarget.dataset.tname;
            const teamCode = e.currentTarget.dataset.tcode;
            const mobileAppHeaerValues = getMobileAppHeaderData();
            if (mobileAppHeaerValues && mobileAppHeaerValues.device.toUpperCase() === 'ANDROID') {
                if (window.KblAndroid && window.KblAndroid.updateFavoritePlayer) {
                    window.KblAndroid.updateFavoritePlayer(sq, name, teamName);
                }
            } else if (mobileAppHeaerValues && mobileAppHeaerValues.device.toUpperCase() === 'IOS') {
                if (window.webkit
                  && window.webkit.messageHandlers
                  && window.webkit.messageHandlers.updateFavoritePlayer
                  && window.webkit.messageHandlers.updateFavoritePlayer.postMessage) {
                    window.webkit.messageHandlers.updateFavoritePlayer.postMessage({sq: sq, name: name, teamName: teamName});
                }
            } else {
                if (window.opener) {
                    window.opener.postMessage(
                      {
                          sq: sq,
                          name: name,
                          teamName: teamName,
                          teamCode: teamCode,
                          img: img
                      }, '*');
                    window.close();
                    return;
                }
            }
        }
        window.close();
    }

    const enterKeyPress = e => {
        if (e.key === 'Enter') {
            clickSearchButton(e);
        }
    }

    useEffect(() => {
        const getTeamList = async () => {
            const teamList = await getTeamInfoSelectList();
            setTeamList(teamList);
        }
        getTeamList();
    }, []);

    useEffect(_ => {
        // window.ntmEvent();
    }, [])

    return (
        <div className={`contents`}>
            <AlertComponent
                isDisplay={isAlertPopupDisplay}
                clickCloseButton={alertPopupClose}
                content={alertMessage}
            />
            <div className={`con-header`}>
                <h2>선수</h2>
                <p>선수 정보를 확인할 수 있습니다.</p>
            </div>
            <ul className={`filter-wrap`}>
                {
                    domainTeamCode === 'XX' || domainTeamCode === 'YH' ?
                        <li>
                            <h6>구단</h6>
                            <FilterComponent
                                selectedKey={teamCode}
                                selectList={teamList}
                                selectChange={changeTeamCode}
                            />
                        </li>
                        : null
                }
            </ul>
            <div className={`con-search`}>
                <ul className={`sort-wrap`}>
                    {
                        displayList
                            ? displayList.map((item, index) =>
                                item.list && item.list.length > 0 ?
                                    <li className={selectedGangul === item.key ? 'active' : ''}
                                        data-key={item.key}
                                        data-value={item.value}
                                        key={item.key}
                                        onClick={clickHangul}>{item.key}</li>
                                    : null
                            ) : null
                    }
                </ul>
                <div className={`search-input`}>
                    <input type='text' className='cs' placeholder={'선수명을 입력해주세요.'} value={keyword} onChange={changeKeyword} onKeyPress={enterKeyPress} />
                    <button onClick={clickSearchButton}><i className={`ic-search-20`} /></button>
                </div>
            </div>
            {
                displayList ?
                    displayList.map((item, index) =>
                        item.list ?
                            <div className={`con-box`} key={`player-${index}`} id={`hangul-${item.key}`}>
                                <div className={`con-tit`}>
                                    <h4>{item.key}</h4>
                                </div>
                                {
                                    isPhotoType ?
                                        <ul className={`player-list-wrap`} key={`player-${index}`} id={`hangul-${item.key}`}>
                                            {
                                                item.list.map((v, i) =>
                                                    <li
                                                        data-pcode={v.pcode}
                                                        data-name={v.pname}
                                                        data-img={`/files/kbl/players-photo/${v.pcode}.png`}
                                                        data-tname={v.tname}
                                                        data-tcode={v.tcode}
                                                        onClick={onPlayerClicked}>
                                                        <div className={`img`} style={{backgroundImage: `url(${(v.tcode === '16' || v.tcode === '55' || v.tcode === '06' || v.tcode === '70' || v.tcode === '60' || v.tcode === '64' || v.tcode === '35' || v.tcode === '50' || v.tcode === '10' || (v.tcode === '66' && v.pcode !== '291266' && v.pcode !== '291488' && v.pcode !== '290763' && v.pcode !== '291604')) ? `/files/kbl/players-photo/${v.pcode}.png` : '/imgs/player/img-player-no.png'}), linear-gradient(180deg, #E3E6ED 0%, #BAC0D4 100%)`}}></div>
                                                        {/*<div className={`img`} style={{backgroundImage: `url(/files/kbl/players-photo/${v.pcode}.png), linear-gradient(180deg, #E3E6ED 0%, #BAC0D4 100%)`}}></div>*/}
                                                        <div className={`txt`}>
                                                            <h6>{v.pname}</h6>
                                                            <span>{v.tname}</span>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                        :
                                        <ul className={`player-list-wrap`} key={`player-${index}`} id={`hangul-${item.key}`}>
                                            {
                                                item.list.map((v, i) =>
                                                    <li
                                                        data-pcode={v.pcode}
                                                        data-name={v.pname}
                                                        data-img={`/files/kbl/players-photo/${v.pcode}.png`}
                                                        data-tname={v.tname}
                                                        data-tcode={v.tcode}
                                                        onClick={onPlayerClicked}>
                                                        <div className={`txt`}>
                                                            <h6>{v.pname}</h6>
                                                            <span>{v.tname}</span>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                }
                            </div>
                            : null
                    )
                    : null
            }
        </div>
    );
}

export default FavoritePlayerListComponent;