import React from 'react';
import {isEmailAddress} from "../../../../../helpers/stringHelper";

const YouthMemberSearchComponent = props => {
    const {setEmail, setIsValidEmail, setIsDisplaySearchMemberPopup, searchMember, setName, setPrevId} = props;

    const changeEmail = e => {
        const value = e.currentTarget.value;
        setIsValidEmail(isEmailAddress(value));
        setEmail(value);
    }

    const changeName = e => {
        const value = e.currentTarget.value;
        setName(value);
    }

    const changeId = e => {
        const value = e.currentTarget.value;
        setPrevId(value);
    }

    return(
        <div className={`pop-wrap`}>
            <div className={`pop-tit`}>
                <h4>기존 회원 계정 등록</h4>
                <i className={`ic-pop-close-24`} onClick={() => {setIsDisplaySearchMemberPopup(false)}} />
            </div>
            <div className={`pop-con`}>
                <div className={`pop-box`}>
                    <div className={`pop-box-tit`}>
                        <h6>아이디 찾기</h6>
                    </div>
                    <span>기존 유소년 홈페이지 가입시 기재한 <br/> 이름 및 계정/이메일(두 개 중 선택)을 입력해주세요.</span>
                </div>
                <ul className={`info-input`}>
                    <li>
                        <h6>이름</h6>
                        <input type={`text`} placeholder={'이름을 입력해주세요'} onChange={changeName}/>
                    </li>
                    <li>
                        <h6>계정</h6>
                        <input type={`prevId`} placeholder={`기존 유소년 홈페이지의 아이디를 입력해 주세요.`} onChange={changeId} />
                    </li>
                    <li>
                        <h6>이메일</h6>
                        <input type={`email`} placeholder={`이메일 주소를 입력해 주세요.`} onChange={changeEmail} />
                    </li>
                </ul>
            </div>
            <div className={`pop-btn`}>
                <button className={`light-pb`} onClick={() => {setIsDisplaySearchMemberPopup(false)}}>취소</button>
                <button className={`pb`} onClick={searchMember}>확인</button>
            </div>
        </div>
    );
}

export default YouthMemberSearchComponent;