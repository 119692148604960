import React from 'react';
import FilterComponent from "../../../commons/filter/FilterComponent";
import EmptyContentComponent from "../../../commons/table/EmptyContentComponent";
import PaginationComponent from "../../../commons/table/PaginationComponent";

const MeasurementManageListComponent = (props) => {
  const { history, changeParams, list, pagingData, searchType, searchWord, changeSearchType, changeSearchWord, startIndex} = props;

  const clickSearch = e => changeParams('clickSearch', e);
  const changePage = e => changeParams('changePage', e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-value'));
  const moreView = e => changeParams('moreView', e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-value'));

  const enterKeyPress = e => {
    if (e.key === 'Enter') {
      changeParams('clickSearch', e);
    }
  }

  const searchTypeSelectList = [
    {
      key: 'name',
      value: '이름',
    },
    {
      key: 'school',
      value: '학교명',
    },
    // {
    //   key: 'id',
    //   value: '아이디',
    // },
  ]

  const detailPage = e => {
    e.preventDefault();
    history.push({
      pathname: `${history.location.pathname}/${e.currentTarget.getAttribute('data-id')}`,
      state: {
        from: history.location.pathname,
        memberSq: e.currentTarget.getAttribute('data-id') ? e.currentTarget.getAttribute('data-id') : null,
      }
    });
  }


  return (
      <div className={`contents`}>
        <div className={`con-header`}>
          <h2>내 기록 확인 관리</h2>
          <p>개인별 측정 데이터 종합 및 개인 피지컬 측정 데이터를 확인해 볼 수 있습니다.</p>
        </div>
        <div className={`con-box`}>
          <div className={`con-tit`}>
            <h4></h4>
            <div className={`option-wrap`}>
              <FilterComponent
                  type={'changeSearchType'}
                  selectedKey={searchType}
                  optionClass={'m pc'}
                  selectList={searchTypeSelectList}
                  selectChange={changeSearchType}
              />
              <div className={`search-input`}>
                <input type='text' value={searchWord} onChange={changeSearchWord} placeholder='검색어를 입력해주세요.' onKeyPress={enterKeyPress}/>
                <button onClick={clickSearch}><i className={`ic-search-20`} /></button>
              </div>
            </div>
          </div>
          <div className='news-section' style={{ display:'block' }}>
            {
              list ?
                  <>
                    <div className={`desktop-only`}>
                      <table className={`table-style01 regular-bbs-list`}>
                        <colgroup>
                          <col style={{width: '10%'}} />
                          <col style={{width: '10'}} />
                          <col style={{width: '15%'}} />
                          <col style={{width: 'auto%'}} />
                          <col style={{width: '10%'}} />
                          <col style={{width: '15%'}} />
                          <col style={{width: '20%'}} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>번호</th>
                            <th>측정년도</th>
                            <th>학교구분</th>
                            <th>학교명</th>
                            <th>학년</th>
                            <th>이름</th>
                            <th>측정일</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          !list || list.length < 1 ?
                              <tr>
                                <td colSpan={7}>
                                  <EmptyContentComponent />
                                </td>
                              </tr>
                          :
                              list.map((item, index) =>
                                  <tr key={`bbs-list-${index}`} onClick={detailPage} data-id={item.no} data-index={Number(startIndex) + index} style={{cursor: 'pointer'}}>
                                    <td>
                                      {
                                        pagingData && pagingData.totalCount
                                            ? (startIndex ? startIndex === 0 ? pagingData.totalCount : pagingData.totalCount - startIndex : pagingData.totalCount) - index
                                            : item.sortNum
                                      }
                                    </td>
                                    <td className={`font-gr`}>{item.year}</td>
                                    <td className={`font-gr`}>{item.schoolGrade}</td>
                                    <td className={`font-gr`}>{item.schoolInfo}</td>
                                    <td className={`font-gr`}>{item.schoolNum}</td>
                                    <td>{item.name}</td>
                                    <td className={`font-gr`}>{item.regDttm}</td>
                                  </tr>
                              )
                        }
                        </tbody>
                      </table>
                    </div>
                    <div className={`mobile-only`}>
                      <table className={`table-style01`}>
                        <colgroup>
                          <col style={{width: '25%'}} />
                          <col style={{width: 'auto'}} />
                          <col style={{width: 'auto'}} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>번호</th>
                            <th>학교명</th>
                            <th>이름</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          !list || list.length < 1 ?
                              <tr>
                                <td colSpan={3}>
                                  <EmptyContentComponent />
                                </td>
                              </tr>
                          :
                             list.map((item, index) =>
                                 <tr key={`bbs-list-${index}`} onClick={detailPage} data-id={item.no} data-index={Number(startIndex) + index}>
                                   <td>
                                     {
                                       pagingData && pagingData.totalCount
                                           ? (startIndex && startIndex === 0 ? pagingData.totalCount - startIndex : pagingData.totalCount) - index
                                           : item.artcSq
                                     }
                                   </td>
                                   <td>{item.schoolInfo}</td>
                                   <td>{item.name}</td>
                                 </tr>
                             )
                        }
                        </tbody>
                      </table>
                    </div>
                  </>
                  :
                  null
            }
            {
              pagingData ?
                  <PaginationComponent
                      pagingData={pagingData}
                      changePage={changePage}
                      moreView={moreView}
                  />
                  :
                  null
            }
          </div>
        </div>
      </div>
  );
}

export default MeasurementManageListComponent;