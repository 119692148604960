import React, {useEffect, useState} from 'react';
import {youthModify} from "../../../../service/youth/Signup.service";
import FilterComponent from "../../../commons/filter/FilterComponent";
import {getAllGymList, getAllList} from "../../../../service/youth/Branch.service";
import YouthMemberSearchContainer from "../../youth/regist/popup/YouthMemberSearchContainer";
import AlertComponent from "../../../commons/popup/AlertComponent";
import YouthRegistSuccess from "../../youth/regist/YouthRegistSuccess";
import MypageYouthSchoolComponent from "./MypageYouthSchoolComponent";
import moment from "moment";
import {getMobileAppHeaderData} from "../../../../helpers/apiHelper";
import {getPhysicalNumberValue} from "../../../../helpers/stringHelper";
import ConfirmComponent from "../../../commons/popup/ConfirmComponent";

const YouthPrivateInfoComponent = props => {
    const {history, info} = props;

    const year = moment().format('YYYY');

    const [memberType, setMemberType] = useState(info.type);
    const [id, setId] = useState(info.joinId);
    const [height, setHeight] = useState(info.height);
    const [weight, setWeight] = useState(info.weight);

    const [school, setSchool] = useState(info.school);

    const [schoolGrade, setSchoolGrade] = useState(info.schoolGrade); //1:초 2:중 3:고
    const changeSchoolGrade = e => {
        const value = e.currentTarget.value;
        setSchoolGrade(value);
    }

    const [schoolNum, setSchoolNum] = useState(info.schoolNum); //학년
    const changeSchoolNum = e => {
        const value = e.currentTarget.value;
        setSchoolNum(value);
    }

    const [clubList, setClubList] = useState(null);
    const [teamCode, setTeamCode] = useState(info.clubCode);
    const changeTeamCode = (key, value) => setTeamCode(key);

    const [allBranchList, setAllBranchList] = useState(null);
    const [branchList, setBranchList] = useState(null);
    const [branchCode, setBranchCode] = useState(info.placeCode);
    const changeBranchCode = (key, value) => setBranchCode(key);

    const [schoolOpt, setSchoolOpt] = useState(info.schoolNo);
    const changeSchoolOpt = (key, value) => setSchoolOpt(key);

    const [sidoOpt, setSidoOpt] = useState(info.school1);
    const changeSidoOpt = (key, value) => setSidoOpt(key);

    const [gugunOpt, setGugunOpt] = useState(info.school2);
    const changeGugunOpt = (key, value) => setGugunOpt(value);

    const [level, setLevel] = useState(1);

    const [alertMessage, setAlertMessage] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('');
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [isDisplaySearchMemberPopup, setIsDisplaySearchMemberPopup] = useState(false);
    const [isRegistPopupDisplay, setIsRegistPopupDisplay] = useState(false);
    const [isConfirmPopupDisplay, setIsConfirmPopupDisplay] = useState(false);

    const changeMemberType = e => {
        const value = e.currentTarget.value;
        setMemberType(value);
    }

    const changeLevel = level => setLevel(level);
    /*useEffect(() => {
        setLevel(1);
    }, [isDisplaySearchMemberPopup]);*/

/*    const searchBtn = e => {
        e.preventDefault();
        setIsDisplaySearchMemberPopup(true);
        setLevel(1);
    }*/

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }
    const alertPopupClose = e => {
        e.preventDefault();
        setAlertMessage('');
        setIsAlertPopupDisplay(false)
    }

    let numberRegex = new RegExp("^([0-9]{1,3})(\.[0-9]{1,1})?$");
    const isNumber = value => numberRegex.test(value);

    const changeHeight = e => {
        const value = getPhysicalNumberValue(e.currentTarget.value);
        if(value.length > 5){
            return
        }
        setHeight(value);
    }
    const changeWeight = e => {
        const value = getPhysicalNumberValue(e.currentTarget.value);
        if(value.length > 5){
            return
        }
        setWeight(value);
    }

    const setClubSelectList = list => {
        const result = [];
        if(list){
            result.push({ key: "XX", value: "클럽명"});
            list.map(item => {
                result.push({ key: item.code, value: item.teamName});
            })
        }
        return result;
    }
    function branchListFilter(list){
        const result = [];
        if(list){
            result.push({ key: "XX", value: "지점명"});
            const filtered = list.filter(item => item.code == teamCode);
            filtered.map(item => {
                result.push({ key: item.num, value: item.title});
            })
        }
        return result;
    }

    useEffect(() => {
        const getClubList = async () => { //모든 클럽 목록
            setClubList(setClubSelectList(await getAllGymList()));
        }
        getClubList();

        const getAllBranchList = async () => { //선택된 클럽의 지점, 모든 지점 목록 세팅
            setBranchList(branchListFilter(await getAllList()));
            setAllBranchList(await getAllList());
        }
        getAllBranchList();

        const setOptions = async () => {
            setSchoolNum(info.schoolNum);
            setSchoolOpt(info.schoolNo);
        }
        setOptions();
    }, []);

    useEffect(() => {
        if(allBranchList) { //선택된 클럽의 지점 목록
            setBranchList(branchListFilter(allBranchList));
            setBranchCode('');
        }
    }, [teamCode]);

    const clickUpdate = async e => { //수정
        e.preventDefault();

        if(memberType == 'J'){ //클럽 선수
            if(teamCode=='XX'){
                openAlertPopup('클럽명을 선택해 주세요.');
                return;
            }

            if(branchCode==''){
                openAlertPopup('지점명을 선택해 주세요.');
                return;
            }
        }
        if(height != '' && !isNumber(height)){
            openAlertPopup('키는 소수점 첫째자리까지의 숫자만 입력 가능합니다.');
            return;
        }
        if(weight != '' && !isNumber(weight)){
            openAlertPopup('몸무게는 소수점 첫째자리까지의 숫자만 입력 가능합니다.');
            return;
        }

        if (memberType == 'E') { // 엘리트 선수
            if(!sidoOpt || !gugunOpt || !school){
                openAlertPopup('학교 정보를 선택해 주세요.');
                return;
            }
            if(!schoolNum) {
                openAlertPopup('학년을 선택해 주세요.');
                return;
            }
        }

        if(info.step > 1) {
            // 선수 전환
            if (memberType !== info.type) {
                openConfirmPopup(`${memberType === 'J' ? '클럽 선수' : '엘리트 선수'}로 수정 하실 경우 ${memberType === 'J' ? '이적 ' : ''}신청이 진행됩니다.\n${memberType === 'J' ? '이적 ' : ''} 신청을 원하시면 아래 확인 버튼을 눌러주세요.`);
                return;
            }

            // 클럽이적, 지점이동
            if (info.clubCode !== teamCode || info.placeCode !== branchCode) {
                openConfirmPopup("클럽 및 지점을 수정 하실 경우 이적 신청이 진행됩니다.\n이적 신청을 원하시면 아래 확인 버튼을 눌러주세요.");
                return;
            }
        }

        let isSchoolReg = false;
        if(school && school != '' && Array.isArray(school)) {
            isSchoolReg = true;
        }

        const params = {
            no : info.no,
            type : memberType,
            clubCode : teamCode,
            placeCode : branchCode,
            school1 : isSchoolReg ? sidoOpt : '',
            school2 : isSchoolReg ? gugunOpt : '',
            schoolGrade : schoolGrade, //초중고
            school : isSchoolReg ? school[0]?.value : '',
            schoolYear : year,
            schoolNum : schoolNum,
            schoolCode : schoolGrade + '' + schoolNum,
            schoolNo : isSchoolReg ? school[0]?.key : '',
            height : height,
            weight : weight,
            memberSq : info.memberSq,
            joinId : id
        }

        const result = await youthModify(params);

        if(result.status === 200){
            openAlertPopup('회원정보가 수정되었습니다.');

            const mobileAppHeaerValues = getMobileAppHeaderData();
            // app 인 경우 웹뷰 callback 호출
            if (mobileAppHeaerValues) {
                if (mobileAppHeaerValues.device.toUpperCase() === 'ANDROID') {
                    if (window.KblAndroid && window.KblAndroid.changeMemInfoCallback) {
                        window.KblAndroid.changeMemInfoCallback();
                    }
                } else {
                    if (window.webkit
                        && window.webkit.messageHandlers
                        && window.webkit.messageHandlers.changeMemInfoCallback
                        && window.webkit.messageHandlers.changeMemInfoCallback.postMessage) {
                        window.webkit.messageHandlers.changeMemInfoCallback.postMessage('');
                    }
                }
            }

        }else{
            openAlertPopup(result.data.message);
        }
    }

    const openConfirmPopup = message => {
        setConfirmMessage(message);
        setIsConfirmPopupDisplay(true);
    }

    const confirmPopupClose = e => {
        e.preventDefault();
        setIsConfirmPopupDisplay(false);
        return
    }

    const modifyMember = async e => {
        setIsConfirmPopupDisplay(false);

        let isSchoolReg = false;
        if(school && school != '' && Array.isArray(school)) {
            isSchoolReg = true;
        }

        // 수정
        const params = {
            no : info.no,
            type : memberType,
            clubCode : teamCode,
            placeCode : branchCode,
            school1 : isSchoolReg ? sidoOpt : '',
            school2 : isSchoolReg ? gugunOpt : '',
            schoolGrade : schoolGrade, //초중고
            school : isSchoolReg ? school[0]?.value : '',
            schoolYear : year,
            schoolNum : schoolNum,
            schoolCode : schoolGrade + '' + schoolNum,
            schoolNo : isSchoolReg ? school[0]?.key : '',
            height : height,
            weight : weight,
            memberSq : info.memberSq,
            joinId : id
        }

        const result = await youthModify(params);

        if(result.status === 200){
            openAlertPopup('회원정보가 수정되었습니다.');

            const mobileAppHeaerValues = getMobileAppHeaderData();
            // app 인 경우 웹뷰 callback 호출
            if (mobileAppHeaerValues) {
                if (mobileAppHeaerValues.device.toUpperCase() === 'ANDROID') {
                    if (window.KblAndroid && window.KblAndroid.changeMemInfoCallback) {
                        window.KblAndroid.changeMemInfoCallback();
                    }
                } else {
                    if (window.webkit
                        && window.webkit.messageHandlers
                        && window.webkit.messageHandlers.changeMemInfoCallback
                        && window.webkit.messageHandlers.changeMemInfoCallback.postMessage) {
                        window.webkit.messageHandlers.changeMemInfoCallback.postMessage('');
                    }
                }
            }

        }else{
            openAlertPopup(result.data.message);
        }
    }

    return(
        <div className={`con-box`}>
            <div className={`con-tit`}>
                <h4></h4>
                <p>수정 시 변경 가능한 항목에 대해서 개인정보 수정이 가능합니다.</p>
            </div>
            <form>
                <div className={`modify-wrap`}>
                    <ul className={`modify-input`}>
                        <li>
                            <h6>구분</h6>
                            <ul className={`flex-input`}>
                                <li><input type={`radio`} name={'type'} id={'type_J'} value={'J'} checked={memberType === 'J'} onChange={changeMemberType} /><label htmlFor={'type_J'}><span>클럽 선수</span></label></li>
                                <li><input type={`radio`} name={'type'} id={'type_E'} value={'E'} checked={memberType === 'E'} onChange={changeMemberType} /><label htmlFor={'type_E'}><span>엘리트 선수</span></label></li>
                            </ul>
                        </li>
                        {
                            memberType === 'E' &&
                            <li>
                                <h6 className={'font-gr'}>*엘리트 선수의 경우 클럽 및 지점 등록을 안하셔도 됩니다.</h6>
                            </li>
                        }
                        <li>
                            <h6>클럽명</h6>
                            <FilterComponent
                                selectedKey={teamCode}
                                selectList={clubList}
                                selectChange={changeTeamCode}
                            />
                        </li>
                        <li>
                            <h6>지점명</h6>
                            <FilterComponent
                                selectedKey={branchCode}
                                selectList={branchList}
                                selectChange={changeBranchCode}
                            />
                        </li>
                        {/*<li>*/}
                        {/*    <h6 className={'font-gr'}>*클럽 및 지점 수정이 필요하시면 1:1문의를 이용해주세요.</h6>*/}
                        {/*</li>*/}
                        <li>
                            <h6>키</h6>
                            <input type={`text`} value={height} onChange={changeHeight}/>
                        </li>
                        <li>
                            <h6>몸무게</h6>
                            <input type={`text`} value={weight} onChange={changeWeight}/>
                        </li>
                        <MypageYouthSchoolComponent
                            memberType={memberType}
                            schoolGrade={schoolGrade}
                            changeSchoolGrade={changeSchoolGrade}
                            year={year}
                            changeSchoolOpt={changeSchoolOpt}
                            schoolOpt={schoolOpt}
                            setSchoolOpt={setSchoolOpt}
                            setSchool={setSchool}
                            changeSchoolNum={changeSchoolNum}
                            sidoOpt={sidoOpt}
                            changeSidoOpt={changeSidoOpt}
                            gugunOpt={gugunOpt}
                            changeGugunOpt={changeGugunOpt}
                            setSidoOpt={setSidoOpt}
                            setGugunOpt={setGugunOpt}
                            schoolNum={schoolNum}
                        />
                        {/*<li>*/}
                        {/*    <h6>계정 등록</h6>*/}
                        {/*    <input type={`text`} value={id} readOnly/>*/}
                        {/*    <button className={`pb`} onClick={searchBtn}>기존 회원 계정 등록</button>*/}
                        {/*    <h6 className={'font-gr'}>기존 유소년 홈페이지 회원 등록을 하셔야 기존 데이터를 확인 할 수 있습니다.</h6>*/}
                        {/*</li>*/}
                        <li>
                            <button onClick={clickUpdate}>저장</button>
                        </li>
                    </ul>
                </div>
            </form>

            {/* 팝업 */}
            <YouthMemberSearchContainer
                isDisplaySearchMemberPopup={isDisplaySearchMemberPopup}
                setIsDisplaySearchMemberPopup={setIsDisplaySearchMemberPopup}
                openAlertPopup={openAlertPopup}
                setId={setId}
                changeLevel={changeLevel}
                level={level}
            />

            <AlertComponent
                isDisplay={isAlertPopupDisplay}
                clickCloseButton={alertPopupClose}
                content={alertMessage}
            />
            <YouthRegistSuccess
                isRegistPopupDisplay={isRegistPopupDisplay}
                setIsRegistPopupDisplay={setIsRegistPopupDisplay}
                history={history}
            />
            <ConfirmComponent
                isDisplay={isConfirmPopupDisplay}
                clickNoButton={confirmPopupClose}
                clickOkButton={modifyMember}
                content={confirmMessage}
            />
        </div>
    );
}

export default YouthPrivateInfoComponent;