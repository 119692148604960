import React, { useEffect } from 'react';
import GreetingComponent from './GreetingComponent'

const GreetingContainer = props => {

    useEffect(_ => {
        return function cleanup() {
            window.scrollTo(0, 0);
        }
    }, [])
    return (
        <GreetingComponent/>
    );
}

export default GreetingContainer;