import React from 'react';

const SalaryHistoryComponent = props => {

    return (
        <div className={`con-box`}>
            <div className={`con-tit`}>
                <h4>선수 선발 및 출전 규정</h4>
            </div>
            <div className={`story-player-rule-table01`}>
                <table>
                    <colgroup>
                        <col style={{width: '20%'}}/>
                        <col style={{width: '40%'}}/>
                        <col style={{width: '40%'}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>시즌</th>
                            <th>국내선수</th>
                            <th>외국선수</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>2023-2024</td>
                            <td>
                                <dl>
                                    <dt>샐러리캡 : 28억원</dt>
                                    <dt>소프트캡 시행</dt>
                                    <dd>당해 시즌 샐러리캡 초과 사용 구단</dd>
                                    <dd>샐러리캡의 10% 이하 : 초과금의 30%</dd>
                                    <dd>샐러리캡의 10~20% : 초과금의 40%</dd>
                                    <dd>샐러리캡의 20% 초과 : 초과금의 50%</dd>
                                    <dt>트레이드 등으로 인한 일시적 초과 시에도 기금 납부</dt>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>선발방식</dt>
                                    <dd>자유계약 방식으로 선수 선발</dd>
                                    <dt>보수 (세금 공제 전)</dt>
                                    <dd>2인 $800,000 (1인 상한 $600,000)</dd>
                                    <dt>선발 규정</dt>
                                    <dd>제한없음</dd>
                                    <dt>출전 규정</dt>
                                    <dd>1~4쿼터 (1명 만 출전 가능)</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2022-2023</td>
                            <td>
                                <dl>
                                    <dt>샐러리캡 : 26억원</dt>
                                    <dt>소프트캡 시행</dt>
                                    <dd>당해 시즌 샐러리캡 초과 사용 구단</dd>
                                    <dd>샐러리캡의 10% 이하 : 초과금의 30%</dd>
                                    <dd>샐러리캡의 10~20% : 초과금의 40%</dd>
                                    <dd>샐러리캡의 20% 초과 : 초과금의 50%</dd>
                                    <dt>트레이드 등으로 인한 일시적 초과 시에도 기금 납부</dt>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>선발방식</dt>
                                    <dd>자유계약 방식으로 선수 선발</dd>
                                    <dt>보수 (세금 공제 전)</dt>
                                    <dt>2인 $900,000 (1인 상한 $650,000)</dt>
                                    <dt>선발 규정</dt>
                                    <dd>제한없음</dd>
                                    <dt>출전 규정</dt>
                                    <dd>1~4쿼터 (1명 만 출전 가능)</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2021-2022</td>
                            <td>
                                <dl>
                                    <dt>셀러리캡 : 25억원</dt>
                                    <dt>소프트캡 시행</dt>
                                    <dd>당해 시즌 샐러리캡 초과 사용 구단</dd>
                                    <dd>샐러리캡의 10% 이하 : 초과금의 30%</dd>
                                    <dd>샐러리캡의 10~20% : 초과금의 40%</dd>
                                    <dd>샐러리캡의 20% 초과 : 초과금의 50%</dd>
                                    <dt>트레이드 등으로 인한 일시적 초과 시에도 기금 납부</dt>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>선발방식</dt>
                                    <dd>자유계약 방식으로 선수 선발</dd>
                                    <dt>보수 (세금 공제 전)</dt>
                                    <dd>2인 $900,000 (1인 상한 $650,000)</dd>
                                    <dt>선발 규정</dt>
                                    <dd>제한없음</dd>
                                    <dt>출전 규정</dt>
                                    <dd>1~4쿼터 (1명 만 출전 가능)</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2020-2021</td>
                            <td>
                                <dl>
                                    <dt>셀러리캡 : 25억원</dt>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>선발방식</dt>
                                    <dd>자유계약 방식으로 선수 선발</dd>
                                    <dt>보수 (세금 공제 후)</dt>
                                    <dd>2인 $700,000 (1인 상한 $500,000)</dd>
                                    <dt>신장 규정</dt>
                                    <dd>제한없음</dd>
                                    <dt>출전 규정</dt>
                                    <dd>1~4쿼터 (1명 만 출전 가능)</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2019-2020</td>
                            <td>
                                <dl>
                                    <dt>셀러리캡 : 25억원</dt>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>선발방식</dt>
                                    <dd>자유계약 방식으로 선수 선발</dd>
                                    <dt>보수 (세금 공제 후)</dt>
                                    <dd>2인 $700,000 (1인 상한 $500,000)</dd>
                                    <dt>신장 규정</dt>
                                    <dd>제한없음</dd>
                                    <dt>출전 규정</dt>
                                    <dd>1~4쿼터 (1명 만 출전 가능)</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2018-2019</td>
                            <td>
                                <dl>
                                    <dt>셀러리캡 : 24억원</dt>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>선발방식</dt>
                                    <dd>자유계약 방식으로 선수 선발</dd>
                                    <dt>신장 규정</dt>
                                    <dt>단신 (186cm 이하)</dt>
                                    <dt>장신 (200cm 이하)</dt>
                                    <dt>보수 (세금 공제 후)</dt>
                                    <dt>2인 합산 $700,000</dt>
                                    <dt>출전 규정</dt>
                                    <dd>1~4쿼터 (1명 만 출전 가능)</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2017-2018</td>
                            <td>
                                <dl>
                                    <dt className={`font700`}>드래프트</dt>
                                    <dt>1차 추첨 (1-4순위 추첨)</dt>
                                    <dd>PO우승팀 : 0.5%</dd>
                                    <dd>PO준우승팀: 1.5%</dd>
                                    <dd>챔프전 진출실패팀(2팀): 각 5%</dd>
                                    <dd>4강 PO 진출실패팀(2팀): 각 12%</dd>
                                    <dd>정규경기 7-10위팀(4팀): 각 16%</dd>
                                    <dd>2차 추첨 (5-8순위 추첨)</dd>
                                    <dd>전 시즌 정규경기 순위 역순 배분 추첨(정규경기 성적 상위팀부터 하위팀순으로 10%, 20%, 30%, 40%의 확률을 부여해 5~8순위 추첨)</dd>
                                    <dd>PO 우승, 준 우승팀 배치</dd>
                                    <dd className={`no-dash`}>1) 1차 추첨에서 추첨되지 않을 경우, 9,10순위 배치</dd>
                                    <dd className={`no-dash`}>2) 1차 추첨에서 추첨될 경우, 잔여 팀 중 정규경기 순위 상위팀이 9순위 (or 9,10순위) 배치</dd>
                                    <dd>신인선수 출전 : 2라운드부터 출전 가능</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>드래프트</dt>
                                    <dd>국내선수 드래프트와 동일</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2016-2017</td>
                            <td>
                                <dl>
                                    <dt>국내선수 드래프트 행사</dt>
                                    <dt>순위추첨-선수지명 이원화</dt>
                                    <dt>신인 선수 보수</dt>
                                    <dd>1~4순위 : 7천만원~1억원</dd>
                                    <dd>5~10순위: 4천 5백만원~7천만원</dd>
                                    <dd>2라운드 선수 : 4천만원~5천만원</dd>
                                    <dd>3라운드 이하 : 3천만원 이상</dd>
                                    <dd>(샐러리캡 : 23억원)</dd>
                                    <dt>드래프트</dt>
                                    <dd>전 시즌 우승/준우승팀 제외한 8개팀 동일확률로(각 12.5%) 1~4순위까지 추첨</dd>
                                    <dd>나머지 4개팀, 정규리그 성적 상위팀부터 하위팀순으로 10%, 20%, 30%, 40%의 확률을 부여해 5~8순위 추첨</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>드래프트</dt>
                                    <dd>국내선수 드래프트와 동일</dd>
                                    <dt>팀별 외국인선수 2명보유</dt>
                                    <dd>1,4쿼터 : 1명, 2,3쿼터 : 2명 출전 (4~6라운드는, 1~3쿼터 출전은 자율)</dd>
                                    <dt>신장 규정</dt>
                                    <dd>장신 : 193cm 초과</dd>
                                    <dd>단신 : 193cm 이하</dd>
                                    <dt>월봉 (세금 공제 후)</dt>
                                    <dd>1라운드 : $30,000</dd>
                                    <dd>2라운드 : $20,000</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2015-2016</td>
                            <td>
                                <dl>
                                    <dt>신인 선수 보수</dt>
                                    <dd>1~4순위 : 7천만원~1억원</dd>
                                    <dd>5~10순위: 4천 5백만원~7천만원</dd>
                                    <dd>2라운드 선수 : 4천만원~5천만원</dd>
                                    <dd>3라운드 이하 : 3천만원 이상</dd>
                                    <dd>(샐러리캡 : 23억원)</dd>
                                    <dt>드래프트</dt>
                                    <dd>전 시즌 우승/준우승팀 제외한 8개팀 동일확률로(각 12.5%) 1~4순위까지 추첨</dd>
                                    <dd>나머지 4개팀, 정규리그 성적 상위팀부터 하위팀순으로 10%, 20%, 30%, 40%의 확률을 부여해 5~8순위 추첨</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>드래프트</dt>
                                    <dd>국내선수 드래프트와 동일</dd>
                                    <dt>팀별 외국인선수 2명보유</dt>
                                    <dd>1라운드: 쿼터당 1명 출전</dd>
                                    <dd>2~3라운드: 3쿼터에 한해 2명 출전 가능</dd>
                                    <dd>4~6라운드, 플레이오프: 2, 3쿼터에 한해 2명 출전 가능</dd>
                                    <dt>신장 규정</dt>
                                    <dd>장신 : 193cm 초과</dd>
                                    <dd>단신 : 193cm 이하</dd>
                                    <dt>월봉 (세금 공제 후)</dt>
                                    <dd>1라운드 : $30,000</dd>
                                    <dd>2라운드 : $20,000</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2014-2015</td>
                            <td>
                                <dl>
                                    <dt>신인 선수 보수</dt>
                                    <dd>1~4순위 : 7천만원~1억원</dd>
                                    <dd>5~10순위: 4천 5백만원~7천만원</dd>
                                    <dd>2라운드 선수 : 4천만원~5천만원</dd>
                                    <dd>3라운드 이하 : 3천만원 이상</dd>
                                    <dd>(샐러리캡 : 23억원)</dd>
                                    <dt>드래프트</dt>
                                    <dd>전 시즌 우승/준우승팀 제외한 8개팀 동일확률 부여(각 12.5%)</dd>
                                </dl>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>2013-2014</td>
                            <td>
                                <dl>
                                    <dt>신인 선수 보수</dt>
                                    <dd>1~4순위 : 7천만원~1억원</dd>
                                    <dd>5~10순위: 4천 5백만원~7천만원</dd>
                                    <dd>11순위이상 : 3천8백만원~4천 5백만원</dd>
                                    <dd>(샐러리캡 : 22억원)</dd>
                                    <dt>드래프트</dt>
                                    <dd>전 시즌 정규 리그 성적 7~10위 23.5%,시즌 정규 리그 성적 3~6위 1.5% 확률의 구슬로 지명권 순위 결정</dd>
                                </dl>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>2012-2013</td>
                            <td>
                                <dl>
                                    <dt>국내 선수 보수 상한액</dt>
                                    <dd>팀 샐러리캡의 30%</dd>
                                    <dt>신인 선수 보수</dt>
                                    <dd>1~4순위 : 7천만원~1억원</dd>
                                    <dd>5~10순위: 4천 5백만원~7천만원</dd>
                                    <dd>11순위이상 : 3천8백만원~4천 5백만원</dd>
                                    <dd>11순위이상 : 3천8백만원~4천 5백만원</dd>
                                    <dt>(샐러리캡 : 21억원)</dt>
                                    <dd>전 시즌 정규 리그 성적 7~10위 23.5%,시즌 정규 리그 성적 3~6위 1.5% 확률의 구슬로 지명권 순위 결정</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>선수 선발</dt>
                                    <dd>자유계약제도 → Tryout & Draft</dd>
                                    <dt>선수 교체</dt>
                                    <dd>2012 외국선수 Tryout 참가 선수를 교체 대상으로 제한</dd>
                                    <dt>연봉</dt>
                                    <dd>1라운드 : $35,000</dd>
                                    <dd>2라운드 : $25,000</dd>
                                    <dt>팀별 외국인선수 2명보유 1명출전</dt>
                                    <dd>쿼터 별 출전 제한 없음</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2011-2012</td>
                            <td>
                                <dl>
                                    <dt>국내 선수 보수 상한액</dt>
                                    <dd>팀 샐러리캡의 30%</dd>
                                    <dt>신인 선수 보수</dt>
                                    <dd>1~4순위 : 7천만원~1억원</dd>
                                    <dd>5~10순위: 4천 5백만원~7천만원</dd>
                                    <dd>11순위이상 : 4천 5백만원 이하</dd>
                                    <dd>(샐러리캡 : 20억원)</dd>
                                    <dt>드래프트</dt>
                                    <dd>9순위는 챔피언결정전 준우승팀</dd>
                                    <dd>10순위는 챔피언결정전 우승팀에게 주어짐</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>자유계약제 도입</dt>
                                    <dd>각 구단 1명 보유 1명 출전</dd>
                                    <dt>선수 선발</dt>
                                    <dd>드래프트 → 자유계약</dd>
                                    <dt>연봉</dt>
                                    <dd>1인 최대 $350,000, 계약기간 7개월, 인센티브 $50,000 별도</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2010-2011</td>
                            <td>
                                <dl>
                                    <dt>국내 선수 보수 상한액</dt>
                                    <dd>팀 샐러리캡의 30%</dd>
                                    <dt>신인 선수 보수</dt>
                                    <dd>1~4순위 : 7천만원~1억원</dd>
                                    <dd>5~10순위: 4천 5백만원~7천만원</dd>
                                    <dd>11순위이상 : 4천 6백만원이하</dd>
                                    <dd>(샐러리캡 : 19억원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>선수 선발</dt>
                                    <dd>Tryout & Draft를 통한 선발</dd>
                                    <dt>선수 교체</dt>
                                    <dd>2010 외국 선수 Tryout 참가 선수를 교체 대상으로 제한</dd>
                                    <dt>연봉</dt>
                                    <dd>외국 선수 한명의 연봉은 $175,000로 제한($25,000×7개월)</dd>
                                    <dt>팀별 외국 선수 2명 보유, 1명 출전</dt>
                                    <dd>쿼터별 출전 제한 없음</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2009-2010</td>
                            <td>
                                <dl>
                                    <dt>국내 선수 보수 상한액</dt>
                                    <dd>팀 샐러리캡의 30%</dd>
                                    <dt>신인 선수 보수</dt>
                                    <dd>1~4순위 : 7천만원~1억원</dd>
                                    <dd>5~10순위 : 4천 5백만원~7천만원</dd>
                                    <dd>11순위이상 : 4천 6백만원 이하</dd>
                                    <dd>(샐러리캡 : 18억원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>선수 선발</dt>
                                    <dd>Tryout & Draft를 통한 선발</dd>
                                    <dt>선수 교체</dt>
                                    <dd>2009 외국 선수 Tryout 참가 선수를 교체 대상으로 제한</dd>
                                    <dt>연봉</dt>
                                    <dd>외국 선수 한명의 연봉은 $175,000로 제한($25,000×7개월)</dd>
                                    <dt>팀별 외국 선수 2명 보유, 1명 출전</dt>
                                    <dd>쿼터별 출전 제한 없음</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2008-2009</td>
                            <td>
                                <dl>
                                    <dt>자유계약선수(FA)제도 관련</dt>
                                    <dd>전년도 전체 연봉서열 30위(기존:20위) 이내의 FA 선수와 계약을 체결한 구단은 보상 선수 1명과 해당선수 전년도 연봉
                                        100%의 금액을 전 소속 구단에 보상해야 함</dd>
                                    <dd>전 소속 구단에서 보상선수를 원하지 않을 경우, 해당 구단은 해당 FA선수 전년도 연봉의 300% 금액을 전 소속구단에 보상해야 함</dd>
                                    <dd>(샐러리캡 : 17억원 / 인센티브 : 1억원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>신장 제한 규정 폐지</dt>
                                    <dt>선수 선발</dt>
                                    <dd>Tryout & Draft를 통한 선발</dd>
                                    <dt>선수 교체</dt>
                                    <dd>2008 외국 선수 Tryout 참가 선수를 교체 대상으로 제한</dd>
                                    <dt>연봉</dt>
                                    <dd>외국 선수 한 명의 연봉은 $175,000로 제한($25,000×7개월)</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2007-2008</td>
                            <td>
                                <dl>
                                    <dt>변경사항 없음</dt>
                                    <dd>(샐러리캡 : 17억원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>선수 선발</dt>
                                    <dd>Tryout & 드래프트를 통한 선발로 회귀</dd>
                                    <dt>선수 교체</dt>
                                    <dd>2007 외국 선수 Tryout 참가 선수를 교체 대상으로 제한</dd>
                                    <dt>연봉</dt>
                                    <dd>두 외국 선수 연봉 합을 $400,000이하로 제한</dd>
                                    <dd>외국 선수 한명의 연봉은 $175,000로 제한($25,000×7개월)</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2006-2007</td>
                            <td>
                                <dl>
                                    <dt>변경사항 없음</dt>
                                    <dd>(샐러리캡 : 16억원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>외국 선수 2,3쿼터 한 명 출전</dt>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2005-2006</td>
                            <td>
                                <dl>
                                    <dt>해외 교포 선수 드래프트 참가</dt>
                                    <dd>(샐러리캡 : 15억원)</dd>
                                </dl>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>2004-2005</td>
                            <td>
                                <dl>
                                    <dt>변경사항 없음</dt>
                                    <dd>(샐러리캡 : 13억7천5백만원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>자유계약제 도입</dt>
                                    <dt>신장 제한</dt>
                                    <dd>두 외국 선수 신장의 합계는 400Cm 초과할 수 없음</dd>
                                    <dd>한 선수의 신장은 208Cm로 제한</dd>
                                    <dt>연봉</dt>
                                    <dd>두 외국 선수 연봉 합이 $280,000를 초과할 수 없음</dd>
                                    <dd>한 선수의 최고 연봉은 $200,000로 제한</dd>
                                    <dt>5라운드 이후 또는 회수제한 2회 초과 시</dt>
                                    <dd>시즌 대체 할 경우 승인일 익일부터 시즌 대체 선수와 시즌 대체 당한 선수는 5경기 출장금지</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2003-2004</td>
                            <td>
                                <dl>
                                    <dt>변경사항 없음</dt>
                                    <dd>(샐러리캡 : 12억5천만원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>선수의 계약위반은 재정위원회 심의를 거쳐 총재의 승인을 받은 후 대체 가능, 이 경우 승인 익일부터 계약 위반선수 및 대체 선수는 5경기 출장금지</dt>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2002-2003</td>
                            <td>
                                <dl>
                                    <dt>변경사항 없음</dt>
                                    <dd>(샐러리캡 : 11억5천만원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>외국 선수 2쿼터 출전 1명으로 제한</dt>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2001-2002</td>
                            <td>
                                <dl>
                                    <dt>올스타 휴식기에 드래프트 시행</dt>
                                    <dd>1~4순위 : 전 시즌 정규리그 성적 7~10위</dd>
                                    <dd>5~8위 : 전 시즌 정규리그 성적 3~6위</dd>
                                    <dd>1~4순위, 5~8순위 지명권은 네 개팀 모두 동일하게 25개의 구슬로 지명권 순위 결정</dd>
                                    <dd>9순위는 전 시즌 2위, 10순위는 전 시즌 1위에게 주어짐</dd>
                                    <dt>수련 선수 제도 시행</dt>
                                    <dd>(샐러리캡 : 10억5천만원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>외국 선수 교체</dt>
                                    <dd>일시 교체 : 2주이상 8주미만</dd>
                                    <dd>시즌 대체 : 8주이상</dd>
                                    <dt>외국 선수는 기타 사유로 4라운드 종료까지 2회 교체 가능</dt>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>2000-2001</td>
                            <td>
                                <dl>
                                    <dd>시즌 전 드래프트 시행으로 97, 97~98, 98~99 세 시즌 성적을 토대로 선발순위 결정</dd>
                                    <dd>삼성, SK, 골드뱅크, 동양은 각각 40개, 30개, 20개,10개의 구슬을 추첨기에 넣어 1~4순위 지명권 순위 결정</dd>
                                    <dd>SBS, 신세기, LG, 삼보, 현대, 기아순으로 5~10순위지명권 행사</dd>
                                    <dd>(샐러리캡 : 10억원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>신장 규정</dt>
                                    <dd>두 외국 선수의 신장 합계는 398.78Cm 초과할 수 없음</dd>
                                    <dd>한 선수의 신장은 208.28Cm로 제한</dd>
                                    <dt>외국 선수 교체</dt>
                                    <dd>일시 교체 : 4주 이상 8주미만</dd>
                                    <dd>시즌 대체 : 8주이상</dd>
                                    <dt>외국 선수는 기타 사유로 3라운드 종료까지 2회 교체 가능</dt>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>1999-2000</td>
                            <td>
                                <dl>
                                    <dt>변경사항 없음</dt>
                                    <dd>(샐러리캡 : 9억4천 5백만원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>외국 선수 교체</dt>
                                    <dd>일시 교체 : 4주 이상 12주이하</dd>
                                    <dd>시즌 대체 : 12주초과</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>1998-1999</td>
                            <td>
                                <dl>
                                    <dt>시즌 종료 후 드래프트 실시</dt>
                                    <dd>1~4순위 : 전 시즌 정규리그 성적에 따라 10위 40개,9위 30개, 8위 20개, 7위 10개의 구슬을 추첨기에 넣어 지명권 순위 결정</dd>
                                    <dd>5~10순위 : 전 시즌 정규리그 1~6위의 역순</dd>
                                    <dd>2라운드는 1라운드 역순이며, 3라운드는 1라운드순과 동일</dd>
                                    <dd>(샐러리캡 : 9억원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>드래프트 지명권 순위 결정</dt>
                                    <dd>1~4순위 : 전 시즌 성적 10위 40개, 9위 30개, 8위 20개, 7위 10개 구슬을 추첨기에 넣어 지명권 순위 결정</dd>
                                    <dd>5~6순위 : 전 시즌 순위 6위 60개, 5위 40개의 구슬로 결정</dd>
                                    <dd>7~10순위 : 전 시즌 성적 4위 40개, 3위 30개, 2위 20개, 1위 10개 구슬을 추첨기에 넣어 지명 권 순위 결정</dd>
                                    <dt>신장 규정 (신발 착용 신장임)</dt>
                                    <dd>장신 : 205.7Cm이하</dd>
                                    <dd>단신 : 193.5Cm이하</dd>
                                    <dt>연봉 : 월 $10,000(7개월)</dt>
                                    <dd>재계약시 : 월 $11,000</dd>
                                    <dd>2시즌 이상 재계약시 : 월 $12,000</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>1997-1998</td>
                            <td>
                                <dl>
                                    <dt>신인 드래프트 참가자 나이 제한 규정</dt>
                                    <dd>대학 3학년 재학생 드래프트 참가 허용</dd>
                                    <dd>(샐러리캡 : 9억원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>드래프트 지명권 순위 결정</dt>
                                    <dd>97시즌 정규리그 성적 역순</dd>
                                    <dd>신생팀 LG, 진로(SK)는 각각 5,6순위</dd>
                                    <dt>외국 선수 교체는 4주 이상 부상일 때 한 팀당 1회 가능</dt>
                                    <dt>연봉 : $70,000(7개월)</dt>
                                    <dd>성과급 $10,000이내 추가 지급 가능</dd>
                                </dl>
                            </td>
                        </tr>
                        <tr>
                            <td>1997</td>
                            <td>
                                <dl>
                                    <dt>미시행</dt>
                                    <dd>(샐러리캡 : 10억원)</dd>
                                </dl>
                            </td>
                            <td>
                                <dl>
                                    <dt>트라이아웃 및 드래프트로 외국 선수 선발</dt>
                                    <dt>드래프트 지명권 순위 결정</dt>
                                    <dd>7가지 색상 20개씩 총 140개 구슬과 추첨기를 통해 지명권 순서결정</dd>
                                    <dd>2라운드는 1라운드 지명 역순</dd>
                                    <dt>뒤늦게 합류한 나산은 7개 구단 선수 선발 이후 외국 선수 선발</dt>
                                    <dt>신장 규정</dt>
                                    <dd>장신 : 203.2Cm이하</dd>
                                    <dd>단신 : 190.5Cm이하</dd>
                                    <dt>연봉 : $70,000(8개월)</dt>
                                </dl>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SalaryHistoryComponent;