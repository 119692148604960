import React, {useEffect} from 'react';
import MeasurementPercentComponent from "./MeasurementPercentComponent";

const MeasurementPercentContainer = props => {

    useEffect(() => {
        console.log('MeasurementPercentContainer::componentDidMount');

        return function cleanup() {
            console.log('MeasurementPercentContainer::componentWillUnmount');
        }
    }, []);

    return(
        <MeasurementPercentComponent
            {...props}
        />
    );
}
export default MeasurementPercentContainer;