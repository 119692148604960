import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {getYouthBannerList, getYouthMainArticles} from '../../../store/main/main'
import YouthMainComponent from "./YouthMainComponent";

const MainContainer = props => {
    const { articles, banners } = useSelector(state => state.mainPage);
    const dispatch = useDispatch();

    const youthArticlesParams = {
        movieCnt: 4,
        photoCnt: 5,
        newsCnt: 4,
        noticeCnt: 4
    };

    const getInfos = _ => {
            dispatch(getYouthMainArticles(youthArticlesParams));
            dispatch(getYouthBannerList());
    }

    useEffect(_ => {
        getInfos();
        return function cleanup() {
        // window.scrollTo(0, 0);
        }
    }, [dispatch])

    return (
        articles.data !== null ?
            <YouthMainComponent
                {...props}
                articles={articles.data}
                banners={banners.data}
            />
            : null
    );
}

export default MainContainer;