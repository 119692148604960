import * as ApiService from '../Api.service'

const preFix = '/web';
export const getSignupInfo = async _ => {
    console.log('Signup.service:getSignupInfo');
    const url = preFix + '/youths/join';

    return ApiService.get(url);
}

export const signup = async params => {
    console.log('Signup.service:signup');
    const url = preFix + '/youths/join';

    return ApiService.post(url, params);
}

export const getSchoolList = async () => {
    console.log('Signup.service:getSchoolList');
    const url = preFix + '/youths/school';

    return ApiService.get(url);
}

export const youthRegist = async params => {
    console.log('Signup.service:youthRegist');
    const url = preFix + '/youths/regist';

    return ApiService.post(url, params);
}

export const getYouthMemberInfo = async (params) => {
    console.log('Signup.service:getYouthMemberInfo');
    const url = preFix + `/youths/members`;

    return ApiService.get(url, params);
}

export const youthModify = async params => {
    console.log('Signup.service:youthModify');
    const url = preFix + `/youths/members`;

    return ApiService.post(url, params);
}