import * as ApiService from '../Api.service'

export const getTeamCodeList = async (params) => {
    console.log('Code.service:getList');
    const url = '/codes/teams';

    return ApiService.get(url, params);
}

export const getBankCodeList = async () => {
    console.log('Code.service:getBankCodeList');
    const url = '/codes/banks';

    return ApiService.get(url);
}