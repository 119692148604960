import React  from 'react';
import YouthDetailViewComponent from "../../../../commons/table/YouthDetailViewComponent";

const YouthNewsViewComponent = props => {

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>뉴스</h2>
                <p>최신 소식을 확인 할 수 있는 공간입니다.</p>
            </div>
            <YouthDetailViewComponent
                {...props}
                articleClass={'regular-bbs-info'}
                listUrl={`/youth/community/news`}
            />
        </div>
    );
}

export default YouthNewsViewComponent;