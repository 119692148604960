import React, {useState} from 'react';
import {getMemberId} from "../../../../../service/member/Member.service";
import YouthMemberSearchComponent from "./YouthMemberSearchComponent";
import YouthMemberSearchFail from "./YouthMemberSearchFail";

const YouthMemberSearchContainer = props => {
    const {isDisplaySearchMemberPopup, setIsDisplaySearchMemberPopup, openAlertPopup, setId, changeLevel, level} = props;

    const [isValidEmail, setIsValidEmail] = useState(true);
    const [email, setEmail] = useState(null);

    const [name, setName] = useState(null);
    const [prevId, setPrevId] = useState(null);

    const searchMember = async e => {
        e.preventDefault();

        if(!name){
            openAlertPopup('이름을 입력해주세요.');
            return;
        }
        if(!email && !prevId){
            openAlertPopup('계정이나 이메일을 입력해주세요.');
            return;
        }
        if(email && !isValidEmail){
            openAlertPopup('올바르지 않은 이메일 주소입니다.');
            return;
        }
        const params = {
            name : name,
            email : email,
            id : prevId,
        }
        const result = await getMemberId(params);
        if(result.length>0){
            setId(result);
            setIsDisplaySearchMemberPopup(false);
        }
        else{
            changeLevel(2);
            setName(null);
            setPrevId(null);
            setEmail(null);
        }
    }

    return(
        <div className={`popup`} style={{ display: isDisplaySearchMemberPopup ? 'block' : 'none' }}>
            {
                level === 1 ?
                    <YouthMemberSearchComponent
                        setEmail={setEmail}
                        setIsValidEmail={setIsValidEmail}
                        setName={setName}
                        setPrevId={setPrevId}
                        setIsDisplaySearchMemberPopup={setIsDisplaySearchMemberPopup}
                        changeLevel={changeLevel}
                        searchMember={searchMember}
                    />
                    : <YouthMemberSearchFail
                        setIsDisplaySearchMemberPopup={setIsDisplaySearchMemberPopup}
                    />
            }
        </div>
    );
}

export default YouthMemberSearchContainer;