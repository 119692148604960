import React, { useEffect, useState } from 'react';
import {getYouthTeamRankList} from "../../../../../service/youth/Match.service";
import FilterComponent from "../../../../commons/filter/FilterComponent";
import EmptyContentComponent from "../../../../commons/table/EmptyContentComponent";

const YouthTeamRankComponent = props => {
    const { seasonKind, seasonCode } = props;
    const [rankList, setRankList] = useState(null);
    const [gameKind, setGameKind] = useState('1');
    const gameKindList = [
        {key: '1', value: '예선경기'},
        {key: '2', value: '본선경기'},
    ]

    const changeParams = (type, key, value) => {
        if (type === 'changeGameKind') {
            if (gameKind === key) {
                return;
            }
            setGameKind(key);
            return;
        }
    }

    const getDataList = async () => {
        if (!seasonCode) return
        const _rankList = await getYouthTeamRankList({seasonKind: seasonKind, seasonCode: seasonCode, gameKind: gameKind});
        setRankList(_rankList);
    }

    useEffect( () => {
        getDataList();
    }, [seasonCode, gameKind])

    return (
        <div className={`tab-con active`}>
            <div className={`con-box`}>
                {
                    seasonKind == 2 &&
                    <div className={`con-tit`}>
                        <h4></h4>
                        <div className={`option-wrap`}>
                            <FilterComponent
                                type={'changeGameKind'}
                                selectedKey={gameKind}
                                selectList={gameKindList}
                                selectChange={changeParams}
                            />
                        </div>
                    </div>
                }
                {
                    rankList && rankList.length > 0 ?
                        rankList.map((item, index) =>
                            <div className={`con-box`} key={`team_rank_group_${index}`}>
                                <div className={`con-tit`}>
                                    <h4>{gameKind !== '2' ? item.groupName : ''}</h4>
                                </div>
                                <div className={`youth-grid-2`}>

                                    {
                                        item.groupList && item.groupList.length > 0 ?
                                            item.groupList.map((i, idx) =>
                                                <div key={`team_rank_jo_${idx}`}>
                                                    <h6>
                                                        {`${i.title !== '-' && seasonKind == 1 ? `${i.title}조`: i.title}`}
                                                    </h6>
                                                    <div>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{width: '15%'}} />
                                                                <col style={{width: 'auto'}} />
                                                                <col style={{width: '10%'}} />
                                                                <col style={{width: '10%'}} />
                                                                <col style={{width: '15%'}} />
                                                                <col style={{width: '15%'}} />
                                                            </colgroup>
                                                            <thead>
                                                            <tr>
                                                                <th>순위</th>
                                                                <th>팀명</th>
                                                                <th>승</th>
                                                                <th>패</th>
                                                                <th>승률</th>
                                                                <th>승차</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                i.rankList && i.rankList.length > 0 ?
                                                                    i.rankList.map((r, rIdx) =>
                                                                        <tr key={`team_rank_${rIdx}`}>
                                                                            <td>{r.rank}</td>
                                                                            <td>
                                                                                <div className='team-name'>
                                                                                    <img src={`/imgs/youth/emblem/em_KBL${r.teamCode}.png`} style={{width: '4rem', height:'2.6rem'}} />
                                                                                    {/*<i className={`emblem-ss`} />*/}
                                                                                    <p>{r.teamName}</p>
                                                                                </div>
                                                                            </td>
                                                                            <td>{r.win}</td>
                                                                            <td>{r.lose}</td>
                                                                            <td>{r.per}%</td>
                                                                            <td>{r.gap}</td>
                                                                        </tr>
                                                                    )
                                                                    : null
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )
                                            : null
                                    }
                                </div>
                            </div>
                        )
                        : <EmptyContentComponent />
                }
            </div>
        </div>
    )
}

export default YouthTeamRankComponent;