import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {
    getYouthMeasurementMemberRecordsByMemberSq,
    getYouthMeasurementRecordsBySchoolGradeByMemberSq
} from "../../../../service/youth/Measurement.service";
import MeasurementMemberComponent from "./MeasurementMemberComponent";
import AlertComponent from "../../../commons/popup/AlertComponent";

const MeasurementManageViewContainer = (props) => {
    const { history, location } = props;
    const { isLogin, userInfo } = useSelector(state => state.commonAuth);

    const [personalData, setPersonalData] = useState(null); //개인 기록
    const [physicalData, setPhysicalData] = useState(null); //개인 피지컬 측정 데이터
    const [bySchoolData, setBySchoolData] = useState(null); //학교 구분별 최저, 평균, 최대, 개인 기록
    const [schoolGradeList, setSchoolGradeList] = useState(null); //개인별 학교 구분 리스트

    const [avgData, setAvgData] = useState(null); //그래프용 평균 기록
    const [gapData, setGapData] = useState(null); //그래프용 Gap

    const [schoolGrade, setSchoolGrade] = useState("2"); //학교 구분 선택값 (1:초, 2:중, 3:고)

    const [alertMessage, setAlertMessage] = useState('');
    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }
    const closeAlertPopup = e => {
        e.preventDefault();
        setAlertMessage('');
        setIsAlertPopupDisplay(false);
        if (!isLogin || !userInfo.isYouthAdmin) {
            history.push({ pathname: '/', state: { from: history.location.pathname} });
        }
    }

    useEffect(() => {
        console.log('MeasurementManageViewContainer::componentDidMount');

        if (!isLogin || !userInfo.isYouthAdmin) {
            openAlertPopup('접근 권한이 없습니다.');
        }else{
            const setMemberMeasurementData = async(memberSq) => {
                const result = await getYouthMeasurementMemberRecordsByMemberSq(memberSq);

                if (result) {
                    setPersonalData(result.personalData);
                    setPhysicalData(result.physicalData);
                    setSchoolGradeList(result.schoolGradeList);
                    setSchoolGrade(result.schoolGradeList[result.schoolGradeList.length-1]);
                    setAvgData(result.avgData);
                    setGapData(result.gapData);
                }
            }
            if (location.state && location.state.memberSq) {
                if (Number(location.state.memberSq) !== 0) {
                    setMemberMeasurementData(Number(location.state.memberSq));
                }
            }
        }

        return function cleanup() {
            console.log('MeasurementManageViewContainer::componentWillUnmount');
        }
    }, [location]);

    useEffect(() => {
        if (!isLogin || !userInfo.isYouthAdmin) {
            openAlertPopup('접근 권한이 없습니다.');
        }else{
            const getAvgMeasurementData = async() => {
                if (location.state && location.state.memberSq) {
                    if (Number(location.state.memberSq) !== 0) {
                        const result = await getYouthMeasurementRecordsBySchoolGradeByMemberSq({schoolGrade: schoolGrade, memberSq: Number(location.state.memberSq)});

                        if(result && (Array.isArray(result))) {
                            setBySchoolData(result);
                        }
                    }
                }
            }
            getAvgMeasurementData();
        }
    }, [schoolGrade]);

    return(
        <>
            {
                isLogin && userInfo.isYouthAdmin ?
                    <MeasurementMemberComponent
                        {...props}
                        personalData={personalData}
                        physicalData={physicalData}
                        bySchoolData={bySchoolData}
                        schoolGradeList={schoolGradeList}
                        schoolGrade={schoolGrade}
                        setSchoolGrade={setSchoolGrade}
                        avgData={avgData}
                        gapData={gapData}
                    />
                :
                    null
            }
            <AlertComponent
                isDisplay={isAlertPopupDisplay}
                clickCloseButton={closeAlertPopup}
                content={alertMessage}
            />
        </>
    );
}

export default MeasurementManageViewContainer;