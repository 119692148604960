import React,  { useState, useEffect, useContext } from 'react';

import { BranchContext } from "./YouthClubContainer";
import TeamBtnComponent from "./TeamBtnComponent";
import KaKaoMapComponent from "./KaKaoMapComponent";
import AlertComponent from "../../../commons/popup/AlertComponent";

const YouthClubBranchComponent = props => {
    const value = useContext(BranchContext);

    const [filterData, setFilterData] = useState("");
    const [findData, setFindData] = useState("");
    const [selectedTeam, setSelectedTeam] = useState("");

    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => { //지점명 버튼 클릭 시
        gymFilter(selectedTeam);
    }, [selectedTeam]);

    function gymFilter(teamCd){
        const temp = value.gymList.filter(item => item.code == teamCd); //팀코드 검색
        setFilterData(temp);
        const firstAddr = temp.find(item => item.address != null); //첫번째 주소 (center addr)
        setFindData(firstAddr);

        if(teamCd === '64' || teamCd === '99'){
            openAlertPopup("준비중입니다.");
        }
    }

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }

    const alertPopupClose = e => {
        e.preventDefault();
        setAlertMessage('');
        setIsAlertPopupDisplay(false);
    }

    return (
        <>
            <AlertComponent
                isDisplay={isAlertPopupDisplay}
                clickCloseButton={alertPopupClose}
                content={alertMessage}
            />

            <TeamBtnComponent
                setSelectedTeam = {setSelectedTeam}
                selectedTeam = {selectedTeam}
            />

            <KaKaoMapComponent
                selectedAddr = {findData}
                addrlist = {filterData}
                key = {filterData.num}
                teamCd = {selectedTeam}
                setSelectedGymSq = {props.setSelectedGymSq}
                history = {props.history}
            />
        </>
    )
}

export default YouthClubBranchComponent;