import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setRegulationParams, setIsMore } from '../../../../store/story/rule';
import PaginationComponent from '../../../commons/table/PaginationComponent';
import { getNumberWithComma } from '../../../../helpers/stringHelper';
import { updateDownloadCount } from '../../../../service/story/Story.service';

const RegulationComponent = props => {
    const { regulationList, regulationPagingData, pageSize } = useSelector(state => state.storyRule);
    const dispatch = useDispatch();

    const getDefaultParams = _ => {
        dispatch(setRegulationParams({ start: 0, limit: pageSize, boardCatSq: 22 }));
    }

    const changePage = e => {
        e.preventDefault();
        const value = e.currentTarget.getAttribute('data-value');
        if (value === regulationPagingData.currentPage) {
            return;
        }
        dispatch(setIsMore(false));
        regulationPagingData.start = (value - 1) * pageSize;
        regulationPagingData.limit = pageSize;
        regulationPagingData.boardCatSq = 22;

        dispatch(setRegulationParams(regulationPagingData));
    }

    const moreView = e => {
        e.preventDefault();
        const value = e.currentTarget.getAttribute('data-value');
        if (value === regulationPagingData.currentPage) {
            return;
        }
        dispatch(setIsMore(true));
        regulationPagingData.start = (value - 1) * pageSize;
        regulationPagingData.limit = pageSize;
        regulationPagingData.boardCatSq = 22;

        dispatch(setRegulationParams(regulationPagingData));
    }
    const updateDownloadCnt = async e => {
        await updateDownloadCount(e.currentTarget.dataset.key);
        regulationPagingData.boardCatSq = 22;
        dispatch(setRegulationParams(regulationPagingData));
    }

    useEffect(() => {
        getDefaultParams();
    }, []);

    return (
        <div className={`con-box`}>
            <ul className={`rule-list-wrap`}>
                {
                    regulationList.data && !regulationList.loading ?
                        regulationList.data.map((item, index) =>
                            <li>
                                <div className={`img`} style={{backgroundImage: `url(${item.mediaListThumbPath})`}} />
                                <div className={`txt`}>
                                    <h6>{item.title}</h6>
                                    <ul className={`flex-txt`}>
                                        <li><h6>다운로드</h6><p>{getNumberWithComma(item.downCnt)}</p></li>
                                    </ul>
                                </div>
                                <button>
                                    <a href={item.mediaPath} target={"_blank"} onClick={updateDownloadCnt} data-key={item.mediaSq}>
                                        PDF 다운로드
                                    </a>
                                </button>
                            </li>
                        )
                        :
                        null
                }
            </ul>
            {
                regulationPagingData ?
                    <PaginationComponent
                        pagingData={regulationPagingData}
                        changePage={changePage}
                        moreView={moreView}
                    />
                    : null
            }
        </div>
    );
}

export default RegulationComponent;