import * as ApiService from '../Api.service'

const preFix = '/web';

export const getList = async (params = {}) => {
    console.log('Media.service:getList');
    const url = preFix + '/articles';

    return ApiService.get(url, params);
}

export const getYouthList = async (params = {}) => {
    console.log('Media.service:getList');
    const url = preFix + '/youth/articles';

    return ApiService.get(url, params);
}

export const getInfo = async id => {
    console.log('Media.service:getInfo');
    const url = preFix + `/articles/${id}`;

    return ApiService.get(url);
}

export const getYouthInfo = async id => {
    console.log('Media.service:getInfo');
    const url = preFix + `/youth/articles/${id}`;

    return ApiService.get(url);
}

export const updateViewCount = async id => {
    console.log('Media.service:updateViewCount');
    const url = preFix + `/articles/viewcnt/${id}`;

    return ApiService.put(url);
}

export const updateYouthViewCount = async id => {
    console.log('Media.service:updateViewCount');
    const url = preFix + `/youth/articles/viewcnt/${id}`;

    return ApiService.put(url);
}

export const getReporterContentList = async (params = {}) => {
    console.log('Media.service:getReporterContentList');
    const url = preFix + `/articles/reporters`;

    return ApiService.get(url, params);
}