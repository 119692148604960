import React, { useState, useEffect } from 'react';
import * as CommonHelper from '../../../helpers/commonHelper'
import PropTypes from 'prop-types';

const FilterComponent = props => {
    const { type, selectedValue, selectList, selectChange, optionClass, selectedKey, zIndex, disabled } = props;

    const clickItem = e => e.currentTarget.classList.contains('on') ? e.currentTarget.classList.remove('on') : e.currentTarget.classList.add('on')
    const clickOption = e => {
        e.preventDefault();
        const key = e.target.children[e.target.selectedIndex].getAttribute('data-key');
        const value = e.target.children[e.target.selectedIndex].getAttribute('data-value');
        type ? selectChange(type, key, value) : selectChange(key, value);
    }

    const getValueFromKey = key => {
        const obj = selectList.find(item => {
        return item.key === key;
        });

        return obj ? obj.value : '';
    }

    const optionStyle = zIndex ? { zIndex } : {};

  return (
      selectList ?
          <select style={optionStyle} onChange={clickOption} value={selectedKey ? selectedKey : selectedValue} disabled={disabled ? disabled : false}>
            {
              selectList.map((item, index) =>
                  <option key={index} data-key={item.key} data-value={item.value} selected={selectedKey === item.key} value={selectedKey? item.key : item.value}>{item.value}</option>
              )
            }
          </select>
          : null
  );
}


FilterComponent.propTypes = {
  type: PropTypes.string,
  selectList: PropTypes.array,
  selectChange: PropTypes.func,
  // optionClass: PropTypes.string,
  selectedKey: PropTypes.string,
};

export default FilterComponent;