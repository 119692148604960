import { getUA } from "react-device-detect";

export const appUserAgentKey = 'KblWebView';
export let appTokenValues = null;

export const getPagingData = (start, pageInfo, pageSize) => {
    if (!pageInfo) {
        return null;
    }

    const last = Number(start) + pageSize;
    let totalCount = 0;
    if(pageInfo.totalPage > 0) {
        if(pageInfo.pageMod > 0){
            totalCount = pageInfo.totalPage * pageSize - (pageSize - pageInfo.pageMod);
        } else {
            totalCount = pageInfo.totalPage * pageSize;
        }
    } else {
        totalCount = pageInfo.pageMod;
    }
    const currentPage = last / pageSize;
    const totalPage = pageInfo.totalPage;
    const from = Math.floor((currentPage - 1) / 10) * 10 + 1;
    const to = from + 9 < totalPage ? from + 9 : totalPage;
    return { currentPage, totalPage, from, to, totalCount }
}

export const getQueryStringFromObject = (slug = {}) => {
    return '?' + Object.keys(slug).filter(key => (slug[key] != null && slug[key] !== 'null'))
        .map(key => key + '=' + slug[key]).join('&');
}


// 모바일 App Header Value, UserAgent로 전달
export const getMobileAppHeaderData = _ => {
    if (!getUA.includes(appUserAgentKey)) {
        return null;
    }

    const splitedValueList = getUA.split(' ')

    let kblUserAgentValue = '';

    splitedValueList.map(item => {
        if (item.startsWith(appUserAgentKey)) {
            kblUserAgentValue = item;
        }
    })

    const mobileAppHeaderValues = kblUserAgentValue.split(';');

    if (mobileAppHeaderValues.length != 7) {
        return null;
    }

    if (!appTokenValues) {
        setAppTokenValues({
            accessToken: mobileAppHeaderValues[5],
            refreshToken: mobileAppHeaderValues[6]
        });
    }

    return {
        channel: mobileAppHeaderValues[1],
        teamCode: mobileAppHeaderValues[2],
        appVersion: mobileAppHeaderValues[3],
        device: mobileAppHeaderValues[4],
        accessToken: appTokenValues.accessToken,
        refreshToken: appTokenValues.refreshToken,
    };
}

export const setAppTokenValues = values => {
    appTokenValues = { accessToken: values.accessToken, refreshToken: values.refreshToken };
};