import React, { useEffect, useState } from 'react';
import {getTermsList} from '../../../../service/member/Member.service'

const UsageTermsComponent = props => {
    //const [termsList, setTermsList] = useState(null)
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');

    const getContentInfo = async _ => {
        const result = await getTermsList({ termsKind: '' });

        if (result) {
            const privacyTerms = result.find(item => item.termsType === '2');

            setContent(privacyTerms ? privacyTerms.contents : '');
            setTitle(privacyTerms ? privacyTerms.title : '')
        }
    }

    useEffect(_ => {
        getContentInfo();

        return function cleanup() {
            console.log('sageTermsComponent::componentWillUnmount');
            window.scrollTo(0, 0);
        }
    }, [])

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>이용약관</h2>
                <p>이용약관 안내 페이지입니다.</p>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>{title}</h4>
                </div>
                <div className={`terms-wrap`}>
                    {
                        content.split('\n').map((line, idx) => (<React.Fragment key={`terms-${idx}`}>{line}<br /></React.Fragment>))
                    }
                </div>
            </div>


        </div>
    )

}
export default UsageTermsComponent;