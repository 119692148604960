import React, { useState, useEffect } from 'react';

const EmptyContentComponent = props => {
    const { content } = props;

    return(
        <div className={`con-empty`}>
            <i className={`img-empty01`} />
            <h6>{content ? content : '데이터가 없습니다.'}</h6>
        </div>
    )
}

export default EmptyContentComponent;