import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { getQueryStringFromObject } from '../../../../../helpers/apiHelper';
import {isEmptyObject} from "../../../../../helpers/objectHelper";
import {setIsMore, setYouthVideoParams, setTopVideoEmpty, setTopVideoId} from "../../../../../store/youth/content";
import YouthVideoListComponent from "./YouthVideoListComponent";
import * as CommonHelper from "../../../../../helpers/commonHelper";
import {getYouthGameList} from "../../../../../service/youth/Match.service";

const YouthVideoListContainer = props => {
  const { history, location, match } = props;
  const { videoList, videoParams, videoPagingData, videoPageSize, topVideo } = useSelector(state => state.youthContent);
  const [seasonList, setSeasonList] = useState(null);
  const [season, setSeason] = useState('all');
  const [gameList, setGameList] = useState([{key: 'all', value: '전체'}]);
  const [game, setGame] = useState('all');
  const [videoType, setVideoType] = useState(null);
  const dispatch = useDispatch();

  const searchParams = {
    boardCode: 'VIDEO',
    boardCatSq: '1',
    season: '',
    game: '',
    start: 0,
    limit: videoPageSize
  }

  const changeVideoId = videoId => {
    dispatch(setTopVideoEmpty());
    dispatch(setTopVideoId(Number(videoId)))
    history.push(`/youth/contents/video/${videoId}${getQueryStringFromObject(videoParams)}`)
  }

  const getDataList = async () => {
    const _seasonSelectList = await CommonHelper.getYouthSeasonSelectList();
    setSeasonList(_seasonSelectList);
  }

  const getGameListBySeason = async (season) => {
    if(!season) return;

    let result = [];
    result.push({key: 'all', value: '전체'});
    if(season !== 'all' && seasonList) {
      let seasonData = seasonList.find(item => item.key == season);
      if (!isEmptyObject(seasonData)) {
        let res = await getYouthGameList({seasonKind: seasonData.seasonKind, seasonCode: season});
        if(res && res.length > 0) {
          res.map(item => {
            let obj = {};
            obj.key = item.gmkey;
            obj.value = `[${item.tcodeName}] ${item.tnameA} : ${item.tnameH}`;
            obj.seasonKind = item.seasonKind;
            result.push(obj);
          })
        }
      }
    }
    setGameList(result);
  }

  const setDefaultParams = async () => {
    dispatch(setTopVideoEmpty());

    // 메인에서 id로 들어온 경우
    const id = match.params.id;
    if (id) {
      dispatch(setTopVideoId(Number(id)))
    }

    const _queryString = queryString.parse(location.search);

    if (!isEmptyObject(_queryString)) {
      dispatch(setYouthVideoParams(_queryString));
      setVideoType(_queryString.boardCatSq);
      setSeason(_queryString.season);
      setGame(_queryString.game);
      await getGameListBySeason(_queryString.season);
    } else {
      dispatch(setYouthVideoParams(searchParams));
      setVideoType('1');
    }
  }

  const changeParams = async (type, key, value) => {
    if (type === 'changeSeason') {
      if (videoParams.seasonCode === key) {
        return;
      }
      dispatch(setTopVideoEmpty());
      setSeason(key);
      dispatch(setIsMore(false));
      videoParams.season = key != 'all' ? key : '';
      videoParams.game = '';
      videoParams.start = 0;
      videoParams.limit = videoPageSize;
      await getGameListBySeason(key);
    } else if (type === 'changeGame') {
      if (videoParams.boardCatSq === key) {
        return;
      }
      dispatch(setTopVideoEmpty());
      setGame(key);
      dispatch(setIsMore(false));
      videoParams.game = key != 'all' ? key : '';
      videoParams.start = 0;
      videoParams.limit = videoPageSize;
    } else if (type === 'changeVideoType') {
      if (videoParams.boardCatSq === key) {
        return;
      }
      dispatch(setTopVideoEmpty());
      setVideoType(key);
      dispatch(setIsMore(false));
      videoParams.boardCatSq = key;
      videoParams.start = 0;
      videoParams.limit = videoPageSize;
    } else if (type === 'changePage') {
      if (value === videoPagingData.currentPage) {
        return
      }
      dispatch(setTopVideoEmpty());
      dispatch(setIsMore(false));
      videoParams.start = (value - 1) * videoPageSize
      videoParams.limit = videoPageSize;
    } else if (type === 'moreView') {
      if (value === videoPagingData.currentPage) {
        return
      }
      dispatch(setIsMore(true));
      videoParams.start = (value - 1) * videoPageSize;
      videoParams.limit = videoPageSize;
    }
    history.push(`${history.location.pathname}${getQueryStringFromObject(videoParams)}`)

    dispatch(setYouthVideoParams(videoParams));
  }

  useEffect(_ => {
    getDataList();
    return function cleanup() {
      // window.scrollTo(0, 0);
    }
  }, [location])

  useEffect(_ => {
    if(!seasonList) return
    setDefaultParams();
  }, [seasonList])

  return (
    videoList.data && !videoList.loading && !isEmptyObject(topVideo)?
      <YouthVideoListComponent
        history={history}
        changeParams={changeParams}
        list={videoList.data}
        topVideo={topVideo}
        pagingData={videoPagingData}
        seasonList={seasonList}
        season={season}
        gameList={gameList}
        game={game}
        videoType={videoType}
        params={videoParams}
        changeVideoId={changeVideoId}
      />
      : null
  );
}

export default YouthVideoListContainer;