import React, { useRef, useEffect, useState } from "react";
import KaKaoMapSearch from "./KaKaoMapSearch";
import { isMobile } from "react-device-detect";
import KaKaoMapSearchMb from "./KaKaoMapSearchMb";

const KaKaoMapComponent = (props) => {
    const container = useRef(null); //지도를 담을 영영의 DOM 레퍼런스
    const { kakao } = window;

    const [centerAddr, setCenterAddr] = useState("");
    const [searchAddr, setSearchAddr] = useState("");
    const [searchState, setSearchState] = useState("N");

    const [markerArray, setMarkerArray] = useState("");
    const [infowindowArray, setInfowindowArray] = useState("");
    const [defaultMap, setDefaultMap] = useState(null);

    const [selectedGym, setSelectedGym] = useState("");

    const [searchGym, setSearchGym] = useState("");
    const [markerState, setMarkerState] = useState("");

    var lilist = {
        ...props.addrlist
    }
    const firstaddr = {
        ...props.selectedAddr
    }

/*    const styles = {
        chainView : {
            position: 'absolute',
            backgroundColor: 'var(--white01)',
            width: '32rem',
            borderRadius: '.8rem',
            boxShadow: '.4rem .4rem 1.2rem rgba(70, 81, 109, .16)',
            top: '20%',
            left: '50%'
        }
    }*/

    useEffect(()=>{
        setSearchAddr(lilist);
        setCenterAddr(firstaddr.address);
    }, [props.selectedAddr]);
    useEffect( () => {
        createMap();
        setInfowindowArray(infoWindowArr);
        setMarkerArray(markerArr);
    },[centerAddr, searchAddr]);
    useEffect(() => {
        if(markerArray.length>0){
            closeInfoWindow(); //열려있는 모든 인포윈도우 닫음
            // infowindowArray[selectedGym].setMap(defaultMap); //커스텀 오버레이 열기
            var moveLatLon = markerArray[selectedGym].getPosition(); //마커의 현재 위치
            defaultMap.setCenter(moveLatLon); //해당 마커로 센터 설정
            defaultMap.setLevel(3); //지도 레벨 변경
            createOverlay(moveLatLon, searchAddr[selectedGym]);
        }
    }, [selectedGym]);
    useEffect(() => {
        if(markerArray.length>0){
            closeInfoWindow(); //열려있는 모든 인포윈도우 닫음
            // infowindowArray[selectedGym].setMap(defaultMap); //커스텀 오버레이 열기
            var moveLatLon = markerArray[markerState].getPosition(); //마커의 현재 위치
            defaultMap.setCenter(moveLatLon); //해당 마커로 센터 설정
            defaultMap.setLevel(3); //지도 레벨 변경
            createOverlay(moveLatLon, searchAddr[markerState]);
        }
    }, [markerState]);
    useEffect(() => {
        if(Object.keys(searchGym).length>0){
            setSearchAddr(searchGym.gymBranch);
            setCenterAddr(searchGym.firstAddr.address);
        }
    }, [searchGym]);

    var markerArr = []; //마커 배열
    var infoWindowArr = []; //인포윈도우 배열
    //var tempArr = [];

    /*function closeInfoWindowMarker() { //인포윈도우 닫음 (createMap 내부 addListener에서 사용)
        console.log("infoWindowArr1 : ",infoWindowArr);
        for(var idx=0; idx<infoWindowArr.length; idx++){
            infoWindowArr[idx].setMap(null);
        }
    }*/
    function closeInfoWindow() { //인포윈도우 닫음 (useEffect에서 버튼 클릭시 사용)
        console.log("infoWindowArray : ",infowindowArray);
        if(infowindowArray.length > 0){
            for(var idx=0; idx<infowindowArray.length; idx++){
                infowindowArray[idx].setMap(null);
            }
        }
    }
    function createOverlay(tempaddr, searchAddr){
        var iwContent = "<div class='chain-view' style='top: 20%, left: 50%'>" +
            "                    <div class='title'>" +
            "                        <h4>" + searchAddr.title + "</h4>" +
            "                        <i class='ic-pop-close-24 closeBtn'></i>" +
            "                    </div>" +
            "                    <div class='cont'>" +
            "                        <ul>";
            if(Object.keys(searchAddr.address).length > 0){
                iwContent += "          <li>" +
                    "                       <h6>위치안내</h6>" +
                    "                       <p>" + searchAddr.address + "</p>" +
                    "                   </li>" ;
            }
            if(Object.keys(searchAddr.tel).length > 0 && searchAddr.tel != ' '){
                iwContent += "          <li>" +
                    "                       <h6>문의전화</h6>" +
                    "                       <p>" + searchAddr.tel + "</p>" +
                    "                   </li>" ;
            }
            if(searchAddr.time != null){
                iwContent += "          <li>" +
                    "                       <h6>문의시간</h6>" +
                    "                       <p>" + searchAddr.time + "</p>" +
                    "                   </li>" ;
            }
            iwContent += "           </ul>" +
            "                        <button class='pb movePageBtn'>지점 안내 이동</button>" +
            "                    </div>" +
            "                </div>";

        if(isMobile){
            var overlayAddr = new kakao.maps.LatLng(tempaddr.Ma + 0.011, tempaddr.La - 0.0055); //세, 가
            var centerAddr = new kakao.maps.LatLng(tempaddr.Ma + 0.005, tempaddr.La);
        }
        else{
            var overlayAddr = new kakao.maps.LatLng(tempaddr.Ma  + 0.0015, tempaddr.La  + 0.001); //세, 가
        }
        var overlay = new kakao.maps.CustomOverlay({
            content : iwContent,
            map: defaultMap,
            position: overlayAddr
        });

        if(isMobile){
            defaultMap.setCenter(centerAddr); //해당 마커로 센터 설정
            defaultMap.setLevel(5); //지도 레벨 변경
        }

        overlay.setMap(defaultMap);
        infowindowArray.push(overlay);

        const temss = document.querySelector('.closeBtn');
        if(temss != null){
            temss.onclick = function() {
                closeInfoWindow();
            };
        }
        const _pb = document.querySelector('.movePageBtn');
        if(_pb != null){
            _pb.onclick = function(){
                moveInfoPage(searchAddr.infoSq);
            }
        }

    }
    function moveInfoPage(gymSq){
        props.history.push(`/youth/club/info/${gymSq}`);
    };

    const geocoder = new kakao.maps.services.Geocoder(); //주소-좌표 변환 객체 생성

    const addressSearch = address => {
        return new Promise((resolve, reject) => {
            geocoder.addressSearch(address, function(result, status) {
                if (status === kakao.maps.services.Status.OK) {
                    resolve({"lat": result[0].y, "lng": result[0].x});
                } else {
                    reject(status);
                }
            });
        });
    };

    function createMap() {
        // const geocoder = new kakao.maps.services.Geocoder(); //주소-좌표 변환 객체 생성
        //주소로 좌표 검색
        geocoder.addressSearch(centerAddr, function(result, status) {
            if(status === kakao.maps.services.Status.OK){ //검색 성공시

                const coords = new kakao.maps.LatLng(result[0].y, result[0].x); //센터 좌표 설정

                const mapOptions = {
                    mapTypeId: kakao.maps.MapTypeId.PLAN,
                    $scale: false,
                    center: coords, //좌표
                    level: 10 //확대 레벨
                };

                const map = new kakao.maps.Map(container.current, mapOptions); //지도 생성
                setDefaultMap(map);
                map.setCenter(coords); //센터 설정

                //test
                /*for(let i=0; i<Object.keys(searchAddr).length; i++){
                     geocoder.addressSearch(searchAddr[i].address, function(result, status){
                        console.log("SEARCH ::: ", searchAddr[i], " / ", searchAddr[i].address, " ( ", status, " ) ");

                        if(status === kakao.maps.services.Status.OK){
                            /!* 주소검색(위도,경도) *!/
                            const tempaddr = new kakao.maps.LatLng(result[0].y, result[0].x);

                            /!* 마커 *!/
                            var marker = new kakao.maps.Marker({ //마커 생성
                                map: map,
                                position: tempaddr,
                                clickable: true
                            });
                            markerArr.push(marker);
                            marker.setMap(map); //마커 지도에 set

                            /!* 커스텀 오버레이 *!/
                            /!*var iwContent = "<div class='chain-view' style='top: 20%, left: 50%'>" +
                                "                    <div class='title'>" +
                                "                        <h4>" + searchAddr[i].deps2 + "</h4>" +
                                "                        <i class='ic-pop-close-24'></i>" +
                                "                    </div>" +
                                "                    <div class='cont'>" +
                                "                        <ul>" +
                                "                            <li>" +
                                "                                <h6>위치안내</h6>" +
                                "                                <p>" + searchAddr[i].addr + "</p>" +
                                "                            </li>" +
                                "                            <li>" +
                                "                                <h6>문의전화</h6>" +
                                "                                <p>" + searchAddr[i].phone + "</p>" +
                                "                            </li>" +
                                "                            <li>" +
                                "                                <h6>문의시간</h6>" +
                                "                                <p>" + searchAddr[i].time + "</p>" +
                                "                            </li>" +
                                "                        </ul>" +
                                "                        <button class='pb'>지점 안내 이동</button>" +
                                "                    </div>" +
                                "                </div>";*!/

                            /!*var iwContent = "<div class='chain-view'>d</div>";

                            const overlayAddr = new kakao.maps.LatLng(tempaddr.Ma  + 0.0015, tempaddr.La  + 0.001); //세, 가
                            var overlay = new kakao.maps.CustomOverlay({
                                content : iwContent,
                                position: overlayAddr
                            });*!/
                            // const temss = document.querySelector('.ic-pop-close-24');
                            /!*
                            temss.addEventListener('click', function(){
                                closeInfoWindow();
                            });*!/
                            /!*temss.onclick = function() { overlay.setMap(null); };*!/
                            // infoWindowArr.push(overlay);

                            kakao.maps.event.addListener(marker, 'click', function (){
                                // closeInfoWindowMarker(tempaddr, searchAddr[i]); //인포윈도우 다 닫음
                                // createOverlay(tempaddr, searchAddr[i]); //인포윈도우 다 닫음
                                // overlay.setMap(map); //해당 인포윈도우만 표시

                                map.setCenter(tempaddr); //해당 마커로 센터 설정
                                map.setLevel(3); //지도 레벨 변경
                                setMarkerState(i);
                            });
                        }
                    });
                }*/

                (async () => {
                    try{
                        const positions = [];
                        for(let i=0; i<Object.keys(searchAddr).length; i++){
                            const result = await addressSearch(searchAddr[i].address);
                            // console.log(searchAddr[i].title, " / ", searchAddr[i].address, " (", result, ") ");
                            positions.push(result);
                        }

                        var markers = positions.map(function(position, idx){
                            // console.log("positions ::: ", positions);
                            const tempaddr = new kakao.maps.LatLng(position.lat, position.lng);

                            var marker = new kakao.maps.Marker({
                                map: map,
                                position: tempaddr,
                                clickable: true
                            });
                            markerArr.push(marker);
                            marker.setMap(map); //마커 지도에 set

                            kakao.maps.event.addListener(marker, 'click', function (){
                                defaultMap.setCenter(tempaddr); //해당 마커로 센터 설정
                                defaultMap.setLevel(3); //지도 레벨 변경
                                setMarkerState(idx);
                            });
                            return marker;
                        });
                    }catch(e){
                        console.log("주소 검색 실패 : ", e);
                    }
                })();

                setSearchState("Y");
            }
            else{
                console.log("주소 검색 실패");
                const coords = new kakao.maps.LatLng(37.5161907196547, 127.02084946446956); //센터 좌표 설정

                const mapOptions = {
                    center: coords, //좌표
                    level: 11 //확대 레벨
                };

                const map = new kakao.maps.Map(container.current, mapOptions); //지도 생성
                setDefaultMap(map);
                map.setCenter(coords); //센터 설정
            }
        });
    }

    return(
        isMobile && isMobile === true ?
            <div className={`mobile-only chain-search-mobile`}>
                <KaKaoMapSearchMb
                    filterData = {props.addrlist} //필터로 걸러진 데이터(구단별 지점 list)
                    setSelectedGym = {setSelectedGym} //지점 버튼 선택 함수
                    setSearchGym = {setSearchGym} //지점 검색 결과 함수(지점 list)
                    setCenterAddr = {setCenterAddr} //지점 검색 결과 함수(center용 첫번째 addr)
                    teamCd = {props.teamCd} //팀 코드
                />
                <div
                    className = "chain-map-wrap"
                    ref = {container}
                >
                </div>
            </div>

            :

            <div
                className = "chain-map-wrap"
                ref = {container}
            >
                {
                    searchState && searchState === 'Y' ?
                        <KaKaoMapSearch
                            filterData = {props.addrlist} //필터로 걸러진 데이터(구단별 지점 list)
                            setSelectedGym = {setSelectedGym} //지점 버튼 선택 함수
                            setSearchGym = {setSearchGym} //지점 검색 결과 함수(지점 list)
                            setCenterAddr = {setCenterAddr} //지점 검색 결과 함수(center용 첫번째 addr)
                            teamCd = {props.teamCd} //팀 코드
                        />
                        : null
                }

            </div>
    );
}


export default KaKaoMapComponent;
