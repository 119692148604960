import * as ApiService from '../Api.service'

const preFix = '/web';

export const getAllTermsInfo = async () => {
    console.log('Member.service:getAllTermsInfo');
    const url = `/terms`;

    return ApiService.get(url);
}

export const getTermsInfo = async (termsType) => {
    console.log('Member.service:getTermsInfo');
    const url = `/web/terms/${termsType}`;

    return ApiService.get(url);
}

export const getTermsAgree = async (termsType) => {
    console.log('Member.service:getTermsAgree');
    const url = `/terms/agree/${termsType}`;

    return ApiService.get(url);
}

export const postTermsAgree = async (termsType, agree) => {
    console.log('Member.service:postTermsAgree');
    const url = `/auth/terms/agree`;

    return ApiService.post(url, {
        termsType: termsType,
        agree: agree
    });
}

export const getTermsList = async params => {
    console.log('Member.service:getTermsList');
    const url = `/web/terms`;

    return ApiService.get(url, params);
}

export const checkDuplicatedId = async (newid) => {
    console.log('Member.service:checkDuplicatedId');
    const url = `/web/members/check/id/${newid}`;

    return ApiService.get(url);
}

export const signup = async (parmas) => {
    console.log('Member.service:signup');
    const url = `/members`;

    return ApiService.post(url, parmas);
}

export const getMemberInfo = async () => {
    console.log('Member.service:getMemberInfo');
    const url = preFix + `/members`;

    return ApiService.get(url);
}

export const checkinList = async () => {
    console.log('Member.service:checkinList');
    const url = `/members/checkinList`;

    return ApiService.get(url);
}

export const checkinReward = async (params) => {
    console.log('Member.service:checkinReward');
    const url = `/members/checkinReward`;

    return ApiService.post(url, params);
}

export const getProductList = async () => {
    console.log('Member.service:getMemberInfo');
    const url = `/web/members/goods`;

    return ApiService.get(url);
}

export const getInquiryList = async (params) => {
    console.log('Member.service:getInquiryList');
    const url = `/web/members/questions`;

    return ApiService.get(url, params);
}

export const getTicketList = async (params) => {
    console.log('Member.service:getTicketList');
    const url = `/members/tickets`;

    return ApiService.get(url, params);
}

export const getCouponList = async (params) => {
    console.log('Member.service:getCouponList');
    const url = `/members/coupons`;

    return ApiService.get(url, params);
}

export const getRefundAccountInfo = async () => {
    console.log('Auth.service:getRefundAccountList');
    const url = '/members/refund-account';

    return ApiService.get(url);
}

export const resetPwd = async (params) => {
    console.log('Auth.service:resetPwd');
    const url = '/web/members/password/reset';

    return ApiService.put(url, params);
}

export const confirmPwd = async (params) => {
    console.log('Auth.service:confirmPwd');
    const url = '/web/members/password/confirm';

    return ApiService.post(url, params);
}

export const registRefundAccount = async (params) => {
    console.log('Auth.service:registRefundAccount');
    const url = '/members/refund-account';

    return ApiService.post(url, params);
}

export const updateRefundAccount = async (params) => {
    console.log('Auth.service:updateRefundAccount');
    const url = '/members/refund-account';

    return ApiService.put(url, params);
}

export const updateMemberInfo = async (params) => {
    console.log('Auth.service:updateMemberInfo');
    const url = preFix + '/members';

    return ApiService.patch(url, params);
}

export const getTicketReservationInfo = async (reservationId, params) => {
    console.log('Auth.service:getTicketReservationInfo');
    const url = `/members/tickets/${reservationId}`;

    return ApiService.get(url, params);
}

export const getTicketPartialCancelCommission = async (reservationId, ticketIdList) => {
    console.log('Auth.service:getTicketPartialCancelCommission');
    const url = `/members/tickets/commissions/${reservationId}/${ticketIdList}`;

    return ApiService.get(url);
}


export const cancelTicketReservation = async (reservationId) => {
    console.log('Auth.service:cancelTicketReservation');
    const url = `/members/tickets/${reservationId}`;

    return ApiService.remove(url);
}

export const cancelTicketPartialReservation = async (reservationId, ticketIdList) => {
    console.log('Auth.service:cancelTicketReservation');
    const url = `/members/tickets/${reservationId}/${ticketIdList}`;

    return ApiService.remove(url);
}

export const deleteMemberInfo = async (params) => {
    console.log('Auth.service:deleteMemberInfo');
    const url = '/members';

    return ApiService.remove(url, params);
}

export const resetMemberPwd = async (params) => {
    console.log('Auth.service:resetMemberPwd');
    const url = '/web/members/password';

    return ApiService.put(url, params);
}

export const certedMobilePhoneNumberChanged = async (params) => {
    console.log('Auth.service:certedMobilePhoneNumberChanged');
    const url = '/members/certs';

    return ApiService.patch(url, params);
}

export const addInquireInfo = async (params) => {
    console.log('Auth.service:addInquireInfo');
    const url = '/web/members/questions';

    return ApiService.post(url, params);
}

export const addYouthInquireInfo = async (params) => {
    console.log('Auth.service:addYouthInquireInfo');
    const url = '/web/members/youth/questions';

    return ApiService.post(url, params);
}

export const updateInquireReplyScore = async (params) => {
    console.log('Auth.service:updateInquireReplyScore');
    const url = `/web/members/questions`;

    return ApiService.patch(url, params);
}

export const getInquireInfo = async (id) => {
    console.log('Auth.service:getInquireInfo');
    const url = `/web/members/questions/${id}`;

    return ApiService.get(url);
}


export const deleteRequestItem = async (id) => {
    console.log('Auth.service:deleteRequestItem');
    const url = `/web/members/questions/${id}`;

    return ApiService.remove(url);
}

export const getReceiveAgreeAll = async () => {
    console.log('Auth.service:getReceiveAgreeAll');
    const url = `/receive/agree/all`;

    return ApiService.get(url);
}

export const issueEventCoupon = async () => {
    console.log('Auth.service:issueEventCoupon');
    const url = `/coupon/event`;

    return ApiService.post(url);
}

export const addKblExperience = async (params) => {
    console.log('Member.service:addKblExperience');
    const url = '/members/experience';

    return ApiService.post(url, params);
}

export const cancelSleeper = async (id) => {
    console.log('Member.service:cancelSleeper');
    const url = `/sleeper/cancel/${id}`;

    return ApiService.put(url);
}

export const updateMarketingAgree = async () => {
    console.log('Member.service:updateMarketingAgree');
    const url = `/agree/marketing`;

    return ApiService.patch(url);
}

export const getMemberId = async (parmas) => {
    console.log('Member.service:getMemberId');
    const url = `/members/youth/search`;

    return ApiService.get(url, parmas);
}

export const pushForeignerSMS = async (params) => {
    console.log('Member.service:pushForeignerSMS');
    const url = `/auth/web-cert/foreigner/phone/call`;

    return ApiService.post(url, params);
}

export const checkForeignerSMS = async (params) => {
    console.log('Member.service:checkForeignerSMS');
    const url = `/auth/web-cert/foreigner/phone/check`;

    return ApiService.post(url, params);
}

export const certedMobilePhoneNumberChangedForeigner = async (params) => {
    console.log('Auth.service:certedMobilePhoneNumberChangedForeigner');
    const url = '/members/certs/foreigner';

    return ApiService.patch(url, params);
}

export const updateMemberInfoForeigner = async (params) => {
    console.log('Auth.service:updateMemberInfoForeigner');
    const url = '/members/foreigner';

    return ApiService.patch(url, params);
}

export const getTimeChk = async (params) => {
    console.log('Member.service:getTimeChk');
    const url = `/test/timeChk`;

    return ApiService.get(url, params);
}