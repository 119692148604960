import React, {useEffect, useState} from 'react';
import {getYouthMeasurementAvgRecord, getYouthMeasurementYearList} from "../../../../service/youth/Measurement.service";

const MeasurementRecordComponent = props => {
    const {} = props;

    const [year, setYear] = useState('2023');
    const [yearList, setYearList] = useState(null);
    const [avgTypeKey, setAvgTypeKey] = useState('');
    const [subAvgTypeKey, setSubAvgTypeKey] = useState('');
    const [avgTypeName, setAvgTypeName] = useState(null);
    const [subStat, setSubStat] = useState(false);

    const [avgData, setAvgData] = useState(null);
    const [subAvgData, setSubAvgData] = useState(null);

    const [isDetailPopupDisplay, setIsDetailPopupDisplay] = useState(false);
    const [isSubDetailPopupDisplay, setIsSubDetailPopupDisplay] = useState(false);

    const schoolSelectList = [
        {key: 'G3N3' , value: '고3'},
        {key: 'G3N2' , value: '고2'},
        {key: 'G3N1' , value: '고1'},
        {key: 'G2N3' , value: '중3'},
        {key: 'G2N2' , value: '중2'},
        {key: 'G2N1' , value: '중1'},
        {key: 'G1N6' , value: '초6'},
        {key: 'G1N5' , value: '초5'},
        {key: 'G1N4' , value: '초4'},
        {key: 'G1N3' , value: '초3'},
        {key: 'G1N2' , value: '초2'},
        {key: 'G1N1' , value: '초1'},
    ]

    const unitList = [
        {key: 'height', value: 'cm'},
        {key: 'arm_length', value: 'cm'},
        {key: 'standing_reach', value: 'cm'},
        {key: 'muscle_mass', value: ''},
        {key: 'body_fat_per', value: ''},
        {key: 'grip_strength', value: ''},
        {key: 'pushup_cnt', value: ''},
        {key: 'v_jump', value: 'cm'},
        {key: 'run_v_jump', value: 'cm'},
        {key: 'lane_agility', value: ''},
        {key: 'yard_sprint', value: ''},
        {key: 'court_sprint', value: ''},
        {key: 'shuttle_run', value: ''},
    ]

    const getRecordData = async () => {
        const record = await getYouthMeasurementAvgRecord({ year: year, type: avgTypeKey });
        setAvgData(record);

        if(subStat){
            const records = await getYouthMeasurementAvgRecord({ year: year, type: subAvgTypeKey });
            setSubAvgData(records);
        }
    }

    const changeParams = (type, key, value = true) => {
        if (type === 'changeYear') {
            setYear(key);
        } else if (type === 'changeType') {
            value ? setAvgTypeKey(key) : setSubAvgTypeKey(key);
        }
    }

    const clickRecordDetail = (e, key, value) => {
        e.preventDefault();
        setAvgTypeName(value);
        setSubStat(false);
        changeParams('changeType', key);
        openRecordPopup();
    }
    const clickSubRecordDetail = (e, key, value, subKey) => {
        e.preventDefault();
        setAvgTypeName(value);
        setSubStat(true);
        changeParams('changeType', key, true);
        changeParams('changeType', subKey, false);
        openSubRecordPopup();
    }

    const openRecordPopup = () => {
        // e.preventDefault();
        setIsDetailPopupDisplay(true);
    }
    const closeRecordPopup = (e) => {
        e.preventDefault();
        setIsDetailPopupDisplay(false);
    }
    const openSubRecordPopup = () => {
        // e.preventDefault();
        setIsSubDetailPopupDisplay(true);
    }
    const closeSubRecordPopup = (e) => {
        e.preventDefault();
        setIsSubDetailPopupDisplay(false);
    }

    useEffect(() => {
        console.log('MeasurementRecordComponent::componentDidMount');

        const setDefaultInfo = async () => {
            const result = await getYouthMeasurementYearList();
            if (result && (Array.isArray(result))) {
                let list = [];
                let obj = {};
                result.map(((value, index) => {
                    obj = {key: value, value: value};
                    list.push(obj);
                }))
                setYearList(list);
                setYear(list[0].key);
            }
        }
        setDefaultInfo();

        return function cleanup() {
            console.log('MeasurementRecordComponent::componentWillUnmount');
        }
    }, []);

    useEffect(() => {
        if(year && avgTypeKey){
            console.log("# avgTypeKey: ", subStat);
            getRecordData();
        }
        if(year && subAvgTypeKey){
            console.log("# subAvgTypeKey: ", subStat);
            getRecordData();
        }
    }, [year, avgTypeKey]);

    return(
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>평균 기록</h2>
                <p>KBL은 유소년 농구 선수들의 올바른 신체 성장과 기량 향상을 위하여 유소년 선수 육성 프로젝트를 시행하고 있습니다.</p>
            </div>

            <div className={`con-box`}>
                <ul className='youth-record-part'>
                    <li>
                        <div>
                            <h3>신체조성</h3>
                            <ul>
                                <li style={{cursor: 'pointer'}} onClick={(e) => clickRecordDetail(e, 'height', '신장 측정')}>
                                    <img src='/imgs/youth/physical/score-height.png' />
                                    <p>신장 측정<i className='ic-arrow-right-16'/></p>
                                </li>
                                <li style={{cursor: 'pointer'}} onClick={(e) => clickRecordDetail(e, 'arm_length', '윙스펜 측정')}>
                                    <img src='/imgs/youth/physical/score-wing.png' />
                                    <p>윙스펜 측정<i className='ic-arrow-right-16'/></p>
                                </li>
                                <li style={{cursor: 'pointer'}} onClick={(e) => clickRecordDetail(e, 'standing_reach', '스탠딩리치')}>
                                    <img src='/imgs/youth/physical/score-standing.png' />
                                    <p>스탠딩리치<i className='ic-arrow-right-16'/></p>
                                </li>
                                <li style={{cursor: 'pointer'}} onClick={(e) => clickSubRecordDetail(e, 'muscle_mass', '근육량, 체지방량', 'body_fat_per')}>
                                    <img src='/imgs/youth/physical/score-kg.png' />
                                    <p>근육량, 체지방량<i className='ic-arrow-right-16'/></p>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h3>근력</h3>
                            <ul>
                                <li style={{cursor: 'pointer'}} onClick={(e) => clickRecordDetail(e, 'grip_strength', '악력 측정')}>
                                    <img src='/imgs/youth/physical/score-grip.png' />
                                    <p>악력 측정<i className='ic-arrow-right-16'/></p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>근지구력</h3>
                            <ul>
                                <li style={{cursor: 'pointer'}} onClick={(e) => clickRecordDetail(e, 'pushup_cnt', '푸쉬업 측정')}>
                                    <img src='/imgs/youth/physical/score-pushup.png' />
                                    <p>푸쉬업 측정<i className='ic-arrow-right-16'/></p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>순발력 및 점프력</h3>
                            <ul>
                                <li style={{cursor: 'pointer'}} onClick={(e) => clickRecordDetail(e, 'v_jump', '서전트 점프')}>
                                    <img src='/imgs/youth/physical/score-sargent.png' />
                                    <p>서전트 점프<i className='ic-arrow-right-16'/></p>
                                </li>
                                <li style={{cursor: 'pointer'}} onClick={(e) => clickRecordDetail(e, 'run_v_jump', '맥스버티컬 점프')}>
                                    <img src='/imgs/youth/physical/score-max.png' />
                                    <p>맥스버티컬 점프<i className='ic-arrow-right-16'/></p>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h3>방향전환 및 민첩성</h3>
                            <ul>
                                <li style={{cursor: 'pointer'}} onClick={(e) => clickRecordDetail(e, 'lane_agility', '레인어질리티')}>
                                    <img src='/imgs/youth/physical/score-rain.png' />
                                    <p>레인어질리티<i className='ic-arrow-right-16'/></p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>가속도 및 속도</h3>
                            <ul>
                                <li style={{cursor: 'pointer'}} onClick={(e) => clickRecordDetail(e, 'yard_sprint', '10야드 스프린트')}>
                                    <img src='/imgs/youth/physical/score-10y.png' />
                                    <p>10야드 스프린트<i className='ic-arrow-right-16'/></p>
                                </li>
                                <li style={{cursor: 'pointer'}} onClick={(e) => clickRecordDetail(e, 'court_sprint', '¾스프린트')}>
                                    <img src='/imgs/youth/physical/score-3/4.png' />
                                    <p>¾스프린트<i className='ic-arrow-right-16'/></p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>심폐 능력</h3>
                            <ul>
                                <li style={{cursor: 'pointer'}} onClick={(e) => clickRecordDetail(e, 'shuttle_run', '300야드 셔틀런')}>
                                    <img src='/imgs/youth/physical/score-300y.png' />
                                    <p>300야드 셔틀런<i className='ic-arrow-right-16'/></p>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h3>퍼포먼스 및 부상 예방</h3>
                            <ul>
                                <li>
                                    <img src='/imgs/youth/physical/score-science.png' />
                                    <p>과학화 장비 측정</p>
                                </li>
                                <li>
                                    <img src='/imgs/youth/physical/score-ybalance.png' />
                                    <p>y balance test</p>
                                </li>
                                <li>
                                    <img src='/imgs/youth/physical/score-fms.png' />
                                    <p>FMS test</p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>측정결과 피드백</h3>
                            <ul>
                                <li>
                                    <img src='/imgs/youth/physical/score-feedback.png' />
                                    <p>측정결과 피드백</p>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>

            <div className={`popup`} style={{ display: isDetailPopupDisplay ? 'block' : 'none' }}>
                {
                    avgData ?
                        <div className={`pop-wrap`}>
                            <div className={`pop-tit`}>
                                <h4>{avgTypeName}</h4>
                                <i className={`ic-pop-close-24`} onClick={closeRecordPopup} />
                            </div>
                            <div className={`pop-con`}>
                                <div className='table-desc'>
                                    <select onChange={(e) => changeParams('changeYear', e.target.value)}>
                                    {
                                        yearList && yearList.length > 0 ?
                                            yearList.map((item) =>
                                                <option value={item.key}>{item.value}</option>
                                            )
                                        :
                                            <option>선택</option>
                                    }
                                    </select>
                                </div>
                                <div style={{marginTop: '1.2rem'}}>
                                    <table>
                                        <colgroup>
                                            <col style={{width: '33%'}}/>
                                            <col style={{width: '33%'}}/>
                                            <col style={{width: '33%'}}/>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>학년</th>
                                                <th>최고기록</th>
                                                <th>평균기록</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            avgData.map((item, idx) =>
                                                <tr>
                                                    <td>{schoolSelectList.find(list => list.key === item.school).value}</td>
                                                    <td>{item.maxValue}{unitList.find(list => list.key === item.type).value}</td>
                                                    <td>{item.avgValue}{unitList.find(list => list.key === item.type).value}</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={`pop-btn`}>
                                <button className={`pb`} onClick={closeRecordPopup}>확인</button>
                            </div>
                        </div>
                    :
                        null
                }
            </div>

            <div className={`popup`} style={{ display: isSubDetailPopupDisplay ? 'block' : 'none' }}>
                {
                    avgData && subAvgData ?
                        <div className={`pop-wrap`}>
                            <div className={`pop-tit`}>
                                <h4>{avgTypeName}</h4>
                                <i className={`ic-pop-close-24`} onClick={closeSubRecordPopup} />
                            </div>
                            <div className={`pop-con`}>
                                <div className='table-desc'>
                                    <select onChange={(e) => changeParams('changeYear', e.target.value)}>
                                        {
                                            yearList && yearList.length > 0 ?
                                                yearList.map((item) =>
                                                    <option value={item.key}>{item.value}</option>
                                                )
                                                :
                                                <option>선택</option>
                                        }
                                    </select>
                                </div>

                                {
                                    !avgTypeName ? null :
                                        <p>{avgTypeName.substring(0, avgTypeName.lastIndexOf(','))}</p>
                                }
                                <div style={{marginTop: '1.2rem'}}>
                                    <table>
                                        <colgroup>
                                            <col style={{width: '33%'}}/>
                                            <col style={{width: '33%'}}/>
                                            <col style={{width: '33%'}}/>
                                        </colgroup>
                                        <thead>
                                        <tr>
                                            <th>학년</th>
                                            <th>최고기록</th>
                                            <th>평균기록</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            avgData.map((item, idx) =>
                                                <tr>
                                                    <td>{schoolSelectList.find(list => list.key === item.school).value}</td>
                                                    <td>{item.maxValue}{unitList.find(list => list.key === item.type).value}</td>
                                                    <td>{item.avgValue}{unitList.find(list => list.key === item.type).value}</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    !avgTypeName ? null :
                                        <p>{avgTypeName.substring(avgTypeName.lastIndexOf(',') + 1)}</p>
                                }
                                <div style={{marginTop: '1.2rem'}}>
                                    <table>
                                        <colgroup>
                                            <col style={{width: '33%'}}/>
                                            <col style={{width: '33%'}}/>
                                            <col style={{width: '33%'}}/>
                                        </colgroup>
                                        <thead>
                                        <tr>
                                            <th>학년</th>
                                            <th>최고기록</th>
                                            <th>평균기록</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            subAvgData.map((item, idx) =>
                                                <tr>
                                                    <td>{schoolSelectList.find(list => list.key === item.school).value}</td>
                                                    <td>{item.maxValue}{unitList.find(list => list.key === item.type).value}</td>
                                                    <td>{item.avgValue}{unitList.find(list => list.key === item.type).value}</td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={`pop-btn`}>
                                <button className={`pb`} onClick={closeSubRecordPopup}>확인</button>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        </div>
    );
}
export default MeasurementRecordComponent;