import moment from 'moment';
import 'moment/locale/ko'

export const setKrDayInfo = _ => {
    moment.locale('ko', {
        weekdays: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
        weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"],
    });
}

export const getFormattedDateValue = (time, format = 'YYYY-MM-DD') => {
    setKrDayInfo();
    const _time = time ? moment(time) : moment();
    return _time.format(format);
}

export const getTimeDiffValue = (endTime, startTime) => {
    const _startTime = startTime ? startTime : moment();
    const timeDiffValue = moment.duration(moment(endTime).diff(_startTime));
    return { days: timeDiffValue.days(), hours: timeDiffValue.hours(), minutes: timeDiffValue.minutes(), seconds: timeDiffValue.seconds() }
}

export const isSameDate = (targetDate, refDate) => {
    if (!refDate) {
        return moment().isSame(targetDate);
    } else {
        return moment(refDate).isSame(targetDate);
    }
}

export const isBeforeDate = (targetDate, refDate) => {
    if (!refDate) {
        return moment().isBefore(targetDate);
    } else {
        return moment(refDate).isBefore(targetDate);
    }
}

export const getTimeFormatValue = (seconds, lang = true) => {
    let min = parseInt(seconds / 60);
    let sec = seconds % 60;
    if (min.toString().length === 1) min = "0" + min;
    if (sec.toString().length === 1) sec = "0" + sec;

    return !lang ? `${min} min ${sec} sec` : `${min}분 ${sec}초`;
}

export const getTimeSimpleFormatValue = seconds => {
    let min = parseInt(seconds / 60);
    let sec = seconds % 60;
    if (min.toString().length === 1) min = "0" + min;
    if (sec.toString().length === 1) sec = "0" + sec;

    return `${min}:${sec}`;
}


export const getMonthList = () => {
    const result = [];
    for (let i = 1; i <= 12; i++) {
        let obj = {};
        let v = i < 10 ? `0${i}` : `${i}`
        obj.key = v;
        obj.value = v;
        result.push(obj);
    }

    return result;
}

export const getYearList = () => {
    const startYear = Number(moment().format('YYYY'));
    const result = [];

    for (let i = startYear + 1; i > startYear - 25; i--) {
        let obj = {};
        let v = i < 10 ? `0${i}` : `${i}`
        obj.key = v;
        obj.value = v;
        result.push(obj);
    }

    return result;
}

export const currentDate = () => {
    const currentDate = moment().format('YYYYMMDD');

    return { year: currentDate.substring(0, 4), month: currentDate.substring(4, 6), date: currentDate.substring(6, 8) };
    // return { year: '2021', month: '10', date: '13' };
}

export const getDateFormat = (yyyyMMdd) => {
    if (!yyyyMMdd || yyyyMMdd.length !== 8) {
        return null
    }
    return { year: yyyyMMdd.substring(0, 4), month: yyyyMMdd.substring(4, 6), date: yyyyMMdd.substring(6, 8) };
}

export const nextDate = (year, month, date) => {
    setKrDayInfo();
    const dateInfo = year && month && date ? moment(`${year}-${month}-${date}`) : moment();
    const nextDate = dateInfo.add(1, 'days');
    const nextDateFormatData = nextDate.format('YYYYMMDD');
    const nextDateDay = nextDate.format('ddd');

    return { year: nextDateFormatData.substring(0, 4), month: nextDateFormatData.substring(4, 6), date: nextDateFormatData.substring(6, 8), day: nextDateDay };
}

export const prevDate = (year, month, date) => {
    setKrDayInfo();
    const dateInfo = year && month && date ? moment(`${year}-${month}-${date}`) : moment();
    const prevtDate = dateInfo.subtract(1, 'days');
    const prevtDateFormatData = prevtDate.format('YYYYMMDD');
    const prevtDateDay = prevtDate.format('ddd');
    return { year: prevtDateFormatData.substring(0, 4), month: prevtDateFormatData.substring(4, 6), date: prevtDateFormatData.substring(6, 8), day: prevtDateDay };
}

export const nextWeek = (year, month, date) => {
    const dateInfo = year && month && date ? moment(`${year}-${month}-${date}`) : moment();
    const nextWeek = dateInfo.add(1, 'weeks').format('YYYYMMDD');

    return { year: nextWeek.substring(0, 4), month: nextWeek.substring(4, 6), date: nextWeek.substring(6, 8) };
}

export const prevWeek = (year, month, date) => {
    const dateInfo = year && month && date ? moment(`${year}-${month}-${date}`) : moment();
    const prevWeek = dateInfo.subtract(1, 'weeks').format('YYYYMMDD');

    return { year: prevWeek.substring(0, 4), month: prevWeek.substring(4, 6), date: prevWeek.substring(6, 8) };
}

export const nextMonth = (year, month) => {
    const yearMonth = year && month ? moment(`${year}-${month}`) : moment();
    const nextMonth = yearMonth.add(1, 'months').format('YYYYMM');

    return { year: nextMonth.substring(0, 4), month: nextMonth.substring(4, 6) };
}

export const prevMonth = (year, month) => {
    const yearMonth = year && month ? moment(`${year}-${month}`) : moment();
    const prevMonth = yearMonth.subtract(1, 'months').format('YYYYMM');

    return { year: prevMonth.substring(0, 4), month: prevMonth.substring(4, 6) };
}

export const getMonthDateRange = (year, month) => {
    const yearMonth = year && month ? moment(`${year}-${month}`) : moment();

    const startOfMonth = yearMonth.clone().startOf('month').format('YYYYMMDD');
    const endOfMonth = yearMonth.clone().endOf('month').format('YYYYMMDD');

    return { startDate: startOfMonth, endDate: endOfMonth };
}

export const getWeekDateRange = (year, month, date) => {

    const dateInfo = year && month && date ? moment(`${year}-${month}-${date}`) : moment();
    // mon - sun
    const startOfWeek = dateInfo.clone().startOf('week').add(1, 'd').format('YYYYMMDD');
    const endOfWeek = dateInfo.clone().endOf('week').add(1, 'd').format('YYYYMMDD');

    return { startDate: startOfWeek, endDate: endOfWeek };
}

// YYYY-MM-DD HH:mm:SS 를 YYYY-MM-DD로 변환
export const getSimpleDateFormat = fullFormat => {
    if (!fullFormat) {
        return fullFormat;
    } else {
        return fullFormat.split(' ')[0];
    }
}

export const getDateFormatWithDelim = (yyyyMMdd, delim = '.') => {
    if (!yyyyMMdd || yyyyMMdd.length !== 8) {
        return null
    }
    return `${yyyyMMdd.substring(0, 4)}${delim}${yyyyMMdd.substring(4, 6)}${delim}${yyyyMMdd.substring(6, 8)}`;
}

export const getTimeStamp = () =>{
    return moment().unix();
}