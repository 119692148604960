import * as StoryService from '../../service/story/Story.service';
import {createPromiseThunk, reducerUtils, handleAsyncActions,} from '../../helpers/asyncHelper';
import { getPagingData } from '../../helpers/apiHelper';

// /* 액션 타입 */
const SET_IS_MORE = 'story_rule/SET_IS_MORE';

const SET_RULE_PARAMS = 'story_rule/SET_RULE_PARAMS';
const SET_RULE_PAGING_DATA = 'story_rule/SET_RULE_PAGING_DATA';
const GET_RULE_LIST = 'story_rule/GET_RULE_LIST';
const GET_RULE_LIST_SUCCESS = 'story_rule/GET_RULE_LIST_SUCCESS';
const GET_RULE_LIST_ERROR = 'story_rule/GET_RULE_LIST_ERROR';


const SET_SYSTEM_PARAMS = 'story_rule/SET_SYSTEM_PARAMS';
const SET_SYSTEM_PAGING_DATA = 'story_rule/SET_SYSTEM_PAGING_DATA';
const GET_SYSTEM_LIST = 'story_rule/GET_SYSTEM_LIST';
const GET_SYSTEM_LIST_SUCCESS = 'story_rule/GET_SYSTEM_LIST_SUCCESS';
const GET_SYSTEM_LIST_ERROR = 'story_rule/GET_SYSTEM_LIST_ERROR';


const SET_REGULATION_PARAMS = 'story_rule/SET_REGULATION_PARAMS';
const SET_REGULATION_PAGING_DATA = 'story_rule/SET_REGULATION_PAGING_DATA';
const GET_REGULATION_LIST = 'story_rule/GET_REGULATION_LIST';
const GET_REGULATION_LIST_SUCCESS = 'story_rule/GET_REGULATION_LIST_SUCCESS';
const GET_REGULATION_LIST_ERROR = 'story_rule/GET_REGULATION_LIST_ERROR';

export const getRuleList = createPromiseThunk(GET_RULE_LIST, StoryService.getRulePdfList, SET_RULE_PAGING_DATA, 'medias');
export const getSystemList = createPromiseThunk(GET_SYSTEM_LIST, StoryService.getSystemPdfList, SET_SYSTEM_PAGING_DATA, 'medias');
export const getRegulationList = createPromiseThunk(GET_REGULATION_LIST, StoryService.getRegulationPdfList, SET_REGULATION_PAGING_DATA, 'medias');

export const setRuleParams = params => {
  return dispatch => {
    dispatch({ type: SET_RULE_PARAMS, params });
    dispatch(getRuleList(params));
  }
}

export const setSystemParams = params => {
  return dispatch => {
    dispatch({ type: SET_SYSTEM_PARAMS, params });
    dispatch(getSystemList(params));
  }
}

export const setRegulationParams = params => {
  return dispatch => {
    dispatch({ type: SET_REGULATION_PARAMS, params });
    dispatch(getRegulationList(params));
  }
}

export const setIsMore = value => ({ type: SET_IS_MORE, isMore: value });

const initialState = {
  ruleParams: {},
  ruleList: reducerUtils.initial(),
  rulePagingData: {},
  systemParams: {},
  systemList: reducerUtils.initial(),
  systemPagingData: {},
  regulationParams: {},
  regulationList: reducerUtils.initial(),
  regulationPagingData: {},
  pageSize: 8,
  isMore: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_RULE_PARAMS:
      return {
        ...state,
        ruleParams: action.params
      };
    case GET_RULE_LIST:
    case GET_RULE_LIST_SUCCESS:
    case GET_RULE_LIST_ERROR:
      return handleAsyncActions(GET_RULE_LIST, 'ruleList')(state, action);
    case SET_RULE_PAGING_DATA:
      return {
        ...state,
        rulePagingData: getPagingData(state.ruleParams.start, action.pageInfo, state.pageSize)
      }
    case SET_SYSTEM_PARAMS:
      return {
        ...state,
        systemParams: action.params
      };
    case GET_SYSTEM_LIST:
    case GET_SYSTEM_LIST_SUCCESS:
    case GET_SYSTEM_LIST_ERROR:
      return handleAsyncActions(GET_SYSTEM_LIST, 'systemList')(state, action);
    case SET_SYSTEM_PAGING_DATA:
      return {
        ...state,
        systemPagingData: getPagingData(state.systemParams.start, action.pageInfo, state.pageSize)
      }
    case SET_REGULATION_PARAMS:
      return {
        ...state,
        regulationParams: action.params
      };
    case GET_REGULATION_LIST:
    case GET_REGULATION_LIST_SUCCESS:
    case GET_REGULATION_LIST_ERROR:
      return handleAsyncActions(GET_REGULATION_LIST, 'regulationList')(state, action);
    case SET_REGULATION_PAGING_DATA:
      return {
        ...state,
        regulationPagingData: getPagingData(state.regulationParams.start, action.pageInfo, state.pageSize)
      }
    case SET_IS_MORE:
      return {
        ...state,
        isMore: action.isMore
      }
    default:
      return state;
  }
}