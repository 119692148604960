import React from 'react';
import PaginationComponent from '../../../../commons/table/PaginationComponent';
import {bbsSearchTypeSelectList} from "../../../../../helpers/codeHelper";
import FilterComponent from "../../../../commons/filter/FilterComponent";
import YouthBBSTableComponent from "../../../../commons/table/YouthBBSTableComponent";


const YouthNewsListComponent = props => {
    const { history, changeParams, list, pagingData, searchWord, params, searchType, changeSearchType, changeSearchWord } = props;

/*    const filterTeam = e => changeParams('filterTeam', e);
    const changeTab = e => changeParams('changeTab', e);
 */
    const clickSearch = e => changeParams('clickSearch', e);
    const changePage = e => changeParams('changePage', e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-value'));
    const moreView = e => changeParams('moreView', e.currentTarget.getAttribute('data-key'), e.currentTarget.getAttribute('data-value'));

    const enterKeyPress = e => {
        if (e.key === 'Enter') {
            changeParams('clickSearch', e);
        }
    }

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>뉴스</h2>
                <p>최신 소식을 확인 할 수 있는 공간입니다.</p>
            </div>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4></h4>
                    <div className={`option-wrap`}>
                        <FilterComponent
                            type={'changeSearchType'}
                            selectedKey={searchType}
                            optionClass={'m pc'}
                            selectList={bbsSearchTypeSelectList}
                            selectChange={changeSearchType}
                        />
                        <div className={`search-input`}>
                            <input type='text' value={searchWord} onChange={changeSearchWord} placeholder='검색어를 입력해주세요.' onKeyPress={enterKeyPress}/>
                            <button onClick={clickSearch}><i className={`ic-search-20`} /></button>
                        </div>
                    </div>
                </div>
                <div className='news-section' style={{ display:'block' }}>
                    {
                        list ?
                            <YouthBBSTableComponent
                                tableClass={'regular-bbs-list'}
                                history={history}
                                startIndex={params.start}
                                {...props}
                            />
                            : null
                    }
                    {
                        pagingData ?
                            <PaginationComponent
                                pagingData={pagingData}
                                changePage={changePage}
                                moreView={moreView}
                            />
                            : null
                    }
                </div>
            </div>
        </div>
    );
}

export default YouthNewsListComponent;