import React from 'react';

const GreetingComponent = props => {

    return (
        <React.Fragment>
            <div className={`contents`}>
                <div className={`con-header`}>
                    <h2>소개∙인사말</h2>
                    <p>KBL 소개 페이지입니다.</p>
                </div>
                <div className={`con-box`}>
                    <div className={`con-tit`}>
                        <h4>소개</h4>
                    </div>
                    <div className={`greeting-para`}>
                        <p>
                            <div className={`font700`}>"새로운 여가선용의 기회"</div>
                            KBL은 국민에게 농구를 널리 보급하여 새로운 여가선용의 기회를 제공하는 건전한 사회 분위기를 조성해 스포츠의 진흥 및 국민 문화 발전에 이바지하고 있습니다.
                        </p>
                        <img src={`/imgs/kbl-greeting-img01.jpg`} />
                    </div>
                </div>
                <div className={`con-box`}>
                    <div className={`con-tit`}>
                        <h4>KBL 사업분야</h4>
                    </div>
                    <ul className={`business-field`}>
                        <li>
                            <i className={`ic-bf-01`} />
                            <p>농구 경기의 주최</p>
                        </li>
                        <li>
                            <i className={`ic-bf-02`} />
                            <p>농구의 국제 교류 사업</p>
                        </li>
                        <li>
                            <i className={`ic-bf-03`} />
                            <p>농구 기술에 관한<br/>조사 연구</p>
                        </li>
                        <li>
                            <i className={`ic-bf-04`} />
                            <p>농구선수/지도자 및 심판,<br/>경기원 양성과 자격 인정</p>
                        </li>
                        <li>
                            <i className={`ic-bf-05`} />
                            <p>KBL 농구 관계자들을 위한<br/>복지후생사업</p>
                        </li>
                        <li>
                            <i className={`ic-bf-06`} />
                            <p>농구 관련<br/>출판물 발간</p>
                        </li>
                        <li>
                            <i className={`ic-bf-07`} />
                            <p>언론매체를 통한<br/>농구의 보급</p>
                        </li>
                        <li>
                            <i className={`ic-bf-08`} />
                            <p>농구 경기 신설,<br/>용구 검정 및 승인</p>
                        </li>
                        <li>
                            <i className={`ic-bf-09`} />
                            <p>대한민국농구협회<br/>목적 사업에 대한 지원</p>
                        </li>
                        <li>
                            <i className={`ic-bf-10`} />
                            <p>기타 KBL의 목적 달성에<br/>필요한 사업</p>
                        </li>
                    </ul>
                </div>
                <div className={`con-box`}>
                    <div className={`con-tit`}>
                        <h4>찾아오시는 길</h4>
                    </div>
                    <div className={`map-wrap`}>
                        <div className={`map-img`}>
                            <img className={`desktop-only`} src={`/imgs/map/map-kbl.jpg`} />
                            <img className={`mobile-only`} src={`/imgs/map/map-kbl-mb.jpg`} />
                        </div>
                        <p>서울특별시 강남구 도산대로 110 케이비엘 센터 (5, 6층)</p>
                        <ul className={`transport-wrap`}>
                            <li>
                                <h6>정보</h6>
                                <ul>
                                    <li><p>전화번호</p><span>02 - 2106 - 3000</span></li>
                                    <li><p>팩스</p><span>02 - 2106 - 3091</span></li>
                                </ul>
                            </li>
                            <li>
                                <h6>지하철</h6>
                                <ul>
                                    <li><p style={{color: '#FF601C'}}>3호선</p><span>신사역 1번 출구에서 우측</span></li>
                                </ul>
                            </li>
                            <li>
                                <h6>버스</h6>
                                <ul>
                                    <li><p style={{color: '#236CD9'}}>간선(Blue)</p><span>신사역 148</span></li>
                                    <li><p style={{color: '#31B24A'}}>지선(Green)</p><span>신사역 4212, 4412, 4422</span></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GreetingComponent;