import React from 'react';

const PrivacyTermsComponent = props => {
    const { content } = props;

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>개인정보처리방침</h2>
                <p>개인정보처리방침 안내 페이지입니다.</p>
            </div>
            <div className={`con-box`}>
                <div className={`terms-wrap`}>
                    {
                        content.split('\n').map((line, idx) => (<React.Fragment key={`terms-${idx}`}>{line}<br /></React.Fragment>))
                    }
                </div>
            </div>
        </div>
    )

}
export default PrivacyTermsComponent;