import React from 'react';

const YouthMemberSearchFail = props => {
    const {setIsDisplaySearchMemberPopup} = props;
    return(
        <div className={`pop-wrap`}>
            <div className={`pop-tit`}>
                <h4>회원정보 불일치</h4>
                <i className={`ic-pop-close-24`} onClick={() => {setIsDisplaySearchMemberPopup(false)}} />
            </div>
            <div className={`pop-con`}>
                <div className={`pop-empty`}>
                    <i className={`img-empty02`} />
                    <h6>회원정보 확인이 안됩니다.</h6>
                    <p>기존 회원 정보가 일치하지 않거나 없는 회원입니다.<br/>기존 회원정보 관련 문의는 1:1 게시판으로 문의해주세요</p>
                </div>
            </div>
            <div className={`pop-btn`}>
                <button className={`light-pb`} onClick={() => {setIsDisplaySearchMemberPopup(false)}}>취소</button>
                <button className={`pb`} onClick={() => {setIsDisplaySearchMemberPopup(false)}}>확인</button>
            </div>
        </div>
    );
}

export default YouthMemberSearchFail;