import React, { useEffect, useState, useContext } from 'react';
import { BranchContext } from "../YouthClubContainer";
import TeamBtnComponent from "../TeamBtnComponent";
import YouthBranchInfoTop from "./YouthBranchInfoTop";
import YouthBranchInfoMid from "./YouthBranchInfoMid";
import YouthBranchInfoBot from "./YouthBranchInfoBot";

const YouthClubIntroComponent = props => {
    const value = useContext(BranchContext);

    const [selectedTeam, setSelectedTeam] = useState(props.selectedTeam); //팀 버튼 선택
    const [selectedTeamInfo, setSelectedTeamInfo] = useState(""); //선택된 팀의 정보 ex)DB프로미
    const [selectedBranchInfo, setSelectedBranchInfo] = useState(""); //선택된 팀의 지점 정보 ex)마포점, 합정점

    useEffect(() => { //지점명 버튼 클릭 시
        if (selectedTeam != "") {
            let teamCd = selectedTeam;
            if (parseInt(teamCd) < 10) {
                teamCd = "0" + selectedTeam;
            }
            gymFilter(teamCd);
            gymBranchFilter(teamCd);
        }
    }, [selectedTeam]);
    useEffect(() => {
    }, [selectedBranchInfo]);
    useEffect(() => {
        props.setSelectedTeam(selectedTeam);
    }, [selectedTeam]);

    function gymFilter(teamCd) {
        const result = value.teamList.filter(item => item.code == teamCd); //팀코드 검색
        setSelectedTeamInfo(result);
    }

    function gymBranchFilter(teamCd) {
        const result = value.gymList.filter(item => item.code == teamCd);
        setSelectedBranchInfo(result);
    }

    return (
        <>
            <div className={`con-box`}>
                <div className={`con-tit`}>
                    <h4>구단 선택</h4>
                </div>
                <TeamBtnComponent
                    setSelectedTeam={setSelectedTeam}
                    selectedTeam={selectedTeam}
                />
            </div>
            {
                selectedTeam.length > 0 ?
                    selectedTeamInfo.length > 0 ?
                        <>
                            <YouthBranchInfoTop
                                info={selectedTeamInfo}
                            />
                            <YouthBranchInfoMid
                                info={selectedBranchInfo}
                                history = {props.history}
                            />
                        {
                            selectedTeam == '6' ? null :
                            <YouthBranchInfoBot
                                info={selectedTeamInfo}
                            />
                        }
                        </>
                        :
                        <div className={`con-box`}>
                            <div className={`con-tit`}>
                                <h4>준비중 입니다</h4>
                            </div>
                        </div>
                    :
                    null
            }
        </>
    )

}
export default YouthClubIntroComponent;