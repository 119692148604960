import React from 'react';

const YouthClubInfoStaff = props => {
    const {info} = props;

    return (
        <div className={`con-box`}>
            <div className={`con-tit`}>
                <h4>강사진</h4>
            </div>
            <ul className={`youth-branch-teacher`}>
                {
                    info.map((item, index) => {
                        return (
                            <li key={item.staffSq.toString()}>
                                <div className={`img`} style={{backgroundImage: `url(${item.mediaPath})`}}></div>
                                <div className={`info`}>
                                    <h6>{item.staffName}&nbsp;{item.staffPosition}</h6>
                                </div>
                                <p className={`teacher-info`}>
                                    {
                                        item.info.map((it, i) =>
                                            <div key={it.careerSq.toString()}>{it.careerContent}</div>
                                        )
                                    }
                                </p>

                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
}

export default YouthClubInfoStaff;