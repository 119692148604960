import * as ApiService from '../Api.service';

export const getEbookList = async (params = {}) => {
    console.log('Story.service:getEbookList');
    const url = '/ebooks';

    return ApiService.get(url, params);
}

export const getRulePdfList = async (params = {}) => {
    console.log('Story.service:getRulePdfList');
    const url = '/story/rules';

    return ApiService.get(url, params);
}

export const getSystemPdfList = async (params = {}) => {
    console.log('Story.service:getSystemPdfList');
    const url = '/story/systems';

    return ApiService.get(url, params);
}

export const getRegulationPdfList = async (params = {}) => {
    console.log('Story.service:getRegulationPdfList');
    const url = '/story/laws';

    return ApiService.get(url, params);
}

export const updateDownloadCount = async (id ) => {
    console.log('Story.service:updateDownloadCount');
    const url = `/data/inc-media-download/${id}`;

    return ApiService.put(url);
}

