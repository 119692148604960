import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

import RegistYouthStep1Component from "./RegistYouthStep1Component";
import AlertComponent from "../../../commons/popup/AlertComponent";
import * as MemberService from "../../../../service/member/Member.service";
import moment from "moment";

const RegistYouthContainer = props => {
    const { history } = props;
    const { isLogin, userInfo } = useSelector(state => state.commonAuth);

    const [memberInfo, setMemberInfo] = useState(null);

    const [isAlertPopupDisplay, setIsAlertPopupDisplay] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const openAlertPopup = message => {
        setAlertMessage(message);
        setIsAlertPopupDisplay(true);
    }
    const alertPopupClose = e => {
        e.preventDefault();
        setAlertMessage('');
        setIsAlertPopupDisplay(false);
        history.push('/');
    }

    const setInfo = async () => {
        setMemberInfo(await MemberService.getMemberInfo());
    }

    useEffect(_ => {
        console.log('RegistYouthContainer::componentDidMount');
        if(!isLogin){ //로그인 안함 -> 로그인 페이지
            history.push('/auth/login');
        }
        if(isLogin){
            setInfo();
        }
        return function cleanup() {
            console.log('RegistYouthContainer::componentWillUnmount');
        }
    }, [])

    useEffect(() => {
        if(!userInfo) return;
        if(userInfo.isYouth){ //이미 등록함 -> 등록했다 알람 -> 뒤로가기
            openAlertPopup('이미 등록한 회원입니다.')
            return
        }

        const year = moment().format('YYYY');
        if(userInfo.birthDate && userInfo.birthDate.substring(0, 4) && year){
            const memberYear = userInfo.birthDate.substring(0, 4);
            if(year - memberYear > 20) {
                openAlertPopup('유소년 등록 가능한 연령이 아닙니다.\n' +
                    '(KBL 회원가입 시 유소년 회원정보로 가입 후\n 재 신청 부탁드립니다.)');
                return
            }
        }
    }, [userInfo]);

    return(
        <>
            <AlertComponent
                isDisplay={isAlertPopupDisplay}
                clickCloseButton={alertPopupClose}
                content={alertMessage}
            />
            {
                userInfo && !userInfo.isYouth && memberInfo && !memberInfo.isYouth ?
                    <RegistYouthStep1Component
                        {...props}
                        userInfo={memberInfo}
                    />
                    : null
            }
        </>
    );
}

export default RegistYouthContainer;