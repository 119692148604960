import React, { useEffect, useState } from 'react';
import clubData from "../../../../../json/youth/info/info-club.json";
import weekendData from "../../../../../json/youth/info/info-weekend.json";
import schoolData from "../../../../../json/youth/info/info-school.json";
import finalData from "../../../../../json/youth/info/info-final.json";
import EmptyContentComponent from "../../../../commons/table/EmptyContentComponent";

const YouthGameInfoComponent = props => {
    const { seasonKind, seasonCode } = props;
    const [gameInfo, setGameInfo] = useState(null);

    const setDataList = () => {
        if(!seasonKind || !seasonCode) return
        if(seasonKind == 1){ // 클럽대회
            setGameInfo(clubData.find(item => item.seasonCode == seasonCode));
        } else if (seasonKind == 2) { // 주말리그
            setGameInfo(weekendData.find(item => item.seasonCode == seasonCode));
        } else if (seasonKind == 3) { // 스쿨리그
            setGameInfo(schoolData.find(item => item.seasonCode == seasonCode));
        } else if (seasonKind == 4) { // 농구최강전
            setGameInfo(finalData.find(item => item.seasonCode == seasonCode));
        }
    }

    useEffect(() => {
        setDataList();
    }, [seasonCode]);

    return (
        <div className={`tab-con active`}>
            {
                gameInfo ?
                    <>
                        {
                            gameInfo.emblemImg && gameInfo.emblemImg.length > 0 ?
                                <div className={`youth-box youth-match-info`}>
                                    <div className='img' style={{ backgroundImage: `url(${gameInfo.emblemImg})`}}></div>
                                </div>
                                : null
                        }
                        {
                            gameInfo.info && gameInfo.info.length > 0 ?
                                <div className={`youth-box youth-match-info`}>
                                    <div className={`youth-tit`}>
                                        <h4>대회개요</h4>
                                    </div>
                                    <div className={`ticket-guidance`}>
                                        <ol className={`desc`}>
                                            {
                                                gameInfo.info.map((i, idx) =>
                                                    <li key={`info_${idx}`}>
                                                        <h6>{i.title}</h6>
                                                        <p>
                                                        {
                                                            i.values && i.values.length > 0 ?
                                                                i.values.map((v, vIdx) =>
                                                                    <span key={vIdx}>{v}<br/></span>
                                                                )
                                                                : null
                                                        }
                                                        {
                                                            i.sub && i.sub.length > 0 ?
                                                                i.sub.map((s, sIdx) =>
                                                                    <>
                                                                        {
                                                                            s.caption ?
                                                                                <span>{s.caption}<br/></span> : null
                                                                        }
                                                                        <table>
                                                                            <thead>
                                                                            <tr>
                                                                                {
                                                                                    s.header ?
                                                                                        s.header.map((header, hIdx) =>
                                                                                            s.mergeH ?
                                                                                            <th key={`header-${sIdx}`} colSpan={`${s.mergeH[hIdx]}`}>{header}</th>
                                                                                            : <th key={`header-${sIdx}`} >{header}</th>
                                                                                        )
                                                                                        : null
                                                                                }
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {
                                                                                s.data ?
                                                                                    s.data.map((data, idx) =>
                                                                                        <tr key={`data-${idx}`}>
                                                                                            {
                                                                                                data ?
                                                                                                    data.map((value, i) =>
                                                                                                        value != "" ?
                                                                                                            s.mergeD ?
                                                                                                            <td key={i} rowSpan={`${s.mergeD[idx][i]}`}>{value}</td>
                                                                                                            : <td key={i}>{value}</td>
                                                                                                        : null
                                                                                                    )
                                                                                                    : null
                                                                                            }
                                                                                        </tr>
                                                                                    )
                                                                                    : null
                                                                            }
                                                                            </tbody>
                                                                        </table>
                                                                        <span>{s.desc}<br/></span>
                                                                    </>
                                                                )
                                                                : null
                                                        }
                                                        </p>
                                                    </li>
                                                )
                                            }
                                        </ol>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            gameInfo.broadcastInfo ?
                                <div className={`ticket-guidance`}>
                                    <p><span className={`font-red`}>※</span>{gameInfo.broadcastInfo}</p>
                                </div>
                                : null
                        }
                        {
                            gameInfo.rule ?
                                <div className={`youth-box`}>
                                    <div className={`youth-tit`}>
                                        <h4>{gameInfo.rule.title}</h4>
                                    </div>
                                    <table>
                                        <thead>
                                        <tr>
                                            {
                                                gameInfo.rule.header ?
                                                    gameInfo.rule.header.map((header, i) =>
                                                        <th key={`header-${i}`}>{header}</th>
                                                    )
                                                    : null
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            gameInfo.rule.data ?
                                                gameInfo.rule.data.map((data, idx) =>
                                                    <tr key={`data-${idx}`}>
                                                        {
                                                            data ?
                                                                data.map((value, i) =>
                                                                    <td key={i}>{value}</td>
                                                                )
                                                                : null
                                                        }
                                                    </tr>
                                                )
                                                : null
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                : null
                        }
                        {
                            gameInfo.schedule ?
                                <div className={`youth-box`}>
                                    <div className={`youth-tit`}>
                                        <h4>{gameInfo.schedule.title}</h4>
                                    </div>
                                    <table>
                                        <thead>
                                        <tr>
                                            {
                                                gameInfo.schedule.header ?
                                                    gameInfo.schedule.header.map((header, i) =>
                                                        <th key={`header-${i}`}>{header}</th>
                                                    )
                                                    : null
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            gameInfo.schedule.data ?
                                                gameInfo.schedule.data.map((data, idx) =>
                                                    <tr key={`data-${idx}`}>
                                                        {
                                                            data ?
                                                                data.map((value, i) =>
                                                                    <td key={i}>{value}</td>
                                                                )
                                                                : null
                                                        }
                                                    </tr>
                                                )
                                                : null
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                : null
                        }
                        {
                            gameInfo.guidelines && gameInfo.guidelines.length > 0 ?
                                <div className={`youth-box`}>
                                    <div className={`youth-tit`}>
                                        <h4>대회운영 요강</h4>
                                    </div>

                                    <div className={`youth-guide`}>
                                        {
                                            gameInfo.guidelines.map((item, index) =>
                                            <div key={`guide_${index}`}>
                                                <div className={`title`}>
                                                    <h6>{item.title}</h6>
                                                </div>
                                                {
                                                    item.values && item.values.length > 0 ?
                                                        <div className={`desc`}>
                                                            {
                                                                item.values && item.values.length > 0 ?
                                                                    <p>
                                                                        {
                                                                            item.values.map((v, vIdx) =>
                                                                                <span key={vIdx}>{v}<br/></span>
                                                                            )
                                                                        }
                                                                    </p>
                                                                    : null
                                                            }
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    item.boldCont && item.boldCont.length > 0 ?
                                                        <div className={`desc`}>
                                                            <p>
                                                                <b><span key={`bold`}>{item.boldCont}</span></b>
                                                            </p>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    item.sub && item.sub.length > 0 ?
                                                        <div className={`desc`}>
                                                            {
                                                                item.sub.map((s, sIdx) =>
                                                                    <>
                                                                        <p>{s.caption}</p>
                                                                        <table key={`t_${sIdx}`}>
                                                                            <thead>
                                                                            <tr>
                                                                                {
                                                                                    s.header ?
                                                                                        s.header.map((header, i) =>
                                                                                            <th key={`header-${i}`}>{header}</th>
                                                                                        )
                                                                                        : null
                                                                                }
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {
                                                                                s.data ?
                                                                                    s.data.map((data, idx) =>
                                                                                        <tr key={`data-${idx}`}>
                                                                                            {
                                                                                                data ?
                                                                                                    data.map((value, i) =>
                                                                                                        <td key={i}>{value}</td>
                                                                                                    )
                                                                                                    : null
                                                                                            }
                                                                                        </tr>
                                                                                    )
                                                                                    : null
                                                                            }
                                                                            </tbody>
                                                                        </table>
                                                                        <p>{s.desc}</p>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    : null
                                                }
                                                {
                                                    item.subValues && item.subValues.length > 0 ?
                                                        <div className={`desc`}>
                                                            {
                                                                item.subValues && item.subValues.length > 0 ?
                                                                    <p>
                                                                        {
                                                                            item.subValues.map((v, vIdx) =>
                                                                                <span key={vIdx}>{v}<br/></span>
                                                                            )
                                                                        }
                                                                    </p>
                                                                    : null
                                                            }
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            )
                                        }
                                    </div>
                                </div>
                                : null
                        }
                    </>
                    : <EmptyContentComponent />
            }
        </div>
    )
}

export default YouthGameInfoComponent;