import React from 'react';
import NotFoundComponent from './NotFoundComponent'


const NotFoundContainer = props => {
  const { history } = props
  return (
    <NotFoundComponent history={history} />
  );
}

export default NotFoundContainer;