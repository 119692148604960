import React from 'react';
import FilterComponent from "../../../commons/filter/FilterComponent";
import YouthGameScheduleComponent from "./schedule/YouthGameScheduleComponent";
import YouthTeamRankComponent from "./rank/YouthTeamRankComponent";
import YouthPlayerRankComponent from "./rank/YouthPlayerRankComponent";
import YouthPlayerInfoComponent from "./player/YouthPlayerInfoComponent";
import YouthGameInfoComponent from "./info/YouthGameInfoComponent";
import YouthGameRecordComponent from "./record/YouthGameRecordComponent";
import {youthLeagueInfo} from "../../../../helpers/commonHelper";

const YouthGameComponent = props => {
    const { changeParams, seasonKind, seasonCode, seasonBanner, seasonList, tabList, curTab } = props;

    const onTab = e => changeParams('changeTab', e.currentTarget.dataset.key);

    return (
        <div className={`contents`}>
            <div className={`con-header`}>
                <h2>{youthLeagueInfo[seasonKind]?.title}</h2>
                <p>유소년 대회의 일정 및 기록 정보를 보실 수 있습니다.</p>
            </div>
            <ul className={`filter-wrap `}>
                <li style={{minWidth:'fit-content'}}>
                    <h6>시즌</h6>
                    <FilterComponent
                        type={'changeSeason'}
                        selectedKey={seasonCode}
                        optionClass={'team pc'}
                        selectList={seasonList}
                        selectChange={changeParams}
                    />
                </li>
            </ul>
            {/*대회 배너*/}
            {
                seasonBanner &&
                <ul className={`club-game-img-banner`}>
                    <li style={{backgroundImage: `url(${seasonBanner})`}}></li>
                </ul>
            }
            <ul className={`tab-style01`}>
                {
                    tabList.map((item) =>
                        <li key={item.key} data-key={item.key} className={`${curTab === item.key ? 'active' : ''}`} onClick={onTab}>{item.value}</li>
                    )
                }
            </ul>
            {/*일정/결과*/}
            {curTab === 'schedule' && <YouthGameScheduleComponent {...props} />}
            {/*예선 순위*/}
            {curTab === 'teamRank' && <YouthTeamRankComponent {...props} />}
            {/*개인 순위*/}
            {curTab === 'playerRank' && <YouthPlayerRankComponent {...props} />}
            {/*팀 정보(선수 정보)*/}
            {curTab === 'playerInfo' && <YouthPlayerInfoComponent {...props} />}
            {/*대회 정보*/}
            {curTab === 'gameInfo' && <YouthGameInfoComponent {...props} />}
            {/*역대 전적*/}
            {curTab === 'record' && <YouthGameRecordComponent {...props} />}
        </div>
    )
}

export default YouthGameComponent;