import React, { useState, useEffect, createContext } from 'react';
import YouthClubComponent from './YouthClubComponent'
import {getAllGymList, getAllList } from "../../../../service/youth/Branch.service";

const YouthClubContainer = props => {
    const [gymList, setGymList] = useState([]); //지점 찾기
    const [teamList, setTeamList] = useState([]); //지점 찾기

    //const [ clubList, setClubList ] = useState([]); //구단 클럽 운영 현황

    useEffect(() => {
        (async () => {
            const getGymList = await getAllList(); //모든 구단의 모든 지점명
            let gymInfo = [];
            if(getGymList && getGymList.length > 0) {
                getGymList.map(item => {
                    gymInfo.push(item);
                });
                setGymList(gymInfo);
            }
            const getTeamList = await getAllGymList(); //모든 클럽별 정보
            let teamInfo = [];
            if(getTeamList && getTeamList.length > 0) {
                getTeamList.map(item => {
                    teamInfo.push(item);
                });
                setTeamList(teamInfo);
            }
        })();
        return function cleanup() {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <BranchContext.Provider value={{ gymList, teamList }}>
            <YouthClubComponent
                {...props}
            />
        </BranchContext.Provider>
    )
}

export const BranchContext = createContext();
export default YouthClubContainer;