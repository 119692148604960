import axios from 'axios';
import { getQueryStringFromObject } from '../helpers/apiHelper'

export const get = async (url, slug = {}) => {
    console.log('Api.service:get');
    const params = getQueryStringFromObject(slug);

    return axios.get(url + params)
        .then(({ status, data }) => {
            if (status === 200) {
                return data;
            }
        }).catch(errorHandler);
}

export const post = async (url, params) => {
    console.log('Api.service:post');

    return axios.post(url, params)
        .then(({ status, data }) => {
            return { status, data };
        }).catch(errorHandler);
}

export const put = async (url, params) => {
    console.log('Api.service:put');

    return axios.put(url, params)
        .then(({ status, data }) => {
            return { status, data };
        }).catch(errorHandler);
}

export const patch = async (url, params) => {
    console.log('Api.service:put');

    return axios.patch(url, params)
        .then(({ status, data }) => {
            return { status, data };
        }).catch(errorHandler);
}

export const remove = async (url, params = {}) => {
    console.log('Api.service:remove');
    //const params = getQueryStringFromObject(slug);

    return axios.delete(url, {data: params})
        .then(({ status, data }) => {
            return { status, data };
        }).catch(errorHandler);
}

const errorHandler = ({ response }) => {
    console.log(response)
    // const { data, status } = response;
    // alert(data.message);
    // console.log(status);
}