// axios 인터 셉터
import axios from 'axios'
// import * as AuthManager from '../managers/Auth.manager';
// import cookie from 'react-cookies';
import { getAccessToken, setAccessToken, getRefreshToken, setRefreshToken } from '../helpers/authHelper';
import { getTeamCodeByDomain } from '../helpers/commonHelper';
import { getMobileAppHeaderData, setAppTokenValues } from '../helpers/apiHelper';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

// const cmsAccessKey = process.env.REACT_APP_CMS_ACCESS_KEY;

export const init = async () => {
    console.log("Axios.interceptor::init()")
    axios.interceptors.request.use((config) => {
        console.log('Axios.interceptor::request');

        const mobileAppHeaerValues = getMobileAppHeaderData();

        // 모바일 앱 헤더 처리
        if (mobileAppHeaerValues) {
            config.headers['Channel'] = mobileAppHeaerValues.channel;
            config.headers['TeamCode'] = mobileAppHeaerValues.teamCode;
            config.headers['AppVersion'] = mobileAppHeaerValues.appVersion;
            config.headers['Device'] = mobileAppHeaerValues.device;
            config.headers['lang'] = 'ko';

            const mobileAccessToken = mobileAppHeaerValues.accessToken;
            if (mobileAccessToken) {
                config.headers['Authorization'] = `Bearer ${mobileAccessToken}`;
            }

        } else {
            const accessToken = getAccessToken();
            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }
            config.headers['Channel'] = 'WEB';
            config.headers['TeamCode'] = getTeamCodeByDomain();
            config.headers['lang'] = 'ko';
        }

        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        config.headers['Access-Control-Allow-Credentials'] = true;
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers['Access-Control-Allow-Methods'] = 'GET, PUT, POST, DELETE, OPTIONS';
        config.headers['Cache-Control'] = 'no-cache';
        config.headers['Pragma'] = 'no-cache';

        return config;
    }, err => {
        console.error(err);
        Promise.reject(err);
    });

    axios.interceptors.response.use(response => {
        return response;
    },
        err => {
            const response = err.response;
            return new Promise((resolve, reject) => {
                // Do refresh Token
                if (response.status === 401 && response.data.code === -1001) {
                    console.log('do refresh token');
                    let config = response.config;
                    let headers = config.headers;

                    const mobileAppHeaerValues = getMobileAppHeaderData();

                    let refreshTokenParams = null;

                    // 모바일 앱인 경우
                    if (mobileAppHeaerValues) {
                        headers['Authorization'] = `Bearer ${mobileAppHeaerValues.refreshToken}`;
                        refreshTokenParams = { accessToken: mobileAppHeaerValues.accessToken, refreshToken: mobileAppHeaerValues.refreshToken };
                    } else {
                        headers['Authorization'] = `Bearer ${getRefreshToken()}`;
                        refreshTokenParams = { accessToken: getAccessToken(), refreshToken: getRefreshToken() };
                    }

                    const refreshRequest = axios.create({ headers });
                    return refreshRequest.post('/auth/accessToken', refreshTokenParams)
                        .then(({ status, data }) => {
                            if (status === 200) {
                                console.log('get new token')
                                const newAccessToken = data.accessToken;

                                const mobileAppHeaerValues = getMobileAppHeaderData();

                                // 모바일 앱인 경우 accessToken 갱신 성공 callBackFunc 호출
                                if (mobileAppHeaerValues) {
                                    setAppTokenValues(data)
                                    // andriod, ios 분기처리
                                    if (mobileAppHeaerValues.device.toUpperCase() === 'ANDROID') {
                                        if (window.KblAndroid && window.KblAndroid.accessTokenCallback) {
                                            window.KblAndroid.accessTokenCallback(JSON.stringify(data));
                                        }
                                    } else {

                                        if (window.webkit
                                            && window.webkit.messageHandlers
                                            && window.webkit.messageHandlers.accessTokenCallback
                                            && window.webkit.messageHandlers.accessTokenCallback.postMessage) {
                                            window.webkit.messageHandlers.accessTokenCallback.postMessage(JSON.stringify(data));
                                        }
                                    }
                                } else {
                                    setAccessToken(newAccessToken);
                                    setRefreshToken(data.refreshToken);
                                }

                                headers['Authorization'] = `Bearer ${newAccessToken}`;

                                const retryAxios = axios.create({ headers });

                                if (config.method === 'get') {
                                    return retryAxios.get(config.url)
                                } else if (config.method === 'post') {
                                    return retryAxios.post(config.url, config.data);
                                } else if (config.method === 'patch') {
                                    return retryAxios.patch(config.url, config.data);
                                } else if (config.method === 'put') {
                                    return retryAxios.put(config.url, config.data);
                                } else if (config.method === 'delete') {
                                    return retryAxios.delete(config.url, { data: config.data });
                                }
                            } else {
                                const mobileAppHeaerValues = getMobileAppHeaderData();

                                // 모바일 앱인 경우 accessToken 갱신 실패 callBackFunc 호출
                                if (mobileAppHeaerValues) {
                                    // andriod, ios 분기처리
                                    if (mobileAppHeaerValues.device.toUpperCase() === 'ANDROID') {
                                        window.KblAndroid.accessTokenErrorCallback(JSON.stringify(data));
                                    } else {
                                        window.webkit.messageHandlers.accessTokenErrorCallback.postMessage(JSON.stringify(data));
                                    }
                                }

                                resolve(status);
                                //throw new Error('알 수 없는 에러가 발생했습니다.');
                            }
                        })
                        .then(result => {
                            console.log('result', result);
                            resolve(result);
                        })
                        .catch(_ => {
                            console.log('axios response interceptor error!!!')
                            resolve(response);
                        })
                        ;
                } else {
                    console.log(err);
                    console.log(response)
                    resolve(response);
                }
            })
        })

    // axios.interceptors.response.use(response => {
    //     console.log('axios.interceptors.response.use:success', response.status);
    //     return response;
    // }, function(err){
    //     console.error(err);
    // })

    // axios.interceptors.response.use(response => {
    //     return new Promise((resolve, reject) => {
    //         console.log('axios.interceptors.response');
    //         console.log(response.data);

    //         const result = response.data;
    //         let status = result.status;
    //         console.log(status);

    //         // status = 8122;

    //         // 8101	"유효하지 않은 Token입니다"	
    //         // 8111	"유효하지 않은 Access Token입니다"	
    //         // 8112	"만료된 Access Token입니다"	
    //         // 8121	"유효하지 않은 Refresh Token입니다"	
    //         // 8122	"만료된 Refresh Token입니다"	

    //         // console.log('response.config', response.config);
    //         // Test code for expired accessToken
    //         // if (response.config.url.includes('/v1/article/updateReply')) {
    //         //     console.log('modify reply')
    //         //     status = 8112;
    //         // }
    //         // refresh AccessToken
    //         if (status == 8112) {
    //             console.log('doRefreshToken');
    //             let config = response.config;

    //             const memberId = AuthHelper.getMemberId();
    //             if (!memberId) {
    //                 AuthHelper.removeLoginInfo();
    //                 alert('정상적인 접근이 아닙니다.(refreshAccessToken');
    //                 window.location.href = '/';
    //             }
    //             let refreshTokenData = new FormData();
    //             refreshTokenData.append('user.memberId', memberId);

    //             const accessToken = AuthHelper.getAccessToken();
    //             const refreshToken = AuthHelper.getRefreshToken();
    //             let headers = config.headers;
    //             headers['Access-Token'] = accessToken;
    //             headers['Refresh-Token'] = refreshToken;

    //             console.log('headers', headers);

    //             const refreshAxios = axios.create({ headers });
    //             return refreshAxios.post('/v1/auth/refreshAccessToken', refreshTokenData)
    //                 .then(({ status, data }) => {
    //                     console.log(status);
    //                     console.log('prev accessToken', accessToken);
    //                     console.log('new accessToken', data.data);
    //                     if(status == 8112) {
    //                         throw new Error('다시 로그인 해주세요.')
    //                     }

    //                     let loginInfo = AuthHelper.getLoginInfo();
    //                     loginInfo.accessToken = data.data.accessToken;
    //                     loginInfo.refreshToken = data.data.refreshToken;
    //                     AuthHelper.setLoginInfo(loginInfo);

    //                     return loginInfo;
    //                 })
    //                 .then(loginInfo => {
    //                     console.log('try again');
    //                     let retryHeaders = config.headers;
    //                     retryHeaders['Access-Token'] = loginInfo.accessToken;
    //                     retryHeaders['Refresh-Token'] = loginInfo.refreshToken;

    //                     const retryAxios = axios.create({headers: retryHeaders});

    //                     if (config.method === 'get') {
    //                         return retryAxios.get(config.url)
    //                     }
    //                     else if(config.method === 'post') {
    //                         return retryAxios.post(config.url, config.data)
    //                     }
    //                 })
    //                 .then(response => {
    //                     console.log('try again response');
    //                     console.log(response)
    //                     return resolve(response);
    //                 })
    //                 .catch(err => {
    //                     // alert(`로그인이 필요합니다.(${status})`);
    //                     // AuthHelper.removeLoginInfo();
    //                     // window.location.href = '/login';
    //                     return resolve(response);

    //                     // original 
    //                     // console.log('after refreshAxios error');
    //                     // console.error(err);
    //                     // throw new Error(err.message);
    //                 });

    //             //     })

    //             // const token = result.data.new_token;
    //             // loginInfo['accessToken'] = token;
    //             // // cookie.save('loginInfo', loginInfo, { domain: process.env.REACT_APP_COOKIE_DOMAIN, secure: false, httpOnly: false, path: '/' });

    //             // let config = response.config;
    //             // const newAxios = axios.create({ headers: { 'Authorization': `bearer ${token}` } });

    //             // if (config.method === 'get') {
    //             //     return newAxios.get(config.url)
    //             //         .then(newResponse => {
    //             //             return resolve(newResponse);
    //             //         })
    //             // }
    //             // else {
    //             //     if (config.data != null) {
    //             //         if ('object' !== typeof config.data) {
    //             //             config.data = JSON.parse(config.data);
    //             //         }
    //             //     }

    //             //     if (config.method === 'post') {
    //             //         return newAxios.post(config.url, config.data)
    //             //             .then(newResponse => {
    //             //                 return resolve(newResponse);
    //             //             });
    //             //     }
    //             //     else if (config.method === 'put') {
    //             //         return newAxios.put(config.url, config.data)
    //             //             .then(newResponse => {
    //             //                 return resolve(newResponse);
    //             //             });
    //             //     }
    //             // }

    //         }
    //         else if (status == 1000 || status == 2016 || status == 8101 || status == 8111 || status == 8121 || status == 8122) {
    //             alert(`로그인이 필요합니다.(${status})`);
    //             // AuthHelper.removeLoginInfo();
    //             // window.location.href = '/login';
    //             return resolve(response);
    //         }
    //         else {
    //             return resolve(response);
    //         }
    //     })
    // });
}