import React, {useState} from 'react';

const MeasurementMemberPhysicalComponent = props => {
    const { physicalData, schoolGradeName } = props;

    /*const [teamData, setTeamData] = useState(null);
    const [schoolData, setSchoolData] = useState(null);
    const [personalData, setPersonalData] = useState(null);*/

    const [isMoreData, setIsMoreData] = useState(false);
    const changeIsMoreData = () => setIsMoreData(!isMoreData);

    const part1stList = [
        {key: "height",         sort: true,  round: 0,  value: "신장"},
        {key: "weight",         sort: true,  round: 0,  value: "체중"},
        {key: "body_fat_per",   sort: true,  round: 0,  value: "체지방"},
        {key: "muscle_mass",    sort: true,  round: 0,  value: "근육량"},
    ]
    const part2ndList = [
        {key: "arm_length",     sort: true,  round: 0,  value: "윙스펜"},
        {key: "standing_reach", sort: true,  round: 0,  value: "스탠딩리치"},
        {key: "grip_strength",  sort: true,  round: 0,  value: "악력"},
        {key: "pushup_cnt",     sort: true,  round: 0,  value: "1분 푸쉬업"},
        {key: "v_jump",         sort: true,  round: 0,  value: "버티컬 점프"},
        {key: "run_v_jump",     sort: true,  round: 0,  value: "맥스버티컬 점프"},
        {key: "lane_agility",   sort: false, round: 10,  value: "레인 어질리티"},
        {key: "yard_sprint",    sort: false, round: 100,  value: "10야드 스프린트"},
        {key: "court_sprint",   sort: false, round: 100,  value: "¾코트 전력질주"},
        {key: "shuttle_run",    sort: false, round: 10,  value: "300야드 셔틀런"},
    ]

    const getPhysicalDataByPart = (part, type, name, sort, idx, round) => { //항목(height, weight), 평균 종류(team, school), 메뉴이름(div명), 정렬, x축 순서, 반올림 단위
        let data = null;
        let record = 0;

        if(physicalData){
            physicalData.map((item) => item.part === part ? data = item : null);

            if(name === 'data'){
               record = type === 'team' ? data.teamValue
                        : type === 'school' ? data.schoolValue
                        : type === 'personal' ? data.personalValue : null;
            }else if(name === 'record'){
                record = type === 'team' ? (data.teamValue - data.minAvgValue) / (data.maxAvgValue - data.minAvgValue) * 100
                       : type === 'school' ? (data.schoolValue - data.minAvgValue) / (data.maxAvgValue - data.minAvgValue) * 100
                       : type === 'personal' ? (data.personalValue - data.minAvgValue) / (data.maxAvgValue - data.minAvgValue) * 100 : null;
            }else if(name === 'background'){
                let per = idx === 0 ? data.minAvgValue
                        : idx === 9 ? data.maxAvgValue
                        : data.minAvgValue + ((data.maxAvgValue - data.minAvgValue) / 10) * idx;

                record = sort ? Math.round(per) : Math.floor(per * round) / round;
            }
        }
        return isNaN(record) ? 0 : record;
    }

    return(
        <div className='physical-data'>
            {
                part1stList && physicalData ?
                    part1stList.map((item) =>
                        <div>
                            <h6>{item.value}</h6>
                            <div>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>팀 평균</th>
                                        <td>{getPhysicalDataByPart(item.key, 'team', 'data')}</td>
                                    </tr>
                                    <tr>
                                        <th>{schoolGradeName} 평균</th>
                                        <td>{getPhysicalDataByPart(item.key, 'school', 'data')}</td>
                                    </tr>
                                    <tr>
                                        <th>내 기록</th>
                                        <td>{getPhysicalDataByPart(item.key, 'personal', 'data')}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className='graph'>
                                    <div className='record'>
                                        <div className='team'>
                                            <div style={{width: `${getPhysicalDataByPart(item.key, 'team', 'record')}%`}}><p>{getPhysicalDataByPart(item.key, 'team', 'data')}</p></div>
                                        </div>
                                        <div className='school'>
                                            <div style={{width: `${getPhysicalDataByPart(item.key, 'school', 'record')}%`}}><p>{getPhysicalDataByPart(item.key, 'school', 'data')}</p></div>
                                        </div>
                                        <div className='my'>
                                            <div style={{width: `${getPhysicalDataByPart(item.key, 'personal', 'record')}%`}}><p>{getPhysicalDataByPart(item.key, 'personal', 'data')}</p></div>
                                        </div>
                                    </div>
                                    <div className='background'>
                                        <ul className='score'>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 0)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 1)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 2)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 3)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 4)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 5)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 6)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 7)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 8)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 9)}</li>
                                        </ul>
                                        <ul className='line'>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                : null
            }
            {
                part2ndList && physicalData && isMoreData ?
                    part2ndList.map((item) =>
                        <div>
                            <h6>{item.value}</h6>
                            <div>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>팀 평균</th>
                                        <td>{getPhysicalDataByPart(item.key, 'team', 'data')}</td>
                                    </tr>
                                    <tr>
                                        <th>{schoolGradeName} 평균</th>
                                        <td>{getPhysicalDataByPart(item.key, 'school', 'data')}</td>
                                    </tr>
                                    <tr>
                                        <th>내 기록</th>
                                        <td>{getPhysicalDataByPart(item.key, 'personal', 'data')}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className='graph'>
                                    <div className='record'>
                                        <div className='team'>
                                            <div style={{width: `${getPhysicalDataByPart(item.key, 'team', 'record')}%`}}><p>{getPhysicalDataByPart(item.key, 'team', 'data')}</p></div>
                                        </div>
                                        <div className='school'>
                                            <div style={{width: `${getPhysicalDataByPart(item.key, 'school', 'record')}%`}}><p>{getPhysicalDataByPart(item.key, 'school', 'data')}</p></div>
                                        </div>
                                        <div className='my'>
                                            <div style={{width: `${getPhysicalDataByPart(item.key, 'personal', 'record')}%`}}><p>{getPhysicalDataByPart(item.key, 'personal', 'data')}</p></div>
                                        </div>
                                    </div>
                                    <div className='background'>
                                        <ul className='score'>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 0, item.round)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 1, item.round)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 2, item.round)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 3, item.round)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 4, item.round)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 5, item.round)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 6, item.round)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 7, item.round)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 8, item.round)}</li>
                                            <li>{getPhysicalDataByPart(item.key, '', 'background', item.sort, 9, item.round)}</li>
                                        </ul>
                                        <ul className='line'>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    : null
            }
            <button onClick={changeIsMoreData} style={{marginBottom: '8rem'}}>{!isMoreData ? `더보기` : `접기`}<i className={!isMoreData ? `ic-arrow-down-16-gr` : `ic-arrow-up-16-gr`} /></button>

        </div>
    );
}

export default MeasurementMemberPhysicalComponent;