import React from 'react';

const YouthBranchInfoTop = (props) => {
    const { info } = props;

    return(
        <div className={`con-box`}>
            <div className={`con-tit`}>
                <h4>클럽 정보</h4>
            </div>
            <div className={`club-info-wrap`}>
                <div className={`club-emblem`}>
                    <i className={`emblem-${info[0].teamNameShort}`} />
                </div>
                <div className={`desktop-only`}>
                    <table>
                        <tbody>
                        <tr>
                            <th>클럽명</th>
                            <td>{info[0].title}</td>
                            <th>연고지</th>
                            <td>{info[0].location}</td>
                        </tr>
                        <tr>
                            <th>유소년 담당</th>
                            <td>{info[0].name}</td>
                            <th>전화</th>
                            <td>{info[0].tel}</td>
                        </tr>
                        <tr>
                            <th>홈페이지</th>
                            <td colSpan={3}><a target={'_blank'} href={info[0].homepage}>{info[0].homepage}</a></td>
                        </tr>
                        <tr>
                            <th>주소</th>
                            <td colSpan={3}>{info[0].address}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`mobile-only`}>
                    <table>
                        <tbody>
                        <tr>
                            <th>클럽명</th>
                            <td>{info[0].title}</td>
                        </tr>
                        <tr>
                            <th>연고지</th>
                            <td>{info[0].location}</td>
                        </tr>
                        <tr>
                            <th>유소년 담당</th>
                            <td>{info[0].name}</td>
                        </tr>
                        <tr>
                            <th>전화</th>
                            <td>{info[0].tel}</td>
                        </tr>
                        <tr>
                            <th>홈페이지</th>
                            <td><a target={'_blank'} href={info[0].homepage}>{info[0].homepage}</a></td>
                        </tr>
                        <tr>
                            <th>주소</th>
                            <td>{info[0].address}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default YouthBranchInfoTop;